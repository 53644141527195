<template>
  <div :class="{body: true, active: menuActive}">
    <menu-wrapper :page-name="name" />
    <div class="page-wrapper">
      <main class="content" :class="{ 'content--short': isPublicUser || isLockOrder }">
        <div class="container">
          <elk-error-page-public v-if="isPublicUser" />
          <elk-error-order-page-public v-else-if="isLockOrder" />
          <div v-else class="err-404">
            <div class="err-404__row">
              <div class="err-404__col">
                <div class="err-404__text-container">
                  <div class="err-404__body">
                    <h2 class="err-404__header">
                      {{ $t(getCommonCaptionByName('404')) }}
                    </h2>
                    <h3 class="err-404__subheader">
                      {{ $t(getCommonCaptionByName('error_smthWentWrong')) }}
                    </h3>
                    <p class="err-404__text">
                      {{ $t(getCommonCaptionByName('error_wrongPage')) }}
                    </p>
                    <div class="err-404__btns">
                      <div class="err-404__btns-item">
                        <a
                          href="/"
                          class="btn"
                        >
                          {{ $t(getCommonCaptionByName('toMainPage')) }}
                        </a>
                      </div>
                      <!-- <div class="err-404__btns-item">
                        <a href="/" class="btn btn--secondary">Назад</a>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="err-404__col">
                <div class="err-404__img">
                  <error404svg class="error404svg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <page-footer />
  </div>
</template>

<script>
import PageFooterVue from '@/components/PageFooter.vue';
import menuWrapper from '@/components/menuWrapper';
import error404svg from '@/assets/icons/error-404.svg?inline';
import {mapState, mapGetters} from 'vuex';
import {getCommonCaptionByName} from '@/i18n/utils';
import ElkErrorPagePublic from './ElkErrorPagePublic.vue';
import ElkErrorOrderPagePublic from "@/views/404/ElkErrorOrderPagePublic.vue";

export default {
  name: 'ElkErr404',
  components: {
    menuWrapper,
    PageFooter: PageFooterVue,
    error404svg,
    ElkErrorPagePublic,
    ElkErrorOrderPagePublic,
  },
  props: {
    name: String,
    isLockOrder: Boolean,
  },
  computed: {
    ...mapState(['menuActive']),
    ...mapGetters(['isPublicUser'])
  },
  methods: { getCommonCaptionByName },
};
</script>
