import store from '@/store';
import {HOME_ROUTE} from '@/router/routes';

const botEvent = new Event('toggle-widget');
const BLOCK_NAME_WIDGET_BOT = 'bot';

function hasUserWidgetBot() {
  const mainPage = store.state.user.pageTree.find(
    page => page.url === HOME_ROUTE
  );

  return mainPage?.widgets.some(
    widget => widget.block_name === BLOCK_NAME_WIDGET_BOT
  );
}

export function haveBot(path) {
  const chatbotPaths = [
    '/service/ELK_ADM_AllUsers/ELK_UD_AllUsers',
    '/service/ELK_ADM_AllUsers/ELK_PU_AllUsers',
    '/service-detail/2'
  ];
  return chatbotPaths.includes(path);
}

export function toggleBot(enabled) {
  if (!hasUserWidgetBot()) return;

  window.clientSettings.widget_enabled = enabled;

  window.dispatchEvent(botEvent);
}
