<template>
    <div class="selection-points-box" v-show="option.length">
        <slot></slot> 
    </div>
</template>

<script>
export default {
    props: {
            option: Array,
        },
    data(){ 
        return{
            name: 'SelectionPointBox'
        }
    }
}
</script>
<style lang="scss">
.selection-points-box{
    position: relative;
    display: flex;
    flex-wrap: wrap; 
    transition: all 500ms ease; 
    overflow: hidden;
    .selection-points{
        margin-right: 8px;
        margin-bottom: 8px;
    }
}
@media screen and (max-width: 768px){
    .selection-points-box {
        display: block;
        .selection-points{
            margin-right: 0;
        }
    }
        
    }
</style>