<template>
    <div class="vi-drop-down-list" :class="{'vi-drop-down-list__active': active}">
        <button type="button" class="vi-drop-down-list__btn" @click="toggleDDList"> 
            {{ priceRungElemName }} <span v-if="priceRungSeleectedParameters != 0"> ({{ priceRungSeleectedParameters }})</span>
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 5H7V0H5V5H0V7H5V12H7V7H12V5Z" fill="#6B7A99"/>
            </svg> 
        </button>
        <div class="vi-drop-down-list__window">
            <slot name="inner"></slot>
        </div>
    </div>
</template>

<script>

export default{
    props: {
        priceRungElemName: String,
        priceRungSeleectedParameters: Number
    },
    name: 'ViDropDownList',
    data(){
        return{
            active: false,
        }
    },
    methods:{
        toggleDDList(){
            this.active = !this.active; 
        },
   
    },
    mounted(){
            document.addEventListener('click', (e)=>{
                if(!e.target.closest('.vi-drop-down-list') || e.target.classList.contains('ddl-box__btn') ){
                    this.active = false; 
                }  
            });
    }
}
</script>
<style lang="scss">
    .vi-drop-down-list{
        position: relative;  
        z-index: 10;
        .vi-drop-down-list__btn{
            position: relative;
            display: flex;
            align-items: center;
            border: 1px solid #D3D8E6; 
            border-radius: 4px;
            padding: 14px 32px 14px 16px;
            width: 100%;
            height: 48px; 
            background-color: #ffffff; 
            font-family: inherit;
            font-size: 16px;
            font-weight: 400;
            line-height: 1;
            color: #6B7A99; 
            cursor: pointer;
            box-sizing: border-box;
            span{
                margin-left: 4px;
            }
            svg{
                position: absolute;
                top: 48%;
                right: 18px; 
                transform: translateY(-50%) rotate(0);
                transition: all 200ms ease-in-out;
            }
        }
        .vi-drop-down-list__window{
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            padding: 16px 16px;
            background-color: #ffffff;  
            border-left: 1px solid #e8e8e8;
            border-bottom: 1px solid #e8e8e8;
            border-right: 1px solid #e8e8e8;
            box-sizing: border-box;
            transition: all 100ms ease;
            border-bottom-right-radius: 5px;
            border-bottom-left-radius: 5px; 
            visibility: hidden;
            opacity: 0;
            transform: scaleY(0);
            transform-origin: center top;
        }
        &__active{
            .vi-drop-down-list__btn{
                border-bottom-right-radius: 0;
                border-bottom-left-radius: 0;
                svg{
                    transform: translateY(-50%) rotate(45deg);
                }
            }
            .vi-drop-down-list__window{ 
                visibility: visible;
                opacity: 1;
                transform: scaleY(1); 
            }            
        }
        .el-slider__bar{
            background-color: #0050CF;
        }
        .el-slider__button{
            border: 2px solid #0050CF;
        }
    }
</style>