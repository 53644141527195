<template>
  <div class="lang">
    <loading v-if="isLoading" class="loader" />
    <template v-else>
      <component :is="iconLoader(locale_id)" />
      <select
        @click.stop
        v-model="locale_id"
      >
        <option :value="1">Ру</option>
        <option :value="3">En</option>
      </select>
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { SET_LOCALE as SET_LOCALE_ACTION } from '@/store/actionsName';

import iconLoader from '@/utils/iconLoader';

export default {
  name: 'locale-changer',
  components: {
    loading: () =>
      import(
        /* webpackChunkName: "loading-component" */ '@/components/common/elkLoading'
      ),
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    locale_id: {
      get() {
        return this.$store.state.locale.localeId;
      },
      async set(value) {
        this.isLoading = true;
        await this.setLocale(value);
        this.isLoading = false;
      },
    },
  },
  methods: {
    ...mapActions('locale', { setLocale: SET_LOCALE_ACTION }),
    iconLoader,
  },
};
</script>
