<template>
  <div :class="{ body: true, active: menuActive }">
    <menu-wrapper :page-name="name" />
    <div class="page-wrapper">
      <main
        class="content"
        :class="pageClass"
      >
        <section class="questions-answers-section">
          <div class="container">
            <h1 class="page-title">
              {{ name }}
            </h1>
            <search-form
              v-model="searchValue"
              :no-form="true"
              :field-placeholder="'Поиск по вопросам и ответам ...'"
              @input="onSearch"
              @clearField="onClearSearch"
            />
            <Loading v-if="isLoading && !itemsQA.length" />
            <quan-grid v-else-if="itemsQA.length">
              <quan-grid-item
                v-for="item in itemsQA"
                :key="item.qgiId"
                :qgi-id="item.qgiId"
                :qgi-caption="item.qgiCaption"
                :qgi-text="item.qgiText"
                :qgi-link="item.link"
              />
            </quan-grid>
            <EmptySearchResultMessage v-else />
          </div>
        </section>
      </main>
    </div>
    <page-footer />
  </div>
</template>

<script>
    import Loading from '@/components/common/elkLoading';
    import menuWrapper from "@/components/menuWrapper";
    import PageFooter from "@/components/PageFooter";
    import EmptySearchResultMessage from '@/components/common/elkEmptySearchResultMessage';
    import SearchForm from "@/components/forms/SearchForm.vue" // форма поиска
    import QuanGrid from "@/components/questions-answers/QuanGrid.vue" //контейнера для вопросов
    import QuanGridItem from "@/components/questions-answers/QuanGridItem.vue" //контейнера для вопросов
    import { debounce } from '@/utils/debounce';
    import { mapState, mapGetters } from 'vuex';
    import { QUESTIONS_AND_ANSWERS_DETAILED } from '@/router/routes'
    import Axios from '@/utils/Elk-Axios';
    import { getFaq } from "@/api";

    export default {
        name: 'QuestionsAndAnswers',
        components: {
            Loading,
            menuWrapper,
            PageFooter,
            EmptySearchResultMessage,
            'search-form': SearchForm,
            'quan-grid': QuanGrid,
            'quan-grid-item': QuanGridItem,
        },
        data(){
            return{
                pageClass: 'questions-and-answers',
                name: 'Вопросы и ответы',
                searchValue: '',
                searchOldValue: '',
                rootItems: [],
                itemsQA: [],
                isLoading: false,
            }
        },
        computed: {
            ...mapState(['menuActive']),
            ...mapGetters(['isPublicUser']),
            searchQueryValue() {
              return this.$route.query['q'] ?? ''
            }
        },
        watch: {
          isLoading() {
            if (!this.isLoading && this.searchOldValue !== this.searchValue) {
              this.onSearch(this.searchValue)
            }
          }
        },
        mounted() {
          this.searchValue = this.searchQueryValue
          this.fetchFaq().then((r) => {
            this.rootItems = r
            this.refreshItems(this.rootItems)
          })
        },
        methods: {
            getQuery() {
              let query = ''
              if (this.searchValue) {
                query += `?q=${this.searchValue}`
              }

              return query
            },
            setQuery() {
              this.$router.push({
                path: `${this.$route.path}${this.getQuery()}`,
              })
            },
            refreshItems(data) {
              this.itemsQA = data
            },
            mapItems(items) {
              if (items.length) {
                return items.map((item) => ({
                  qgiId: item.qgiId ?? 0,
                  qgiCaption: item.qgiCaption ?? '',
                  qgiText: item.qgiText ?? '',
                  tags: items.tags ?? [],
                  link: item.link ?? `${QUESTIONS_AND_ANSWERS_DETAILED}/${item.url ?? ''}`
                }))
              }
              return []
            },
            mapFaqResponse(data) {
              if (data?.category?.length) {
                return data.category.map((item) => ({
                  qgiId: item.id ?? 0,
                  qgiCaption: item.title ?? '',
                  qgiText: item.description ?? '',
                  tags: [],
                  link: `${QUESTIONS_AND_ANSWERS_DETAILED}/${item.url ?? ''}`,
                }))
              }
            },
            async fetchFaq () {
              this.isLoading = true
              try {
                const response = (await Axios.get(`${getFaq}`))?.data
                return this.mapFaqResponse(response)
              } catch {
                return Promise.resolve([])
              } finally {
                this.isLoading = false
              }
            },
            filterItems(query = ''){
              return this.mapItems(this.rootItems.filter((v) => {
                const caption = v.qgiCaption.toLocaleLowerCase()
                return caption.includes(query.toLocaleLowerCase())
              }));
            },
            onSearch: debounce(async function (searchText){
              if (this.isLoading) {
                return
              }
              this.searchOldValue = searchText

              this.setQuery()

              const result = this.filterItems(searchText)
              this.refreshItems(result)

            }, 350),
            onClearSearch() {
              this.searchValue = ''
              this.onSearch('')
            },
        },
    }
</script>
<style lang="scss">
    .questions-and-answers{
        .quan-grid{
            margin-top: 24px;

        }
        .info-noty {
          margin-top: 24px;
        }
    }
</style>
