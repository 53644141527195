<template>
  <el-dialog
    :append-to-body="true"
    :visible.sync="visible"
    custom-class="max-w-[450px] rounded-xl mx-4 calendar-sync-dialog"
    title=""
    width="100%"
  >
    <template slot="title">
      <h4 class="font-semibold text-3xl text-center">
        <div v-if="$i18n.locale === 'ru'">
          Экспорт расписания
        </div>
        <div v-else>

        </div>
      </h4>
    </template>
    <template>
      <div class="text-lg break-normal calendar-sync-dialog__body">
        <div v-if="$i18n.locale === 'ru'">
          <div v-if="this.type === 'google'">
            <div class="mt-1.5">
              Откройте настройки <a href="https://calendar.google.com/calendar/u/0/r/settings/addbyurl" target="_blank">google календаря.</a> <br>
            </div>
            <div class="mt-1.5">
              Сделайте импорт календаря через опцию "Добавить по URL"
            </div>
          </div>
          <div v-else>
            <div class="mt-1.5">
              Создайте новый календарь <a href="https://calendar.yandex.ru/month" target="_blank"> в Яндекс.Календаре.</a> <br>
            </div>
            <div class="mt-1.5">
              Выберете "Импорт", далее "По ссылке"
            </div>
          </div>
          <div class="mt-1.5">
            или <a :href="this.input">скачайте календарь</a> в формате iCal
          </div>

        </div>
        <div v-else>

        </div>
      </div>
    </template>
    <template>
      <div class="flex mt-4 calendar-sync-dialog__input-container">
        <el-input v-model="input" class="calendar-sync-dialog__input" />
      </div>
      <div class="calendar-sync-dialog__btn-container">
        <button class="el-button el-button--primary el-button--small" @click="clipboard">{{ btn }}</button>
      </div>

    </template>


  </el-dialog>
</template>

<script>
import { getCommonCaptionByName } from '@/i18n/utils';
import Axios from '@/utils/Elk-Axios';
import { GET_SCHEDULE_EXPORT_ID } from '@/api';

export default {
  data() {
    return {
      visible: false,
      export_id: '',
      input: '',
      btn: 'Скопировать',
      type: 'yandex',
    };
  },
  methods: {
    clipboard() {
      navigator.clipboard.writeText(this.input)
        .then(() => {
          this.btn = '✓ готово!';
        })

    },
    getCommonCaptionByName,
    async get_export_id() {
      const {data} = await Axios.get(GET_SCHEDULE_EXPORT_ID);
      if (data.code === 200) {
        this.input =  data.data[0];
      }
    },

    show(type) {
      this.type = type;
      this.visible = true;
      this.get_export_id();
    },
  },
};
</script>

<style>
.calendar-sync-dialog__btn-container {
  margin-top: 30px;
}
.calendar-sync-dialog__btn-container .el-button,
.calendar-sync-dialog__input .el-input__inner {
  height: 44px;
}
</style>
