import Axios from '@/utils/Elk-Axios';
import {REFERENCES_LANGUAGES} from '@/api';

export default {
  namespaced: true,
  state: {
    /** @type {Reference[]} */
    items: []
  },
  getters: {
    /** @returns {Reference[]} */
    items: state => () => state.items
  },
  mutations: {
    set(state, items) {
      state.items = items ?? [];
    }
  },
  actions: {
    async fetch({commit}) {
      const response = await Axios.get(REFERENCES_LANGUAGES);
      commit('set', response?.data?.data ?? []);
    }
  }
};
