import { SET_SIMPLE_DICTIONARY } from '@/store/mutationsName';
import { GET_DICTIONARY } from '@/store/gettersName';
import { GET_SIMPLE_DICTIONARIES } from '@/store/gettersName';

export default {
  namespaced: true,
  state: {
    simpleDictionaries: {
      countries: null,
    },
  },
  getters: {
    [GET_DICTIONARY]: (state) => (value) => {
      return state.simpleDictionaries[value];
    },
    [GET_SIMPLE_DICTIONARIES]: (state) => () => {
      return state.simpleDictionaries;
    },
  },

  mutations: {
    [SET_SIMPLE_DICTIONARY]: (state, { nameDictionary, valueDictionary }) => {
      state.simpleDictionaries[nameDictionary] = valueDictionary;
    },
  },
};
