<template>
  <el-tooltip
    placement="right"
    effect="light"
    popper-class="custom-tooltip"
  >
    <div
      slot="content"
      style="max-width: 680px"
    >
      <div>
        <h6 class="tooltip-header">{{ localeText.inPersonTitleInfo }}</h6>
        <span class="tooltip-text">
          {{ localeText.inPersonInfo }}
        </span>
      </div>
      <div>
        <h6 class="tooltip-header">{{ localeText.mixedTitleInfo }}</h6>
        <span class="tooltip-text">
          {{ localeText.mixedInfo }}
        </span>
      </div>
      <div>
        <h6 class="tooltip-header">
          {{ localeText.onlineAsynchronouslyTitleInfo }}
        </h6>
        <span class="tooltip-text">{{
          localeText.onlineAsynchronouslyInfo
        }}</span>
      </div>
      <div>
        <h6 class="tooltip-header">
          {{ localeText.onlineSynchronouslyTitleInfo }}
        </h6>
        <span class="tooltip-text">
          {{ localeText.onlineSynchronouslyInfo }}
        </span>
      </div>
    </div>
    <el-button />
  </el-tooltip>
</template>

<script>
import localization from '@/i18n/localization';

export default {
  name: 'TooltipEduFormat',
  data() {
    return {
      localization: localization('dpo.programs.comparison')
    };
  },
  computed: {
    localeText() {
      return {
        inPersonTitleInfo: this.localization.widgetCaption('inPersonTitleInfo'),
        inPersonInfo: this.localization.widgetCaption('inPersonInfo'),
        mixedTitleInfo: this.localization.widgetCaption('mixedTitleInfo'),
        mixedInfo: this.localization.widgetCaption('mixedInfo'),
        onlineAsynchronouslyTitleInfo: this.localization.widgetCaption(
          'onlineAsynchronouslyTitleInfo'
        ),
        onlineAsynchronouslyInfo: this.localization.widgetCaption(
          'onlineAsynchronouslyInfo'
        ),
        onlineSynchronouslyTitleInfo: this.localization.widgetCaption(
          'onlineSynchronouslyTitleInfo'
        ),
        onlineSynchronouslyInfo: this.localization.widgetCaption(
          'onlineSynchronouslyInfo'
        )
      };
    }
  }
};
</script>

<style>
.tooltip-header {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
}

.tooltip-text {
  display: block;
  font-size: 14px;
  line-height: 20px;
  color: #6b7a99;
  margin-bottom: 15px;
}
</style>
