export const formatNumberAsCurrencyString = (
  number,
  locales = 'ru-RU',
  options = { 
    maximumFractionDigits: 0, /* цифр после запятой */
    style: 'currency',
    currency: 'RUB',
}) => {
  if (!(number % 1 === 0) /* проверка на число */) return '';

  /** https://caniuse.com/?search=NumberFormat - 96% */
  return new Intl.NumberFormat(locales, options).format(number);
}
