<template>
  <div>
    <p class="profile__table-title">
      {{
        $t(
          getCommonCaptionByName(
            `${isAdm ? 'contacts_personalEmail' : 'contacts_additionalEmail'}`
          )
        )
      }}
    </p>
    <div
      v-show="!edit"
      class="profile__table-info"
    >
      <div class="input-profile-contact">
        <div class="input-profile-contact__value">
          <a
            v-if="personalEmail"
            :href="`mailto:${personalEmail}`"
            class="link"
          >
            {{ personalEmail }}
          </a>
          <span v-else>
            {{ $t(getCommonCaptionByName('isNotIndicated')) }}
          </span>
        </div>
        <div class="input-profile-contact__btn">
          <btn-show
            :btn-visibility="btnVisibility"
            :icon-visibility="showEmail"
            :title-btn="titleBtn"
            :loader-btn="loader"
            @edit-show="editShow"
          />
          <button
            class="btn btn--secondary btn--icon"
            @click="startEdit"
          >
            <edit-icon class="edit-icon" />
          </button>
        </div>
      </div>
    </div>
    <form
      v-show="edit"
      class="input-profile-contact !items-start"
      @submit.prevent="changeStep(step, true)"
    >
      <template v-if="step === REGISTRATION_STEPS.GET_PERSONAL_DATA">
        <div class="input-profile-contact__value">
          <input
            id="new-email"
            ref="input"
            v-model.trim="v$.email.$model"
            type="email"
            name="new-email"
            placeholder="example@hse.ru"
            class="input input--new-value"
            @keyup="closeEdit"
          />
          <div
            v-if="v$.email.maxLength.$invalid"
            class="error-message"
          >
            <span>{{ textChunks.maxLengthEmail }}</span>
          </div>
          <div
            v-if="v$.email.emailValidate.$invalid"
            class="error-message"
          >
            <span>{{ textChunks.insertCorrectEmail }}</span>
          </div>
          <div
            v-else-if="
              !isEmailAvailable &&
              isEmailAvailable != null &&
              !v$.email.emailValidate.$invalid
            "
            class="error-message"
          >
            <span v-if="isEmailDisposable">
              {{ textChunks.errorTempMail }}
            </span>
            <span v-else-if="!isEmailDomainAvailable">
              {{ textChunks.domainNotAvailable }}
            </span>
            <span v-else>
              {{ textChunks.emailError }}
            </span>
          </div>
        </div>
        <div class="input-profile-contact__btn">
          <button
            type="submit"
            class="btn btn--secondary btn--icon"
          >
            <checkmark class="edit-icon" />
          </button>
        </div>
        <div class="input-profile-contact__btn">
          <button
            class="btn btn--secondary"
            @click.prevent="closeEdit"
          >
            {{ $t(getCommonCaptionByName('cancel')) }}
          </button>
        </div>
      </template>
      <template v-if="step === REGISTRATION_STEPS.CONFIRM_EMAIL">
        <div class="confirm__block mt-1.5 w-full">
          <p class="title-register__info">
            {{ textChunks.onEmail }} <span>{{ email }}</span>
            {{ textChunks.mailSend }}
          </p>
          <confirm-message
            :label="textChunks.emailCode"
            :error-text="textChunks.codeError"
            :re-send-text="textChunks.resendCode"
            :timer-text="textChunks.timerText"
            :time-remaining="300"
            :value="v$.emailCode"
            type="number"
            :preloader="false"
            @on-reset-timer="sendEmailConfirmationCode"
          />
        </div>
        <div class="btn-block__form w-full">
          <button
            class="btn btn-checkout"
            type="submit"
            :disabled="v$.email.$invalid || btnStepPreloaderShow"
            @click.prevent="changeStep(step, true)"
          >
            <loading
              v-if="btnStepPreloaderShow"
              color="white"
              small
            />
            <template v-else>
              {{ button.next }}
            </template>
          </button>
          <button
            class="btn btn-checkout-phone"
            type="submit"
            :disabled="false"
            @click.prevent="changeStep(step)"
          >
            {{ button.back }}
          </button>
        </div>
      </template>
    </form>
  </div>
</template>

<script setup>
import Axios from '@/utils/Elk-Axios';
import EditIcon from '@/assets/icons/edit.svg?inline';
import Checkmark from '@/assets/icons/check-mark.svg?inline';
import setNotification from '@/utils/setNotification';
import btnShow from '../btnShow';
import loading from '@/components/common/elkLoading.vue';
import {
  CHECK_EMAIL_CODE,
  SEND_EMAIL_CODE,
  SET_EMAIL_PERSONAL,
  SET_EMAIL_VISIBLE
} from '@/api';
import {getUiItemCaption, getCommonCaptionByName} from '@/i18n/utils';
import {computed, ref, watch, inject} from 'vue';
import {useI18n} from 'vue-i18n-composable';
import {useVuelidate} from '@vuelidate/core';
import {
  required,
  email as emailValidate,
  maxLength,
  minLength
} from 'vuelidate/lib/validators';
import {isInternalEmployeeEmail, isInternalStudentEmail} from '@/utils/regExps';
import {checkUserByEmail} from '@/components/widgets/elkRegistration/api';
import DaDataAPI from '@/api/dadata/dadata';
import ConfirmMessage from '@/components/confMessage/ConfirmMessage.vue';
import {useRoute} from 'vue-router/composables';

const {t} = useI18n();
const signature = inject('signature');

const props = defineProps({
  personalEmail: {
    type: String,
    default: ''
  },
  showEmail: Boolean,
  isAdm: Boolean
});

const emit = defineEmits(['update-email-show', 'update-info']);
const route = useRoute();

const REGISTRATION_STEPS = {
  RECAPTCHA_ERROR: 'recaptcha_error',
  GET_PERSONAL_DATA: 'get_personal_data',
  CONFIRM_EMAIL: 'confirm_email_and_phone',
  SUCCESS: 'success'
};

const btnStepPreloaderShow = ref(false);
const step = ref(REGISTRATION_STEPS.GET_PERSONAL_DATA);
const isEmailAvailable = ref(true);
const isEmailDisposable = ref(false);
const isEmailDomainAvailable = ref(null);
const input = ref(null);
const edit = ref(false);
const email = ref(props.personalEmail);
const emailCode = ref('');
const loader = ref(false);
const titleBtnOff = ref(
  t(getUiItemCaption(signature, 'turnOffVisibilityOfPersonalEmail'))
);

const titleBtnOn = ref(
  t(getUiItemCaption(signature, 'turnOnVisibilityOfPersonalEmail'))
);

const alreadyExistEmails = ref([]);

const btnVisibility = computed(() => !!email.value);
const titleBtn = computed(() =>
  props.showEmail ? titleBtnOff.value : titleBtnOn.value
);

const button = computed(() => {
  return {
    back: t(getCommonCaptionByName('back')),
    next: t(getCommonCaptionByName('next'))
  };
});

const textChunks = computed(() => {
  return {
    errorTempMail: t(getUiItemCaption(signature, 'errorTempMail')),
    maxLengthEmail: t(getUiItemCaption(signature, 'maxLengthEmail')),
    domainNotAvailable: t(getUiItemCaption(signature, 'DomainNotAvailable')),
    emailError: t(getUiItemCaption(signature, 'EmailError')),
    insertCorrectEmail: t(getUiItemCaption(signature, 'insertCorrectEmail')),
    onEmail: t(getUiItemCaption(signature, 'OnEmail')),
    mailSend: t(getUiItemCaption(signature, 'MailSend')),
    emailCode: t(getUiItemCaption(signature, 'EmailCode')),
    codeError: t(getUiItemCaption(signature, 'CodeError')),
    resendCode: t(getUiItemCaption(signature, 'ResendCode')),
    timerText: t(getUiItemCaption(signature, 'TimerText')),
    sendingRequestError: t(getCommonCaptionByName('sendingRequestError'))
  };
});

function startEdit() {
  edit.value = true;
  setTimeout(() => {
    input.value?.focus();
  }, 0);
}

function closeEdit(e) {
  if (e.keyCode === 27 || e.type === 'click') {
    email.value = props.personalEmail;
    edit.value = false;
  }
}

async function editEmail() {
  try {
    if (email.value === '') {
      await Axios.post(SET_EMAIL_VISIBLE, {
        is_email_personal_show: false
      });
      emit('update-email-show', {value: false});
    }
    let res = await Axios.post(SET_EMAIL_PERSONAL, {
      email_personal: email.value
    });
    if (res.status === 200) {
      emit('update-info', {type: 'email', value: email.value});
      edit.value = false;
    }
    setNotification({
      message: `Информация сохранена`,
      type: 'success'
    });
  } catch (err) {
    if (!err.isHandled) {
      setNotification({
        message: t(getUiItemCaption(signature, 'failSettingNewEmail'))
      });
    }
  }
}

async function editShow() {
  loader.value = true;
  try {
    const value = {is_email_personal_show: !props.showEmail};
    let res = await Axios.post(SET_EMAIL_VISIBLE, value);
    if (res.status === 200) {
      emit('update-email-show', {value: !props.showEmail});
    }
  } catch (err) {
    if (!err.isHandled) {
      setNotification({
        message: t(getUiItemCaption(signature, 'failSettingVisibialityOfEmail'))
      });
    }
  } finally {
    loader.value = false;
  }
}

function changeStep(currentStep, forward = false) {
  switch (true) {
    case currentStep === REGISTRATION_STEPS.GET_PERSONAL_DATA:
      if (
        !v$.value.email.emailValidate.$invalid &&
        isEmailDomainAvailable.value &&
        forward
      ) {
        gotoConfirmation();
      }
      break;
    case currentStep === REGISTRATION_STEPS.CONFIRM_EMAIL:
      if (forward) {
        gotoFinish();
      } else {
        step.value = REGISTRATION_STEPS.GET_PERSONAL_DATA;
      }
      break;
    default:
      break;
  }
}

async function gotoConfirmation() {
  if (!v$.value.$invalid && !isEmailDisposable.value) {
    await sendEmailConfirmationCode();
  }
}

async function gotoFinish() {
  btnStepPreloaderShow.value = true;
  let message = '';

  const postData = {
    email: email.value,
    email_code: emailCode.value
  };
  try {
    const {data} = await Axios.post(CHECK_EMAIL_CODE, postData);

    if (data.result === 'success') {
      gotoSuccess();
    } else if (data.result === 'wrong code') {
      message = textChunks.value.codeError;
    }
  } catch (e) {
    message = textChunks.value.sendingRequestError;
  } finally {
    btnStepPreloaderShow.value = false;
    if (message) {
      setNotification({
        message
      });
    }
  }
}

async function gotoSuccess() {
  step.value = REGISTRATION_STEPS.GET_PERSONAL_DATA;

  await editEmail();
  email.value = '';
  emailCode.value = '';
  const returnUrl = route.query?.returnurl;

  if (returnUrl?.length) {
    return location.assign(returnUrl);
  }
}

async function sendEmailConfirmationCode() {
  btnStepPreloaderShow.value = true;

  try {
    const res = await Axios.post(SEND_EMAIL_CODE, {email: email.value});
    if (res.status === 200) {
      step.value = REGISTRATION_STEPS.CONFIRM_EMAIL;
    }
  } catch (e) {
    setNotification({
      message: textChunks.value.sendingRequestError
    });
  } finally {
    btnStepPreloaderShow.value = false;
  }
}

const rules = {
  email: {
    required,
    emailValidate,
    maxLength: maxLength(50),
    isNotInternal: email =>
      !isInternalEmployeeEmail(email) && !isInternalStudentEmail(email)
  },
  emailCode: {
    maxLength: maxLength(4),
    minLength: minLength(4)
  }
};

const v$ = useVuelidate(rules, {email, emailCode});

const updateEmailAvailability = async () => {
  if (
    email.value &&
    v$.value.email.emailValidate.$invalid === false &&
    v$.value.email.$anyDirty
  ) {
    try {
      if (await checkUserByEmail(email.value)) {
        alreadyExistEmails.value.push(email.value);
      } else {
        const {data: emailInfo} = await DaDataAPI.getEmailInfo({
          email: email.value
        });

        isEmailDisposable.value = emailInfo?.e_type === 'DISPOSABLE';
        isEmailDomainAvailable.value = email.value.endsWith('.ru');

        if (localStorage.getItem('allowDisposableEmail'))
          isEmailDisposable.value = false;
        if (localStorage.getItem('allowAllDomainEmail'))
          isEmailDomainAvailable.value = true;
      }
    } catch (e) {
      throw new Error(e);
    }

    const isExist = alreadyExistEmails.value.includes(email.value);

    return !isExist && !isEmailDisposable.value && isEmailDomainAvailable.value;
  }
};

watch(email, async () => {
  isEmailAvailable.value = await updateEmailAvailability();
});
</script>

<script>
export default {
  name: 'PersonalEmail'
};
</script>
<style lang="scss">
// некоторые стили тут: /var/www/frontend/elk_frontend/src/assets/styles/components/profile.scss
</style>
