<template>
  <div class="no-overflow">
    <loading
      v-if="isLoadAvatar"
      class="no-overflow"
    />
    <template v-else>
      <div class="relative group">
        <avatar
          :url="userData.avatar"
          :gender-id="userData.gender_code"
          :fio-separate="[userData.first_name, userData.last_name]"
          :size="$store.state.width < 1024 ? '64' : '164'"
        />
        <a
          v-if="userData.avatar"
          class="profile-avatar__delete absolute top-0 leading-none right-0 z-10 transition transition-opacity shadow rounded cursor-pointer opacity-0 group-hover:opacity-100 p-1 bg-white"
          :class="{'!opacity-100': isTouchDevice}"
          @click.prevent="dialogRemoveAvatar = true"
        >
          <i class="el-icon-circle-close text-2xl text-red drop-shadow" />
        </a>
      </div>

      <label class="btn min-w-fit !px-1">
        <span>Новое фото</span>
        <input
          :style="{display: 'none'}"
          type="file"
          name="image"
          accept="image/png,image/jpg,image/jpeg"
          @change="setAvatar"
        />
      </label>

      <el-button
        v-if="$store.state.width >= 1024 && userData.is_adm"
        class="btn"
        @click="showQR"
      >
        {{ $t(getUiItemCaption(signature, 'AddContact')) }}
      </el-button>
    </template>
    <avatar-editor
      ref="avatarEditorProfile"
      :is-avatar-editor-visible="isAvatarEditorVisible"
      :img-src="avatarSrc"
      @hide-dialog="hideDialog"
      @save-avatar="saveAvatar"
    />

    <el-dialog
      :title="$t(getUiItemCaption(signature, 'RemoveAvatarTitle'))"
      :visible.sync="dialogRemoveAvatar"
      :show-close="false"
      class="list-wrapper-document__dialog"
      top="15vh"
    >
      {{ $t(getUiItemCaption(signature, 'RemoveAvatarConfirm')) }}
      <div class="flex justify-end mt-8">
        <el-button
          class="btn btn--blue"
          @click="dialogRemoveAvatar = false"
        >
          {{ $t(getCommonCaptionByName('booleanValueNo')) }}
        </el-button>
        <el-button
          class="btn btn--danger"
          type="danger"
          :loading="isRemovingAvatar"
          @click="removeAvatarAction"
        >
          {{ $t(getCommonCaptionByName('booleanValueYes')) }}
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script setup>
import avatar from '@/components/common/elkAvatar';
import avatarEditor from '@/components/common/elkAvatarEditor';
import setNotification from '@/utils/setNotification';
import Loading from '@/components/common/elkLoading';
import {ref, nextTick, computed, inject, getCurrentInstance} from 'vue';
import {getCommonCaptionByName, getUiItemCaption} from '@/i18n/utils';
import {apiTask} from '@/api/taskRequest';
import {useI18n} from 'vue-i18n-composable';
import store from '@/store';

const props = defineProps(['userData', 'isLoadAvatar', 'signature']);
const emit = defineEmits(['update-user-avatar', 'show-qr']);
const clearAvatar = inject('clearAvatar');
const instance = getCurrentInstance();
const {t} = useI18n();

const isAvatarEditorVisible = ref(false);
const avatarSrc = ref(null);
const avatarEditorProfile = ref(null);
const dialogRemoveAvatar = ref(false);

const removeAvatarTask = apiTask();
const isRemovingAvatar = computed(() => {
  return removeAvatarTask.isRunning.value;
});

const hideDialog = () => {
  isAvatarEditorVisible.value = false;
  setTimeout(() => {
    avatarSrc.value = null;
  }, 0);
};

const setAvatar = e => {
  const file = e.target.files[0];
  const maxFileSize = 2097152;

  if (file.size < maxFileSize) {
    const reader = new FileReader();

    reader.onload = event => {
      const imgUrl = event.target.result;

      const image = new Image();
      image.src = imgUrl;

      image.onerror = function () {
        setNotification({message: 'Не удалось загрузить изображение'});
      };

      image.onload = function () {
        const height = this.height;
        const width = this.width;

        const isSmallResolution = height < 380 || width < 380;
        const isLargeResolution = height > 3000 || width > 3000;

        if (isSmallResolution) {
          setNotification({
            message: 'Минимальное разрешение изображения — 380х380'
          });
        } else if (isLargeResolution) {
          setNotification({
            message: 'Максимальное разрешение изображения — 3000х3000'
          });
        } else {
          isAvatarEditorVisible.value = true;

          nextTick().then(() => {
            const cropper = avatarEditorProfile.value.$refs.cropper;
            cropper.enable();
            cropper.replace(imgUrl);

            avatarSrc.value = imgUrl;
          });
        }
      };
    };

    reader.readAsDataURL(file);
  } else {
    setNotification({
      message: 'Размер фото не должен превышать 2 Mb'
    });
  }

  e.target.value = null;
};

const saveAvatar = avatar => emit('update-user-avatar', avatar);

const showQR = () => emit('show-qr');

const removeAvatarAction = async () => {
  await removeAvatarTask.perform('delete-avatar', {});
  if (removeAvatarTask.isError.value) {
    instance.proxy.$notify({
      title: t(getCommonCaptionByName('Error')),
      type: 'error',
      message: t(getUiItemCaption(props.signature, 'ErrorDeleteAvatar'))
    });
  } else {
    instance.proxy.$notify({
      title: t(getCommonCaptionByName('Success')),
      type: 'success',
      message: t(getUiItemCaption(props.signature, 'SuccessDeleteAvatar'))
    });
    clearAvatar();
  }
  dialogRemoveAvatar.value = false;
};
const isTouchDevice = store.state.isTouchDevice;
</script>

<script>
export default {
  name: 'ProfileAvatar'
};
</script>

<style lang="scss">
.avatar {
  margin-bottom: 13px;
}
</style>
<style lang="scss" scoped>
.el-dialog__wrapper .btn {
  width: auto;
  min-width: 120px;
  margin-top: 0 !important;
}
</style>
