<script>
import { mapState } from 'vuex';
import MultipleSelectFilter from "@/components/filters/MultipleSelectFilter.vue";

export default {
  name: 'CampusFilter',
  extends: MultipleSelectFilter,
  props: {
    label: {
      type: String,
      default: 'Кампус'
    },
    param: {
      type: String,
      default: 'campus'
    }
  },
  computed: {
    ...mapState({
      types: state => state.refCampuses.items.map(type => {
        return {
          value: type.code,
          label: type.name
        }
      }),
    }),
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$store.dispatch('refCampuses/fetch').then(() => this.optionList = this.types)
    }
  }
}
</script>
