<script>
import {filterBus} from '@/main';

export default {
  name: 'PresetFilterCascadeMixin',
  props: {
    param: {
      type: String,
      required: true,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selected: [],
      selectedItems: [],
      removeCodeItem: '',
      removeEventName: 'removeCFilter',
      clearItems: false
    };
  },
  watch: {
    selected(v) {
      if (v.length === 0 && this.selectedItems.length) {
        this.clearItems = true;
        this.selectedItems = [];
      }
    }
  },
  mounted() {
    filterBus.$on(this.removeEventName, value => {
      this.removeCodeItem = this.getCodeByName(value) ?? '';
    });
  },
  methods: {
    getValue() {
      return this.selected?.map(e => {
        return {
          label: e.name,
          value: e.code,
          param: this.param
        };
      });
    },
    toLabel() {
      return this.selected.map(e => ({
        name: e.name,
        removeEventName: this.removeEventName
      }));
    },
    toParam() {
      return {
        [this.param]: this.selected?.map(e => e.code)
      };
    },
    getCodeByName(name) {
      return this.selected.find(e => e.name === name)?.code;
    },
    onCleared() {
      this.clearItems = false;
    },
    onChange(items = []) {
      this.selectedItems = items;
      this.selected = items;
      this.$emit(
        'input',
        items.map(e => ({
          label: e.name,
          value: e.code
        }))
      );
    },
    onCodeItemRemoved() {
      this.removeCodeItem = '';
    }
  }
};
</script>
