<template>
  <button class="button-add__element" @click.stop.prevent="onClick">
    {{ title }}
  </button>
</template>

<script>
export default {
  name: 'ButtonAdd',

  props: {
    title: {
      type: String,
      default: '',
    },
  },

  methods: {
    onClick() {
      this.$emit('on-click');
    },
  },
};
</script>

<style lang="scss">
.button-add__element {
  width: 100%;
  height: 48px;
  background: #ffffff;
  border-radius: 4px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #6b7a99;
  border: 1px solid #d3d8e6;
  box-sizing: border-box;
  cursor: pointer;
}
</style>
