<template>
  <div
    :class="{
      'inputWrap-custom': true,
      'error-system': hasError,
      'inputWrap-custom--with-tooltip': tooltipData
    }"
  >
    <label
      :class="{required: required}"
      :for="id"
    >
      {{ label }}
    </label>
    <div class="input-form__input-box">
      <input
        :id="id"
        v-model.trim="value.$model"
        class="input-form__auth"
        :disabled="disabled"
        :type="type"
        :name="id"
        :required="required"
        :readonly="readonly"
        @blur="onBlur"
      />
      <tooltip-info
        v-if="tooltipData"
        :tooltip-data="tooltipData"
      />
    </div>
    <slot name="errors" />
  </div>
</template>

<script>
import tooltypInfo from '@/components/helpElements/tooltipInfo.vue';
export default {
  name: 'CustomInput',
  components: {
    'tooltip-info': tooltypInfo
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    required: {
      type: Boolean,
      default: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => ({$model: ''})
    },
    tooltipData: {
      type: String,
      default: ''
    }
  },
  computed: {
    hasError() {
      return this.value.$error || (this.value.$dirty && this.value.$invalid);
    }
  },

  methods: {
    onBlur(event) {
      this.$emit('blur', event.target.value);
    }
  }
};
</script>

<style lang="scss">
.inputWrap-custom {
  margin-bottom: 28px;
}

.inputWrap-custom {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  .input-form__input-box {
    position: relative;

    .tooltip-info {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);

      .tooltip-info__window {
        right: -30%;
      }
    }
  }

  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $dusk-gray-color;
    margin-bottom: 8px;
    position: relative;
    display: inline-flex;
    width: fit-content;
  }

  label.required:before {
    content: '*';
    position: absolute;
    top: 0;
    right: -6px;
    width: 5px;
    height: 5px;
    display: block;
  }

  .input-form__auth {
    width: 100%;
    height: 48px;
    border-radius: 4px;
    border: 1px solid $border-color;
    padding: 14px 14px 12px 14px;

    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $base-black-color;
    transition: all 0.3s;
    box-sizing: border-box;
    transition: all 10ms ease;

    &:focus {
      outline: 1px solid #101010 !important;
      border: 1px solid #101010 !important;
    }

    &[readonly] {
      color: #6b7a99;
      background-color: #fafafa;
      cursor: not-allowed;

      &:focus {
        outline: none !important;
        border: 1px solid #d3d8e6 !important;
        cursor: not-allowed;
      }
    }

    &[disabled='disabled'] {
      outline: none;
      background-color: #fafafa;
      color: #545479;
      cursor: not-allowed;
    }
  }

  .input-form__auth:focus-visible {
    border: 1px solid $blue-color;
  }

  &.error-system .input-form__auth:focus-visible {
    border-color: $error-system-red;
    outline: 1px solid $error-system-red;
  }

  &.error-system input {
    border: 1px solid $error-system-red;
  }

  &--with-tooltip {
    .input-form__auth {
      padding-right: 45px;
    }
  }
}
</style>
