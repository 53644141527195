<template>
  <div class="profile__born">
    <p class="profile__table-title">
      {{ $t(getCommonCaptionByName('contacts_birthDate')) }}
    </p>
    <div
      class="profile__table-info"
      :class="{profile_el_empty: profileFillErrors}"
    >
      <div
        v-if="!editorBirthDate"
        class="profile__contacts-container"
      >
        <span class="profile__contacts-date">
          {{ bornDate }}
        </span>
        <button
          v-if="isShowEditorBirthDate"
          class="btn btn--secondary btn--icon"
          @click="toggleEditorBirthDate"
        >
          <edit class="edit-icon" />
        </button>
        <el-select
          v-model.number="value"
          class="profile__contacts-select !max-w-fit"
          @change="setShowType"
        >
          <el-option
            :value="1"
            :label="$t(getUiItemCaption(signature, 'fullDateFormatLabel'))"
          >
            {{ $t(getUiItemCaption(signature, 'fullDateFormatLabel')) }}
          </el-option>
          <el-option
            :value="2"
            :label="$t(getUiItemCaption(signature, 'Day&MonthFormatLabel'))"
          >
            {{ $t(getUiItemCaption(signature, 'Day&MonthFormatLabel')) }}
          </el-option>
          <el-option
            :value="3"
            :label="$t(getUiItemCaption(signature, 'dontShowDateFormatLabel'))"
          >
            {{ $t(getUiItemCaption(signature, 'dontShowDateFormatLabel')) }}
          </el-option>
        </el-select>
      </div>
      <elk-editor-birth-date
        v-else
        :placeholder="$t(getCommonCaptionByName('date_format_similar'))"
        :default-mask="true"
        @toggle-editor-birthDate="toggleEditorBirthDate"
        @update-info="updateInfo"
      />
    </div>
  </div>
</template>

<script setup>
import setNotification from '@/utils/setNotification';
import {getUiItemCaption, getCommonCaptionByName} from '@/i18n/utils';
import Edit from '@/assets/icons/edit.svg?inline';
import ElkEditorBirthDate from './editorBirthDate';
import {apiSetBirthDateShowType} from './api';
import {inject, ref, computed} from 'vue';
import store from '@/store';
import {useI18n} from 'vue-i18n-composable';

const signature = inject('signature');

const props = defineProps(['birthDate', 'showType', 'isAdm']);
const emit = defineEmits(['update-info']);

const {d, t} = useI18n();

const value = ref(props.showType);
const editorBirthDate = ref(false);

const profileFillErrors = computed(() => {
  let errors = store.getters['profileFillCheck/profileFillErrors'].map(
    function (obj) {
      return obj.code;
    }
  );
  if (errors?.includes('birthday')) return true;
});

const bornDate = computed(() => {
  if (props.birthDate) {
    try {
      return d(new Date(props.birthDate), 'birthDate');
    } catch (e) {
      //sentry
      return '-';
    }
  } else {
    return '-';
  }
});
const isShowEditorBirthDate = computed(() => {
  if (props.isAdm && !props.birthDate) {
    return true;
  } else if (!props.isAdm) {
    return true;
  } else {
    return false;
  }
});

const toggleEditorBirthDate = () =>
  (editorBirthDate.value = !editorBirthDate.value);

const updateInfo = value => emit('update-info', value);

const setShowType = async type => {
  try {
    let res = await apiSetBirthDateShowType(type);

    if (res.status == 200) {
      emit('update-info', {
        type: 'show-birthdate-type',
        value: type
      });
    }
  } catch (err) {
    if (!err.isHandled) {
      setNotification({
        message: t(
          getUiItemCaption(signature, 'failSettingBirthDateVIsibilityFormat')
        )
      });
    }
  }
};
</script>

<script>
export default {
  name: 'BornDate'
};
</script>
