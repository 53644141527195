<template>
  <el-dialog
    title="Выберите способ оплаты"
    :visible.sync="visible"
    width="500px"
  >
    <el-form
      ref="form"
      :rules="rules"
      :model="form"
    >
      <el-form-item
        class="order__radio-buttons"
        prop="value"
        required
      >
        <el-radio-group v-model="form.value">
          <el-radio
            v-for="opts in options"
            :key="opts.value"
            :label="opts.value"
          >
            {{ opts.label }}
          </el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>

    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancelClick">
        Отмена
      </el-button>
      <el-button
        type="primary"
        :disabled="loading"
        @click="handleSubmitClick"
      >
        <Loading v-if="loading" small />
        <template v-else>Оплатить</template>
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import {mapState} from "vuex";
import Loading from '@/components/common/elkLoading';

export default {
  name: 'SelectPaymentOptionsDialog',
  components: {
    Loading
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visible: false,
      paymentOptions: [],
      form: {
        value: null
      },
      rules: {
        value: [
          {required: true, message: 'Выберите способ оплаты', trigger: 'change'}
        ]
      }
    };
  },
  computed: {
    ...mapState({
      options: state => state.refPaymentOptions.options?.filter(option => option.value !== 'paylater')
    })
  },
  mounted() {
    this.$store.dispatch('refPaymentOptions/fetch');
  },
  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
    handleSubmitClick() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('submit', this.form.value);
        }
      });
    },
    handleCancelClick() {
      this.$emit('cancel');
      this.hide();
    }
  }
};
</script>

<style scoped lang="scss">
.el-dialog {
  .el-form {
    padding: 0;
    width: auto;
    box-shadow: none;
  }
}

.dialog-footer {
  display: flex;
}
</style>
