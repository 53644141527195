<template>
  <el-date-picker
    ref="re"
    :format="format"
    :picker-options="pickerOptions"
    :value="value"
    :placeholder="placeholder"
    type="date"
    v-bind="$attrs"
    class="elk-date-picker"
    @input="datePickerInput"
    @input.native="handleDateByMask"
  />
</template>

<script>
import { getCommonCaptionByName } from '@/i18n/utils';
import getFirstDayOfWeek from '@/utils/getFirstDayOfWeek';
import I18N from '@/i18n';

export default {
  name: 'ElkDatePicker',
  props: {
    value: [String, Date],
    placeholder: {
      type: String,
      default: ''
    },
    format: {
      type: String,
      default: I18N.t(getCommonCaptionByName('date_format')),
    },
    defaultMask: {
      type: Boolean
    }
  },
  data() {
    return {
      pickerOptions: {
        firstDayOfWeek: this.getFirstDayOfWeek(),
      },
    };
  },
  methods: {
    getFirstDayOfWeek,
    getCommonCaptionByName,
    datePickerInput($event) {
      this.$emit('input', $event);
    },
    maskit(
      value,
      mask = '##.##.####',
      tokens = {
        '#': { pattern: /\d/ },
        X: { pattern: /[0-9a-zA-Z]/ },
        S: { pattern: /[a-zA-Z]/ },
        A: { pattern: /[a-zA-Z]/, transform: (v) => v.toLocaleUpperCase() },
        a: { pattern: /[a-zA-Z]/, transform: (v) => v.toLocaleLowerCase() },
        '!': { escape: true },
      }
    ) {
      value = value || '';
      mask = mask || '';
      var iMask = 0;
      var iValue = 0;
      var output = '';
      while (iMask < mask.length && iValue < value.length) {
        var cMask = mask[iMask];
        var masker = tokens[cMask];
        var cValue = value[iValue];
        if (masker && !masker.escape) {
          if (masker.pattern.test(cValue)) {
            output += masker.transform ? masker.transform(cValue) : cValue;
            iMask++;
          }
          iValue++;
        } else {
          if (masker && masker.escape) {
            iMask++; // take the next mask char and treat it as char
            cMask = mask[iMask];
          }
          output += cMask;
          if (cValue === cMask) iValue++; // user typed the same char
          iMask++;
        }
      }

      // fix mask that ends with a char: (#)
      var restOutput = '';
      while (iMask < mask.length) {
        // eslint-disable-next-line no-redeclare
        var cMask = mask[iMask];
        if (tokens[cMask]) {
          restOutput = '';
          break;
        }
        restOutput += cMask;
        iMask++;
      }

      return output + restOutput;
    },
    handleDateByMask(e) {
      const mask = this.defaultMask ? '##.##.####' : this.$t(getCommonCaptionByName('dateFormatForDatePicker'));

      e.target.value = this.maskit(
        e.target.value,
        mask
      );
    },
  },
};
</script>
