import Axios from '@/utils/Elk-Axios';
import { GET_FOOTER } from '@/store/actionsName';
import { SET_FOOTER } from '@/store/mutationsName';
import { GET_FOOTER as GET_FOOTER_API } from '@/api';

export default {
  namespaced: true,

  state: {
    groupLinks: undefined,
  },

  mutations: {
    [SET_FOOTER](state, groupLinks) {
      state.groupLinks = groupLinks;
    },
  },
  actions: {
    async [GET_FOOTER](context) {
      try {
        const { data } = await Axios.get(GET_FOOTER_API);
        if (data && Array.isArray(data.group_links)) {
          context.commit(SET_FOOTER, data.group_links);
          return Promise.resolve();
        } else {
          //sentry
          throw new Error();
        }
      } catch (e) {
        context.commit(SET_FOOTER, null);
        return Promise.reject(e);
      }
    },
  },
};
