<template>
  <div :class="{body: true, active: menuActive}">
    <menu-wrapper :page-name="name" />
    <div class="page-wrapper">
      <main
        class="content"
        :class="pageClass"
      >
        <section class="comparison-section">
          <div class="container">
            <h1 class="page-title">{{ name }}</h1>
            <div class="comparison-box">
              <div class="comparison-filter-panel">
                <div
                  class="comparison-filter-panel__item comparison-filter-panel-item"
                >
                  <span class="comparison-filter-panel-item__caption"
                    >Показывать:
                  </span>
                  <span class="comparison-filter-panel-item__mobile-caption"
                    >Только отличающиеся
                  </span>
                  <switch-checkbox
                    v-model="sortValue"
                    class="comparison-checked"
                    :checked="true"
                    :caption-off="'Все характеристики'"
                    :caption-on="'Только отличающиеся'"
                  />
                </div>
              </div>
              <ul class="comparison-labels">
                <li class="comparison-label" />
                <li class="comparison-label">
                  <div class="comparison-label__caption">Направление</div>
                </li>
                <li class="comparison-label">
                  <div class="comparison-label__caption">
                    Стоимость
                    <div class="cl-hint">
                      <button class="cl-hint__btn">
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6 0C4.81331 0 3.65328 0.351894 2.66658 1.01118C1.67989 1.67047 0.910851 2.60754 0.456726 3.7039C0.00259972 4.80026 -0.11622 6.00666 0.115291 7.17054C0.346802 8.33443 0.918247 9.40353 1.75736 10.2426C2.59648 11.0818 3.66558 11.6532 4.82946 11.8847C5.99335 12.1162 7.19975 11.9974 8.2961 11.5433C9.39246 11.0892 10.3295 10.3201 10.9888 9.33342C11.6481 8.34673 12 7.18669 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0ZM7 10H5V6H4V5H7V10ZM6 4C5.80222 4 5.60888 3.94135 5.44443 3.83147C5.27998 3.72159 5.15181 3.56541 5.07612 3.38268C5.00044 3.19996 4.98063 2.99889 5.01922 2.80491C5.0578 2.61093 5.15304 2.43275 5.2929 2.29289C5.43275 2.15304 5.61093 2.0578 5.80491 2.01921C5.99889 1.98063 6.19996 2.00043 6.38269 2.07612C6.56541 2.15181 6.72159 2.27998 6.83147 2.44443C6.94135 2.60888 7 2.80222 7 3C7 3.26522 6.89465 3.51957 6.70711 3.70711C6.51957 3.89464 6.26522 4 6 4Z"
                            fill="#6B7A99"
                          />
                        </svg>
                      </button>
                      <div class="cl-hint__tooltip cl-hint-tooltip">
                        <span class="cl-hint-tooltip__caption">
                          Формат обучения
                        </span>
                        <p>
                          В статье 17 федерального закона «Об образовании в РФ»
                          используются два взаимосвязанных термина: формы
                          получения образования и формы обучения. Форм получения
                          образования всего две: в организациях, осуществляющих
                          образовательную деятельность, и вне таких организаций
                          (проще говоря, где ты учишься).
                        </p>
                        <p>
                          Таким образом, форма обучения — это способ организации
                          обучения.
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="comparison-label">
                  <div class="comparison-label__caption">Скидка</div>
                </li>
                <li class="comparison-label">
                  <div class="comparison-label__caption">Cтарт курса</div>
                </li>
                <li class="comparison-label">
                  <div class="comparison-label__caption">
                    Выдаваемый документ
                  </div>
                </li>
                <li class="comparison-label">
                  <div class="comparison-label__caption">
                    Формат обучения
                    <div class="cl-hint">
                      <button class="cl-hint__btn">
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6 0C4.81331 0 3.65328 0.351894 2.66658 1.01118C1.67989 1.67047 0.910851 2.60754 0.456726 3.7039C0.00259972 4.80026 -0.11622 6.00666 0.115291 7.17054C0.346802 8.33443 0.918247 9.40353 1.75736 10.2426C2.59648 11.0818 3.66558 11.6532 4.82946 11.8847C5.99335 12.1162 7.19975 11.9974 8.2961 11.5433C9.39246 11.0892 10.3295 10.3201 10.9888 9.33342C11.6481 8.34673 12 7.18669 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0ZM7 10H5V6H4V5H7V10ZM6 4C5.80222 4 5.60888 3.94135 5.44443 3.83147C5.27998 3.72159 5.15181 3.56541 5.07612 3.38268C5.00044 3.19996 4.98063 2.99889 5.01922 2.80491C5.0578 2.61093 5.15304 2.43275 5.2929 2.29289C5.43275 2.15304 5.61093 2.0578 5.80491 2.01921C5.99889 1.98063 6.19996 2.00043 6.38269 2.07612C6.56541 2.15181 6.72159 2.27998 6.83147 2.44443C6.94135 2.60888 7 2.80222 7 3C7 3.26522 6.89465 3.51957 6.70711 3.70711C6.51957 3.89464 6.26522 4 6 4Z"
                            fill="#6B7A99"
                          />
                        </svg>
                      </button>
                      <div class="cl-hint__tooltip cl-hint-tooltip">
                        <span class="cl-hint-tooltip__caption">
                          Формат обучения
                        </span>
                        <p>
                          В статье 17 федерального закона «Об образовании в РФ»
                          используются два взаимосвязанных термина: формы
                          получения образования и формы обучения. Форм получения
                          образования всего две: в организациях, осуществляющих
                          образовательную деятельность, и вне таких организаций
                          (проще говоря, где ты учишься).
                        </p>
                        <p>
                          Таким образом, форма обучения — это способ организации
                          обучения.
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
                <li class="comparison-label">
                  <div class="comparison-label__caption">Продолжительность</div>
                </li>
                <li class="comparison-label">
                  <div class="comparison-label__caption">Всего часов</div>
                </li>
              </ul>
              <swiper
                class="comparison-sl"
                grab-cursor
                :slides-per-view="4"
                :space-between="0"
                :perspective="false"
                :loop="false"
                :speed="400"
                :set-wrapper-size="true"
                :enabled="false"
                :long-swipes="false"
                :observer="true"
                :breakpoints="{
                  '300': {
                    slidesPerView: 2
                  },
                  '700': {
                    slidesPerView: 3
                  },
                  '1024': {
                    slidesPerView: 4
                  }
                }"
                :navigation="{
                  nextEl: '.swiper-button-next',
                  prevEl: '.swiper-button-prev'
                }"
                :pagination-clickable="true"
                :mousewheel-control="true"
                :performance-mode="false"
                @swiper="onSwiper"
                @slideChange="onSlideChange"
              >
                <swiper-slide
                  v-for="(program, index) in arComparison"
                  :key="program.id"
                >
                  <ul class="program-comparison">
                    <li
                      class="program-comparison-item program-comparison-item--caption"
                    >
                      <span class="program-comparison-item__count"
                        >{{ index + 1 }} /{{ arComparison.length }}</span
                      >
                      <button
                        class="program-comparison__cross"
                        :data-id="program.pcId"
                        @click="removeProgram(program.pcId, arComparison)"
                      >
                        <svg
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                            fill="#D3D8E6"
                          />
                        </svg>
                      </button>
                      <span class="program-comparison-item__value">
                        {{ program.pcCaption }}
                      </span>
                    </li>
                    <li
                      class="program-comparison-item program-comparison-item--direction"
                    >
                      <span class="program-comparison-item__value">
                        {{ program.pcDirect }}
                      </span>
                    </li>
                    <li
                      class="program-comparison-item program-comparison-item--cost"
                    >
                      <span
                        v-if="program.pcPrice == 0"
                        class="program-comparison-item__value program-comparison-item__value--green"
                      >
                        > Бесплатно!
                      </span>
                      <span
                        v-else
                        class="program-comparison-item__value"
                      >
                        {{ program.pcPrice | formatPrice }}
                      </span>
                    </li>
                    <li
                      class="program-comparison-item program-comparison-item--discount"
                    >
                      <span class="program-comparison-item__value">
                        <template
                          v-if="
                            calcDiscount(program.pcOldPrice, program.pcPrice) !=
                            '—'
                          "
                        >
                          {{
                            calcDiscount(program.pcOldPrice, program.pcPrice)
                              | formatPrice
                          }}
                        </template>
                        <template v-else> — </template>
                      </span>
                    </li>
                    <li
                      class="program-comparison-item program-comparison-item--begin"
                    >
                      >
                      <span class="program-comparison-item__value">
                        {{ program.pcStart }}
                      </span>
                    </li>
                    <li
                      class="program-comparison-item program-comparison-item--doc"
                    >
                      <span class="program-comparison-item__value">
                        {{ program.pcDocuments }}
                      </span>
                    </li>
                    <li
                      class="program-comparison-item program-comparison-item--training-format"
                    >
                      >
                      <span class="program-comparison-item__value">
                        {{ program.pcTrainingFormat }}
                      </span>
                    </li>
                    <li
                      class="program-comparison-item program-comparison-item--duration"
                    >
                      <span class="program-comparison-item__value">
                        {{ program.pcDuration }}
                      </span>
                    </li>
                    <li
                      class="program-comparison-item program-comparison-item--total-hours"
                    >
                      <span class="program-comparison-item__value">
                        {{ program.pcTotalHours }}
                      </span>
                    </li>
                  </ul>
                </swiper-slide>

                <button class="swiper-button swiper-button-prev">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 8H14L7.5 1.5L9 0L18 9L9 18L7.5 16.5L14 10H0V8Z"
                    />
                  </svg>
                </button>
                <button class="swiper-button swiper-button-next">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0 8H14L7.5 1.5L9 0L18 9L9 18L7.5 16.5L14 10H0V8Z"
                    />
                  </svg>
                </button>
              </swiper>
            </div>
          </div>
        </section>
      </main>
    </div>
    <page-footer />
  </div>
</template>

<script>
import menuWrapper from '@/components/menuWrapper';
import PageFooter from '@/components/PageFooter';
import SwitchCheckbox from '@/components/formElements/SwitchCheckbox.vue';

import {Swiper, SwiperSlide} from 'swiper-vue2';
// Import Swiper styles
import 'swiper/swiper-bundle.min.css';

import {mapState, mapGetters} from 'vuex';

export default {
  name: 'Services',
  components: {
    menuWrapper,
    PageFooter,
    Swiper,
    SwiperSlide,
    'switch-checkbox': SwitchCheckbox
  },
  filters: {
    formatPrice: function (price) {
      if (!(price % 1 === 0) /* проверка на число */) return '';
      /** https://caniuse.com/?search=NumberFormat - 96% */
      return new Intl.NumberFormat('ru-RU', {
        maximumFractionDigits: 0 /* цифр после запятой */,
        style: 'currency',
        currency: 'RUB'
      }).format(price);
    }
  },
  setup() {
    const onSwiper = swiper => {};
    const onSlideChange = () => {};
    return {
      onSwiper,
      onSlideChange
    };
  },
  data() {
    return {
      pageClass: 'comparison-page',
      name: 'Сравнение курсов',
      sortValue: true,
      arComparison: [
        {
          pcId: 10,
          pcActive: true,
          pcDirect:
            'Управление водородными звездолетами с пустотными двигателясм',
          pcCaption: 'Космические полеты и навигация',
          pcStart: '02.03.2023',
          pcDuration: '18 месяцев',
          pcPrice: 163000,
          pcOldPrice: 170000,
          pcType: 'prof-retraining',
          pcFavorites: true,
          pcComparison: true,
          pcDocuments: 'Диплом',
          pcTrainingFormat: 'Очно',
          pcTotalHours: 96
        },
        {
          pcId: 20,
          pcActive: true,
          pcDirect:
            'Гибкие навыки менеджера, лидерство, Государственное муниципальное управление',
          pcCaption: 'Менеджмент',
          pcStart: '02.02.2023',
          pcDuration: '8 месяцев',
          pcPrice: 63000,
          pcOldPrice: 70000,
          pcType: 'prof-retraining',
          pcFavorites: true,
          pcComparison: true,
          pcDocuments: 'Сертификат',
          pcTrainingFormat: 'Онлайн-асинхронно',
          pcTotalHours: 108
        },
        {
          pcId: 30,
          pcActive: true,
          pcDirect: 'Психология',
          pcCaption:
            'Анализ оценок экспертов с использованием современной теории тестирования',
          pcStart: '30.01.2023',
          pcDuration: '8 месяцев',
          pcPrice: 35000,
          pcOldPrice: 0,
          pcType: 'prof-dev',
          pcFavorites: true,
          pcComparison: false,
          pcDocuments: 'Удостоверение',
          pcTrainingFormat: 'Онлайн-синхронно',
          pcTotalHours: 48
        },
        {
          pcId: 40,
          pcActive: true,
          pcDirect: 'Мат. анализ',
          pcCaption: 'Теорема Каши',
          pcStart: '16.02.2023',
          pcDuration: '12 месяцев',
          pcPrice: 0,
          pcOldPrice: 0,
          pcType: 'spec-master',
          pcFavorites: true,
          pcComparison: false,
          pcDocuments: 'Сертификат',
          pcTrainingFormat: 'Смешанный',
          pcTotalHours: 108
        },
        {
          pcId: 50,
          pcActive: true,
          pcDirect: 'Психология',
          pcCaption: 'Как управлять миром не вызвая подозрения санитаров',
          pcStart: '16.02.2023',
          pcDuration: '12 месяцев',
          pcPrice: 0,
          pcOldPrice: 0,
          pcType: 'spec-master',
          pcFavorites: true,
          pcComparison: false,
          pcDocuments: 'Сертификат',
          pcTrainingFormat: 'Смешанный',
          pcTotalHours: 108
        }
      ]
    };
  },
  methods: {
    calcDiscount(oldPrice, price) {
      let discount = oldPrice - price;
      if (discount <= 0) {
        return '—';
      } else {
        return discount;
      }
    },
    // метод удяляет программу из сравления
    removeProgram(id, arr) {
      arr.map((item, index) => {
        if (item.pcId == id) {
          arr.splice(index, 1);
        }
      });
    }
  },
  computed: {
    ...mapState(['menuActive']),
    ...mapGetters(['isPublicUser'])
  },
  mounted() {
    this.$nextTick(function () {
      /**
       * фукция устанавливает общую высоту элементами из списка узлов
       * @param {nodeList} nodeList список узлов, которым нужно установить общую максимальную высоту
       */
      function setHeight(nodeList) {
        let cellMaxHeight = 0;
        nodeList.forEach(item => {
          item.style.height = 'auto';
        });
        nodeList.forEach(item => {
          if (cellMaxHeight < item.offsetHeight) {
            cellMaxHeight = item.offsetHeight;
          }
        });
        nodeList.forEach(item => {
          item.style.height = cellMaxHeight + 'px';
        });
      }

      //устанавливает высоту выбранным спискам узлов
      function setHeightsAll() {
        let nlCellCaption = document.querySelectorAll(
          '.program-comparison-item--caption'
        );
        let nlCellDirection = document.querySelectorAll(
          '.program-comparison-item--direction'
        );
        let nlCellDoc = document.querySelectorAll(
          '.program-comparison-item--doc'
        );
        let nlCellFormat = document.querySelectorAll(
          '.program-comparison-item--training-format'
        );
        let nlCellDuration = document.querySelectorAll(
          '.program-comparison-item--duration'
        );

        setHeight(nlCellCaption);
        setHeight(nlCellDirection);
        setHeight(nlCellDoc);
        setHeight(nlCellFormat);
        setHeight(nlCellDuration);
      }

      //устанавливаю высоту элементам с подписями
      function setPositionLabels() {
        let nlComparisonLabel = document.querySelectorAll('.comparison-label');
        let nlProgrammCell = document.querySelectorAll(
          '.swiper-slide-active .program-comparison-item'
        );
        nlProgrammCell.forEach((cell, index) => {
          nlComparisonLabel[index].style.height = cell.offsetHeight + 'px';
        });
      }
      //устанавливаю ширину родительскому элементу с  подписями
      function setWidthLabelCaption() {
        let comparisonSl = document.querySelector('.comparison-sl');
        let comparisonSlWidth = comparisonSl.offsetWidth;
        let nlLabelCaption = document.querySelectorAll(
          '.comparison-label__caption'
        );
        nlLabelCaption.forEach(caption => {
          caption.style.width = comparisonSlWidth + 'px';
        });
      }

      let filterPanel = document.querySelector('.comparison-filter-panel');
      function placeFilterPanel() {
        if (window.innerWidth < 700) {
          let cellDirection = document.querySelector(
            '.program-comparison-item--direction'
          );
          let filterPanel = document.querySelector('.comparison-filter-panel');
          filterPanel.style.top = cellDirection.offsetTop - 60 + 'px';
        } else {
          filterPanel.style.top = 'auto';
        }
      }
      setHeightsAll();
      setPositionLabels();
      setWidthLabelCaption();
      placeFilterPanel();

      window.addEventListener('resize', function () {
        setHeightsAll();
        setPositionLabels();
        setWidthLabelCaption();
        placeFilterPanel();
      });

      let clHintBtn = document.querySelectorAll('.cl-hint__btn');
      if (clHintBtn) {
        clHintBtn.forEach(item => {
          item.addEventListener('click', function () {
            clHintBtn.forEach(item => {
              item.parentElement.classList.remove('cl-hint--active');
            });
            item.parentElement.classList.add('cl-hint--active');
          });
        });
      }
      document.addEventListener('click', function (e) {
        if (!e.target.closest('.cl-hint')) {
          clHintBtn.forEach(item => {
            item.parentElement.classList.remove('cl-hint--active');
          });
        }
      });
    });
  }
};
</script>
<style lang="scss">
.comparison-page {
  .page-title {
    margin-bottom: 76px;
  }
}
.comparison-filter-panel {
  width: 100%;
  .comparison-filter-panel__item {
    .comparison-checked {
      .switch__label {
        line-height: 1.33;
        margin-right: 16px;
        font-size: 18px;
        line-height: 1.33;
      }
      span.switch__label {
        color: #0050cf;
        box-shadow: 0 1px 0 0 #0050cf;
      }
      label.switch__label {
        color: #0f0f14;
      }
      .switch__elem {
        display: none;
      }
    }
  }
}

.comparison-filter-panel-item {
  display: flex;
  .comparison-filter-panel-item__caption {
    display: block;
    margin-right: 7px;
    font-size: 18px;
    line-height: 1.33;
    color: #6b7a99;
  }
  .comparison-filter-panel-item__mobile-caption {
    display: none;
    font-size: 14px;
    line-height: 1.4;
    color: #0f0f14;
  }
}

.cl-hint {
  position: relative;
  display: inline-block;
  margin-left: 3px;
  .cl-hint__btn {
    width: 12px;
    height: 12px;
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    svg {
      width: 100%;
      height: auto;
      path {
        transition: all 200ms ease;
        fill: #6b7a99;
      }
    }
    &:hover {
      svg {
        path {
          fill: #0050cf;
        }
      }
    }
    &::before {
      content: '';
      position: absolute;
      bottom: -8px;
      left: 3px;
      width: 6px;
      height: 6px;
      border-left: 1px solid #0050cf;
      border-top: 1px solid #0050cf;
      transform: rotate(45deg);
      background-color: #ffffff;
      z-index: 1;
      transition: opacity 200ms ease;
      opacity: 0;
      visibility: hidden;
    }
  }
  .cl-hint__tooltip {
    position: absolute;
    top: 23px;
    left: -100%;
    width: 350px;
    height: auto;
    transition: all 200ms ease;
    transform-origin: 14px top;
    transform: scaleY(0);
    opacity: 0;
  }
  &--active {
    .cl-hint__btn {
      svg {
        path {
          fill: #0050cf;
        }
      }
      &::before {
        opacity: 1;
        visibility: visible;
      }
    }
    .cl-hint__tooltip {
      transform: scaleY(1);
      opacity: 1;
    }
  }
}

.cl-hint-tooltip {
  padding: 10px;
  background-color: #ffffff;
  box-sizing: border-box;
  border: 1px solid #0050cf;
  border-radius: 3px;
  color: #0f0f14;
  box-shadow: 0px 4px 24px rgba(106, 122, 152, 0.15);
  white-space: pre-wrap;
  * {
    margin: 8px 0;
    font-size: 14px;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .cl-hint-tooltip__caption {
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 600;
  }
}

.comparison-box {
  position: relative;
  .comparison-filter-panel {
    position: absolute;
    bottom: calc(100% + 28px);
  }
}
.comparison-labels {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  // background-color: red;
  z-index: 2;
  .comparison-label {
    padding-top: 16px;
    font-size: 14px;
    line-height: 1.2;
    color: #6b7a99;
    white-space: nowrap;
    box-sizing: border-box;
    .comparison-label__caption {
      display: block;
      padding-left: 24px;
      padding-right: 24px;
      height: 0;
      white-space: nowrap;
    }
  }
}

.comparison-sl {
  position: relative;
  opacity: 1;
  transition: opacity 200ms ease;

  &.comparison-sl--no-shadow {
  }
  .swiper-button-disabled {
    opacity: 0;
  }
  .swiper-button {
    width: 50px;
    height: 85%;
    padding: 0;
    bottom: 0;
    top: auto;
    margin: 0;
    border: none;
    outline: none;
    background-color: transparent;
    box-sizing: border-box;
    &.swiper-button-prev {
      transform: scaleX(-1);
      left: 0;
      justify-content: flex-end;
      padding-right: 3px;
    }
    &.swiper-button-next {
      right: 0;
      justify-content: flex-end;
      padding-right: 3px;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 50%
      );
    }
    &::after {
      display: none;
    }
    svg {
      width: 18px;
      height: 18px;
      path {
        transition: all 200ms ease;
        fill: #6b7a99;
      }
    }
    &:hover {
      svg {
        path {
          fill: #0050cf;
        }
      }
    }
  }
}
.program-comparison {
  background-color: #ffffff;
  .program-comparison-item {
    position: relative;
    display: flex;
    align-items: flex-end;
    border-left: 1px solid #edeff3;
    padding: 16px 24px;
    box-sizing: border-box;
    &:not(:last-child) {
      border-bottom: 1px solid #edeff3;
    }
    &:not(:first-child) {
      padding-top: 44px;
      &::after {
        content: '';
        position: absolute;
        top: -2px;
        left: 0;
        height: 4px;
        width: 24px;
      }
    }
    .program-comparison-item__count {
      display: none;
      position: absolute;
      top: 20px;
      left: 16px;
      font-size: 14px;
      line-height: 1;
      color: #0050cf;
      font-weight: 600;
    }
    &:last-child {
      padding-bottom: 38px;
    }
    .program-comparison__cross {
      position: absolute;
      top: 29px;
      right: 29px;
      width: 14px;
      height: 14px;
      padding: 0;
      margin: 0;
      background-color: transparent;
      border: none;
      outline: none;
      cursor: pointer;
      svg {
        width: 100%;
        height: auto;
        path {
          fill: #d3d8e5;
          transition: all 200ms ease;
        }
      }
      &:hover {
        svg {
          path {
            fill: #0050cf;
          }
        }
      }
    }
    .program-comparison-item__value {
      font-size: 16px;
      line-height: 1.25;
      font-weight: 400;
      &--green {
        color: #28c75d;
      }
    }
    &--caption {
      align-items: flex-start;
      padding: 24px;
      padding-right: 65px;
      .program-comparison-item__value {
        font-size: 18px;
        font-weight: 600;
      }
    }
    &--direction {
    }
    &--cost {
      .program-comparison-item__value {
        font-weight: 600;
      }
    }
    &--discount {
      .program-comparison-item__value {
        font-weight: 600;
      }
    }
    &--begin {
    }
    &--doc {
    }
    &--training-format {
    }
    &--duration {
    }
    &--total-hours {
    }
  }
}
.swiper-slide-active {
  .program-comparison-item {
    &:not(:first-child) {
      &::after {
        background-color: #ffffff;
      }
    }
  }
}
.swiper-slide {
  &:last-child {
    .program-comparison-item {
      &:not(:first-child) {
        &::after {
          content: '';
          position: absolute;
          top: -2px;
          right: 0;
          left: auto;
          height: 4px;
          width: 24px;
          background-color: #ffffff;
        }
      }
    }
  }
}

@media screen and (max-width: 1450px) {
  .program-comparison {
    .program-comparison-item {
      .program-comparison__cross {
        right: 20px;
      }
    }
    .program-comparison-item--caption {
      padding: 16px 24px;
      .program-comparison-item__value {
        font-size: 16px;
      }
    }
  }
  .program-comparison {
    .program-comparison-item--caption {
      align-items: flex-start;
      padding: 24px;
      padding-right: 45px;
    }
  }
}
@media screen and (max-width: 700px) {
  .comparison-page .page-title {
    margin-bottom: 24px;
  }
  .comparison-sl {
    .swiper-button {
      display: none;
    }
    .swiper-slide {
      box-shadow: 0 0 0px 1px #ffffff;
    }
  }
  .comparison-labels {
    .comparison-label {
      .comparison-label__caption {
        text-align: center;
      }
    }
  }
  .cl-hint {
    .cl-hint__tooltip {
      left: -182px;
      width: 300px;
    }
  }

  .program-comparison {
    .program-comparison-item {
      padding: 16px;
      border-left: none;
      align-items: center;

      &:not(:first-child) {
        &::after {
          width: 16px;
        }
        .program-comparison-item__value {
          display: block;
          width: 100%;
          text-align: center;
        }
      }
      .program-comparison-item__count {
        display: block;
      }
      .program-comparison__cross {
        top: 20px;
        right: 20px;
      }
    }
    .program-comparison-item--caption {
      align-items: flex-start;
      padding: 16px;
      padding-top: 40px;
      padding-bottom: 60px;
    }
  }
  .comparison-box {
    .comparison-filter-panel {
      box-sizing: border-box;
      bottom: auto;
      top: 0;
      z-index: 10;
      .comparison-filter-panel__item {
        border-top: 1px solid #edeff3;
        height: 60px;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        box-sizing: border-box;
        .comparison-filter-panel-item__caption {
          display: none;
        }
        .comparison-filter-panel-item__mobile-caption {
          display: block;
        }
        .comparison-checked {
          .switch__label {
            display: none;
          }
          .switch__elem {
            display: block;
            margin: 0;
          }
        }
      }
    }
  }

  // .program-comparison .program-comparison-item
}
</style>
