<template>
    <div class="tooltip-info">
        <div class="tooltip-info__trigger"></div>
        <div class="tooltip-info__window" v-html="tooltipData"></div>
    </div>
</template>

<script>
    export default{
        name: 'tooltip-info', 
        props: {
            tooltipData: {
                type: String,
                default: ''
            }
        },
        data(){
            return {

            }
        }
    }
</script>
<style lang="scss"> 
.tooltip-info{
    position: relative;
    z-index: 1;
    .tooltip-info__trigger{
        width: 18px;
        height: 18px;
        background-color: #6B7A99;
        -webkit-mask-image: url('@/assets/icons/icon-info.svg');
        mask-image: url('@/assets/icons/icon-info.svg');
        -webkit-mask-position: center center;
        mask-position: center center;
        -webkit-mask-size: contain;
        mask-size: contain;
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        transition: background-color 200ms ease;
        cursor: pointer;
    }
    .tooltip-info__window{
        position: absolute; 
        top: 120%; 
        background: #ffffff;
        border: 1px solid #ebeef5;
        border-radius: 4px; 
        box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
        color: #606266;
        font-size: 14px;
        line-height: 1.4;
        min-width: 150px;
        padding: 12px; 
        box-sizing: border-box;
        text-align: left;
        // word-break: break-all;
        z-index: 100;
        transition: all 200ms ease;
        opacity: 0;
        visibility: hidden;
        &>*{ 
            &:not(:first-child){
                margin-top: 5px;
            }
            margin-bottom: 0;
        }
        b{
            font-weight: bold;
        } 
    }
    .tooltip-info__trigger{
        &:hover {
            background-color: #0050CF;
            &~ .tooltip-info__window{
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

</style>