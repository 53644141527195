<template>
  <div
    :class="{
      inputWrap: true,
      'error-system': hasError,
    }"
  >
    <label :for="id">
      {{ label }}
    </label>
    <input
      :id="id"
      v-model.trim="value.$model"
      :disabled="disabled"
      :name="id"
      :placeholder="placeholder"
      :required="required"
      :type="type"
      class="input-form__prof"
      @blur="onBlur"
    />
  </div>
</template>

<script>
export default {
  name: 'InputText',

  props: {
    label: { type: String, default: '' },
    id: { type: String, required: true },
    disabled: { type: Boolean, default: false },
    required: { type: Boolean, default: true },
    placeholder: { type: String, default: '' },
    type: { type: String, default: 'text' },
    charLimit:  {type: Number, default: -1 },
    value: {
      type: Object,
      default: () => ({
        $dirty: false,
        $error: false,
        $invalid: false,
        $model: '',
      }),
    },
  },

  computed: {
    hasError() {
      return this.value.$error || (this.value.$dirty && this.value.$invalid);
    },
  },

  methods: {
    onBlur(event) {
      this.$emit('blur', event.target.value);
    },
  },
};
</script>

<style></style>
