<!-- Этот компонент отличается от InputDataAddress тем, что здесь валидатор передается отдельным параметром -->
<template>
  <div :class="{inputWrap: true, 'error-system': hasError}">
    <label :for="id">
      {{ label }}
    </label>
    <el-autocomplete
      :id="id"
      v-model="inputValue"
      class="dadata"
      :name="id"
      :fetch-suggestions="searchAddress"
      :disabled="disabled"
      @select="selectAddress"
      @change="changeAddress"
    />
  </div>
</template>

<script>
export default {
  name: 'InputDataAddressV2',
  model: {
    event: 'input-address',
    prop: 'value'
  },
  props: {
    id: {type: String, required: true},
    label: {type: String, default: ''},
    value: {type: String, default: ''},
    validator: {type: Object, default: () => ({$model: ''})},
    disabled: {type: Boolean, default: false}
  },
  data() {
    return {
      inputValue: this.value
    };
  },
  computed: {
    hasError() {
      return (
        this.validator.$error ||
        (this.validator.$dirty && this.validator.$invalid)
      );
    }
  },
  watch: {
    value(v) {
      if (v !== this.inputValue) {
        this.inputValue = v;
      }
    },
    inputValue(v) {
      this.$emit('input-address', v);
    }
  },
  methods: {
    changeAddress() {
      this.$emit('on-select', null, this.id);
    },
    searchAddress(queryString, cb) {
      this.$emit('on-change', queryString, cb, this.id);
    },
    selectAddress(value) {
      this.$emit('on-select', value, this.id);
    }
  }
};
</script>

<style lang="scss">
.inputWrap > .el-autocomplete.dadata > .el-input > input {
  font-size: 16px;
  color: black;
}
</style>
