<script>
import IconSvg from "@/components/icons/IconSvg.vue"

export default {
  name: 'ChevronUpIcon',
  extends: IconSvg,
  props: {
    d: {
      type: String,
      default: 'M7.06 5.4707L4 2.41737L0.94 5.4707L8.21774e-08 4.5307L4 0.530703L8 4.5307L7.06 5.4707Z'
    },
    fillRule: {
      type: String,
      default: 'evenodd'
    },
    clipRule: {
      type: String,
      default: 'evenodd'
    },
  }
}
</script>
