const UI_ITEM = 'ui_item';
const COMMON = 'common';
const LOCALE_NAME_RU = 'ru';
const LOCALE_NAME_EN = 'en';
const LOCALE_ID_RU = 1;
const LOCALE_ID_EN = 3;

const LOCALES = {
  [LOCALE_ID_RU]: LOCALE_NAME_RU,
  [LOCALE_ID_EN]: LOCALE_NAME_EN,
};

const isLocaleId = (locale) => {
  for (let key in LOCALES) {
    if (key == locale) {
      return true;
    }
  }
  return false;
};

const getLocaleName = localeId => {
  return LOCALES[localeId];
};

const getUiItemCaption = (signature, captionName) =>
  `${UI_ITEM}['${signature}'].${captionName}`;

const getCommonCaptionByName = (name) => `${COMMON}.${name}`;

const getFormCaption = (name) => `formGenerator['${name}']`;

export {
  isLocaleId,
  LOCALES,
  LOCALE_NAME_RU,
  LOCALE_NAME_EN,
  LOCALE_ID_RU,
  LOCALE_ID_EN,
  getLocaleName,
  getUiItemCaption,
  getCommonCaptionByName,
  getFormCaption,
};
