<template>
  <div
    id="education-block"
    class="education-form-block"
  >
    <h2 class="h4">
      {{ title }}
    </h2>
    <education-form-unit
      v-for="(education, index) of forms"
      :id="index"
      :key="education.id || index"
      :index="index"
      :item="education"
      :rules="arrRules"
      :type="type"
      :readonly="readonly"
      @on-change="onChangeEducation"
      @on-remove="onRemoveEducation(education.id)"
    />
    <button-add
      :title="label.button"
      @on-click="addEducation"
    />
  </div>
</template>

<script>
import ButtonAdd from '@/components/profFormInputs/btnForm/buttonAdd';
import EducationFormUnit from '@/components/documentsForm/educationFormUnit';

const initialEducation = {
  id: '',
  speciality: '',
  serial: '',
  number: '',
  date: '',
  issuedBy: '',
  files: []
};

export default {
  name: 'EducationFormDocument',

  components: {
    ButtonAdd,
    EducationFormUnit
  },

  props: {
    educations: {type: Array, default: () => [initialEducation]},
    rules: {type: Map, required: true},
    type: {type: Array, default: () => []},
    readonly: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      title: 'Образование',
      label: {
        button: 'Добавить ещё диплом об образовании'
      },
      forms: this.educations
    };
  },

  computed: {
    arrRules() {
      return this.rules;
    }
  },

  watch: {
    educations(val) {
      this.forms = val;
    }
  },

  methods: {
    onChangeEducation(id, $v) {
      this.$emit('on-change', id, $v);
    },
    onRemoveEducation(id) {
      const i = this.forms.findIndex(e => e.id === id);
      if (i >= 0) {
        this.forms.splice(i, 1);
        if (id) {
          this.$emit('on-remove', id);
        }
      }
    },
    addEducation() {
      this.forms.push(initialEducation);
      this.$emit('on-add-education');
    }
  }
};
</script>

<style lang="scss">
.education-form-block {
  margin-bottom: 48px;
  .education-form {
    margin-bottom: 16px;
  }
  .education-form.common-wrapper__documents {
    .col-input-wrapper:first-child {
      display: grid;
      grid-template-columns: 2.5fr 1fr 1fr 1fr;
      gap: 24px;
      .input-form__prof {
        width: 100%;
      }
    }
    .wrapper-select__document {
      width: 100%;
      height: auto;
      border: unset;
      position: relative;
      display: flex;
      flex-direction: column;
      margin-bottom: 28px;
      .span__select-document {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #6b7a99;
        margin-bottom: 8px;
      }
      .el-select {
        width: 100%;
        height: 48px;
        .el-input {
          height: auto;
          .el-select__caret {
            color: $base-black-color;
          }
          .el-input__inner {
            height: 48px;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: $base-black-color;
            &::placeholder {
              font-weight: 400;
              font-size: 16px;
              line-height: 20px;
              color: $base-black-color;
            }
          }
        }
      }
      &.error-system .el-select .el-input .el-input__inner {
        border: 1px solid $error-system-red;
      }
    }
    .col-input-wrapper:first-child {
      .input-form__prof::placeholder {
        color: $base-black-color;
      }
    }
  }
  .inputWrap {
    margin-left: 0;
  }
}
.wrapper-select__document {
  border-radius: 4px;
}
.education-form {
  .add-file {
    width: 100%;
    margin-left: 0;
  }
  .alert--attention {
    margin-top: 24px;
    margin-bottom: 0;
  }
}

@media (max-width: 1440px) {
  .education-form-block {
    .education-form.common-wrapper__documents {
      .col-input-wrapper:first-child {
        grid-template-columns: repeat(3, 1fr);
        gap: 16px;
        .wrapper-select__document {
          width: 100%;
          grid-column-start: 1;
          grid-column-end: 4;
          grid-row-start: 1;
          grid-row-end: 3;
          margin-bottom: 0;
        }
      }
      .inputWrap {
        margin-bottom: 16px;
      }
    }
  }
}

@media (max-width: 1199px) {
  .education-form-block {
    .inputWrap:nth-child(4) {
      width: 346px;
    }
  }
}

@media (max-width: 767px) {
  .education-form-block {
    margin-bottom: 32px;
    .education-form.common-wrapper__documents {
      margin-bottom: 16px;
      .col-input-wrapper:nth-child(1) {
        display: grid;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 16px;
        height: auto;
        width: 100%;
        .wrapper-select__document:nth-child(1) {
          grid-row-start: 1;
          grid-column-start: 1;
          grid-row-end: 2;
          grid-column-end: 5;
        }
        .inputWrap:nth-child(2) {
          grid-row-start: 2;
          grid-column-start: 1;
          grid-row-end: 3;
          grid-column-end: 3;
          margin-bottom: 0;
        }
        .inputWrap:nth-child(3) {
          grid-row-start: 2;
          grid-column-start: 3;
          grid-row-end: 3;
          grid-column-end: 5;
          margin-bottom: 0;
        }
        .inputWrap:nth-child(4) {
          width: 100%;
          grid-row-start: 3;
          grid-column-start: 1;
          grid-row-end: 4;
          grid-column-end: 5;
          margin-bottom: 0;
        }
        .inputWrap {
          margin-bottom: 16px;
        }
        margin-bottom: 16px;
      }
    }
  }
}

@media (max-width: 530px) {
  .education-form-block {
    .education-form.common-wrapper__documents {
      .wrapper-select__document {
        .el-select {
          .el-input {
            .el-input__inner {
              font-size: 14px;
              line-height: 20px;
              &::placeholder {
                font-size: 14px;
                line-height: 20px;
              }
            }
          }
        }
      }
      .col-input-wrapper:first-child {
        .input-form__prof {
          font-size: 14px;
          line-height: 20px;
          &::placeholder {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }
}

@media (max-width: 480px) {
  .education-form-block {
    .education-form.common-wrapper__documents {
      margin-bottom: 8px;
    }
    .button-add__element {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
</style>
