<template>
  <div
    class="switch"
    :class="{'switch-active': switchChecked}"
  >
    <label>
      <input
        id="fresh-input"
        type="checkbox"
        name="fresh"
        @input="returnValue($event.target.checked)"
      />
      <span class="switch__elem" />
    </label>
    <label
      class="switch__label switch__label--switch-off"
      for="fresh-input"
      >{{ captionOff }}</label
    >
    <span class="switch__label switch__label--switch-off">{{
      captionOff
    }}</span>

    <label
      class="switch__label switch__label--switch-on"
      for="fresh-input"
      >{{ captionOn }}</label
    >
    <span class="switch__label switch__label--switch-on">{{ captionOn }}</span>
  </div>
</template>
<script>
export default {
  name: 'SwitchCheckbox',
  props: {
    checked: Boolean,
    captionOff: String,
    captionOn: String
  },
  data() {
    return {
      switchChecked: false
    };
  },
  methods: {
    returnValue(val) {
      this.switchChecked = val;
      this.$emit('input', val);
    }
  }
};
</script>
<style lang="scss">
.switch {
  display: flex;
  align-items: center;

  span.switch__label {
    color: #0f0f14;
  }
  label.switch__label {
    color: #6b7a99;
    cursor: pointer;
  }

  .switch__label--switch-off {
    order: -1;
  }

  span.switch__label--switch-on,
  label.switch__label--switch-on {
    order: 3;
  }
  label.switch__label--switch-off {
    display: none;
  }
  span.switch__label--switch-off {
    display: block;
  }
  label.switch__label--switch-on {
    display: block;
  }
  span.switch__label--switch-on {
    display: none;
  }

  input[type='checkbox'] {
    display: none;
  }
  .switch__elem {
    order: 2;
    position: relative;
    display: block;
    margin: 0 16px;
    width: 56px;
    height: 28px;
    background-color: #0060cf;
    border-radius: 14px;
    padding: 6px;
    box-sizing: border-box;
    transition: all 200ms ease;
    cursor: pointer;
    &::before {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: #ffffff;
      transform: translateX(0);
      transition: all 200ms ease;
    }
  }
  input[type='checkbox']:checked ~ .switch__elem {
    &::before {
      transform: translateX(29px);
    }
  }
  &.switch-active {
    label.switch__label--switch-off {
      display: block;
    }
    span.switch__label--switch-off {
      display: none;
    }
    label.switch__label--switch-on {
      display: none;
    }
    span.switch__label--switch-on {
      display: block;
    }
  }
}
</style>
