<script>
import IconSvg from "@/components/icons/IconSvg.vue"

export default {
  name: 'HeartIcon',
  extends: IconSvg,
  props: {
    d: {
      type: String,
      default: 'M16.4561 27.772L26.5336 17.5946C27.2396 16.9014 27.7914 16.067 28.1529 15.1459C28.5148 14.2237 28.6777 13.2354 28.6309 12.2458C28.584 11.2562 28.3286 10.2877 27.8813 9.40375C27.434 8.5198 26.8049 7.74037 26.0352 7.11656C25.2656 6.49276 24.3728 6.03867 23.4154 5.78406C22.458 5.52946 21.4576 5.48009 20.4797 5.63919C19.5019 5.79829 18.5687 6.16227 17.7414 6.70726C17.1221 7.11524 16.5726 7.61775 16.1121 8.19517C15.3373 7.17072 14.2921 6.37513 13.0876 5.90219C11.6507 5.33807 10.0678 5.26467 8.58492 5.69343C7.10208 6.12219 5.80254 7.02906 4.8885 8.27292C3.97619 9.51445 3.499 11.0222 3.53079 12.5624C3.51972 13.5083 3.70525 14.4462 4.07566 15.3166C4.44599 16.1869 4.99302 16.9707 5.68205 17.6185L15.7457 27.7721L16.101 28.1306L16.4561 27.772Z'
    },
    stroke: {
      type: String,
      default: '#6B7A99'
    }
  }
}
</script>
