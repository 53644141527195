<template>
  <div :class="{ body: true, active: menuActive }">
    <menu-wrapper :pageName="name" />
    <div class="page-wrapper">
      <main class="content" :class="{ 'content--short': isPublicUser }">
        <div class="container">
          <div class="auth-component__section">
            <recoveryStart />
          </div>
        </div>
      </main>
    </div>
    <page-footer />
  </div>
</template>

<script>

import recoveryStart from "@/components/authComponent/recoveryStart.vue"
import menuWrapper from "@/components/menuWrapper";
import PageFooter from "@/components/PageFooter";
import { mapState, mapGetters } from 'vuex';
export default {
  name: "recoveryPage",
  components: {
    recoveryStart,
    menuWrapper,
    PageFooter
  },
  data()  {
    return {
      name: 'Восстановление пароля'
    }
  },
  computed: {
    ...mapState(['menuActive']),
    ...mapGetters(['isPublicUser']),
  }
}
</script>

<style lang="scss">

</style>
