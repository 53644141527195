<template>
  <div
    class="main-layout"
    attr="28"
    @click="hideMenu"
  >
    <router-view />
  </div>
</template>

<script>
import {mapActions, mapState, mapGetters} from 'vuex';
import {getUser, GET_ADFS_ENTRY_POINT} from '@/store/actionsName';
import {deleteAllNotification} from '@/store/mutationsName';
import localStorageService from '@/utils/localStorageService';
import {PUBLIC_TOKEN} from '@/utils/constants';
import {Notification} from 'element-ui';
import setNotification from '@/utils/setNotification';
import checkExternalRedirect from '@/utils/checkExternalRedirect';
import logOut from '@/utils/logOut';
import removePreloader from '@/utils/removePreloader.js';
import {checkMobileViewTokenTimer} from '@/utils/checkMobileViewToken';

checkMobileViewTokenTimer();

export default {
  name: 'ELKApp',
  data() {
    return {
      checkServerErrorTime: null
    };
  },
  computed: {
    ...mapGetters([
      'getPublicAdfsUrl',
      'devConstants/isLocalHost',
      'hasRole',
      'userDpo',
      'userRepresent'
    ]),
    ...mapState(['notifications', 'menuActive', 'mainPageNotAvailable'])
  },
  watch: {
    notifications(val) {
      if (val.length) {
        val.forEach(item => {
          setTimeout(() => {
            Notification(item);
          }, 0);
        });
        this.$store.commit(deleteAllNotification);
      }
    }
  },
  async created() {
    try {
      this.checkTouchDevice();

      this.checkServerErrorTime = setTimeout(() => {
        this.redirectToServerErrorPage();
      }, 20000);

      await this[GET_ADFS_ENTRY_POINT]();
      if (this.needCheckAdfsSession() && !this['devConstants/isLocalHost']) {
        clearTimeout(this.checkServerErrorTime);
        return location.assign(this.getPublicAdfsUrl);
      } else {
        await this.getUserData();
        this.checkAvailableFunctionality();
        clearTimeout(this.checkServerErrorTime);
        removePreloader();

        if (localStorageService.getExternalRedirect()) {
          if (localStorageService.getToken() === PUBLIC_TOKEN) {
            checkExternalRedirect();
          } else {
            await this.logOut();
          }
        }
      }
    } catch (e) {
      this.$i18n.locale = this.$store.getters['locale/getLocaleName'];

      this.redirectToServerErrorPage();
      if (!e.isHandled) {
        setNotification({
          message: this.$t(this.$getCommonCaptionByName('error_loadingSite')),
          duration: 30000
        });
      }
    }

    // Check role KEDO
    if (this.hasRole('kedo')) {
      await this.getStatusSevKedo();
    }

    if (this.userDpo || this.userRepresent) {
      Promise.all([
        this['cart/init'](),
        this['programs/init'](),
        this['compares/init'](),
        this['profileFillCheck/getProfileFillCheck']()
      ]).catch();
    }
  },
  methods: {
    ...mapActions([
      getUser,
      GET_ADFS_ENTRY_POINT,
      'cart/init',
      'compares/init',
      'programs/init',
      'profileFillCheck/getProfileFillCheck',
      'getStatusSevKedo',
      'checkTouchDevice'
    ]),
    hideMenu(e) {
      if (!e.target.closest('.js-aside') && this.menuActive) {
        this.$store.commit('resizeMenu', false);
      }
    },
    async getUserData() {
      try {
        await this[getUser]();
      } catch (e) {
        throw new Error(e);
      }
    },
    needCheckAdfsSession() {
      if (
        localStorageService.getCheckAdfsSession() === null ||
        localStorageService.getToken() === null
      ) {
        localStorageService.setToken(PUBLIC_TOKEN);
        localStorageService.setLocaleId('1');
        localStorageService.setCheckAdfsSession(true);
        localStorageService.setRedirectUrl(this.$route.fullPath);
        return true;
      }

      if (
        localStorageService.getToken() === PUBLIC_TOKEN &&
        localStorageService.isCheckAdfsSessionTrue()
      ) {
        localStorageService.setRedirectUrl(this.$route.fullPath);
        return true;
      }
      localStorageService.setCheckAdfsSession(true);
      return false;
    },
    logOut,
    checkAvailableFunctionality() {
      if (this.$route.path !== '/' && this.mainPageNotAvailable) {
        this.$router.push('/');
      }
    },
    redirectToServerErrorPage() {
      clearTimeout(this.checkServerErrorTime);
      if (this.$route.name !== 'serverError') {
        this.$router.push({name: 'serverError'});
      }
    }
  }
};
</script>
