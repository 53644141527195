import { GET_GENDERS, SET_GENDER } from '@/api';
import Axios from '@/utils/Elk-Axios';
import setNotification from "@/utils/setNotification";

async function getGenders() {
  try {
    const { data } = await Axios.get(GET_GENDERS);
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
}

async function setGender(id) {
  try {
    const { data } = await Axios.post(SET_GENDER, {
      gender_id: id,
    });
    setNotification({
      message: `Пол слушателя изменен`,
      type: 'success',
    });
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
}

export { getGenders, setGender };
