export default {
  namespaced: true,
  state: {
    countries: [],
  },
  getters: {
    get: (state) => () => state.countries,
  },
  mutations: {
    set(state, countries) {
      state.countries = countries ?? [];
    },
  },
};
