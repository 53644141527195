import Axios from '@/utils/Elk-Axios';
import { REF_CAMPUSES } from '@/api';
import {get_codes} from "@/store/utils";

export default {
  namespaced: true,
  state: {
    /** @type {Reference[]} */
    items: [],
  },
  getters: {
    /** @returns {Reference[]} */
    items: (state) => () => state.items,
  },
  mutations: {
    set(state, items) {
      state.items = items ?? []
    },
  },
  actions: {
    async fetch({ commit }) {
      const response = await Axios.get(REF_CAMPUSES)
      commit('set', [
        {
          "name": 'Москва',
          "code": get_codes('москва', response.data.data),
        },
        {
          "name": "Санкт-Петербург",
          "code": get_codes('петербург', response.data.data),
        },
        {
          "name": "Нижний Новгород",
          "code": get_codes('нижний', response.data.data),
        },
        {
          "name": "Пермь",
          "code": get_codes('пермь', response.data.data),
        },
      ] ?? []);
    },
  }
}
