<template>
  <div class="confirm__message">
    <div
      :class="{
        inputWrap: true,
        'error-system': hasError
      }"
    >
      <label :for="id">{{ label }}</label>
      <input
        :id="id"
        v-model="value.$model"
        class="input-form__auth"
        :type="type"
        oninput="(type === 'number') && (this.value = this.value.slice(0, 4))"
        :name="id"
      />
      <span
        v-if="hasError"
        class="error__message"
      >
        {{ errorText }}
      </span>
    </div>
    <div class="resend-message">
      <button
        class="btn btn-resend__message"
        :disabled="!canResendConfirmationCode || preloader"
        @click.prevent="resetTimer"
      >
        <loading
          v-if="preloader"
          small
        />
        <template v-else>
          {{ reSendText }}
        </template>
      </button>
      <div class="resend-message__inform">
        {{ timerText }}<span>{{ timerCountString }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import loading from '@/components/common/elkLoading.vue';

const formatTime = number => (number > 9 ? number : `0${number}`);

export default {
  name: 'ConfirmMessage',
  components: {loading},

  props: {
    errorText: {
      type: String,
      default: 'Неправильный код'
    },
    reSendText: {
      type: String,
      default: 'Выслать код повторно'
    },
    timerText: {
      type: String,
      default: 'Будет доступно через: '
    },
    id: {
      type: String,
      default: 'confirmationCode'
    },
    label: {
      type: String,
      default: 'Код из СМС'
    },
    timeRemaining: {
      type: Number,
      default: 60
    },
    confirmationCode: {
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: () => ({
        $dirty: false,
        $error: false,
        $invalid: false,
        $model: ''
      })
    },
    preloader: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text',
      required: false
    }
  },

  data() {
    return {
      timerCount: this.timeRemaining,
      timerId: null
    };
  },

  computed: {
    hasError() {
      return this.value.$error || (this.value.$dirty && this.value.$invalid);
    },
    canResendConfirmationCode() {
      return this.timerCount === 0;
    },
    timerCountString() {
      return `${formatTime(parseInt(this.timerCount / 60))}:${formatTime(
        this.timerCount % 60
      )}`;
    }
  },

  watch: {
    timerCount(oldTimer) {
      if (oldTimer < 1) return this.stopTimer();
    }
  },

  created() {
    this.startTimer();
    this.$emit('on-init');
  },

  methods: {
    startTimer() {
      if (this.timerCount < 1) return;

      this.timerId = setInterval(() => {
        this.timerCount--;
      }, 1000);
    },
    resetTimer() {
      this.timerCount = this.timeRemaining;
      this.$emit('on-reset-timer');
      this.startTimer();
    },
    stopTimer() {
      clearInterval(this.timerId);
    }
  }
};
</script>

<style lang="scss">
.confirm__message {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .inputWrap {
    width: calc(50% - 23px);
    .error__message {
      position: absolute;
      bottom: -24px;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: $error-system-red;
    }
    .input-form__auth:focus-visible {
      border: 1px solid $blue-color;
    }
  }
  .inputWrap.error-system {
    input {
      border: 1px solid $error-system-red;
    }
    .input-form__auth:focus-visible {
      border-color: $error-system-red;
      outline: 1px solid $error-system-red;
    }
  }
  .resend-message {
    width: 50%;
    margin-top: 24px;
  }
  //.btn.btn.btn-resend__message{
  //  font-weight: 600;
  //  font-size: 14px;
  //  line-height: 20px;
  //  background-color: $light-gray;
  //  color: $blue-color;
  //  opacity: 1;
  //}
  //
  //.btn.btn.btn-resend__message{
  //  height: 48px;
  //}
  .btn.btn.btn-resend__message:disabled {
    background-color: $light-gray;
    cursor: wait;
  }
  .btn.btn.btn-resend__message:disabled {
    opacity: 0.5;
    color: $blue-color;
  }
}
</style>
