<template>
  <div class="info-noty">
    <div class="info-noty__img">
      <error404-img class="errorSearch" />
    </div>
    <div class="info-noty__block">
      <div class="info-noty__title">
        {{ $t(getCommonCaptionByName('error_serviceIsNotAvailable')) }}
      </div>
      <div class="info-noty__subtitle">
        {{ $t(getCommonCaptionByName('recaptcha_errorExecuting')) }}
      </div>
    </div>
  </div>
</template>

<script>
import { getCommonCaptionByName } from '@/i18n/utils';
import error404Img from '@/assets/icons/error-404.svg?inline';

export default {
  name: 'recaptch-error',
  components: {
    error404Img,
  },
  methods: {
    getCommonCaptionByName,
  },
};
</script>
