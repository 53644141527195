<template>
  <ErrorPagePublic v-if="isPublicUser" />
  <div
    v-else
    :class="{body: true, active: menuActive}"
  >
    <menu-wrapper :page-name="labels.title" />
    <div class="page-wrapper">
      <main
        class="content"
        :class="pageClass"
      >
        <section class="selected-programs-section">
          <div
            v-if="arPVHistory.length > 0"
            class="container"
          >
            <h1 class="page-title">
              {{ labels.title }}
            </h1>

            <div class="result-panel">
              <div class="result-panel-item">
                <div class="result-panel-item__key">
                  {{ labels.programsFound }}:
                </div>
                <div class="result-panel-item__value">
                  {{ arPVHistory.length }}
                </div>
              </div>

              <div class="result-panel-item">
                <div class="result-panel-item__key">{{ labels.sortBy }}:</div>
                <div class="result-panel-item__value">
                  <pseudoselect
                    v-model="sortBy"
                    :ar-options="sortOptions"
                    :default-value="sortByInit"
                    @input="onFilterChange"
                  />
                </div>
              </div>
            </div>
            <program-grid>
              <program-card
                v-for="program in arPVHistory"
                :key="program.pcId"
                :pc-id="program.pcId"
                :pc-active="program.pcActive"
                :pc-direct="program.pcDirect"
                :pc-caption="program.pcCaption"
                :pc-start="program.pcStart"
                :pc-duration="program.pcDuration"
                :pc-price="program.pcPrice"
                :pc-old-price="program.pcOldPrice"
                :pc-type="program.pcType"
                :pc-favorites="program.pcFavorites"
                :pc-comparison="program.pcComparison"
                :pc-detail-url="program.pcDetailUrl"
                :pc-campus="program.pcCampus"
                :edu-format="program.eduFormat"
                :pc-duration-value="program.pcDurationValue"
                :pc-training-areas="program.pcTrainingAreas"
                @returnFavoriteId="toggleProgramFavorite"
                @returnComparisonId="toggleProgramComparison"
              />
            </program-grid>
          </div>
          <empty-wrapper v-else>
            <empty-data-message :text="labels.emptyHistory" />
          </empty-wrapper>
        </section>
      </main>
    </div>
    <page-footer />
  </div>
</template>

<script>
import {mapActions, mapState, mapGetters} from 'vuex';
import Axios from '@/utils/Elk-Axios';
import {GET_BROWSING_HISTORY} from '@/api';
import {prepareProgramCardRecommendedView} from '@/utils/prepareProgramCardData';
import setNotification from '@/utils/setNotification';
import menuWrapper from '@/components/menuWrapper';
import PageFooter from '@/components/PageFooter';
import EmptyDataMessage from '@/components/common/elkEmptyDataMessage';
import EmptyWrapper from '@/components/common/elkEmptyWrapper';

import ProgramGrid from '@/components/programs/ProgramGrid.vue'; //контейнер для  программ
import ProgramCard from '@/components/programs/Program-card.vue'; //карточка программы
import Psevdoselect from '@/components/formElements/Psevdoselect.vue';
import ErrorPagePublic from '@/views/404/index.vue';
import localization from '@/i18n/localization';

export default {
  name: 'ProgramViewingHistory',
  components: {
    ErrorPagePublic,
    menuWrapper,
    PageFooter,
    EmptyWrapper,
    EmptyDataMessage,
    'program-grid': ProgramGrid,
    'program-card': ProgramCard,
    pseudoselect: Psevdoselect
  },
  data() {
    return {
      pageClass: 'program-viewing-history',
      localization: localization('dpo.programs.viewing_history'),
      arPVHistory: [],
      sortByInit: {
        label: localization('dpo.programs.viewing_history').commonCaption(
          'sortByDefault'
        ),
        value: 'default'
      },
      sortBy: {}
    };
  },

  computed: {
    ...mapState(['menuActive']),
    ...mapGetters(['isPublicUser']),
    labels() {
      return {
        title: this.localization.widgetCaption('browsingHistory'),
        programsFound: this.localization.commonCaption('programsFound'),
        sortBy: this.localization.commonCaption('sortBy'),
        emptyHistory: this.localization.widgetCaption('emptyHistory')
      };
    },
    sortOptions() {
      return [
        {
          label: this.localization.commonCaption('sortByDefault'),
          value: 'default'
        },
        {
          label: `${this.localization.commonCaption(
            'sortByDate'
          )} <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 13.5V2.5" stroke="#0F0F14" stroke-linecap="round" stroke-linejoin="round"/><path d="M3.5 7L8 2.5L12.5 7" stroke="#0F0F14" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
          value: 'date'
        },
        {
          label: `${this.localization.commonCaption(
            'sortByDate'
          )} <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 2.5L8 13.5" stroke="#0F0F14" stroke-linecap="round" stroke-linejoin="round"/><path d="M12.5 9L8 13.5L3.5 9" stroke="#0F0F14" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
          value: 'date_desc'
        },
        // {
        //   label: this.localization.commonCaption('sortByProgram'),
        //   value: 'title'
        // },
        {
          label: `${this.localization.commonCaption(
            'sortByPriceAsc'
          )} <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 13.5V2.5" stroke="#0F0F14" stroke-linecap="round" stroke-linejoin="round"/><path d="M3.5 7L8 2.5L12.5 7" stroke="#0F0F14" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
          value: 'price'
        },
        {
          label: `${this.localization.commonCaption(
            'sortByPriceDesc'
          )} <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 2.5L8 13.5" stroke="#0F0F14" stroke-linecap="round" stroke-linejoin="round"/><path d="M12.5 9L8 13.5L3.5 9" stroke="#0F0F14" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
          value: 'price_desc'
        },
        // {
        //   label: this.localization.commonCaption('sortByDiscount'),
        //   value: 'discount'
        // }
      ];
    }
  },

  async created() {
    await this.fetchHistory();
  },

  methods: {
    ...mapActions(['addProgramToFavorites', 'deleteProgramFromFavorites']),
    getPayload() {
      let sort = '';

      if (this.sortBy?.value && this.sortBy.value !== 'default') {
        sort += `?sort_desc=true&sort_by=${this.sortBy.value}`;
      }

      return sort;
    },
    async fetchHistory() {
      const query = this.getPayload();
      try {
        const response = await Axios.get(`${GET_BROWSING_HISTORY}${query}`);
        if (response.data?.data?.length) {
          this.arPVHistory = response.data.data.map(
            prepareProgramCardRecommendedView
          );
        }
      } catch (e) {
        setNotification({
          message: e.response?.data?.message ?? ''
        });
      }
    },
    toggleProgramFavorite(id, isFavorite) {
      for (let program of this.arPVHistory) {
        if (program.pcId === id) return (program.pcFavorites = isFavorite);
      }
    },
    toggleProgramComparison(id, isComparison) {
      for (let program of this.arPVHistory) {
        if (program.pcId === id) return (program.pcComparison = isComparison);
      }
    },
    async onFilterChange(v) {
      if (v.length === 2) {
        this.sortBy = {
          value: v[0],
          label: v[1]
        };
      }
      await this.fetchHistory();
      // let tmp = Array.from(this.arPVHistory);
      // tmp = sortFilterDrop(tmp, this.sortBy[0]);
      // this.arPVHistory = Array.from(tmp);
    }
  }
};
</script>

<style lang="scss"></style>
