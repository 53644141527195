import {
  required,
  numeric,
  email,
  minLength,
  minValue,
  maxValue,
  helpers
} from 'vuelidate/lib/validators';
import {
  isDate,
  isAfter,
  isBefore,
  isEqual,
  isValid,
  addDays,
  subDays,
  startOfDay,
  format
} from 'date-fns';

// DATE-RULES:::start
const checkDate = value => {
  const temp = new Date(value);
  return isDate(temp) && isValid(temp);
};

const date = value => {
  if (!helpers.req(value)) return true;
  // Если интервал
  if (Array.isArray(value)) {
    let intervalDate = true;
    value.forEach(e => {
      if (!checkDate(e)) {
        intervalDate = false;
      }
    });
    return intervalDate;
  }
  return checkDate(value);
};

const dateYesterday = () => {
  return subDays(new Date(), 1);
};

const dateTomorrow = () => {
  return addDays(new Date(), 1);
};

const isDateAfter = ruleDate => {
  let tempDate = new Date(ruleDate);
  if (ruleDate === 'tomorrow') {
    tempDate = dateTomorrow();
  } else if (ruleDate === 'yesterday') {
    tempDate = dateYesterday();
  } else if (!isDate(tempDate)) {
    return {};
  }
  tempDate = startOfDay(tempDate);
  return helpers.withParams(
    {type: 'dateAfter', value: format(tempDate, 'dd.MM.yyyy')},
    value => {
      // Если интервал
      if (Array.isArray(value) && value.length >= 2) {
        const tempValue = startOfDay(new Date(value[0]));
        return isAfter(tempValue, tempDate);
      }
      return isAfter(startOfDay(new Date(value)), tempDate);
    }
  );
};

const isDateBefore = ruleDate => {
  let tempDate = new Date(ruleDate);
  if (ruleDate === 'tomorrow') {
    tempDate = dateTomorrow();
  } else if (ruleDate === 'yesterday') {
    tempDate = dateYesterday();
  } else if (!isDate(tempDate)) {
    return {};
  }
  tempDate = startOfDay(tempDate);
  return helpers.withParams(
    {type: 'dateBefore', value: format(tempDate, 'dd.MM.yyyy')},
    value => {
      // Если интервал
      if (Array.isArray(value) && value.length >= 2) {
        const tempValue = startOfDay(new Date(value[1]));
        return isBefore(tempValue, tempDate);
      }
      return isBefore(startOfDay(new Date(value)), tempDate);
    }
  );
};

const isDateAfterOrEqual = ruleDate => {
  let tempDate = new Date(ruleDate);
  if (ruleDate === 'tomorrow') {
    tempDate = dateTomorrow();
  } else if (ruleDate === 'yesterday') {
    tempDate = dateYesterday();
  } else if (!isDate(tempDate)) {
    return {};
  }
  tempDate = startOfDay(tempDate);
  return helpers.withParams(
    {type: 'dateAfterOrEqual', value: format(tempDate, 'dd.MM.yyyy')},
    value => {
      // Если интервал
      if (Array.isArray(value) && value.length >= 2) {
        const tempValue = startOfDay(new Date(value[0]));
        return isEqual(tempValue, tempDate) || isAfter(tempValue, tempDate);
      }
      return (
        isEqual(startOfDay(new Date(value)), tempDate) ||
        isAfter(startOfDay(new Date(value)), tempDate)
      );
    }
  );
};

const isDateBeforeOrEqual = ruleDate => {
  let tempDate = new Date(ruleDate);
  if (ruleDate === 'tomorrow') {
    tempDate = dateTomorrow();
  } else if (ruleDate === 'yesterday') {
    tempDate = dateYesterday();
  } else if (!isDate(tempDate)) {
    return {};
  }
  tempDate = startOfDay(tempDate);
  return helpers.withParams(
    {type: 'dateBeforeOrEqual', value: format(tempDate, 'dd.MM.yyyy')},
    value => {
      // Если интервал
      if (Array.isArray(value) && value.length >= 2) {
        const tempValue = startOfDay(new Date(value[1]));
        return isEqual(tempValue, tempDate) || isBefore(tempValue, tempDate);
      }
      return (
        isEqual(startOfDay(new Date(value)), tempDate) ||
        isBefore(startOfDay(new Date(value)), tempDate)
      );
    }
  );
};
// DATE-RULES:::end

const size = ruleSize =>
  helpers.withParams({type: 'size', value: ruleSize}, value => {
    if (typeof value === 'string' || typeof value === 'number') {
      return value.length == ruleSize;
    }
    if (typeof value === 'boolean') {
      return true;
    }
    if (Array.isArray(value)) {
      return value.length == ruleSize;
    }
    return true;
  });

const validRules = {
  required,
  numeric,
  email,
  min: minLength,
  minValue,
  maxValue,
  digits: size,
  date,
  after: isDateAfter,
  before: isDateBefore,
  after_or_equal: isDateAfterOrEqual,
  before_or_equal: isDateBeforeOrEqual
};

const getRuleField = (currentRule, rules) => {
  const isNumeric = rules?.includes('numeric');
  if (isNumeric && currentRule === 'min') return 'minValue';
  if (isNumeric && currentRule === 'max') return 'maxValue';
  return currentRule;
};

export default (rules = '') => {
  const arr = rules.split('|');
  const exceptions = ['email'];
  const result = {};
  arr.forEach(e => {
    if (e.includes(':')) {
      const [rule, value] = e.split(':');
      const field = getRuleField(rule, arr);
      if (Object.hasOwn(validRules, field)) {
        if (exceptions.includes(field)) {
          result[field] = validRules[field];
        } else {
          result[field] = validRules[field].call(null, value);
        }
      }
    } else if (Object.hasOwn(validRules, getRuleField(e, arr))) {
      const field = getRuleField(e, arr);
      result[field] = validRules[field];
    }
  });

  return result;
};
