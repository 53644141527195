<script>
import RangeFilter from "@/components/filters/RangeFilter.vue";

export default {
  name: 'ProgramPriceFilter',
  extends: RangeFilter,
  props: {
    min: {
      type: Number,
    },
    max: {
      type: Number,
    },
    param: {
      type: String,
      default: 'price'
    },
    range: {
      type: Array,
      default: () => []
    },
  },
  methods: {
    getValue() {
      if (this.selected[1] || null) {
        return [{
          label: 'От ' + this.selected[0] + ' до ' + this.selected[1] + ' руб.',
          value: {
            from: this.selected[0],
            to: this.selected[1]
          },
          param: this.param
        }]
      }

      return null
    }
  }
}
</script>
