<template>
  <label class="cascade-checkbox">
    <input
      v-model="checkbox"
      :disabled="false"
      class="checkbox"
      type="checkbox"
      @change="onChange"
    />
    <span class="checkbox-text cascade-checkbox__text">
      <slot />
    </span>
  </label>
</template>

<script>
import statusToBool, {
  boolToStatus
} from '@/components/formElements/CascadeSelect/statusToBool';

export default {
  name: 'CascadeCheckbox',
  model: {
    prop: 'valueModel',
    event: 'on-change'
  },
  inject: ['changeCheckbox'],
  props: {
    valueModel: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      checkbox: statusToBool(this.valueModel)
    };
  },
  watch: {
    checkbox(v) {
      const newStatus = boolToStatus(v);
      if (this.valueModel !== v) {
        this.$emit('on-change', newStatus);
      }
    },
    valueModel(v) {
      const newValue = statusToBool(v, this.checkbox);
      if (newValue !== this.checkbox) {
        this.checkbox = newValue;
      }
    }
  },
  methods: {
    onChange() {
      setTimeout(() => {
        this.changeCheckbox();
      }, 0);
    }
  }
};
</script>

<style scoped>
.cascade-checkbox {
  display: block;
  padding: 4px 6px;
  border-radius: 4px;
  cursor: pointer;
}
.cascade-checkbox:hover {
  background-color: rgba(0, 79, 207, 0.1);
}
.cascade-checkbox .cascade-checkbox__text::before {
  transition: none;
}
</style>
