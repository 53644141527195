<template>
  <div :class="{ body: true, active: menuActive }">
    <menu-wrapper :page-name="name" />
    <div class="page-wrapper">
      <main class="content" :class="pageClass">
        <div class="container">
          <template v-for="widget in getSortedWidgets">
            <component
              :is="loader(widget.signature)"
              v-if="widget.visible === null || widget.visible === true"
              :key="widget.signature"
              :widget-data="widget"
            />
          </template>
        </div>
      </main>
    </div>
    <page-footer />
  </div>
</template>

<script>
import menuWrapper from "@/components/menuWrapper";
import PageFooter from "@/components/PageFooter";
import { mapState, mapGetters } from "vuex";
import loader from "@/utils/widgetLoader";

export default {
  components: {
    menuWrapper,
    PageFooter,
  },
  props: {
    widgets: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      name: "Главная",
      pageClass: "main-page",
    };
  },
  computed: {
    ...mapState(["menuActive"]),
    ...mapGetters(["isPublicUser"]),
    getSortedWidgets() {
      return this.sortItems(this.widgets, "elk.main.top_notification", 0);
    },
  },
  methods: {
    loader,
    sortItems(array, signature, position) {
      const idOfPlacingItem = array.findIndex(
        (item) => item.signature === signature
      );

      if (idOfPlacingItem !== -1 && idOfPlacingItem !== position) {
        const newArray = [...array];
        newArray.splice(position, 0, newArray.splice(idOfPlacingItem, 1)[0]);
        return newArray;
      }

      return array;
    },
  },
};
</script>

<style lang="scss">
.main-layout .main-page{
  .container{
    padding-left: 20px;
    padding-right: 20px;
  }
}
.temporary-picture{
  margin-bottom: 24px;
}
.prog-selectoin-section {
  padding-top: 30px;
  padding-bottom: 50px;
}

.prog-selectoin-section .slider-container {
  overflow: hidden;
}

.schedule-section {
  padding-top: 30px;
  padding-bottom: 50px;
}

.count-elem {
  position: relative;
  top: -13px;
  right: -5px;
  display: inline-block;
  // min-width: 28px;
  padding: 5px 7px;
  border-radius: 13px;
  background-color: #6B7A99;
  text-align: center;
  font-size: 14px;
  line-height: 14px;
  color: #ffffff;
  font-weight: 600;
}

.banner-sl {
  margin-bottom: 60px;

  .news-line-section {
    padding-bottom: 50px;
  }
}

.addprofed-section {
  padding-top: 45px;
  padding-bottom: 80px;
}
.services-section{
  padding-bottom: 20px;
}
.questions-answers-section{
  padding-top: 32px;
  // padding-bottom: 30px;
}

@media screen and (max-width: 1200px){
  .addprofed-section {
    padding-top: 35px;
    padding-bottom: 50px;
  }
  .prog-selectoin-section,
  .schedule-section {
    padding-top: 20px;
    padding-bottom: 35px;
  }
}

@media screen and (max-width: 1000px) {
  .prog-selectoin-section {
    .container {
      padding-right: 0;
    }
  }
}
@media screen and (max-width: 800px){
  .main-page{
      .widget {
        position: relative;
        margin: 15px 0;
      }
    }
    .addprofed-section {
        padding-top: 20px;
        padding-bottom: 35px;
    }
    .prog-selectoin-section{
      padding-bottom: 0;
    }
    .services-section {
      padding-bottom: 20px;
      padding-top: 20px;
  }
}
@media screen and (max-width: 600px){
    .title-section {
      margin-bottom: 16px;
      font-size: 20px;
      line-height: 1.23;
  }
  .main-page{
    a {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #0050CF;
    }
  }

}
@media screen and (max-width: 360px){
    .title-section {
      margin-bottom: 16px;
      font-size: 18px;
      line-height: 1.2;
  }
}

</style>
