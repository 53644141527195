import { SET_VALIDATION_PERIODS } from '../mutationsName';
import { GET_VALIDATION_PERIODS_ACTION } from '../actionsName';

import { GET_VALIDATION_PERIODS } from '@/api';
import Axios from '@/utils/Elk-Axios';

const state = {
  data: [],
};

const getters = {
  getValidationPeriods() {
    //
  },
};

const mutations = {
  [SET_VALIDATION_PERIODS](state, payload) {
    state.data = payload;
    state.isLoaded = true;
  },
};

const actions = {
  async [GET_VALIDATION_PERIODS_ACTION]({ commit, state }) {
    if (!state.data.length) {
      const { data } = await Axios.get(GET_VALIDATION_PERIODS);

      commit(SET_VALIDATION_PERIODS, data);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
