<template>
  <div
    v-if="isError"
    class="avatar__dummy"
    :style="{
      width: getAvatarSize,
      height: getAvatarSize,
      fontSize: getFontSize
    }"
  >
    {{ getTheFirstLetters }}
  </div>
  <el-image
    v-else
    class="avatar"
    :style="{width: getAvatarSize, height: getAvatarSize}"
    :src="urlAvatar"
    alt="Аватар"
  >
    <template slot="error">
      <img
        :style="{width: getAvatarSize, height: getAvatarSize}"
        v-if="genderAvatar === getGenderCodes.male"
        :src="require(`@/assets/icons/staff/male-icon.svg`)"
      />
      <img
        :style="{width: getAvatarSize, height: getAvatarSize}"
        v-else-if="genderAvatar === getGenderCodes.female"
        :src="require(`@/assets/icons/staff/female-icon.svg`)"
      />
      <div
        v-else
        class="avatar__dummy"
        :style="{
          width: getAvatarSize,
          height: getAvatarSize,
          fontSize: getFontSize
        }"
      >
        {{ getTheFirstLetters }}
      </div>
    </template>
  </el-image>
</template>

<script>
import Axios from '@/utils/Elk-Axios';
import {GET_PHOTO_BY_EMAIL} from '@/api';

export default {
  name: 'avatar',
  props: {
    url: String,
    email: String,
    size: {
      type: [String, Number],
      default: 52
    },
    genderId: Number,
    fioFull: String,
    fioSeparate: Array
  },
  data() {
    return {
      urlAvatar: '',
      genderAvatar: '',
      isError: false
    };
  },
  computed: {
    getGenderCodes() {
      return {
        notDefined: 2,
        male: 0,
        female: 1
      };
    },
    getTheFirstLetters() {
      try {
        if (this.fioSeparate) {
          return this.fioSeparate[0].charAt(0) + this.fioSeparate[1].charAt(0);
        }

        if (this.fioFull) {
          const arrayOfFioParts = this.fioFull.split(' ');
          return arrayOfFioParts[1].charAt(0) + arrayOfFioParts[0].charAt(0);
        }

        return '';
      } catch (e) {
        return '';
      }
    },
    getAvatarSize: function () {
      return this.size + 'px';
    },
    getFontSize() {
      const size = this.size / 3 > 10 ? this.size / 3 : 10;
      return size + 'px';
    }
  },
  watch: {
    url: function (newVal) {
      this.urlAvatar = newVal;
    }
  },
  async created() {
    this.urlAvatar = this.url;
    this.genderAvatar = this.genderId;

    if (this.email) {
      try {
        const {data} = await Axios.get(GET_PHOTO_BY_EMAIL, {
          params: {
            email: this.email
          }
        });

        if (data && (data.avatar || data.gender_code)) {
          if (data.avatar) {
            this.urlAvatar = data.avatar;
          } else if (data.gender_code) {
            this.genderAvatar = data.gender_code;
          }
        } else {
          //sentry
          throw new Error();
        }
      } catch (e) {
        this.isError = true;
      }
    }
  }
};
</script>
