import Axios from '@/utils/Elk-Axios';
import { V1_PROFILE_FILL_CHECK } from '@/api';

export default {
  namespaced: true,
  state: {
    profileFillCheck: {
      fill: false,
      can_order: false,
      is_foreigner: false,
      errors: []
    }
  },
  getters: {
    /** @returns Boolean */
    isProfileFilled: state => state.profileFillCheck.fill,
    /** @returns Boolean */
    isProfileCanOrder: (state) => state.profileFillCheck.can_order,
    /** @returns Boolean */
    isForeigner: (state) => state.profileFillCheck.is_foreigner,
    /** @returns Error[] */
    profileFillErrors: state => state.profileFillCheck.errors
  },
  mutations: {
    setProfileFillCheck: (state, profileFillCheck) => {
      state.profileFillCheck = {
        fill: profileFillCheck?.fill ?? false,
        can_order: profileFillCheck?.can_order ?? false,
        is_foreigner: profileFillCheck?.is_foreigner ?? false,
        errors: profileFillCheck?.errors ?? []
      };
    }
  },
  actions: {
    getProfileFillCheck: async context => {
      try {
        const { data } = await Axios.get(V1_PROFILE_FILL_CHECK);
        if (data.data) {
          context.commit('setProfileFillCheck', data.data);
        }
      } catch {
        /** */
      }
    }
  }
};
