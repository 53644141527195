export const setUser = 'set-user';
export const setRouteTree = 'set-route-tree';
export const clearState = 'clear-state';
export const setNotification = 'set-notification';
export const deleteAllNotification = 'delete-all-notification';
export const setBreadcrumbs = 'set-breadcrumbs';
export const clearBreadcrumbs = 'clear-breadcrumbs';
export const SET_FOOTER = 'set-footer';
export const setAvatar = 'set-avatar';
export const SET_LOCALE = 'set-locale';
export const SET_ADFS_ENTRY_POINT = 'setAdfsEntryPoint';
export const SET_SIMPLE_DICTIONARY = 'set-simple-dictionary';
export const SET_LEARNERS = 'set-learners';
export const SET_CURRENT_STUDY_YEAR = 'set-current-study-year';
export const SET_CURRENT_VALIDATION_PERIOD = 'set-current-validation-period';
export const SET_VALIDATION_PERIODS = 'set-validation-periods';
