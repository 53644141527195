<template>
  <div
    class="filter_item"
    @click="removeFilter(info.removeEventName)"
  >
    <span class="mr-2">{{ info.name ?? info }}</span>

    <component
      :is="iconLoader('cross')"
      class="icon nav__icon"
    />
  </div>
</template>

<script>
import iconLoader from '@/utils/iconLoader';
import {filterBus} from '@/main';

export default {
  name: 'FilterItem',
  props: ['info'],
  computed: {},

  methods: {
    iconLoader,
    removeFilter(eventName) {
      const text = this.info?.name ?? this.info;
      filterBus.$emit(eventName ? eventName : 'removeFilter', text);
    }
  }
};
</script>

<style scoped>
.filter_item {
  min-height: 38px;
  padding: 9px 15px 9px 15px;
  border-radius: 4px;
  background-color: var(--dark-blue);
  display: flex;
  justify-content: space-between;
  color: white;
  font-weight: 600;
  cursor: pointer;
  min-width: 120px;
  margin: 0 10px 10px 0;
  transition: transform 300ms ease, opacity 200ms ease 100ms;
}
</style>
