<template>
  <button class="btn-modal__status">
    <svg
      width="22"
      height="23"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="header-settings__logo"
    >
      <path
        d="M10.51 3H8.72A4.49 4.49 0 000 4.5a4.44 4.44 0 001 2.8v3.2a.5.5 0 00.31.46.429.429 0 00.19 0 .469.469 0 00.35-.15L3.3 9.4a1.49 1.49 0 001.19.6h2.8l1.86 1.85a.47.47 0 00.35.15.429.429 0 00.19 0 .5.5 0 00.31-.5V10h.51A1.49 1.49 0 0012 8.51v-4A1.49 1.49 0 0010.51 3zM1.88 6.78A3.34 3.34 0 011 4.5a3.5 3.5 0 112.72 3.41.51.51 0 00-.47.13L2 9.29V7.12a.52.52 0 00-.12-.34zM11 8.51a.49.49 0 01-.49.49h-1a.5.5 0 00-.5.5v.79L7.85 9.15A.47.47 0 007.5 9h-3a4.42 4.42 0 002.79-1H9.5a.5.5 0 100-1H8.24a4.6 4.6 0 00.48-1h.78a.5.5 0 100-1H9a4.23 4.23 0 000-.5A4.23 4.23 0 009 4h1.56a.49.49 0 01.49.49L11 8.51z"
      />
    </svg>
  </button>
</template>

<script>
export default {
  name: 'ProgramBadgeStatusComment'
};
</script>
