export default async (url, query, count = 7, language = 'ru') => {
  const urlList = {
    urlAddress:
      'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
  };

  const options = {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Token ' + process.env.VUE_APP_DADATA_TOKEN,
    },
    body: JSON.stringify({
      query: query,
      locations: [
        {
          country_iso_code: '*'
        }
      ],
      count: count,
      language: language,
    }),
  };

  const res = await fetch(urlList[url], options);
  if (res.ok) {
    return await res.json();
  } else {
    return Promise.reject(new Error());
  }
};
