<template>
  <div :class="{'nav__link-dropdown': true, active: isDropdownOpen}">
    <div
      class="nav__link link-dropdown"
      @click.stop="toggleDropdown"
    >
      <template v-if="!menuActive">
        <el-popover
          trigger="hover"
          :content="link.title"
          placement="right"
        >
          <div slot="reference">
            <component
              :is="iconLoader(link.signature)"
              v-if="iconLoader(link.signature)"
              class="icon nav__icon"
            />
            <svg
              v-else
              class="icon nav__icon"
              width="25"
              height="25"
            >
              <image
                :xlink:href="link.image"
                :src="link.image"
              />
            </svg>
          </div>
        </el-popover>
      </template>
      <component
        :is="iconLoader(link.signature)"
        v-if="iconLoader(link.signature)"
        class="icon nav__icon"
      />
      <svg
        v-else
        class="icon nav__icon"
        width="25"
        height="25"
      >
        <image
          :xlink:href="link.image"
          :src="link.image"
        />
      </svg>
      <span class="nav__text font_size link-dropdown__name">{{
        link.title
      }}</span>
      <div
        v-if="menuActive"
        class="link-dropdown__arrow"
      />
    </div>
    <div
      class="link__sublink-container"
      :class="{'link__sublink-container_child': isChildren}"
    >
      <template v-for="sublink in link.children">
        <nav-menu-item-dropdown
          v-if="sublink.children?.length"
          :link="sublink"
        />
        <router-link
          v-else-if="!sublink.link?.includes('https')"
          v-show="isDropdownOpen"
          :key="sublink.slug"
          :to="sublink.link.startsWith('/') ? sublink.link : '/' + sublink.link"
          active-class="choosen"
          exact
          class="nav__link link__sublink"
        >
          <svg
            class="icon nav__icon"
            width="25"
            height="25"
          >
            <image
              :xlink:href="sublink.image"
              :src="sublink.image"
            />
          </svg>
          <span class="nav__text">{{ sublink.title }}</span>
        </router-link>
        <a
          v-else
          :href="sublink.link"
          target="_blank"
          class="nav__link link__sublink"
        >
          <svg
            class="icon nav__icon"
            width="25"
            height="25"
          >
            <image
              :xlink:href="sublink.image"
              :src="sublink.image"
            />
          </svg>
          <span class="nav__text">{{ sublink.title }}</span>
        </a>
      </template>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
import iconLoader from '@/utils/iconLoader';

export default {
  name: 'NavMenuItemDropdown',

  props: ['link'],

  data() {
    return {
      isOpen: true,
      isChildren: false
    };
  },

  computed: {
    ...mapState({
      menuActive: state => state.menuActive
    }),
    ...mapGetters(['isTablet', 'isMobile']),
    isDropdownOpen() {
      return this.menuActive && this.isOpen;
    }
  },
  mounted() {
    this.initState();
  },
  methods: {
    iconLoader,
    initState() {
      this.isChildren = this.checkChild();
      this.isOpen = this.checkActiveRoute();
    },
    checkActiveRoute() {
      const currentRoute = this.$route.fullPath;
      const findLink = (links = []) => {
        return !!links.find(e => {
          let result = false;
          if (e.children?.length) {
            this.isChildren = true;
            result = findLink(e.children);
          } else {
            result = e.link == currentRoute;
          }
          return result;
        });
      };
      return findLink(this.link.children);
    },
    checkChild() {
      return !!this.link.children?.find(e => e.children?.length);
    },
    toggleDropdown() {
      if (!this.menuActive) {
        this.$store.commit('resizeMenu', true);
      }

      this.isOpen = !this.isOpen;
    }
  }
};
</script>

<style lang="scss" scoped>
.nav__link-dropdown {
  .link-dropdown {
    position: relative;
  }
  .link-dropdown__name {
    padding-right: 20px;
  }
  .link-dropdown__arrow {
    transition: all 300ms ease-in-out 0ms;
    width: 16px;
    height: 16px;
    padding: 0;
    position: absolute;
    top: calc(50% - 8px);
    right: 20px;
    border: none;
    cursor: pointer;
    background: url('@/assets/icons/arrow-dropdown.svg') no-repeat center center;
  }
  .nav__text {
    width: 100%;
    font-size: 16px;
  }

  &.active > .link-dropdown > .link-dropdown__arrow {
    transform: rotate(-180deg);
  }
}
.font_size {
  font-size: 18px !important;
}

.nav__link-dropdown .nav__link-dropdown .link-dropdown__name {
  font-size: 16px !important;
}

.link__sublink-container {
  transition: max-height 300ms ease 0ms;
  max-height: 1000px;
  opacity: 1;
  overflow: hidden;
}

.link__sublink-container_child {
  margin-left: 20px;
}
.nav__link-dropdown {
  &:not(.active) .link__sublink-container {
    transition: max-height 0ms ease 0ms;
    max-height: 0px;
    opacity: 0;
  }
}
</style>
