<template>
  <form
    id="education-form"
    class="education-form common-wrapper__documents"
  >
    <el-alert
      v-if="item.errorText"
      class="alert alert--danger"
    >
      {{ item.errorText }}
    </el-alert>
    <div class="col-input-wrapper">
      <input-select
        id="specialization"
        label="Тип документа об образовании"
        placeholder="Тип документа об образовании"
        :disabled="item.readonly"
        :options="type"
        :value="$v.form.document_type_id"
      />
      <input-text
        id="serial"
        label="Серия диплома"
        :disabled="item.readonly"
        placeholder=""
        :value="$v.form.serial"
      />
      <input-text
        id="number"
        label="Номер диплома"
        :disabled="item.readonly"
        placeholder=""
        :value="$v.form.number"
      />
      <input-date-education
        id="date"
        label="Когда выдан"
        placeholder="Когда выдан"
        :disabled="item.readonly"
        :value="$v.form.date"
      />
    </div>
    <div class="col-input-wrapper">
      <input-text
        id="issued_by"
        label="Кем выдан"
        :char-limit="255"
        placeholder="Кем выдан"
        :disabled="item.readonly"
        :value="$v.form.issuedBy"
      />
    </div>
    <div class="col-input-wrapper">
      <div class="input-wrap-files">
        <input-file
          :id="'education' + index + item.id"
          text-align="center"
          :label="label.education"
          :accept-extensions="arrImageExtensions"
          :value="$v.form.files"
          :disabled="item.readonly"
          @on-change="onChangeAttachment"
        />
      </div>
    </div>
    <div class="col-input-wrapper">
      <alert-attention :text="text" />
    </div>
    <div class="col-input-wrapper">
      <alert-attention :text="textInfo" />
    </div>
    <div
      v-if="!item.readonly"
      class="col-input-wrapper"
    >
      <el-button @click="$emit('on-remove')">
        Удалить документ об образовании
      </el-button>
    </div>
  </form>
</template>

<script>
import {requiredIf} from 'vuelidate/lib/validators';

import AlertAttention from '@/components/newAlerts/alertAttention';
import InputDateEducation from '@/components/profFormInputs/inputDateEducation.vue';
import InputFile from '@/components/inputComponent/InputFile';
import InputSelect from '@/components/profFormInputs/inputSelect.vue';
import InputText from '@/components/profFormInputs/inputText';
import {MAX_FILE_SIZE_TEXT} from '@/api';

const setData = item => ({
  id: item.id,
  speciality: item.speciality === '' ? '' : +item.speciality,
  document_type_id: item.document_type_id ?? '',
  serial: item.serial,
  number: item.number,
  date: item.date,
  issuedBy: item.issuedBy,
  files: item.files
});

export default {
  name: 'EducationFormUnit',

  components: {
    AlertAttention,
    InputDateEducation,
    InputFile,
    InputSelect,
    InputText
  },

  props: {
    id: {type: Number, required: true},
    index: {type: Number, required: true},
    item: {
      type: Object,
      default: () => ({
        id: '',
        speciality: '',
        document_type_id: '',
        serial: '',
        number: '',
        date: '',
        issuedBy: '',
        files: []
      })
    },
    readonly: {
      type: Boolean,
      default: false
    },
    rules: {type: Map, required: true},
    type: {type: Array, default: () => []}
  },

  data() {
    return {
      text: 'Вы можете прикрепить копию диплома одним документом в одном из форматов: pdf, png, jpeg. Максимальный размер файла ' + MAX_FILE_SIZE_TEXT,
      textInfo:
        'Если хотите провести верификацию через предоставление оригиналов при личном посещении, ' +
        'или же через онлайн-демонстрацию - не прикрепляйте скан-копию документа',
      label: {
        education: 'Прикрепить копию диплома'
      },
      arrImageExtensions: ['jpg', 'jpeg', 'pdf', 'png'],
      form: setData(this.item)
    };
  },

  computed: {
    hasErrorType() {
      return (
        this.$v.form.speciality?.$error ||
        (this.$v.form.speciality?.$dirty && this.$v.form.speciality?.$invalid)
      );
    }
  },

  validations() {
    const form = {};
    this.rules.forEach((value, key) => {
      form[key] = {
        required: requiredIf(function () {
          return value;
        })
      };
    });
    return {form};
  },

  watch: {
    item(val) {
      this.form = setData(val);
      this.$v.$reset();
    }
  },

  created() {
    this.emitValidation();
  },

  methods: {
    onChangeAttachment(file) {
      //
    },
    emitValidation() {
      this.$emit('on-change', this.id, this.$v);
    }
  }
};
</script>

<style lang="scss">
.col-input-wrapper {
  .el-alert {
    margin-top: 24px;
  }
}
</style>
