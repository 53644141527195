<template>
  <div>
    <p class="profile__table-title">
      {{ $t(getCommonCaptionByName('registrationAddress')) }}
    </p>
    <div class="profile__table-info">
      <div
        v-if="!isAddressEditable"
        class="input-profile-contact"
        :class="{profile_el_empty: profileFillErrors}"
      >
        <div class="input-profile-contact__value">
          <span v-if="address && address.name">{{ address.name }}</span>
          <span v-else> - </span>
        </div>
        <div class="input-profile-contact__btn">
          <button
            class="btn btn--secondary btn--icon"
            @click="openAddressEditable"
          >
            <edit class="edit-icon" />
          </button>
        </div>
      </div>
      <select-address
        v-else
        id="registerAddress"
        :address="address"
        @set-address="setAddress"
        @failed-setting-address="closeAddressEditable"
      />
    </div>
  </div>
</template>

<script>
import {getCommonCaptionByName} from '@/i18n/utils';
import Edit from '@/assets/icons/edit.svg?inline';
import SelectAddress from './selectAddress';

export default {
  name: 'ElkProfileInfoRegisterAddress',
  components: {
    Edit,
    SelectAddress
  },

  data() {
    return {
      isAddressEditable: false
    };
  },

  computed: {
    address() {
      return this.$store.getters['profileData/getRegisterAddress'];
    },
    profileFillErrors() {
      let errors = this.$store.getters[
        'profileFillCheck/profileFillErrors'
      ].map(function (obj) {
        return obj.code;
      });
      if (errors?.includes('registration_address')) return true;

      return false;
    }
  },

  methods: {
    getCommonCaptionByName,
    setAddress(addressInfo) {
      this.closeAddressEditable();
    },
    openAddressEditable() {
      this.isAddressEditable = true;
    },
    closeAddressEditable() {
      this.isAddressEditable = false;
    }
  }
};
</script>

<style></style>
