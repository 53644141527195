<template>
  <div
    id="snils-block"
    class="snils-form-block"
  >
    <h2 class="h4">
      {{ title }}
    </h2>
    <form
      id="snils-form"
      class="snils-form common-wrapper__documents"
    >
      <el-alert
        v-if="item.errorText"
        class="alert alert--danger"
      >
        {{ item.errorText }}
      </el-alert>
      <div class="col-input-wrapper">
        <input-mask
          id="number"
          label="Номер"
          :disabled="readonly"
          placeholder="000-000-000 00"
          :mask="['###-###-### ##']"
          :value="$v.form.snils"
        />
        <!--        <input-date-snils-->
        <!--          id="date"-->
        <!--          label="Когда выдан"-->
        <!--          placeholder="Когда выдан"-->
        <!--          :value="$v.form.date"-->
        <!--        />-->
      </div>
      <div class="col-input-wrapper">
        <input-file
          id="user-snils-file"
          text-align="center"
          :required="true"
          :accept-extensions="arrImageExtensions"
          :show-alert-info="true"
          label="Прикрепить копию СНИЛС"
          :value="$v.form.file"
          @on-change="onChangeFile"
        />
      </div>
      <div class="col-input-wrapper">
        <alert-attention
          v-if="!$v.form.snils?.$model"
          text="Рекомендуем заполнить номер СНИЛС. Так граждане РФ автоматически получат подтверждение подлинности документа об образовании в личном кабинете на портале Госуслуг, а работодателям не придется дополнительно запрашивать информацию."
        />
      </div>
    </form>
  </div>
</template>

<script>
import {minLength, requiredIf} from 'vuelidate/lib/validators';
import InputMask from '@/components/inputComponent/InputMask';
import AlertAttention from '@/components/newAlerts/alertAttention';
import InputFile from '@/components/inputComponent/InputFile';

const setData = item => ({
  id: item.id,
  number: item.number,
  snils: item.snils,
  file: item.file ?? ''
  // date: item.date,
});

export default {
  name: 'SnilsFormDocument',
  components: {/*InputDateSnils,*/ InputMask, AlertAttention, InputFile},

  props: {
    item: {type: Object, required: true},
    rules: {type: Map, required: true},
    readonly: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      title: 'СНИЛС',
      form: setData(this.item),
      arrImageExtensions: ['jpg', 'jpeg', 'pdf', 'png']
    };
  },

  watch: {
    item(val) {
      this.form = setData(val);
      this.$nextTick(() => {
        this.$v.$reset();
      });
    }
  },

  validations() {
    const formValidations = {};
    this.rules.forEach((value, key) => {
      formValidations[key] = {
        required: requiredIf(function () {
          return value;
        }),
        minLength: key === 'snils' ? minLength(11) : false
      };
    });

    if (!formValidations['file']) {
      formValidations['file'] = {};
    }
    return {
      form: {
        ...formValidations
      }
    };
  },

  created() {
    this.emitValidation();
  },

  methods: {
    onChangeFile() {
      setTimeout(() => {
        if (this.$v.form.file?.$model?.length === 0) {
          this.$v.form.file.$model = [];
          this.$emit('on-remove-file', this.$v.form.file.$model);
        }
      }, 0);
    },
    emitValidation() {
      this.$emit('on-change', this.$v);
    }
  }
};
</script>

<style lang="scss">
.snils-form{
  margin-top: 20px;
}
.snils-form-block {
  .inputFile-wrapper {
    margin-top: 20px;
  }
}
.snils-form.common-wrapper__documents {
  .col-input-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
    .inputWrap {
      margin-left: 0;
      margin-bottom: 0;
    }
  }
}

@media (max-width: 1440px) {
  .snils-form.common-wrapper__documents {
    .col-input-wrapper {
      gap: 16px;
    }
  }
  .inputWrap:nth-child(4) {
    width: 420px;
  }
}

@media (max-width: 767px) {
  .snils-form.common-wrapper__documents {
    margin-bottom: 32px;
    .col-input-wrapper {
      grid-template-columns: 1fr;
      gap: 0;
      .inputWrap {
        margin-bottom: 16px;
      }
    }
  }
  .snils-form.common-wrapper__documents {
    .col-input-wrapper {
      .inputWrap:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 480px) {
}
</style>
