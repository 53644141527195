<template>
  <div
    :class="{
      inputWrap: true,
      'error-system': hasError
    }"
  >
    <label
      :for="id"
      :class="{required: required}"
    >
      {{ label }}
    </label>

    <the-mask
      :id="id"
      v-model="value.$model"
      :class="inputClassName"
      :name="id"
      :disabled="disabled"
      :required="required"
      :placeholder="placeholder"
      :type="type"
      :mask="mask"
      :masked="masked"
      :readonly="readonly"
      @input="onInput"
    />
  </div>
</template>

<script>
import {TheMask} from 'vue-the-mask'; // компанент маска для элементов форм
export default {
  name: 'InputMask',

  components: {
    TheMask
  },

  props: {
    id: {type: String, required: true},
    label: {type: String, default: ''},
    disabled: {type: Boolean, default: false},
    required: {type: Boolean, default: false},
    masked: {type: Boolean, default: false},
    placeholder: {type: String, default: ''},
    type: {type: String, default: 'text'},
    readonly: {type: Boolean, default: false},
    inputClassName: {type: String, default: 'input-form__prof'},
    mask: {type: Array, default: () => []},
    value: {type: Object, default: () => ({$model: ''})}
  },

  computed: {
    hasError() {
      return this.valu?.$error || (this.value?.$dirty && this.value?.$invalid);
    }
  },

  methods: {
    onInput(value) {
      this.value.$model = value;

      this.$emit('input', value);
    }
  }
};
</script>

<style lang="scss">
.inputWrap {
  margin-bottom: 28px;
}

.inputWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $dusk-gray-color;
    margin-bottom: 8px;
    position: relative;
    display: inline-flex;
    width: fit-content;

    &::before {
      display: none;
    }
  }

  label.required:before {
    content: '*';
    position: absolute;
    top: 0;
    right: -6px;
    width: 5px;
    height: 5px;
    display: block;
  }

  .input-form__mask {
    width: 100%;
    height: 48px;
    border-radius: 4px;
    border: 1px solid $border-color;
    padding: 14px 45px 12px 14px;

    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $base-black-color;
    transition: all 0.3s;
    box-sizing: border-box;
  }

  .input-form__auth {
    transition: all 10ms ease;

    &[readonly] {
      color: #6b7a99;
      background-color: #fafafa;
      border: 1px solid $border-color;
      cursor: not-allowed;

      &:focus {
        outline: none !important;
        border: 1px solid #d3d8e6 !important;
        cursor: not-allowed;
      }
    }

    &:focus {
      outline: 1px solid #101010 !important;
      border: 1px solid #101010 !important;
    }

    // #D3D8E6
    &:disabled {
      background-color: #fafafa;
      outline: none;
      border: 1px solid #d3d8e6;
      color: #545479;
      cursor: not-allowed;
    }
  }

  .input-form__mask:focus-visible {
    border: 1px solid $blue-color;
  }

  &.error-system .input-form__mask:focus-visible {
    border-color: $error-system-red;
    outline: 1px solid $error-system-red;
  }

  &.error-system input {
    border: 1px solid $error-system-red;
  }
}
</style>
