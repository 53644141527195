export default {
  namespaced: true,
  state: {
    devhosts: ['localhost', 'proxyman.debug']
  },
  getters: {
    isLocalHost(state) {
      return state.devhosts.includes(window.location.hostname);
    }
  }
};
