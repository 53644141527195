<template>
  <div class="wrapper-documents-education">
    <div class="wrapper-list__documents">
      <div class="list__documents-title">
        <div class="count-documents">
          <p>
            Всего документов: <span>{{ items.length }}</span>
          </p>
        </div>
        <div class="sort-date">
          <p>Сортировать по:</p>
          <div class="sort-select">Дата</div>
        </div>
      </div>
      <doc-list-educ-component
        :items="items"
        :localization="localization"
      />
    </div>
  </div>
</template>

<script>
import DocListEducComponent from '@/components/listDocuments/docListEducComponent';
export default {
  name: 'DocActsEducComponent',

  components: {DocListEducComponent},

  props: {
    items: {type: Array, default: () => []},
    localization: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss">
.wrapper-tag-documents {
  background-color: #fff;
  padding: 24px;
  border-radius: 4px;
  display: grid;
  gap: 48px;
  grid-template-columns: auto auto;
  margin-bottom: 24px;
}
.tag-document-link {
  position: relative;
  margin-left: 80px;
  width: auto;
  span,
  p {
    color: $base-black-color;
  }
  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    display: inline-block;
    margin-bottom: 8px;
  }
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 0;
  }
  &:before {
    content: '';
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 12px;
    background-color: #edeff3;
    position: absolute;
    top: -3px;
    left: -76px;
    background-repeat: no-repeat;
    background-position: center center;
  }
}
.duplicate-document:before {
  content: '';
  background-image: url('../../assets/icons/icon-duplicate-document.svg');
}
.default-document:before {
  content: '';
  background-image: url('../../assets/icons/icon-certificate.svg');
}

// media
@media (max-width: 920px) {
  .wrapper-tag-documents {
    grid-template-columns: auto;
  }
}
</style>
