import Axios from '@/utils/Elk-Axios';

import {GET_ACTIVITY_WELCOME, CHECK_USER_BY_EMAIL} from '@/api';
import {REGISTRATION_STEP_3} from '@/api';

let cancel = function () {};

async function getActivityWelcome(signature) {
  try {
    const {data} = await Axios.get(GET_ACTIVITY_WELCOME, {
      params: {
        signature: signature
      }
    });
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
}

async function checkUserByEmail(email) {
  cancel();
  try {
    const {data} = await Axios.get(CHECK_USER_BY_EMAIL, {
      params: {
        email
      },
      cancelToken: new Axios.CancelToken(function executor(c) {
        cancel = c;
      })
    });
    return Promise.resolve(data);
  } catch (e) {
    if (!Axios.isCancel(e)) {
      return Promise.reject(e);
    }
  }
}

async function registerUser(postData) {
  try {
    const {data} = await Axios.post(REGISTRATION_STEP_3, postData);
    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
}

export {getActivityWelcome, checkUserByEmail, registerUser};
