<template>
  <div :class="{body: true, active: menuActive}">
    <menu-wrapper :page-name="name" />
    <div class="page-wrapper">
      <main
        class="content"
        :class="pageClass"
      >
        <div class="container">
          <div class="narrow-bedsheet-wr">
            <div class="narrow-bedsheet narrow-bedsheet--congratulation">
              <img
                src="@/assets/img/DPO_Logo_Main.svg"
                class="float-panel__decor-img"
              />
              <h1>{{ localeText.orderCongratulations }}!</h1>
              <p>
                {{ localeText.textSuccess }}
              </p>
              <a
                v-if="pathReceipt"
                download
                :href="pathReceipt"
                class="btn btn--grey"
              >
                {{ localeText.orderDownloadReceipt }}
              </a>
              <el-button
                :loading="isLoading"
                class="btn"
                @click.prevent="toProgramsPage"
              >
                {{ localeText.orderMyProgramsBtn }}
              </el-button>
            </div>
          </div>
        </div>
      </main>
    </div>
    <page-footer />
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
import MenuWrapper from '@/components/menuWrapper';
import Axios from '@/utils/Elk-Axios';
import PageFooter from '@/components/PageFooter';
import {PROGRAMSPAGE} from '@/router/routes';
import {CHECK_ONLINE_PAYMENT_STATUS, GET_RECEIPT} from '@/api';
import setNotification from '@/utils/setNotification';
import localization from '@/i18n/localization';

export default {
  name: 'CongratulationOnPayment',
  components: {
    PageFooter,
    MenuWrapper
  },
  data() {
    return {
      localization: localization('dpo.order.success'),
      name: 'Мои программы',
      pageClass: 'programs-page',
      isLoading: false,
      pathReceipt: ''
    };
  },
  computed: {
    ...mapState(['menuActive']),
    ...mapGetters(['isPublicUser']),
    localeText() {
      return {
        textSuccess: this.localization.widgetCaption('successfulOrderPlaced'),
        orderCongratulations: this.localization.widgetCaption(
          'orderCongratulations'
        ),
        orderDownloadReceipt: this.localization.widgetCaption(
          'orderDownloadReceipt'
        ),
        orderMyProgramsBtn:
          this.localization.widgetCaption('orderMyProgramsBtn')
      };
    }
  },
  mounted() {
    const receiptId = this.$route.query?.id || '';
    if (receiptId) {
      this.getReceipt(receiptId);
    }

    this.checkPaymentStatus();
  },
  methods: {
    async getReceipt(id) {
      this.isLoading = true;
      try {
        const response = await Axios.get(`${GET_RECEIPT}/${id}`);
        this.pathReceipt = response?.data?.data ?? '';
      } catch (e) {
        setNotification({
          message: e.response?.data?.message ?? ''
        });
      } finally {
        this.isLoading = false;
      }
    },
    toProgramsPage() {
      this.$router.push({path: PROGRAMSPAGE});
    },
    checkPaymentStatus() {
      const orderId = this.$route.query.orderId;

      if (orderId) {
        Axios.get(`${CHECK_ONLINE_PAYMENT_STATUS}/${orderId}`);
      }
    }
  }
};
</script>
<style lang="scss">
.narrow-bedsheet--congratulation {
  .hse-svg-icon {
    display: block;
    max-width: 177px;
    width: 100%;
    height: auto;
    margin: 0 auto;
  }
  h1 {
    font-size: 42px;
    line-height: 1.2;
    font-weight: 600;
    margin-top: 40px;
    text-align: center;
  }
  p {
    margin: 24px 0;
    font-size: 18px;
    line-height: 1.33;
    color: #6b7a99;
    text-align: center;
  }

  .btn.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;
    height: 48px;
    text-align: center;
  }
  .btn.btn--grey {
    background-color: #f0f5ff;
    color: #6b7a99;
    &:hover {
      background-color: #f0f5ff;
      color: #6b7a99;
    }
  }
}

@media screen and (max-width: 768px) {
  .narrow-bedsheet--congratulation {
    h1 {
      font-size: 36px;
      line-height: 1.2;
      font-weight: 29px;
      text-align: center;
    }
    .hse-svg-icon {
      display: block;
      max-width: 145px;
      width: 100%;
      height: auto;
      margin: 0 auto;
    }
    .narrow-bedsheet--congratulation p {
      margin: 20px 0;
      font-size: 16px;
    }
    .narrow-bedsheet--congratulation .btn.btn {
      margin-top: 20px;
      height: 48px;
    }
  }
}
</style>
