<template>
  <div class="input-profile-contact input-profile-contact--no-wrap">
    <el-select
      v-model="chosenNationality"
      @change="setNationality"
      filterable
      placeholder=""
      :disabled="isLoading"
      id="nationality"
      value-key="id"
      class="input-profile-contact__value"
      ref="country_select"
    >
      <el-option
        v-for="country in countries"
        :key="country.id"
        :label="country.name"
        :value="country"
      >
      </el-option>
    </el-select>
    <loading v-if="isLoading" class="loader loader--input" />
    <div v-else class="input-profile-contact__btn">
      <button
        @click="setNationalitySettingClose"
        class="btn btn--secondary btn--icon"
      >
        {{ $t(getCommonCaptionByName('cancel')) }}
      </button>
    </div>
  </div>
</template>

<script>
import { GET_COUNTRIES, SET_NATIONALITY } from '@/api';
import Axios from '@/utils/Elk-Axios';
import { getUiItemCaption, getCommonCaptionByName } from '@/i18n/utils';
import Loading from '@/components/common/elkLoading';
import { isArrayNotEmpty } from '@/utils/types';
import setNotification from '@/utils/setNotification';

export default {
  name: 'elk-profile-info-select-nationality',
  inject: ['signature'],
  components: {
    Loading,
  },
  props: {
    nationality: Object,
  },
  data() {
    return {
      countries: null,
      isLoading: false,
      chosenNationality: null,
    };
  },
  methods: {
    getCommonCaptionByName,
    setNationalitySettingClose() {
      this.$emit('failed-setting-nationality');
    },
    async getCountries() {
      this.isLoading = true;
      try {
        const { data } = await Axios.get(GET_COUNTRIES);
        if (isArrayNotEmpty(data)) {
          this.countries = data;
          this.$nextTick(() => this.$refs.country_select.focus());
        } else {
          //sentry
          throw new Error();
        }
      } catch (e) {
        this.$emit('failed-setting-nationality');
        setNotification({
          message: this.$t(
            getUiItemCaption(this.signature, 'fetchCountriesError')
          ),
        });
      } finally {
        this.isLoading = false;
      }
    },
    async setNationality(nationality) {
      this.isLoading = true;
      try {
        await Axios.post(SET_NATIONALITY, {
          nationality_id: nationality.id,
        });
        setNotification({
          message: `Гражданство изменено`,
          type: 'success',
        });
        this.$emit('set-nationality', nationality);
      } catch (e) {
        this.setNationalitySettingClose();
        setNotification({
          message: this.$t(
            getUiItemCaption(this.signature, 'setNationalityError')
          ),
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
  created() {
    this.chosenNationality = this.nationality;
    this.getCountries();
  },
};
</script>

<style></style>
