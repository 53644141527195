import Axios from '@/utils/Elk-Axios';
import {
  COMPARES_INDEX,
  COMPARES_ADD,
  COMPARES_REMOVE,
  COMPARES_CLEAR
} from '@/api';

export default {
  namespaced: true,
  state: {
    /** @type {Program[]} */
    items: [],
    isLoading: false,
    isInit: false
  },

  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    /** @returns {Program[]} */
    items: state => () => state.items,
    /** @returns {Program|null} */
    item: state => id => state.items.find(e => e.id === id),
    counter: state => state.items.length
  },
  mutations: {
    set(state, items) {
      state.items = items ?? [];
    },
    setInit(state, value) {
      state.isInit = value ?? false;
    },
    add(state, item) {
      state.items.push(item);
    },
    remove(state, id) {
      state.items = state.items.filter(item => item.id !== id);
    },
    loading(state, value) {
      state.isLoading = !!value ?? false;
    }
  },
  actions: {
    async fetch({commit}) {
      commit('loading', true);

      const response = await Axios.get(COMPARES_INDEX);

      commit('loading', false);
      commit('set', response?.data?.data ?? []);
    },

    async add({commit}, id) {
      await Axios.post(COMPARES_ADD, {
        program_id: id
      });

      commit('add', {id});
    },

    async remove({commit}, id) {
      await Axios.post(COMPARES_REMOVE, {
        program_id: id
      });

      commit('remove', id);
    },

    async clear({commit}) {
      await Axios.post(COMPARES_CLEAR);

      commit('set', []);
    },
    async init({dispatch, commit, state}) {
      if (state.isInit) {
        return;
      }
      commit('setInit', true);
      try {
        await dispatch('fetch');
      } catch {
        ///
      }
    }
  }
};
