<template>
  <el-dialog
    :append-to-body="true"
    :visible.sync="modalSupportNotAuthorizedUser"
    custom-class="max-w-[600px] rounded-xl mx-4"
    title=""
    width="100%"
  >
    <template slot="title">
      <h4 class="font-semibold text-3xl">
        <div v-if="$i18n.locale === 'ru'">
          Техническая поддержка
        </div>
        <div v-else>
          Technical support
        </div>
      </h4>
    </template>
    <div class="text-lg break-normal">
      <div v-if="$i18n.locale === 'ru'">
        Если у Вас возникли технические сложности свяжитесь с нами по email.
      </div>
      <div v-else>
        If you have any technical difficulties, contact us via email.
      </div>
    </div>
    <template >
      <br/>
    </template>
    <template>
      <a class="text-brand-400 hover:underline" href="mailto:digital@hse.ru">
        digital@hse.ru
      </a>
    </template>
  </el-dialog>
</template>

<script>
import {getCommonCaptionByName} from '@/i18n/utils';

export default {
  data() {
    return {
      modalSupportNotAuthorizedUser: false,
    };
  },
  methods: {
    getCommonCaptionByName,
    openModal() {
      this.modalSupportNotAuthorizedUser = true;
    },
  },
};
</script>
