import Vue from 'vue';
import VueI18n from 'vue-i18n';
import {LOCALE_NAME_RU, LOCALE_NAME_EN} from '@/i18n/utils';
import {createI18n} from 'vue-i18n-composable';
Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const dateTimeFormats = {
  [LOCALE_NAME_EN]: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    },
    officeTask: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    },
    lesson: {
      day: 'numeric',
      month: 'long',
      weekday: 'long'
    },
    bitrhDateFormatMonthAndDay: {
      month: 'long',
      day: 'numeric',
      timeZone: 'UTC'
    },
    birthDate: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      timeZone: 'UTC'
    },
    news: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      timeZone: 'UTC'
    },
    gphDate: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    actionsHistory: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    },
    event: {
      month: 'long',
      day: 'numeric'
    },
    applications: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }
  },
  [LOCALE_NAME_RU]: {
    short: {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    },
    long: {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    },
    officeTask: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    },
    lesson: {
      day: 'numeric',
      month: 'long',
      weekday: 'long'
    },
    bitrhDateFormatMonthAndDay: {
      month: 'long',
      day: 'numeric',
      timeZone: 'UTC'
    },
    birthDate: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      timeZone: 'UTC'
    },
    news: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      timeZone: 'UTC'
    },
    gphDate: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    },
    actionsHistory: {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    },
    event: {
      month: 'long',
      day: 'numeric'
    },
    applications: {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    }
  }
};

export default createI18n({
  silentTranslationWarn: false,
  dateTimeFormats,
  silentFallbackWarn: false,
  locale: 'ru',
  fallbackLocale: 'ru',
  messages: loadLocaleMessages(),
  pluralizationRules: {
    en: function (choice, choicesLength) {
      if (choice === 0) {
        return 0;
      }

      if (choicesLength === 4) {
        if (choice > 3) return 3;
        return choice - 1;
      }

      if (choice > 1) {
        return 2;
      }

      return choice;
    }
  }
});
