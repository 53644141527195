<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="'0 0 ' + width + ' ' + height"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      :d="d"
      :fill="fill"
      :fill-rule="fillRule"
      :clip-rule="clipRule"
      :stroke="stroke"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconSvg',
  props: {
    d: {
      type: String,
      required: true
    },
    fill: {
      type: String,
      default: 'white'
    },
    fillRule: {
      type: String,
      default: null
    },
    clipRule: {
      type: String,
      default: null
    },
    width: {
      type: Number,
      default: 24
    },
    height: {
      type: Number,
      default: 24
    },
    stroke: {
      type: String,
      default: null
    }
  }
}
</script>
