import I18N from '@/i18n';
import store from '@/store';

export default data => {
  document
    .querySelector('html')
    .setAttribute('lang', store.getters['locale/getLocaleName']);
  const localeName = store.getters['locale/getLocaleName'];
  I18N.locale = localeName;
  I18N.setLocaleMessage(localeName, data);
};
