// import libqp from 'libqp';
// import * as windows1251 from 'windows-1251/windows-1251';

export default function (userData, workData) {
  const BEGIN_VCARD = 'BEGIN:VCARD';
  const VERSION = '\nVERSION:2.1';
  const END_VCARD = '\nEND:VCARD';

  const N = generateN(
    userData.last_name,
    userData.first_name,
    userData.middle_name
  );

  const BDAY = generateBDAY(
    userData.birth_date,
    userData.birth_date_show_type_id
  );

  const TEL = workData
    ? generateTEL(workData.phone_work, workData.phone_internal)
    : '';

  const PERSONAL_TEL = generatePersonalTel(
    userData.phone_mobile,
    // userData.is_phone_mobile_show
    true
  );

  const EMAIL = generateEmail(userData.email);

  const PERSONAL_EMAIL = generatePersonalEmail(
    userData.email_personal,
    // userData.is_email_personal_show
    true
  );

  const ORG = generateOrg('НИУ ВШЭ');

  const ADR =
    workData && workData.address
      ? generateAdr(workData.address, workData.room_number, workData.city)
      : '';

  // const TITLE = generateTitle(
  //   'Специалист по учебно-методической работе 1 категории'
  // );

  const vcf = `${BEGIN_VCARD}${VERSION}${N}${BDAY}${TEL}${PERSONAL_TEL}${EMAIL}${PERSONAL_EMAIL}${ORG}${ADR}${END_VCARD}`;

  return vcf;
}

function generateN(last_name, first_name, middle_name) {
  let N = '';

  const fullName = `${last_name || ''};${first_name || ''};${
    middle_name || ''
  }`;

  // const META = '\nN;CHARSET=windows-1251;ENCODING=QUOTED-PRINTABLE:';
  const META = '\nN;CHARSET=utf-8:';

  if (fullName) {
    // const encodedFullName = libqp.encode(windows1251.encode(fullName));
    const encodedFullName = fullName;
    N = `${META}${encodedFullName}`;
  }

  return N;
}

function generateBDAY(birth_date, birth_date_show_type_id = 1) {
  const META = '\nBDAY:';

  let BDAY = '';

  if (birth_date) {
    switch (birth_date_show_type_id) {
      case 1:
        BDAY = `${META}${birth_date.replace('0000', '-')}`;
        break;
      case 2:
        BDAY = `${META}${birth_date.replace(/\d{4}/, '-')}`;
        break;
    }
  }

  return BDAY;
}

function generateTEL(phone_work, phone_internal) {
  const META = '\nTEL;TYPE=WORK, PREF:';

  let TEL = '';

  if (phone_work) {
    TEL = `${META}${phone_work}`;

    if (phone_internal) {
      TEL += `,${phone_internal}`;
    }
  }

  return TEL;
}

function generatePersonalTel(phone_mobile, is_phone_mobile_show = true) {
  const META = '\nTEL;TYPE=CELL:';

  let TEL = '';

  if (is_phone_mobile_show && phone_mobile) {
    TEL = `${META}${phone_mobile}`;
  }

  return TEL;
}

function generateEmail(email) {
  const META = '\nEMAIL;TYPE=WORK, PREF:';

  let EMAIL = '';

  if (email) {
    EMAIL = `${META}${email}`;
  }

  return EMAIL;
}

function generatePersonalEmail(email_personal, is_email_personal_show = true) {
  const META = '\nEMAIL;TYPE==HOME:';

  let EMAIL = '';

  if (is_email_personal_show && email_personal) {
    EMAIL = `${META}${email_personal}`;
  }

  return EMAIL;
}

function generateOrg(org) {
  // const META = '\nORG;CHARSET=windows-1251;ENCODING=QUOTED-PRINTABLE:';
  const META = '\nORG;CHARSET=utf-8:';

  // const encodedOrg = libqp.encode(windows1251.encode(org));
  const encodedOrg = org;

  const ORG = `${META}${encodedOrg}`;

  return ORG;
}

function generateAdr(address, room_number, city) {
  const META =
    // '\nADR;TYPE=WORK;CHARSET=windows-1251;ENCODING=QUOTED-PRINTABLE:';
    '\nADR;TYPE=WORK;CHARSET=utf-8:';

  const workAddress = `${address}${room_number ? ', ' + room_number : ''}`;

  const fullAddress = `;;${workAddress};${city || ''};;;`;

  // const encodedFullAddress = libqp.encode(windows1251.encode(fullAddress));
  const encodedFullAddress = fullAddress;

  return `${META}${encodedFullAddress}`;
}

// function generateTitle(title) {
//   const META = '\nTITLE;CHARSET=utf-8:';

//   return `${META}${title}`;
// }
