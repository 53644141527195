<template>
  <el-badge
    class="link-badge__btn"
    :hidden="true"
    :value="3"
  >
    <a      
      href="#"
      class="link__badge-gear"
      :class="{'link__badge-gear_active': isVisibleSettings}"
      @click.prevent="toggleSettings"
    />
  </el-badge>
</template>

<script>
export default {
  name: "LiknGearHeader",
  computed: {
    isVisibleSettings() {
      return this.$store.state.isSettingOpen && this.isMainPage;
    },
    isMainPage() {
      return this.$route.fullPath === '/'
    },
  },
  methods: {
    toggleSettings() {      
      if (!this.isMainPage) {
        this.$store.commit('setSettingsState', true);
        this.$router.push({
          path: '/'
        })
      } else {
        this.$store.commit('toggleSetting');
      }
    },

  }
}
</script>

<style lang="scss">
.el-badge.link-badge__btn{
  .el-badge__content{
    background-color: var(--dark-blue);
    font-size: 10px;
    line-height: 15px;
    border: 2px solid #FFF;
  }
  .link__badge-gear{
    width: 20px;
    height: 20px;
    display: block;
    background: url("./../../../assets/icons/icon-gear-header.svg") 100% 100% no-repeat;
  }
  .link__badge-gear_active{
    background: url("./../../../assets/icons/icon-gear-header-active.svg") 100% 100% no-repeat;
  }
  .el-badge__content.is-fixed{
    top: -2px;
    right: 6px;
  }
}
</style>
