<template>
  <aside class="aside aside--bottom">
    <nav class="nav aside__nav">
      <iconArrow
        class="nav__arrow nav__arrow--back"
        @click="scrollX(false)"
      />
      <ul class="nav__list js-nav-bottom">
        <li
          v-for="link in filterConsolidateLinks"
          :key="link.signature"
          class="nav__item"
        >
          <router-link
            :to="link.url.startsWith('/') ? link.url : '/' + link.url"
            active-class="choosen"
            class="nav__link"
            exact
          >
            <component
              :is="iconLoader(link.signature)"
              v-if="iconLoader(link.signature)"
              class="icon nav__icon"
            />
            <img
              v-else
              :src="link.image"
              :alt="link.block_name"
              class="icon nav__icon"
            />
          </router-link>
        </li>
      </ul>
      <iconArrow
        class="nav__arrow nav__arrow--forward"
        @click="scrollX(true)"
      />
    </nav>
  </aside>
</template>

<script>
import iconLoader from '@/utils/iconLoader';
import iconArrow from '@/assets/icons/menuIcons/arrow.svg?inline';
import AbsoluteRelativeLink from '@/components/AbsoluteRelativeLink';

export default {
  name: 'ElkMobBottomNav',
  components: {
    AbsoluteRelativeLink,
    iconArrow
  },
  data() {
    return {
      consolidateLinks: []
    };
  },
  computed: {
    filterConsolidateLinks() {
      return this.consolidateLinks.flatMap(e => {
        if (e.children?.length) {
          return e.children
            .filter(el => el.image || el.signature)
            .map((el, i) => ({
              ...el,
              signature: el.signature ?? el.slug ?? i,
              url: el.url ?? el.link ?? ''
            }));
        }
        return e;
      });
    }
  },
  /*  computed: {
    navLinks() {
      return this.$store.getters.pageTreeForMenu;
    }
  },*/
  created() {
    this.consolidateLinks = this.$store.getters.pageTreeForMenu;
    if (this.$store.getters.userDpo && this.$store.getters.dpoNavLinks) {
      if (this.consolidateLinks.findIndex(item => item.type === 'dpo') < 0) {
        this.$store.getters.dpoNavLinks.forEach(item => {
          this.consolidateLinks.push({
            ...item,
            is_in_menu: true,
            anchor_name: item.title,
            block_name: item.title,
            url: item.link,
            ui_item_type: 'Page',
            type: 'dpo'
          });
        });
      }
    }
  },
  methods: {
    iconLoader,
    scrollX(forward) {
      let offset = forward ? 54 : -54;
      document.querySelector('.js-nav-bottom').scrollLeft += offset;
    }
  }
};
</script>
