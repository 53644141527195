<template>
  <div :class="{ body: true, active: menuActive}">
    <menu-wrapper
      :page-name="name"
    />
    <div class="page-wrapper">
      <main
        class="content"
        :class="pageClass"
      >
        <div class="container">
          <new-review-form />
        </div>
      </main>
    </div>
    <page-footer />
  </div>
</template>

<script>
import MenuWrapper from "@/components/menuWrapper/index.vue";
import PageFooter from "@/components/PageFooter.vue";
import NewReviewForm from "@/components/formReview/newReviewForm.vue";

import { mapState, mapGetters } from 'vuex';

export default {
  name: "reviewPage",
  components: {NewReviewForm, PageFooter, MenuWrapper},
  data() {
    return {
      name: 'Оставить отзыв',
      pageClass: 'review'
    }
  },
  computed: {
    ...mapState(['menuActive']),
    ...mapGetters(['isPublicUser']),
  },
}
</script>

<style lang="scss">

</style>
