import Axios from '@/utils/Elk-Axios';
import { REF_DURATION_TYPES } from '@/api';

export default {
  namespaced: true,
  state: {
    /** @type {Reference[]} */
    items: [],
  },
  getters: {
    /** @returns {Reference[]} */
    items: (state) => () => state.items,
  },
  mutations: {
    set(state, items) {
      state.items = items ?? []
    },
  },
  actions: {
    async fetch({ commit }) {
      const response = await Axios.get(REF_DURATION_TYPES)
      commit('set', response?.data?.data ?? [])
    },
  }
}
