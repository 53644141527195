import I18N from '@/i18n';
import { getCommonCaptionByName } from '@/i18n/utils';

const EMAIL_ALREADY_EXIST = 'email already exists';
const SUCCESS = 'success';
const EMAIL_DOMAIN_NOT_ALLOWED = 'email domain not allowed';
const USER_NOT_FOUND = 'user not found';
const WRONG_CODE = {
  code: 'wrong code',
  message: I18N.t(getCommonCaptionByName('confirmationCode_inputedWrong')),
};
const PEP_PHONE_ALREADY_EXISTS = 'pep-phone already exists';

const NO_PEP_PHONE = 'no pep phone';
const WRONG_PHONE = 'wrong phone';
const EMAIL_IS_PERSONAL = 'email is personal';
const NO_VALID_DATA = 'no valid data';

export {
  NO_VALID_DATA,
  EMAIL_IS_PERSONAL,
  WRONG_PHONE,
  NO_PEP_PHONE,
  EMAIL_ALREADY_EXIST,
  SUCCESS,
  EMAIL_DOMAIN_NOT_ALLOWED,
  WRONG_CODE,
  USER_NOT_FOUND,
  PEP_PHONE_ALREADY_EXISTS,
};
