import { GET_PROFILE_REFERENCES, PROFILE_V1 } from '@/api';
import Axios from '@/utils/Elk-Axios';
import setNotification from "@/utils/setNotification";
import Ar from "element-ui/src/locale/lang/ar";

function prepareList(list) {
  if (Array.isArray(list)) {
    return list.length ? list.map(({ value: id, label: name }) => ({ name, id })) : [];
  } else  if (typeof list === 'object') {
    const result = []
    for (const key in list) {
      result.push({
        name: list[key].label,
        id: list[key].value
      })
    }
    return  result;
  }
  return []
}

async function getCategories() {
  try {
    const { data } = await Axios.get(GET_PROFILE_REFERENCES);
    return prepareList(data.data.category_listener);
  } catch (e) {
    return Promise.reject(e);
  }
}

async function setCategory(id) {
  try {
    const { data } = Axios.patch(PROFILE_V1, {
      category_listener: id,
    });

    setNotification({
      message: `Категория слушателя сохранена`,
      type: 'success',
    });

    return Promise.resolve(data);
  } catch (e) {
    return Promise.reject(e);
  }
}

export { getCategories, setCategory };
