<template>
    <div class="program-grid">
        <slot></slot>
    </div>
</template>


<script>
export default {
    name: 'ProgramGrid',
    data(){
        return{

        }
    }
}
</script>
<style lang="scss">
    
</style>