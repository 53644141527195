export default function (interval) {
  return store => {
    setTimeout(() => {
      if (store.state.mainPageNotAvailable) return;
      store.dispatch('notificationCounter/getNotificationCount');
    }, 10000);

    setInterval(() => {
      if (store.state.mainPageNotAvailable) return;
      store.dispatch('notificationCounter/getNotificationCount');
    }, interval);
  };
}
