import elkAxios from '@/utils/Elk-Axios';

const getLocation = params => {
  return elkAxios.get('dadata/get-user-location', {params: params});
};

const getPhoneInfo = params => {
  return elkAxios.get('dadata/get-phone-info', {params: params});
};

async function getEmailInfo(bodyParams) {
  return elkAxios.get('dadata/get-email-info', {params: bodyParams});
}

async function registrationMetaSave(data) {
  return elkAxios.post('dadata/registration-meta-save', {data});
}

export default {
  getLocation,
  getEmailInfo,
  getPhoneInfo,
  registrationMetaSave
};
