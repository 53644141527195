<template>
  <form
    class="form-service-v"
    @keydown.enter.prevent="onSubmit"
  >
    <el-row
      v-for="(field, index) of fields"
      :key="index"
      class="form__item"
    >
      <part-container
        :vuelidate-data="$v.form[field.code]"
        :label="getLabelField(field)"
        :label-size="getLabelSize(field)"
        :code="field.code"
        :external-errors="localeErrors"
      >
        <component
          :is="loadPart(field.type)"
          v-if="field.type !== 'file'"
          v-model="$v.form[field.code].$model"
          :part-data="field"
        />
        <component
          :is="loadPart(field.type)"
          v-else
          v-model="$v.form[field.code]"
          :part-data="field"
        />
      </part-container>
    </el-row>
    <hr />
    <el-row>
      <el-button
        type="primary"
        @click="onSubmit"
      >
        {{
          isPrintedForm ? localeText.btnContinue : localeText.sendApplication
        }}
      </el-button>
    </el-row>
    <el-row>
      <el-button
        type="secondary"
        @click="$emit('back')"
      >
        {{ localeText.comeBack }}
      </el-button>
    </el-row>
  </form>
</template>

<script>
import loadPart from '@/components/formService/loadPart';
import {validationMixin} from 'vuelidate';
import partContainer from '@/components/formService/partContainer';
import getRules from '@/components/formService/getRules';
import setNotification from '@/utils/setNotification';
import partContainerErrors from '@/components/formService/partContainerErrors';

export default {
  name: 'FormServiceV',
  components: {partContainer},
  mixins: [validationMixin],
  props: {
    fields: {
      type: Array,
      default: () => []
    },
    isPrintedForm: {
      type: Boolean,
      default: false
    },
    submitHandler: {
      type: Function,
      default: () => {}
    },
    localization: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    const formData = {};
    this.fields.forEach(e => {
      if (e.multiple) {
        formData[e.code] = e.value || [];
      } else {
        formData[e.code] = e.value ?? '';
      }
    });

    return {
      localeText: {
        errFillFields: this.localization.commonCaption('errAllFillFields'),
        btnContinue: this.localization.commonCaption('btnContinue'),
        sendApplication: this.localization.commonCaption('sendApplication'),
        comeBack: this.localization.commonCaption('comeBack')
      },
      localeErrors: partContainerErrors(this.localization),
      form: {
        ...formData
      }
    };
  },
  methods: {
    loadPart,
    getRules,
    getLabelField(field) {
      if (!field || !field.label || field.type === 'file') return '';
      const internalLabels = ['listeners'];
      if (internalLabels.includes(field.type)) return '';
      return field.label + (field.required ? ' *' : '');
    },
    getLabelSize(field) {
      return field.type === 'checkbox' || field.type === 'radio'
        ? 'large'
        : 'default';
    },
    async onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        if (this.fields.length > 10) {
          setNotification({message: this.localeText.errFillFields});
        }
        return;
      }
      try {
        await this.submitHandler(this.form);
      } catch (e) {
        setNotification({message: e.response?.data?.message ?? ''});
      }
    }
  },
  validations: function () {
    const validationsData = {};
    this.fields.forEach(e => {
      validationsData[e.code] = this.getRules(e.validations);
    });

    return {
      form: {
        ...validationsData
      }
    };
  }
};
</script>

<style lang="scss">
.form-service-v {
  .is-error .el-input__inner,
  .is-error .el-input__inner:focus,
  .is-error .el-textarea__inner,
  .is-error .el-textarea__inner:focus,
  .is-error .input--mask,
  .is-error .input--mask:focus {
    border-color: #f56c6c;
  }
}
</style>
