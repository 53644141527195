<template>
  <form
    id="academic-form"
    class="academic-form common-wrapper__documents"
  >
    <el-alert
      v-if="item.errorText"
      class="alert alert--danger"
    >
      {{ item.errorText }}
    </el-alert>
    <div class="col-input-wrapper">
      <input-select
        id="academicDegree"
        label="Ученая степень"
        placeholder="Ученая степень"
        :options="academicDegree"
        :disabled="item.readonly"
        :value="$v.form.degree"
      />
    </div>
    <div class="col-input-wrapper">
      <input-select
        id="specialization"
        label="Тип документа"
        placeholder="Тип документа"
        :options="documentType"
        :disabled="item.readonly"
        :value="$v.form.type"
      />
      <input-text
        id="serial"
        label="Серия"
        placeholder="0000"
        :disabled="item.readonly"
        :value="$v.form.serial"
      />
      <input-text
        id="number"
        label="Номер"
        placeholder="000000"
        :disabled="item.readonly"
        :value="$v.form.number"
      />
      <input-date-academic
        id="date"
        label="Когда выдан"
        placeholder="Когда выдан"
        :disabled="item.readonly"
        :value="$v.form.date"
      />
    </div>
    <div class="col-input-wrapper">
      <input-text
        id="issued_by"
        class="issued-by-whom"
        label="Кем выдан"
        placeholder="Кем выдан"
        :disabled="item.readonly"
        :value="$v.form.issuedBy"
      />
    </div>
    <div class="col-input-wrapper">
      <div class="input-wrap-files">
        <input-file
          :id="'academicDegree' + index + item.id"
          text-align="center"
          :required="true"
          :label="label.degree"
          :accept-extensions="arrImageExtensions"
          :disabled="item.readonly"
          :value="$v.form.files"
          @on-change="onChangeAttachment"
        />
      </div>
    </div>
    <div class="col-input-wrapper">
      <alert-attention :text="text" />
    </div>
  </form>
</template>

<script>
import {requiredIf} from 'vuelidate/lib/validators';

import InputDateAcademic from '@/components/profFormInputs/inputDateAcademic';
import AlertAttention from '@/components/newAlerts/alertAttention';
import InputFile from '@/components/inputComponent/InputFile';
import InputSelect from '@/components/profFormInputs/inputSelect';
import InputText from '@/components/profFormInputs/inputText';
import {MAX_FILE_SIZE_TEXT} from '@/api';

const setData = item => ({
  id: item.id,
  degree: item.degree == '' ? '' : +item.degree,
  type: item.type == '' ? '' : +item.type,
  serial: item.serial,
  number: item.number,
  date: item.date,
  issuedBy: item.issuedBy,
  files: item.files
});

export default {
  name: 'AcademicDegreeFormUnit',

  components: {
    AlertAttention,
    InputDateAcademic,
    InputFile,
    InputSelect,
    InputText
  },

  props: {
    id: {type: Number, required: true},
    index: {type: Number, required: true},
    item: {
      type: Object,
      default: () => ({
        id: '',
        degree: '',
        type: '',
        serial: '',
        number: '',
        date: '',
        issuedBy: '',
        files: []
      })
    },
    rules: {type: Map, required: true},
    academicDegree: {type: Array, default: () => []},
    documentType: {type: Array, default: () => []}
  },

  data() {
    return {
      text:
        'Внимание! Необходимо прикрепить копии ученой степени одним документом в формате PDF. Максимальный размер файла ' +
        MAX_FILE_SIZE_TEXT,
      label: {
        degree: 'Прикрепить копию ученой степени'
      },
      arrImageExtensions: ['jpg', 'jpeg', 'pdf', 'png'],
      form: setData(this.item)
    };
  },

  validations() {
    const form = {};
    this.rules.forEach((value, key) => {
      form[key] = {
        required: requiredIf(function () {
          return value;
        })
      };
    });
    return {form};
  },

  watch: {
    item(val) {
      this.form = setData(val);
      this.$v.$reset();
    }
  },

  created() {
    this.emitValidation();
  },

  methods: {
    onChangeAttachment() {},
    emitValidation() {
      this.$emit('on-change', this.id, this.$v);
    }
  }
};
</script>
