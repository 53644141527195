<template>
    <div class="selection-points"  data-select-name="selectName">
        <span class="selection-points__caption">{{ spText }}</span>
        <button class="selection-points__btn" @click="$emit('getText', spText, selectName)">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15 2.41L13.59 1L8 6.59L2.41 1L1 2.41L6.59 8L1 13.59L2.41 15L8 9.41L13.59 15L15 13.59L9.41 8L15 2.41Z" fill="white"/>
            </svg> 
        </button>
    </div>
</template>

<script>
export default {
    // props:{
    //     spId: String,
    //     spText: String,
    //     selectName
    // },
    props: [ 'spText', 'selectName'],
    data(){
        return{
            name: 'SelectionPoint'
        }
    },
    methods:{
        // returnSpText(text, event){
        //     this.$emit('retunText')
        //     console.log(text);
        //     console.log(event.target)
        // }
    }
}
</script>
<style lang="scss">

.selection-points{
    display: flex;
    align-items: center;
    border-radius: 4px;
    padding: 9px 15px; 
    background-color: #0050CF;
    box-sizing: border-box;
    .selection-points__caption{
        font-size: 14px;
        line-height: 1.2;
        font-weight: 600;
        color: #ffffff;
    }
    .selection-points__btn{
        flex-shrink: 0;
        margin-left: 18px;
        padding: 0;
        background-color: transparent;
        width: 16px;
        height: 16px;
        border: none;
        outline: none;
        cursor: pointer;
        svg{
            width: 100%;
            height: auto;
        }
    }
}

@media screen and (max-width: 768px){
    .selection-points{
        // width: 100%; 
        .selection-points__caption{
            margin-right: 18px;
        }
        .selection-points__btn{
            margin-left: auto;
        }
    }
}

</style>