<template>
  <div
    class="idashboard-item"
    :data-id="idEl"
  >
    <a
      :href="link"
      class="idashboard-item__link"
      @click="onRoute"
    >
      <span class="idashboard-item__icon-box">
        <img
          :src="imgUrl"
          alt=""
          aria-hidden="true"
        />
      </span>
      <span class="idashboard-item__text-box">
        <span class="idashboard-item__caption">
          {{ caption }}
          <el-popover
            v-if="notification && disabled"
            placement="top-start"
            :width="240"
            trigger="hover"
            class="idashboard-item__popover"
          >
            <template #reference>
              <i class="el-icon-info idashboard-item__popover-icon" />
            </template>
            <p
              class="idashboard-item__popover-content"
              v-html="notification"
            />
          </el-popover>
        </span>
        <span class="idashboard-item__desctiption">
          {{ description }}
        </span>
      </span>
    </a>

    <button
      v-if="visibleFavorite"
      class="idashboard-item__btn idashboard-item-btn"
      @click.prevent.stop="takeId"
    >
      <svg
        v-if="!isFavorite"
        class="btn-with-hint__trigger"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z"
        />
      </svg>
      <svg
        v-else
        class="btn-with-hint__trigger"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24z"
          fill="#6A7A98"
        />
      </svg>

      <span
        v-if="!isFavorite"
        class="btn-with-hint__hint"
      >
        Добавить в избранное
      </span>
      <span
        v-else
        class="btn-with-hint__hint"
      >
        Удалить из избранного
      </span>
    </button>
  </div>
</template>
<script>
export default {
  name: 'IdashboardItem',
  props: {
    idEl: String,
    imgUrl: String,
    caption: String,
    description: String,
    isFavorite: Boolean,
    notification: String,
    disabled: Boolean,
    link: String,
    visibleFavorite: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {
    takeId() {
      this.$emit('returnFavoritesId', this.idEl);
    },
    onRoute(e) {
      if (this.disabled) {
        e.preventDefault();
      }
    }
  }
};
</script>
<style lang="scss">
.idashboard-item {
  position: relative;
  .idashboard-item__link {
    display: flex;
    padding: 10px;
    height: 100%;
    border-radius: 12px;
    padding-right: 40px;
    transition: all 200ms ease;
  }
  .idashboard-item__icon-box {
    position: relative;
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    padding: 10px;
    margin-right: 16px;
    background: #edeff3;
    border-radius: 12px;
    overflow: hidden;
    box-sizing: border-box;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .idashboard-item__text-box {
    flex-grow: 1;
    padding: 7px 0;

    .idashboard-item__caption {
      position: relative;
      top: -2px;
      display: block;
      color: #0f0f14;
      margin-bottom: 8px;
      font-size: 16px;
      line-height: 1.25;
      font-weight: 600;
    }

    .idashboard-item__desctiption {
      display: block;
      font-size: 14px;
      line-height: 1.25;
      font-weight: 400;
    }
  }
  .idashboard-item__btn {
    position: absolute;
    top: 10px;
    right: 10px;
    opacity: 0;
    visibility: hidden;
    transition: all 200ms ease;
  }
  &:hover {
    .idashboard-item__link {
      background-color: #edeff3;
    }

    .idashboard-item__icon-box {
      background-color: #ffffff;
    }
    .idashboard-item__btn {
      opacity: 1;
      visibility: visible;
    }
  }
}

.idashboard-item__popover-icon {
  color: $color-gray-dusk;
}
.idashboard-item__popover-content a {
  font-size: 14px;
}

.idashboard-item-btn {
  width: 24px;
  height: 24px;
  padding: 0;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;

  svg {
    path {
      fill: #6a7a98;
      transition: all 200ms ease;
    }
  }

  .btn-with-hint__hint {
    position: absolute;
    bottom: 120%;
    right: -80%;
    background: #fff;
    border: 1px solid #ebeef5;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    color: #606266;
    font-size: 14px;
    line-height: 1.3;
    min-width: 150px;
    padding: 6px 10px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    z-index: 100;
    transition: all 200ms ease;
    &::after {
      content: '';
      position: absolute;
      top: 100%;
      right: 27px;
      border-top: 6px solid #ffffff;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
  }
  .btn-with-hint__trigger:hover ~ .btn-with-hint__hint {
    opacity: 1;
    visibility: visible;
  }
  &:hover {
    svg {
      path {
        fill: #ff564e;
      }
    }
  }
}
@media screen and (max-width: 1280px) {
  .idashboard-item {
    .idashboard-item__btn {
      opacity: 1;
      visibility: visible;
    }
  }
}
@media screen and (max-width: 640px) {
  .idashboard-item {
    .idashboard-item__icon-box {
      width: 50px;
      height: 50px;
    }
  }
}
</style>
