<script>
import { mapState } from 'vuex';
import MultipleSelectFilter from "@/components/filters/MultipleSelectFilter.vue";

export default {
  name: 'FinalDocumentTypeFilter',
  extends: MultipleSelectFilter,
  props: {
    label: {
      type: String,
      default: 'Вид документа'
    },
    param: {
      type: String,
      default: 'final_documents'
    }
  },
  computed: {
    ...mapState({
      types: state => state.refFinalDocumentTypes.items.map(type => {
        return {
          value: type.code,
          label: type.name
        }
      }),
    }),
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.$store.dispatch('refFinalDocumentTypes/fetch').then(() => this.optionList = this.types)
    }
  }
}
</script>
