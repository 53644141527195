<template>
  <a
    class="btn__programs btn-prog__lms"
    @click.prevent="clickHandler"
  >
    <span>Перейти в LMS</span>
  </a>
</template>

<script>
export default {
  name: 'LinkLMS',
  props: {
    program: {
      type: Object,
      required: true
    }
  },
  methods: {
    clickHandler() {
      const lms = this.program.lms;

      if (!lms) {
        return;
      }

      if (
        (lms.type === 'link' ||
          lms.type === 'ispring' ||
          lms.type === 'moodle') &&
        lms.url
      ) {
        window.open(lms.url, '_blank').focus();
      }

      if (lms.type === 'form' && lms.url && lms.token) {
        const form = document.createElement('form');
        form.setAttribute('method', 'post');
        form.setAttribute('target', '_blank');
        form.setAttribute('action', lms.url);

        const input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', 'jwt');
        input.setAttribute('value', lms.token);

        form.appendChild(input);
        document.body.appendChild(form);
        form.submit();
      }
    }
  }
};
</script>

<style lang="scss">
.btn__programs.btn-prog__lms {
  cursor: pointer;
  width: 220px;
  height: 44px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border-radius: 4px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0;
  position: relative;
  transition: all 0.3s ease-in-out;
  background-color: #28c75d;
  &:before {
    content: '';
    width: 20px;
    height: 20px;
    margin-right: 11px;
    position: relative;
    top: -2px;
    background: url('@/assets/icons/icon-status-dip.svg') 100% 100% no-repeat;
  }
  &:hover {
    background-color: rgb(40 199 93 / 85%);
  }
}
</style>
