<template>
  <div :class="{body: true, active: menuActive}">
    <menu-wrapper :page-name="name" />
    <div class="page-wrapper">
      <main
        class="content"
        :class="pageClass"
      >
        <div class="container">
          <Loading v-if="isLoading" />
          <component
            v-else
            :is="loader(widget.signature)"
            v-for="widget in widgets"
            :key="widget.signature"
            :widget-data="widget"
          />
        </div>
      </main>
    </div>
    <page-footer />
  </div>
</template>

<script>
// import recoveryStart from "@/components/authComponent/recoveryStart.vue"
import loader from '@/utils/widgetLoader.js';
import menuWrapper from '@/components/menuWrapper';
import PageFooter from '@/components/PageFooter';
import Loading from '@/components/common/elkLoading';
import EmptyDataMessage from '@/components/common/elkEmptyDataMessage';
import Idashboard from '@/components/idashboard/Idashboard.vue'; //контейнер дашборда
import IdashboardPanel from '@/components/idashboard/IdashboardPanel.vue'; // панель дашборда
import IdashboardItem from '@/components/idashboard/IdashboardItem.vue'; // элемент дашборда
import {mapState, mapGetters} from 'vuex';

export default {
  name: 'ServicesPage',
  components: {
    // recoveryStart,
    Loading,
    EmptyDataMessage,
    menuWrapper,
    PageFooter,
    idashboard: Idashboard,
    'idashboard-panel': IdashboardPanel,
    'idashboard-item': IdashboardItem
  },
  props: {
    name: String,
    widgets: Array
  },
  data() {
    return {
      pageClass: 'services'
      //name: 'Сервисы ДПО',
    };
  },
  computed: {
    ...mapState(['menuActive']),
    ...mapGetters(['isPublicUser']),
    isLoading() {
      return this.$store.state.services.isLoading;
    },
    groups() {
      return this.$store.getters['services/getGroups'];
    }
  },
  methods: {
    loader
  }
};
</script>
