<template>
  <aside
    :class="{'js-aside': true, active: active}"
    class="aside aside--left"
  >
    <elk-nav />

    <!--      <nav-menu-additional />-->

    <div class="resize-wrapper">
      <a
        class="resize"
        @click.stop="resize(!active)"
      >
        <iconArrow
          :class="{resize__icon: true, rotate: active}"
          class="icon"
        />
        <span class="resize__text">{{
          $t(getCommonCaptionByName('hideSidebar'))
        }}</span>
      </a>
    </div>
  </aside>
</template>

<script>
import NavMenuVue from '@/components/elkSidebar/NavMenu.vue';
import NavMenuAdditional from '@/components/elkSidebar/NavMenuAdditional.vue';
import iconArrow from '@/assets/icons/menuIcons/arrow.svg?inline';
import {mapState} from 'vuex';
import {getCommonCaptionByName} from '@/i18n/utils';

export default {
  name: 'ElkSidebar',
  components: {
    ElkNav: NavMenuVue,
    NavMenuAdditional,
    iconArrow
  },
  computed: {
    ...mapState({
      active: 'menuActive'
    })
  },
  methods: {
    getCommonCaptionByName,
    resize(active) {
      this.$store.commit('resizeMenu', active);
      // if (!this.active) {
      //   this.$refs.submenu.close("/reports");
      // }
    }
  }
};
</script>
