<template>
  <div :class="{ body: true, active: menuActive }">
    <menu-wrapper :pageName="name" />
    <div class="page-wrapper">
      <main class="content" :class="{ 'content--short': isPublicUser }">
        <div class="container">
          <div class="auth-component__section">
            <authStart />
          </div>
        </div>
      </main>
    </div>
    <page-footer />
  </div>
</template>

<script>

import AuthStart from "@/components/authComponent/authStart.vue";
import menuWrapper from "@/components/menuWrapper";
import pageFooter from "@/components/PageFooter";
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'authorization',
  components: {
    AuthStart,
    menuWrapper,
    pageFooter
  },
  data() {
    return {
      name: 'Авторизация'
    }
  },
  computed: {
    ...mapState(['menuActive']),
    ...mapGetters(['isPublicUser']),
  }

}
</script>

<style lang="scss">
html, body{
  background: #EDEFF3;
}
 .auth-component__section{
   display: flex;
   align-items: center;
   justify-content: center;
   padding-bottom: 30px;
 }
 @media (max-width: 530px) {
   .auth-component__section{
     padding-bottom: 50px;
   }
 }
</style>
