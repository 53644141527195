<template>
  <div class="form-alert__info">
    <p v-html="text"></p>
  </div>
</template>

<script>
export default {
  name: 'FormAlert',
  props: {
    text: {
      default: '',
      type: String
    }
  }
};
</script>

<style lang="scss">
.form-alert__info {
  width: 100%;
  height: auto;
  border-radius: 8px;
  padding: 16px 16px 16px 56px;
  margin-bottom: 24px;
  background: #f0f5ff;
  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #002d6e;
    margin-bottom: 0;
    position: relative;
  }
  p:before {
    content: '';
    position: absolute;
    left: -40px;
    display: block;
    width: 18px;
    height: 18px;
    background: url('../../assets/icons/icon-inform.svg') 100% 100% no-repeat;
  }
}
</style>
