<template>
  <ErrorPagePublic v-if="isPublicUser" />
  <div
    v-else
    :class="{body: true, active: menuActive}"
  >
    <menu-wrapper :page-name="localeText.title" />
    <div class="page-wrapper">
      <main
        class="content"
        :class="pageClass"
      >
        <div class="container">
          <h1>{{ localeText.title }}</h1>
          <section class="section-tabs">
            <div class="search-wrapper-component">
              <search-filter-document
                :filter="filter"
                :localization="localization"
                @selectDirectionIds="handlerFilterDirections"
                @selectTypeIds="handlerFilterTypeIds"
                @selectYearValues="handlerFilterYears"
                @inputSearch="handlerFilterQuery"
              />
            </div>
            <div
              v-if="filteredPrograms.length"
              class="wrapper-tabs__element"
            >
              <div class="tab-content">
                <program-documents
                  :documents="filteredPrograms"
                  :statuses="statuses"
                  :send-review-id="reviewSend"
                  :localization="localization"
                  @sendReview="onSendReview"
                />
              </div>
              <!--              <div class="rating-stars-block">-->
              <!--                рейтинг-->
              <!--              </div>-->
            </div>
            <empty-wrapper v-else>
              <empty-data-message :text="localeHtml.emptyMyPrograms" />
            </empty-wrapper>
          </section>
        </div>
        <section
          v-if="$store.getters['programs/recommendations'].length"
          class="prog-selectoin-section"
        >
          <div class="container">
            <h2
              class="title-section"
              style="margin-bottom: unset"
            >
              <a :href="linkRecommends">
                {{ localeText.programsSuitable }}
              </a>
            </h2>
            <component
              :is="loader('dpo.recommendations.widget')"
              key="dpo.favorites.widget"
            />
          </div>
        </section>
        <el-dialog
          :visible="dialogFormVisible"
          class="review-dialog"
          @close="onCloseDialog"
        >
          <new-review-form
            :is-dialog="true"
            :id-program="selectedProgramReview"
            :program-name="getProgramName(selectedProgramReview)"
            @success="onSuccessReview"
          />
        </el-dialog>
      </main>
    </div>
    <page-footer />
  </div>
</template>

<script>
import Axios from '@/utils/Elk-Axios';
import {V1_APPLICATIONS} from '@/api';
import {parseISO, format} from 'date-fns';

import {mapState, mapGetters} from 'vuex';
import MenuWrapper from '@/components/menuWrapper';
import PageFooter from '@/components/PageFooter';
import ProgramDocuments from '@/components/listDocuments/DocumentsPrograms/ProgramDocuments';
import ProgramSlider from '@/components/sliders/ProgramSl.vue'; //слайдер с программами
import SearchFilterDocument from '@/components/listDocuments/DocSearchComponent/searchFilterDocument';
import EmptyDataMessage from '@/components/common/elkEmptyDataMessage';
import EmptyWrapper from '@/components/common/elkEmptyWrapper';
import {
  getColorByStatus,
  getIconColorByStatus,
  PROGRAM_STATUS
} from '@/utils/getProgramDataByStatus';
import ErrorPagePublic from '@/views/404/index.vue';
import {prepareProgramCardRecommendedView} from '@/utils/prepareProgramCardData';
import NewReviewForm from '@/components/formReview/newReviewForm.vue';
import {RECOMMENDED_PROGRAMS} from '@/router/routes';
import loader from '@/utils/widgetLoader';
import localization from '@/i18n/localization';

export default {
  name: 'ProgramsPage',
  components: {
    ErrorPagePublic,
    SearchFilterDocument,
    ProgramDocuments,
    PageFooter,
    MenuWrapper,
    ProgramSlider,
    EmptyWrapper,
    EmptyDataMessage,
    NewReviewForm
  },
  data() {
    return {
      name: 'Мои программы',
      pageClass: 'programs-page',
      dialogFormVisible: false,
      linkRecommends: RECOMMENDED_PROGRAMS,
      arProgram: [],
      statuses: [],
      filter: {},
      selectedProgramReview: '',
      reviewSend: '',
      selectedDirectionIds: [],
      selectedTypeIds: [],
      selectedYearValues: [],
      query: '',
      recommendPrograms: [],
      favoritePrograms: [],
      localization: localization('dpo.programs.list')
    };
  },
  computed: {
    ...mapState(['menuActive']),
    ...mapGetters(['isPublicUser']),
    localeText() {
      return {
        title: this.localization.widgetCaption('myProgramsTitle'),
        programsSuitable: this.localization.commonCaption('programsSuitable'),
        programStatusAll: this.localization.widgetCaption('programStatusAll')
      };
    },
    localeHtml() {
      return {
        emptyMyPrograms: this.localization.widgetCaption('emptyMyPrograms')
      };
    },
    isLoadFavRec() {
      return this.$store.getters['programs/isLoading'];
    },
    filteredPrograms() {
      let items = this.arProgram;

      if (this.selectedDirectionIds.length > 0) {
        items = items.filter(e =>
          this.selectedDirectionIds.includes(e.direction_id)
        );
      }

      if (this.selectedTypeIds.length > 0) {
        items = items.filter(e => this.selectedTypeIds.includes(e.type_id));
      }

      if (this.selectedYearValues.length > 0) {
        items = items.filter(e => this.selectedYearValues.includes(e.year));
      }

      if (this.query) {
        items = items.filter(e => {
          let programName = e.program_full_name
            ? e.program_full_name
            : e.program_name;

          return programName.toLowerCase().includes(this.query.toLowerCase());
        });
      }

      return items;
    }
  },

  watch: {
    isLoadFavRec() {
      this.initFavoritesRec();
    }
  },

  created() {
    this.getProgramsAndFilters();
    this.initFavoritesRec();
  },

  methods: {
    loader,
    initFavoritesRec() {
      if (!this.isLoadFavRec) {
        if (
          this.$store.state.programs.isInitFavorite &&
          this.$store.state.programs.isInitRecommendations
        ) {
          if (!this.recommendPrograms.length) {
            this.recommendPrograms = this.$store.getters[
              'programs/recommendations'
            ]().map(prepareProgramCardRecommendedView);
          }
          if (!this.favoritePrograms.length) {
            this.favoritePrograms = this.$store.getters[
              'programs/favorites'
            ]().map(prepareProgramCardRecommendedView);
          }
        }
      }
    },

    getProgramName(programId) {
      let program = this.arProgram.find(el => el.program_id === programId)
      return program?.program_full_name ? program?.program_full_name : program?.program_name;
    },

    toggleFavoriteParent(returnFavoriteId, state, arr) {
      let favoriteIndex = this.favoritePrograms.findIndex(
        e => e.pcId === returnFavoriteId
      );
      if (favoriteIndex >= 0) {
        this.favoritePrograms[favoriteIndex].pcFavorites = state;
        if (!state) {
          this.favoritePrograms =
            this.favoritePrograms.filter(e => e.pcId !== returnFavoriteId) ??
            [];
        }
      }
      let recProgram = this.recommendPrograms.find(
        e => e.pcId === returnFavoriteId
      );
      if (recProgram) {
        recProgram.pcFavorites = state;
        if (favoriteIndex < 0 && state) {
          this.favoritePrograms.push(recProgram);
        }
      }
      // this.$store.dispatch('programs/fetchFavorites')
    },
    toggleComparisonParent(returnFavoriteId, state, arr) {
      let program = this.favoritePrograms.find(
        e => e.pcId === returnFavoriteId
      );

      if (program) {
        program.pcComparison = state;
      }

      program = this.recommendPrograms.find(e => e.pcId === returnFavoriteId);

      if (program) {
        program.pcComparison = state;
      }
    },
    handlerFilterDirections(event) {
      this.selectedDirectionIds = event;
    },
    handlerFilterTypeIds(event) {
      this.selectedTypeIds = event;
    },
    handlerFilterYears(event) {
      this.selectedYearValues = event;
    },
    handlerFilterQuery(event) {
      this.query = event;
    },
    isActive(menuItem) {
      return this.activeItem === menuItem;
    },
    setActive(menuItem) {
      this.activeItem = menuItem;
    },
    prepareProgram(program) {
      const {uuid: pcId, date_start, date_end} = program;

      const dateFormat = 'dd.MM.yyyy';
      const pcStart = format(parseISO(date_start), dateFormat) ?? '';
      const pcEnd = format(parseISO(date_end), dateFormat) ?? '';

      return {
        ...program,
        pcId,
        // pcActive: true,
        // pcDirect: 'Финансовый учет и отчетность',
        // pcCaption:'Кризисное консультирование в ситуации супружеской или партнерской неверности',
        pcStart,
        pcEnd,
        pcType: program.program_spec,
        pcStatus: 'end'
      };
    },
    onSendReview(id) {
      this.selectedProgramReview = id ?? '';
      if (this.selectedProgramReview) {
        this.dialogFormVisible = true;
      }
    },
    onSuccessReview() {
      this.reviewSend = this.selectedProgramReview?.toString();
      this.onCloseDialog();
    },
    onCloseDialog() {
      this.selectedProgramReview = '';
      this.dialogFormVisible = false;
    },
    async getProgramsAndFilters() {
      try {
        const {data} = await Axios.get(V1_APPLICATIONS);
        const programs = data.data.docs.map(this.prepareProgram);
        this.filter = data.data.filters;

        const calcCountStatuses = (programs, code) => {
          if (code === PROGRAM_STATUS.ALL) {
            return programs.length;
          }
          return programs.filter(e => e.status === code).length;
        };

        this.statuses = [
          {
            value: PROGRAM_STATUS.ALL,
            label: this.localeText.programStatusAll,
            color: getColorByStatus(PROGRAM_STATUS.ALL),
            iconColor: getIconColorByStatus(PROGRAM_STATUS.ALL),
            count: calcCountStatuses(programs, PROGRAM_STATUS.ALL)
          },
          ...data.data.filters.status.map(e => ({
            ...e,
            color: getColorByStatus(e.value),
            iconColor: getIconColorByStatus(e.value),
            count: calcCountStatuses(programs, e.value)
          }))
        ];

        if (data.code === 200 && data.data) {
          this.arProgram = programs.map(e => ({
            ...this.prepareProgram(e),
            status: this.statuses.find(s => s.value === e.status)
          }));
        }
      } catch {
        /** */
      }
    }
  }
};
</script>

<style lang="scss">
.content.programs-page {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.programs-page {
  padding-top: 48px;
  .search-wrapper-component {
    margin-top: 24px;
  }
}
.wrapper-tabs__element {
  display: flex;
  justify-content: space-between;
  .tab-content {
    width: 100%;
    margin-right: 24px;
  }
}
.my-programs-page__header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.my-programs-page__header .el-button {
  flex: 0 0 260px;
}
.rating-stars-block {
  width: 276px;
  min-width: 276px;
  height: 229px;
  background-color: #27a3ff;
  box-shadow: 0 1px 2px rgba(106, 122, 152, 0.15);
  border-radius: 4px;
  padding: 24px;
  box-sizing: border-box;
  margin-top: 77px;
  .start-block {
    h2 {
      font-weight: 600;
      font-size: 42px;
      line-height: 56px;
      color: #fff;
      margin-bottom: 20px;
    }
    .start-block__content {
      .block__content-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #fff;
      }
    }
    .star-element:before {
      content: '';
      width: 240px;
      height: 60px;
      display: block;
      background: url('@/assets/icons/stars-test.png') 100% 100% no-repeat;
    }
    .stars__subtitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: rgba(255, 255, 255, 0.6);
      text-align: center;
      margin-top: 10px;
    }
  }
}

@media (max-width: 640px) {
  .my-programs-page__header {
    flex-direction: column;
    gap: 24px;
    align-items: start;
  }
  .my-programs-page__header .el-button {
    flex: 1 1 auto;
  }
}
</style>
