import {
  getUiItemCaption,
  getCommonCaptionByName,
  getFormCaption,
} from '@/i18n/utils';

export default {
  install: function (Vue) {
    Vue.prototype.$getUiItemCaption = getUiItemCaption;
    Vue.prototype.$getCommonCaptionByName = getCommonCaptionByName;
    Vue.prototype.$getFormCaption = getFormCaption;
  },
};
