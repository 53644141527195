const components = {
  text: () => import('@/components/formService/parts/partInput'),
  textarea: () => import('@/components/formService/parts/partTextarea'),
  interval: () => import('@/components/formService/parts/partInterval'),
  email: () => import('@/components/formService/parts/partInput'),
  phone: () => import('@/components/formService/parts/partPhone'),
  wysiwyg: () => import('@/components/formService/parts/partTextarea'),
  number: () => import('@/components/formService/parts/partInput'),
  select: () => import('@/components/formService/parts/partSelect'),
  listeners: () => import('@/components/formService/parts/partSelectPicker'),
  date: () => import('@/components/formService/parts/partDate'),
  checkbox: () => import('@/components/formService/parts/partGroupCheckbox'),
  textcheckbox: () =>
    import('@/components/formService/parts/partGroupCheckboxText'),
  radio: () => import('@/components/formService/parts/partGroupRadio'),
  file: () => import('@/components/formService/parts/partFile')
};

export default type => {
  if (Object.hasOwnProperty.call(components, type)) {
    return components[type];
  }
};
