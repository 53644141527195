import localStorageService from '@/utils/localStorageService';

function checkExternalRedirect() {
  const externalRedirect = localStorageService.getExternalRedirect();
  if (externalRedirect) {
    localStorageService.removeExternalRedirect();
    return location.assign(externalRedirect);
  }
}

export default checkExternalRedirect;
