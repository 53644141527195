<template>
  <div class="detailed-info">
    <ul class="detailed-info__list">
      <li
        v-for="(item, index) in items"
        :key="index"
      >
        <div class="detailed-info__item">
          <div class="detailed-info__item-name">{{ item.name }}</div>
          <div class="detailed-info__item-value">{{ item.value }}</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'DetailedInformation',
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  emits: ['changeRequest']
};
</script>

<style lang="scss">
.detailed-info {
  &__list li {
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0px;
    }
  }

  &__item-name {
    font-size: 14px;
    margin-bottom: 8px;
    color: $color-gray-dusk;
  }
  &__item-value {
    font-size: 16px;
    color: $primary-midnight-1;
  }
  &__item-name,
  &__item-value {
    font-weight: 400;
    line-height: 20px;
  }

  .detailed-info__button {
    margin-top: 24px;
  }
}
</style>
