<template>
  <div class="shedule-today">
    <h3 class="shedule-today__date">
      {{ formRuDate(currentDaySchedule.date) }}
    </h3>
    <shedule-today-event
      v-for="classes in currentDaySchedule.events"
      :id="classes.id"
      :key="classes.id"
      :event-begin="classes.eventBegin"
      :event-end="classes.eventEnd"
      :type="classes.type"
      :type-of-event="classes.typeOfEvent"
      :caption="classes.caption"
      :direction="classes.direction"
      :venue="classes.venue"
      :venue-link="classes.venueLink"
      :auditorium="classes.auditorium"
      :auditorium-link="classes.auditoriumLink"
      :teacher-rang="classes.teacherRang"
      :teacher-name="classes.teacherName"
    />
  </div>
</template>
<script>
import ScheduleTodayEvent from "@/components/schedule/ScheduleTodayEvent.vue"
export default {
    name: 'ScheduleToday',
    components: {
        'shedule-today-event': ScheduleTodayEvent
    },
    props: {
        currentDaySchedule: Object,
    },
    data() {
        return {

        }
    },
    methods: {
        formRuDate(strDate){
            let date = new Date(strDate);
            let year = date.getFullYear();
            let month = Number(date.getMonth());
            let numberDay = Number(date.getDate());
            let dayOfTheWeek = Number(date.getDay());
            let arDays=['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];
            let arMonths = ["Января", "Февраля", "Марта", "Апреля", "Мая", "Июня", "Июля", "Августа", "Сентября", "Октября", "Ноября",  "Декабря"];
            let currentDay = `${arDays[dayOfTheWeek]}, ${numberDay} ${arMonths[month]}`;
            return currentDay;
        }
    }
}
</script>
<style lang="scss">
.shedule-today {
    padding: 26px 24px;
    background-color: var(--white);
    box-shadow: 0px 1px 2px rgba(106, 122, 152, 0.15);
    border-radius: 4px;
    box-sizing: border-box;

    .shedule-today__date {
        margin-bottom: 24px;
        font-size: 18px;
        line-height: 1.33;
        font-weight: 600;
    }
}

@media screen and (max-width: 870px) {
    .shedule-today {
        .shedule-today__date {
            font-size: 16px;
            margin-bottom: 16px;
        }
    }
}
</style>