<template>
  <CascadeSelect
    :placeholder="placeholder"
    :items="items"
    class="page-control-panel__item"
    :remove-code-item="removeCodeItem"
    :clear-trigger="clearItems"
    @cleared="onCleared"
    @on-change="onChange"
    @code-item-removed="onCodeItemRemoved"
  />
</template>

<script>
import CascadeSelect from '@/components/formElements/CascadeSelect/CascadeSelect';
import PresetFilterCascadeMixin from '@/components/filters/PresetFilterCascadeMixin';
export default {
  name: 'TrainingAreasSelectCascade',
  components: {CascadeSelect},
  mixins: [PresetFilterCascadeMixin],
  data() {
    return {
      items: []
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$store
        .dispatch('refTrainingAreas/fetch')
        .then(() => (this.items = this.$store.state.refTrainingAreas.items));
    }
  }
};
</script>

<style scoped></style>
