import Axios from '@/utils/Elk-Axios';
import {
  FETCH_REPRESENT_LEGAL_PROFILE,
  REPRESENT_PROFILE_LEGAL_EDIT
} from '@/api';

const addIfNotEmptyForm = formData => (val, fieldKey) => {
  if (val) {
    formData.append(fieldKey, val);
  }
};
const addJsonIfNotEmptyForm = formData => (val, fieldKey) => {
  if (val) {
    try {
      const json = JSON.stringify(val);
      formData.append(fieldKey, json);
    } finally {
      // not add
    }
  }
};

const generateFormData = data => {
  const form = new FormData();
  const addIfNotEmpty = addIfNotEmptyForm(form);
  const addJsonIfNotEmpty = addJsonIfNotEmptyForm(form);

  addIfNotEmpty(data.inn, 'inn');
  addIfNotEmpty(data.title, 'title');
  addIfNotEmpty(data.legalAddress, 'legal_address');
  addIfNotEmpty(data.mailingAddress, 'mailing_address');
  addIfNotEmpty(data.ogrn, 'ogrn');
  addIfNotEmpty(data.kpp, 'kpp');
  addIfNotEmpty(data.bankName, 'bank_name');
  addIfNotEmpty(data.bankBic, 'bank_bik');
  addIfNotEmpty(data.bankAccount, 'bank_account');
  addIfNotEmpty(data.correspondentAccount, 'correspondent_account');
  addIfNotEmpty(data.signingPersonFio, 'signing_person_fio');
  addIfNotEmpty(data.signingPersonPosition, 'signing_person_position');

  addIfNotEmpty(data.supervisorFullName, 'supervisor_full_name');
  addIfNotEmpty(data.supervisorPost, 'supervisor_post');

  addIfNotEmpty(data.powerOfAttorneyNumber, 'power_of_attorney_number');
  addIfNotEmpty(data.powerOfAttorneyDate, 'power_of_attorney_date');
  addIfNotEmpty(data.email, 'email');
  addIfNotEmpty(data.addEmail, 'add_email');
  addIfNotEmpty(data.phone, 'phone');
  // addIfNotEmpty(data.site, 'site');

  form.append('site', data.site ? data.site : '');

  addJsonIfNotEmpty(data.innDaData, 'inn_dadata');
  addJsonIfNotEmpty(data.legalAddressDaData, 'legal_address_dadata');
  addJsonIfNotEmpty(data.mailingAddressDaData, 'mailing_address_dadata');
  addJsonIfNotEmpty(data.bankDaData, 'bank_bik_dadata');

  if (data.basicDocument instanceof Object) {
    addIfNotEmpty(data.basicDocument?.code, 'basis_document');
  } else {
    addIfNotEmpty(data.basicDocument, 'basis_document');
  }
  if (data.signingFile instanceof Blob) {
    addIfNotEmpty(data.signingFile, 'signing_file');
  }

  return form;
};

const convertRawProfile = data => {
  return {
    id: data.id,
    title: data.title,
    inn: data.inn,
    ogrn: data.ogrn,
    kpp: data.kpp,
    bankName: data.bank_name,
    bankBic: data.bank_bik,
    bankAccount: data.bank_account,
    correspondentAccount: data.correspondent_account,
    signingPersonFio: data.signing_person_fio,
    signingPersonPosition: data.signing_person_position,
    basicDocument: data.basic_document,
    powerOfAttorneyNumber: data.power_of_attorney_number,
    powerOfAttorneyDate: data.power_of_attorney_date,
    email: data.email,
    addEmail: data.add_email,
    phone: data.phone,
    site: data.site,
    legalAddress: data.legal_address,
    mailingAddress: data.mailing_address,
    signingFile: data.signing_file,
    supervisorFullName: data.supervisor_full_name,
    supervisorPost: data.supervisor_post
  };
};

export default {
  namespaced: true,
  state: {
    initLoaded: false,
    profile: {}
  },
  getters: {
    getProfile(state) {
      return state.profile;
    },
    getInitLoaded(state) {
      return state.initLoaded;
    },
    completelyFilled(state) {
      const excluded = [
        'site',
        'addEmail',
        'powerOfAttorneyDate',
        'powerOfAttorneyNumber',
        'signingFile'
      ];
      let val = true;

      for (const prop in state.profile) {
        if (excluded.includes(prop)) {
          continue;
        }
        val = !!state.profile[prop] && !!val;
      }

      return val;
    }
  },
  mutations: {
    setProfile(state, payload) {
      state.profile = {
        ...state.profile,
        ...payload
      };
    }
  },
  actions: {
    async fetchProfile({commit, state}) {
      const {data} = await Axios.get(FETCH_REPRESENT_LEGAL_PROFILE);

      commit('setProfile', convertRawProfile(data.data));

      state.initLoaded = true;
    },
    async updateProfile({commit}, payload) {
      const form = generateFormData(payload);
      const {data} = await Axios.post(REPRESENT_PROFILE_LEGAL_EDIT, form);

      commit('setProfile', convertRawProfile(data.data));
    },
    async updateProfilePartial({state, commit}, payloadPartial) {
      const payload = {
        ...state.profile,
        ...payloadPartial
      };

      const form = generateFormData(payload);

      const {data} = await Axios.post(REPRESENT_PROFILE_LEGAL_EDIT, form);

      commit('setProfile', convertRawProfile(data.data));
    }
  }
};
