const configIndividualFields = [
  {
    keysTarget: ['full_name'],
    keySource: 'full_name'
  },
  {
    keysTarget: ['birthdate'],
    keySource: 'date_birth'
  },
  {
    keysTarget: ['gender_id'],
    keySource: 'gender'
  },
  {
    keysTarget: ['birth_place'],
    keySource: 'place_birth'
  },
  {
    keysTarget: ['phone'],
    keySource: 'phone'
  },
  {
    keysTarget: ['residential_address'],
    keySource: 'actual_address'
  },
  {
    keysTarget: ['register_address'],
    keySource: 'registration_address'
  },
  {
    keysTarget: ['email'],
    keySource: 'email'
  },
  {
    keysTarget: ['document_type'],
    keySource: 'type_document'
  },
  {
    keysTarget: ['document_serial'],
    keySource: 'serial_number'
  },
  {
    keysTarget: ['document_number'],
    keySource: 'number'
  },
  {
    keysTarget: ['document_issue_date'],
    keySource: 'date_issue'
  },
  {
    keysTarget: ['document_issued_by'],
    keySource: 'issued_by'
  },
  {
    keysTarget: ['document_issued_code'],
    keySource: 'code'
  },
  {
    keysTarget: ['document_period'],
    keySource: 'validity'
  },
  {
    keysTarget: ['register_date'],
    keySource: 'date_registration'
  },
  {
    keysTarget: ['citizenship_id'],
    keySource: 'citizenship'
  },
  {
    keysTarget: ['file'],
    keySource: 'file',
    isFile: true
  }
];

const configLegalFields = [
  {
    keysTarget: ['name'],
    keySource: 'name'
  },
  {
    keysTarget: ['full_name'],
    keySource: 'full_name'
  },
  {
    keysTarget: ['inn'],
    keySource: 'inn'
  },
  {
    keysTarget: ['kpp'],
    keySource: 'kpp'
  },
  {
    keysTarget: ['ogrn'],
    keySource: 'ogrn'
  },
  {
    keysTarget: ['ceo_full_name'],
    keySource: 'manager_name'
  },
  {
    keysTarget: ['signer_full_name'],
    keySource: 'signatory_name'
  },
  {
    keysTarget: ['signer_post'],
    keySource: 'signatory_position'
  },
  {
    keysTarget: ['basis_document_type_id'],
    keySource: 'based'
  },
  {
    keysTarget: ['basis_document_place'],
    keySource: 'place_registration'
  },
  {
    keysTarget: ['basis_document_number'],
    keySource: 'power_attorney_num'
  },
  {
    keysTarget: ['basis_document_date'],
    keySource: 'power_attorney_date'
  },
  {
    keysTarget: ['bank_bik'],
    keySource: 'bic'
  },
  {
    keysTarget: ['bank_name'],
    keySource: 'bank_name'
  },
  {
    keysTarget: ['account_cor'],
    keySource: 'correspondent_account'
  },
  {
    keysTarget: ['account_pay'],
    keySource: 'checking_account'
  },
  {
    keysTarget: ['physical_address'],
    keySource: 'actual_address'
  },
  {
    keysTarget: ['legal_address'],
    keySource: 'legal_address'
  },
  {
    keysTarget: ['phone'],
    keySource: 'phone'
  },
  {
    keysTarget: ['email'],
    keySource: 'email'
  },
  {
    keysTarget: ['contact_full_name'],
    keySource: 'contact_name'
  },
  {
    keysTarget: ['contact_post'],
    keySource: 'contact_position'
  },
  {
    keysTarget: ['contact_phone'],
    keySource: 'contact_phone'
  },
  {
    keysTarget: ['contact_email'],
    keySource: 'contact_mail'
  }
];

const mapper = (config, data) => {
  let result = {};
  config.forEach(e => {
    let value = '';
    e.keysTarget.forEach(key => {
      if (data[key]) {
        if (typeof data[key] === 'string') {
          value += value ? ` ${data[key]}` : data[key];
        } else {
          value = data[key];
        }
      }
    });
    if (e.isFile && value) {
      result[e.keySource] = [value];
    } else if (value) {
      result[e.keySource] = value;
    }
  });

  return result;
};

const mapToLegalPartData = data => {
  return mapper(configLegalFields, data);
};

const mapToIndividualPartData = data => {
  return mapper(configIndividualFields, data);
};

export default (type, data) => {
  if (type === 'BITVUZ.ТрехстороннийСЮрЛицом') {
    return mapToLegalPartData(data);
  }
  if (type === 'BITVUZ.ТрехстороннийСФизЛицом') {
    return mapToIndividualPartData(data);
  }
  return null;
};
