import {
  SET_GROUPS,
  SET_LOADING,
  SET_INIT_STATE,
  SET_USER_DATA,
  SET_PROGRAMS,
  SET_SERVICE_MANAGER,
  SET_CORP_PROGRAMS,
  SET_CORP_GROUPS,
  SET_CORP_USER,
  SET_CORP_INIT_STATE
} from './templates';

export default {
  [SET_GROUPS](state, params) {
    state.groups = params ?? [];
  },
  [SET_LOADING](state, value) {
    state.isLoading = value;
  },
  [SET_INIT_STATE](state, value) {
    state.isInit = value;
  },
  [SET_CORP_INIT_STATE](state, value) {
    state.isCorpInit = value;
  },
  [SET_USER_DATA](state, params) {
    state.user = params;
  },
  [SET_PROGRAMS](state, params) {
    state.programs = params ?? [];
  },
  [SET_SERVICE_MANAGER](state, params) {
    state.serviceManager = params ?? [];
  },
  [SET_CORP_USER](state, params) {
    state.corpUser = params;
  },
  [SET_CORP_PROGRAMS](state, params) {
    state.corpPrograms = params ?? [];
  },
  [SET_CORP_GROUPS](state, params) {
    state.corpGroups = params ?? [];
  }
};
