import store from '@/store';
import { setNotification } from '@/store/mutationsName';

export default function(data) {
  store.commit(setNotification, {
    title: data.title || null,
    message: data.message,
    duration: data.duration || 4000,
    type: data.type || 'error',
  });
};
