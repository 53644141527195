import Axios from '@/utils/Elk-Axios';
import setNotification from '@/utils/setNotification';
import { SET_LOCALE as SET_LOCALE_MUTATION } from '@/store/mutationsName';
import { SET_LOCALE as SET_LOCALE_ACTION } from '@/store/actionsName';
import { SET_LOCALE as SET_LOCALE_API } from '@/api';
import localStorageService from '@/utils/localStorageService';
import { PUBLIC_TOKEN } from '@/utils/constants';
import {
  isLocaleId,
  LOCALE_ID_RU,
  LOCALE_NAME_RU,
  LOCALE_NAME_EN,
  LOCALE_ID_EN,
} from '@/i18n/utils';
import { sentryMessage } from '@/sentry/utils';
import { getCommonCaptionByName } from '@/i18n/utils';
import I18N from '@/i18n';

const getInitialLocaleId = (locale) => {
  const localeId = Number(locale);

  if (isLocaleId(localeId)) {
    return localeId;
  }
  return LOCALE_ID_RU;
};

export default {
  namespaced: true,

  state: {
    localeId: getInitialLocaleId(localStorageService.getLocaleId()),
  },
  getters: {
    getLocaleName(state) {
      const localeId = state.localeId;

      switch (localeId) {
        case LOCALE_ID_RU:
          return LOCALE_NAME_RU;
        case LOCALE_ID_EN:
          return LOCALE_NAME_EN;
        default:
          return LOCALE_NAME_RU;
      }
    },
  },
  mutations: {
    [SET_LOCALE_MUTATION](state, localeId) {
      state.localeId = localeId;
      localStorageService.setLocaleId(localeId);
      document
        .querySelector('html')
        .setAttribute('lang', this.getters['locale/getLocaleName']);
    },
  },
  actions: {
    async [SET_LOCALE_ACTION](context, originalLocaleId) {
      try {
        const localeId = Number(originalLocaleId);

        if (isLocaleId(localeId)) {
          if (localStorageService.getToken() !== PUBLIC_TOKEN) {
            const { status } = await Axios.post(SET_LOCALE_API, {
              locale_id: localeId,
            });

            if (status !== 200) {
              throw new Error();
            }
          }
          context.commit(SET_LOCALE_MUTATION, localeId);
          location.reload();
        } else {
          sentryMessage(`Got wrong localeId for ${SET_LOCALE_ACTION}`, {
            localeId,
            isLocaleIdCheck: isLocaleId(localeId),
          });
        }
      } catch (e) {
        if (!e.isHandled) {
          setNotification({
            message: I18N.t(getCommonCaptionByName('error_settingLocale')),
          });
        }
      }
    },
  },
};
