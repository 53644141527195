import checkboxStatuses from '@/components/formElements/CascadeSelect/checkboxStatuses';

export default (status, oldValue = false) => {
  if (checkboxStatuses.notActive === status) {
    return false;
  } else if (checkboxStatuses.active === status) {
    return true;
  }

  return oldValue;
};

export function boolToStatus(value = false) {
  return value ? checkboxStatuses.active : checkboxStatuses.notActive;
}
