<template>
  <div class="elk-empty-wrapper">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'EmptyWrapper',  
}
</script>
<style lang="scss">
.elk-empty-wrapper {
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

