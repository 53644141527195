let components = {
  //side menu items
  'elk.user_suggestions': () =>
    import(
      /*webpackChunkName: "menu-icons"*/ '@/assets/icons/menuIcons/feedbackHotline.svg?inline'
    ),
  'elk.main': () =>
    import(
      /*webpackChunkName: "menu-icons"*/ '@/assets/icons/menuIcons/main.svg?inline'
    ),
  'elk.hr': () =>
    import(
      /*webpackChunkName: "menu-icons"*/ '@/assets/icons/menuIcons/hr.svg?inline'
    ),
  'elk.claim': () =>
    import(
      /*webpackChunkName: "menu-icons"*/ '@/assets/icons/menuIcons/claim.svg?inline'
    ),
  'elk.notification': () =>
    import(
      /*webpackChunkName: "menu-icons"*/ '@/assets/icons/menuIcons/notification.svg?inline'
    ),
  'elk.task': () =>
    import(
      /*webpackChunkName: "menu-icons"*/ '@/assets/icons/menuIcons/task.svg?inline'
    ),
  'elk.sed': () =>
    import(
      /*webpackChunkName: "menu-icons"*/ '@/assets/icons/menuIcons/sed.svg?inline'
    ),
  'elk.service': () =>
    import(
      /*webpackChunkName: "menu-icons"*/ '@/assets/icons/menuIcons/service.svg?inline'
    ),
  'elk.schedule': () =>
    import(
      /*webpackChunkName: "menu-icons"*/ '@/assets/icons/menuIcons/schedule.svg?inline'
    ),
  'elk.faq': () =>
    import(
      /*webpackChunkName: "menu-icons"*/ '@/assets/icons/menuIcons/faq.svg?inline'
    ),

  'elk.feedback': () =>
    import(
      /*webpackChunkName: "menu-icons"*/ '@/assets/icons/menuIcons/feedback.svg?inline'
    ),

  'elk.home-setting': () =>
    import(
      /*webpackChunkName: "menu-icons"*/ '@/assets/icons/menuIcons/homeSetting.svg?inline'
    ),

  'elk.logout': () =>
    import(
      /*webpackChunkName: "menu-icons"*/ '@/assets/icons/menuIcons/logout.svg?inline'
    ),
  'elk.stat': () =>
    import(
      /*webpackChunkName: "menu-icons"*/ '@/assets/icons/menuIcons/dashboard.svg?inline'
    ),
  'elk.postgraduate.attestation_lists': () =>
    import(
      /*webpackChunkName: "menu-icons"*/ '@/assets/icons/menuIcons/check.svg?inline'
    ),

  //for profile doc
  'profile.document.confirmed': () =>
    import(
      /*webpackChunkName: "profile-doc"*/ '@/assets/icons/profile/confirmed.svg?inline'
    ),
  'profile.document.unconfirmed': () =>
    import(
      /*webpackChunkName: "profile-doc"*/ '@/assets/icons/profile/unconfirmed.svg?inline'
    ),

  'elk.postgraduate.iups.page': () =>
    import(
      /*webpackChunkName: "menu-icons"*/ '@/assets/icons/menuIcons/folder.svg?inline'
    ),

  'elk.postgraduate.research_topics.page': () =>
    import(
      /*webpackChunkName: "menu-icons"*/ '@/assets/icons/menuIcons/education.svg?inline'
    ),

  'elk.postgraduate.internship_reports.page': () =>
    import(
      /*webpackChunkName: "menu-icons"*/ '@/assets/icons/menuIcons/education.svg?inline'
    ),

  'elk.my_publications': () =>
    import(
      /*webpackChunkName: "menu-icons"*/ '@/assets/icons/menuIcons/book.svg?inline'
    ),

  'elk.research_adviser.tasks.page': () =>
    import(
      /*webpackChunkName: "menu-icons"*/ '@/assets/icons/menuIcons/book.svg?inline'
    ),

  'elk.postgraduate.academic_debt.page': () =>
    import(
      /*webpackChunkName: "menu-icons"*/ '@/assets/icons/menuIcons/book.svg?inline'
    ),

  'elk.kedo.page': () =>
    import(
      /*webpackChunkName: "menu-icons"*/ '@/assets/icons/menuIcons/book.svg?inline'
    ),
  'dpo.navlinks': () =>
    import(
      /*webpackChunkName: "menu-icons"*/ '@/assets/icons/icon-arrow-default.svg?inline'
    ),
  default: () =>
    import(
      /*webpackChunkName: "menu-icons"*/ '@/assets/icons/menuIcons/main.svg?inline'
    ),
  cross: () =>
    import(
      /*webpackChunkName: "menu-icons"*/ '@/assets/icons/cross.svg?inline'
    ),
  present: () =>
    import(
      /*webpackChunkName: "menu-icons"*/ '@/assets/icons/present.svg?inline'
    ),
  dubl_doc: () =>
    import(
      /*webpackChunkName: "menu-icons"*/ '@/assets/icons/dubl_doc.svg?inline'
    ),
  icon_cert: () =>
    import(
      /*webpackChunkName: "menu-icons"*/ '@/assets/icons/icon_cert.svg?inline'
    ),
  'dpo.my_menu_icon': () =>
    import(
      /*webpackChunkName: "menu-icons"*/ '@/assets/icons/dpo_menu_icons.svg?inline'
    ),
  'elk.contracts_payments.page': () =>
    import(
      /*webpackChunkName: "menu-icons"*/ '@/assets/icons/rouble.svg?inline'
    ),
  //flags
  1: () => import(/*webpackChunkName: "flags"*/ '@/assets/flags/1.svg?inline'),
  3: () => import(/*webpackChunkName: "flags"*/ '@/assets/flags/3.svg?inline')
};

export default signature => {
  if (Object.prototype.hasOwnProperty.call(components, signature)) {
    return components[signature];
  }
  return false;
};
