<template>
  <button
    class="btn btn--secondary btn--icon btn-visibility"
    @click="editShow"
    :title="title"
    v-if="btnVisibility"
  >
    <template v-if="!loader">
      <VisibilityOn v-if="icon" class="visibility-on-icon" />
      <VisibilityOff v-else class="visibility-off-icon" />
    </template>
    <elkLoading v-else />
  </button>
</template>

<script>
import VisibilityOff from '@/assets/icons/visibility-off.svg?inline';
import VisibilityOn from '@/assets/icons/visibility-on.svg?inline';
import elkLoading from '@/components/common/elkLoading';

export default {
  name: 'btnShow',
  components: {
    VisibilityOff,
    VisibilityOn,
    elkLoading,
  },
  props: ['btnVisibility', 'iconVisibility', 'loaderBtn', 'titleBtn'],
  computed: {
    title() {
      return this.titleBtn;
    },
    icon() {
      return this.iconVisibility;
    },
    loader() {
      return this.loaderBtn;
    },
  },

  methods: {
    editShow() {
      this.$emit('edit-show');
    },
  },
};
</script>
