<template>
  <div ref="refTop" class="auth-form-block reg-wrap">
    <!-- <elk-recaptcha
      ref="recaptcha"
      @error="onRecaptchaError"
      @verify="sendEmailConfirmationCode"
    /> -->

    <recaptcha-error v-if="canShowRecaptchaError" />
    <!-- v-else -->
    <!-- <div v-else class="auth-form__wrapper">  -->
    <div class="auth-form__wrapper">
      <h2>Регистрация</h2>
      <form id="form-registration" class="form-auth" :class="{ 'form-group--error': $v.$invalid }">
        <!-- PERSONAL DATA BLOCK -->
        <template v-if="step === GET_PERSONAL_DATA">
          <div class="form-registration__wrapper">
            <custom-input id="lastName" :label="label.lastName" :value="$v.form.lastName">
              <!-- <template #errors>
                <div
                  v-if="!$v.form.lastName.minLength"
                  class="error"
                >
                  Фамилия не может быть меньше {{ $v.form.lastName.$params.minLength.min }} букв.
                </div>
              </template> -->
            </custom-input>

            <custom-input id="firstName" :label="label.firstName" :value="$v.form.firstName">
              <!-- <template #errors>
                <div
                  v-if="!$v.form.firstName.minLength"
                  class="error"
                >
                  Имя не может быть меньше {{ $v.form.firstName.$params.minLength.min }} букв.
                </div>
              </template> -->
            </custom-input>

            <input-patronymic ref="refPatronymic" :class="labelElement" :value="$v.form.patronymic" />

            <div class="data-wrap__inputs">
              <custom-input id="email" ref="refEmail" type="email" :label="label.email" :value="$v.form.email">
                <template #errors>
                  <div v-if="!$v.form.email.isUnique" class="error-message">
                    Данный Email занят.
                  </div>
                </template>
              </custom-input>

              <input-phone :label="label.phone" :value="$v.form.phone" :required="false" class="register__phone"
                @get-masked-phone="setPhoneString" />

              <input-password id="password" :label="label.password" :value="$v.form.password" />

              <input-password id="passwordConfirm" :label="label.passwordConfirm" :value="$v.form.passwordConfirm">
                <template #errors>
                  <div v-if="!$v.form.passwordConfirm.sameAsPassword" class="error">
                    Пароли должны совпадать.
                  </div>
                </template>
              </input-password>
            </div>
          </div>
          <form-alert />
          <div class="register-form__checkbox">
            <div class="inputCheckbox">
              <input id="checkbox" v-model="$v.form.acceptingPrivacyPolicy.$model" class="custom-checkbox" type="checkbox"
                name="checkbox" checked />
              <label for="checkbox">
                Согласен с
                <a href="https://www.hse.ru/data_protection_regulation" target="_blank">
                  Положением о защите конфиденциальной информации
                </a>
              </label>
            </div>
            <div class="inputCheckbox">
              <input id="checkbox-doc" v-model="$v.form.acceptingTerms.$model" class="custom-checkbox" type="checkbox"
                name="checkbox-doc" />
              <label for="checkbox-doc">
                Ознакомился с документами, в том числе с
                <a href="https://busedu.hse.ru/official_docs" target="_blank">
                  федеральными
                </a>,
                <a href="https://www.hse.ru/org/hse/aup/addedu/new_rules" target="_blank">
                  локальными
                </a>,
                <a href="https://www.hse.ru/docs/175456374.html" target="_blank">
                  уставом НИУ ВШЭ
                </a> и
                <a href="https://www.hse.ru/docs/206618260.html" target="_blank">
                  лицензией НИУ ВШЭ
                </a>
              </label>
            </div>
            <div class="inputCheckbox">
              <input id="confirm" v-model="$v.form.confirmationOfReliability.$model" class="custom-checkbox"
                type="checkbox" name="confirm" />
              <label for="confirm">Я подтверждаю, что лично ознакомился с Положением об обработке
                персональных данных НИУ ВШЭ, вправе предоставлять свои
                персональные данные и давать согласие на их обработку.
                Предоставляя свои персональные данные, принимаю условия этого
                Положения, подтверждаю, что они относятся ко мне, являются
                точными и актуальными, тем самым свободно, своей волей и в своем
                интересе распоряжаюсь ими, осознаю последствия их предоставления
                и выражаю свое согласие на их обработку в соответствии с пунктом
                3.7 Положения об обработке персональных данных НИУ ВШЭ.
              </label>
            </div>
            <smartcaptcha-yandex @on-verify="setCaptchaToken" @on-get-widget-id="setCaptchaId" />
          </div>


          <div class="btn-block__form">
            <button class="btn btn-checkout" type="submit" :disabled="$v.$invalid || state.btnStepPreloaderShow"
              @click.prevent="changeStep(step, true)">
              <loading v-if="state.btnStepPreloaderShow" color="white" small />
              <template v-else>
                {{ button.registration }}
              </template>
            </button>
            <router-link :to="AUTHORIZATION" class="btn btn-checkout-phone">
              {{ button.auth }}
            </router-link>
          </div>
        </template>

        <template v-if="step === CONFIRM_EMAIL_AND_PHONE">
          <div class="confirm__block">
            <p class="title-register__info">
              На почту <span>{{ form.email }}</span> было отправлено письмо с
              кодом.
            </p>
            <confirm-message label="Код из Email" :time-remaining="timeRemaining" :value="$v.form.emailCode"
              :preloader="state.isSendingConfirmationCodeLoading" @on-reset-timer="sendEmailConfirmationCode" />
            <template v-if="needValidatePhone">
              <p class="title-register__info">
                На номер телефона {{ maskedPhone }} отправлено SMS c кодом,
                введите его ниже.
              </p>
              <confirm-message :time-remaining="timeRemaining" :value="$v.form.phoneCode"
                :preloader="state.isSendingPhoneConfirmCode" @on-reset-timer="sendPhoneConfirmationCode" />
            </template>
          </div>
          <div class="btn-block__form">
            <button class="btn btn-checkout" type="submit" :disabled="!isAllCodesChecked || state.btnStepPreloaderShow"
              @click.prevent="changeStep(step, true)">
              <loading v-if="state.btnStepPreloaderShow" color="white" small />
              <template v-else>
                {{ button.next }}
              </template>
            </button>
            <button class="btn btn-checkout-phone" type="submit" :disabled="false" @click.prevent="changeStep(step)">
              {{ button.back }}
            </button>
          </div>
        </template>

      </form>
    </div>
    <div v-if="step === SUCCESS" class="block-finish">
      <div class="img-finish" />
      <h2>
        {{ titleInfo }}
      </h2>
      <p>
        {{ infoFinish }}
      </p>
    </div>
  </div>
</template>

<script>
import {
  email,
  minLength,
  required,
  requiredIf,
  sameAs,
} from 'vuelidate/lib/validators';
import Axios from '@/utils/Elk-Axios';
import {
  V1_CHECK_EMAIL,
  V1_REGISTRATION_STEP_1,
  V1_REGISTRATION_STEP_2,
} from '@/api';

import FormAlert from '@/components/FormAlerts/formAlert';
import ConfirmMessage from '@/components/confMessage/ConfirmMessage';
import InputPhone from '@/components/inputComponent/inputPhone';
import CustomInput from '@/components/inputComponent/CustomInput';
import InputPatronymic from '@/components/inputComponent/inputPatronymic';
import InputPassword from '@/components/inputComponent/inputPassword';
import loading from "@/components/common/elkLoading.vue";
import elkRecaptcha from '@/components/common/recaptcha';
import recaptchaError from '@/components/common/recaptcha/error';
import SmartcaptchaYandex from '@/components/common/smartcaptchaYandex';
import { AUTHORIZATION, HOME_ROUTE } from '@/router/routes';
import {
  // checkEmail,
  isInternalEmployeeEmail,
  isInternalStudentEmail,
} from '@/utils/regExps';
import setNotification from '@/utils/setNotification';

const REGISTRATION_STEPS = {
  RECAPTCHA_ERROR: 'recaptcha_error',
  GET_PERSONAL_DATA: 'get_personal_data',
  CONFIRM_EMAIL_AND_PHONE: 'confirm_email_and_phone',
  SUCCESS: 'success',
};

export default {
  name: 'RegistrationStart',

  components: {
    ConfirmMessage,
    CustomInput,
    FormAlert,
    InputPassword,
    InputPatronymic,
    InputPhone,
    elkRecaptcha,
    recaptchaError,
    SmartcaptchaYandex,
    loading,
  },

  data() {
    return {
      alreadyExistEmails: [],
      AUTHORIZATION,
      button: {
        auth: 'Авторизация',
        back: 'Назад',
        next: 'Далее',
        registration: 'Зарегистрироваться',
      },
      form: {
        lastName: '',
        firstName: '',
        patronymic: '',
        email: '',
        emailCode: '',
        phone: '',
        phoneCode: '',
        password: '',
        passwordConfirm: '',
        acceptingPrivacyPolicy: false,
        acceptingTerms: false,
        confirmationOfReliability: false,
      },
      labelElement: 'label-no-star',
      label: {
        lastName: 'Фамилия',
        firstName: 'Имя',
        patronymic: 'Отчество (при наличии)',
        email: 'E-mail',
        phone: 'Телефон',
        password: 'Придумайте пароль',
        passwordConfirm: 'Повторите пароль',
      },
      state: {
        isRecaptchaError: false,
        isAlreadyExistEmail: false,
        isEmailValidAndChecked: false,
        isPhoneConfirmationCodeSend: false,
        isSendingEmailConfirmCode: false,
        isSendingPhoneConfirmCode: false,
        btnStepPreloaderShow: false,
      },
      captchaId: null,
      captchaToken: '',
      maskedPhone: null,
      step: REGISTRATION_STEPS.GET_PERSONAL_DATA,
      infoFinish:
        'В течение пяти секунд вас переадресует на главную страницу сайта. Удачи!',
      titleInfo: 'Вы зарегистрировались',
      timeRemaining: 300,
      ...REGISTRATION_STEPS,
    };
  },

  computed: {
    needValidateEmail() {
      return this.step === this.CONFIRM_EMAIL_AND_PHONE;
    },
    needValidatePhone() {
      return (
        this.step === this.CONFIRM_EMAIL_AND_PHONE && this.$v.form.$model.phone
      );
    },
    isEmailCodeValid() {
      return !this.$v.form.emailCode.$invalid;
    },
    isPhoneCodeValid() {
      return !this.$v.form.phoneCode.$invalid;
    },
    isAllCodesChecked() {
      return this.isEmailCodeValid && this.isPhoneCodeValid;
    },
    canShowRecaptchaError() {
      return (
        process.env.NODE_ENV === 'production' && this.state.isRecaptchaError
      );
    },
  },

  validations() {
    return {
      form: {
        lastName: {
          required,
        },
        firstName: {
          required,
        },
        patronymic: {},
        email: {
          email,
          isNotInternal: (email) =>
            !isInternalEmployeeEmail(email) && !isInternalStudentEmail(email),
          isUnique: async (value) => {
            if (
              this.$v.form.email.$anyDirty &&
              this.$v.form.email.email &&
              value &&
              typeof value === 'string'
            ) {
              try {
                const { data } = await Axios.get(V1_CHECK_EMAIL + value);

                if (
                  data.status === 'success' &&
                  data.data.result &&
                  !this.alreadyExistEmails.includes(value)
                ) {
                  this.alreadyExistEmails.push(value);
                }
              } catch {
                /** */
              }
            }

            return !this.alreadyExistEmails.includes(value);
          },
        },
        phone: {
          phoneCheck: (value) => (value ? value.length >= 10 : true),
        },
        password: {
          isValid(value) {
            /* проверка на (нижний регистр)(верхний регистр)(цифру)(спецсимвол)[разрешенные символы] */
            const passwordCheck = new RegExp(
              '(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[\\W])[A-Za-z\\d\\W]',
              'gm'
            );
            return passwordCheck.test(value);
          },
          minLength: minLength(8),
        },
        passwordConfirm: {
          sameAsPassword: sameAs('password'),
        },
        acceptingPrivacyPolicy: {
          sameAs: sameAs(() => true),
        },
        acceptingTerms: {
          sameAs: sameAs(() => true),
        },
        confirmationOfReliability: {
          sameAs: sameAs(() => true),
        },
        emailCode: {
          required: requiredIf(function () {
            return this.needValidateEmail;
          }),
          minLength: function (value) {
            return this.needValidateEmail ? minLength(4)(value) : true;
          },
        },
        phoneCode: {
          required: requiredIf(function () {
            return this.needValidatePhone;
          }),
          minLength: function (value) {
            return this.needValidateEmail ? minLength(4)(value) : true;
          },
        },
      },
    };
  },

  methods: {
    scrollIntoView(el, options = { block: 'center', behavior: 'smooth' }) {
      if (el instanceof Node) {
        el.scrollIntoView(options);
      }
    },
    setPhoneString(value) {
      this.maskedPhone = value;
      this.state.isPhoneConfirmationCodeSend = false;
    },
    setCaptchaToken(token) {
      this.captchaToken = token;
    },
    async getCaptchaToken() {
      if (window.smartCaptcha && this.captchaId) {
        try {
          return await window.smartCaptcha.getResponse(this.captchaId);
        } catch {
          /** */
        }
      }
      return null  /** TODO: продумать логику, если капчи все-таки нет*/;
    },
    setCaptchaId(id) {
      this.captchaId = id;
    },
    async sendEmailConfirmationCode(recaptchaToken) {
      if (this.state.isAlreadyExistEmail) {
        this.state.isAlreadyExistEmail = false;
      }

      this.state.isSendingConfirmationCodeLoading = true;
      this.state.btnStepPreloaderShow = true;

      const postData = {
        email: this.form.email,
        phone: this.form.phone ?? null,
        // g_recaptcha_response: recaptchaToken,
      };

      try {
        const { data } = await Axios.post(V1_REGISTRATION_STEP_1, postData);

        if (data.result === 'email already exists') {
          this.alreadyExistEmails.push(this.$v.form.$model.email);
          this.state.isAlreadyExistEmail = true;
          this.$v.$touch();

          this.scrollIntoView(this.$refs.refEmail?.$el);

          setNotification({
            message: 'Email занят',
            type: 'error',
          });
          return false;
        } else {
          return true;
        }
      } catch (e) {
        /** */
      } finally {
        this.state.isSendingConfirmationCodeLoading = false;
        this.state.btnStepPreloaderShow = false;
      }
    },
    async sendPhoneConfirmationCode() {
      this.state.isSendingPhoneConfirmCode = true;
      const postData = {
        email: ' ',
        phone: this.form.phone,
        // g_recaptcha_response: recaptchaToken,
      };

      try {
        await Axios.post(V1_REGISTRATION_STEP_1, postData);
      } catch (e) {
        /** */
      } finally {
        this.state.isSendingPhoneConfirmCode = false;
      }
    },
    async gotoPersonalData() {
      this.step = this.GET_PERSONAL_DATA;
      this.$refs.refTop.scrollIntoView();
    },
    async gotoConfirmation() {
      if (!this.$v.$invalid) {
        this.step = this.CONFIRM_EMAIL_AND_PHONE;
        this.sendEmailConfirmationCode();
        this.$refs.refTop.scrollIntoView();
      }
    },
    async gotoFinish() {
      this.state.btnStepPreloaderShow = true
      let message = '';
      try {
        const { form } = this.$v;

        const postData = {
          email: form.$model.email,
          email_code: form.$model.emailCode,
          phone: form.$model.phone ? form.$model.phone : null,
          phone_code: form.$model.phone ? form.$model.phoneCode : null,
          first_name: form.$model.firstName,
          last_name: form.$model.lastName,
          middle_name: form.$model.patronymic,
          signature: this.$route.query?.type || null,
          password: form.$model.password,
        };

        const { data } = await Axios.post(V1_REGISTRATION_STEP_2, postData);

        if (data.result === 'success') {
          this.gotoSuccess();
        } else if (data.result === 'wrong code') {
          message = 'Введен некорректный код проверки';
        }
      } catch (e) {
        message = 'Не удалось зарегистрировать пользователя, проверьте заполненные данные';
        /** */
      } finally {
        this.state.btnStepPreloaderShow = false;
        if (message) {
          const h = this.$createElement;
          this.$notify({
            title: '',
            type: 'error',
            message: h('i', {}, message)
          });
        }
      }
    },
    gotoSuccess() {
      this.step = this.SUCCESS;
      this.form = {
        lastName: '',
        firstName: '',
        patronymic: '',
        email: '',
        emailCode: '',
        phone: '',
        phoneCode: '',
        password: '',
        passwordConfirm: '',
        acceptingPrivacyPolicy: false,
        acceptingTerms: false,
        confirmationOfReliability: false,
      };
      this.redirectToHome();
    },
    redirectToHome(timeout = 5000) {
      setTimeout(() => {
        this.$router.push({ path: HOME_ROUTE });
      }, timeout);
    },
    onRecaptchaError() {
      this.state.isRecaptchaError = true;
    },
    changeStep(currentStep, forward = false) {
      switch (true) {
        case currentStep === this.GET_PERSONAL_DATA:
          if (forward) {
            this.gotoConfirmation();
          }
          break;
        case currentStep === this.CONFIRM_EMAIL_AND_PHONE:
          if (forward) {
            this.gotoFinish();
          } else {
            this.step = this.GET_PERSONAL_DATA;
          }
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss">
.auth-form-block.reg-wrap {
  .form-auth {
    .inputWrap {
      margin-left: 0;
    }
  }
}

.register-form__checkbox {
  .inputCheckbox {
    label {
      a {
        font-size: 16px;
      }
    }
  }
}

.inputCheckbox {
  .custom-checkbox+label:before {
    position: absolute;
    top: 1px;
    left: 0;
  }
}

.inputCheckbox {
  label {
    padding-left: 24px;

    a {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
    }
  }
}

.inputWrap.register__phone {
  label:before {
    content: unset;
  }
}

.auth-form-block.reg-wrap {
  .register-form__checkbox {
    margin-bottom: 62px;

    .inputCheckbox {
      label {
        display: block;
        position: relative;

        a {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }

  .btn-block__form {
    .btn:last-child {
      margin-bottom: 0;
    }
  }

  .form-alert__info {
    margin-bottom: 34px;
  }

  .data-wrap__inputs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .inputWrap {
      width: 276px;
      margin-left: 0;
    }
  }

  .confirm__block {
    margin-bottom: 48px;

    .resend-message {
      button {
        width: 100%;
      }
    }
  }

  .title-register__info {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    text-align: center;

    span {
      color: #0050cf;
    }
  }

  .form-registration__wrapper {
    .inputWrap.label-no-star {
      label:before {
        content: unset;
      }
    }
  }
}

.inputCheckbox {
  a {
    color: #0050cf;
  }
}

.auth-form-block.reg-wrap {
  width: 776px;
}

@media (max-width: 1440px) {
  .auth-form-block.reg-wrap {
    .data-wrap__inputs {
      flex-direction: column;

      .inputWrap {
        width: 100%;
      }
    }

    .confirm__message {
      flex-direction: column;

      .inputWrap.error-system,
      .resend-message {
        width: 100%;
      }
    }

    .register-form__checkbox {
      .inputCheckbox {
        label {
          font-size: 14px;

          a {
            font-size: 14px;
          }
        }
      }
    }

    .title-register__info {
      font-size: 14px;
    }
  }
}

@media (max-width: 768px) {
  .block-finish {
    .img-finish {
      width: 150px;
      height: 150px;
    }

    h2 {
      font-size: 24px;
      line-height: 32px;
    }

    p {
      display: inline-block;
      width: 350px;
    }
  }
}

@media (max-width: 540px) {
  .auth-form-block.reg-wrap {
    .register-form__checkbox {
      .inputCheckbox label {
        .router-link-active {
          font-size: 14px;
        }
      }
    }
  }

  .block-finish {
    .img-finish {
      width: 100px;
      height: 100px;
    }

    p {
      width: 250px;
    }
  }
}

.error-message {
  color: $error-system-red;
}
</style>
