<template>
  <div class="history-elements">
    <div class="history-element__title">
      <h5>
        Период
      </h5>
    </div>
    <div class="block-date">
      начало конец
    </div>
    <div class="wrapper-table__history">
      <table>
        <thead>
          <tr>
            <th>
              Дата и время
            </th>
            <th>
              Действие
            </th>
            <th>
              Местоположение
            </th>
            <th>
              Устройство и браузер
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, i) in items"
            :key="i"
          >
            <td>
              {{ item.date }}
            </td>
            <td>
              {{ item.authorization }}
            </td>
            <td>
              {{ item.ip }}
            </td>
            <td>
              <div class="table-devices">
                {{ item.device }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <button class="btn-table__more">
      Показать ещё 3 записи
    </button>
  </div>
</template>

<script>
export default {
  name: "HistoryItem",
  data() {
    return {
      items: [
        {date: '13 дек. 2022 г, 14:20', authorization: 'Авторизация', ip: 'Россия, Москва, IP 123.45.678.144', device: 'Unknown, YaBrouser, 22.11.2.807'},
        {date: '15 дек. 2022 г, 18:20', authorization: 'Авторизация', ip: 'Россия, Москва, IP 123.45.678.144', device: 'Unknown, YaBrouser, 22.11.2.807'},
        {date: '18 дек. 2022 г, 19:20', authorization: 'Авторизация', ip: 'Россия, Москва, IP 123.45.678.144', device: 'Unknown, YaBrouser, 22.11.2.807'},
        {date: '23 дек. 2022 г, 17:20', authorization: 'Авторизация', ip: 'Россия, Москва, IP 123.45.678.144', device: 'Unknown, YaBrouser, 22.11.2.807'},
      ]
    }
  }
}
</script>

<style lang="scss">
.btn-table__more{
  margin-top: 16px;
  border: none;
  box-sizing: border-box;
  background: transparent;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: $blue-color;
  padding: 0;
}
  .block-date{
    margin-bottom: 24px;
  }
 .history-element__title{
   h5{
     font-weight: 400;
     font-size: 14px;
     line-height: 20px;
     color: #6B7A99;
   }
 }
 .wrapper-table__history{
   table{
     width: 100%;
     thead{
       tr{
         border-bottom: 1px solid #EDEFF3;
         th{
           text-align: left;
           padding-bottom: 16px;
           font-weight: 400;
           font-size: 14px;
           line-height: 20px;
           color: #6B7A99;
         }
       }
     }
     tbody{
       tr{
         border-bottom: 1px solid #EDEFF3;
         td{
           padding: 16px 0;
           font-weight: 400;
           font-size: 16px;
           line-height: 20px;
           color: $base-black-color;
           .table-devices{
             position: relative;
             padding-left: 43px;
             &:before{
               content: '';
               position: absolute;
               top: 0;
               left: 0;
               display: block;
               width: 24px;
               height: 22px;
               background: url("../../assets/icons/icon-device.svg") 100% 100% no-repeat;
             }
           }
         }
       }
     }
   }
 }

</style>
