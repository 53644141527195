<template>
  <ErrorPagePublic v-if="isPublicUser" />
  <div
    v-else
    :class="{body: true, active: menuActive}"
  >
    <menu-wrapper :page-name="localeText.title" />
    <div class="page-wrapper">
      <main
        class="content"
        :class="pageClass"
      >
        <div class="container">
          <section class="section-tabs">
            <h1 class="page-title">
              {{ localeText.title }}
            </h1>
            <div class="nav-tabs">
              <ul>
                <li class="nav-item">
                  <a
                    class="nav-link__item"
                    href="#tab1"
                    :class="{active: isActive(TABS.TAB1)}"
                    @click.prevent="setActive(TABS.TAB1)"
                  >
                    {{ localeText.agreementsActs }}
                    <span>{{ actsFiltered.length }}</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link__item"
                    href="#tab2"
                    :class="{active: isActive(TABS.TAB2)}"
                    @click.prevent="setActive(TABS.TAB2)"
                  >
                    {{ localeText.trainingDocs }}
                    <span>{{ finalDocsFiltered.length }}</span>
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link__item"
                    href="#tab3"
                    :class="{active: isActive(TABS.TAB3)}"
                    @click.prevent="setActive(TABS.TAB3)"
                  >
                    {{ localeText.billsLabel }}
                    <span v-if="metaInvoices">{{
                      metaInvoices.total || '0'
                    }}</span>
                    <span v-else>0</span>
                  </a>
                </li>
              </ul>
            </div>

            <div class="decor-line" />
            <div
              v-if="isActive(TABS.TAB2) && outputDocumentsTabs.length"
              class="wrap_flex_tag"
            >
              <div
                v-for="(oItem, i) in outputDocumentsTabs"
                :key="i"
                class="flex flex_tag_item"
              >
                <a
                  :href="oItem.url"
                  class="duplicate-document flex-center"
                >
                  <div class="icon_bg flex-center">
                    <img :src="oItem.image" />
                  </div>
                  <span class="header_info">
                    {{ oItem.name }}
                  </span>
                  <p />
                </a>
              </div>
            </div>

            <div
              v-if="isActive(TABS.TAB1)"
              class="page-control-panel-mobil-addition"
            >
              <search-form
                v-model="searchValue"
                :field-placeholder="localeText.filterFindDocument + '...'"
                @clearField="clearSearch"
              />

              <button
                class="vi-btn vi-btn-filter"
                @click.prevent="pageFilter.isOpen = true"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.25 11.25H3V12.75H14.25V11.25ZM16.5 9.75H15V14.25H16.5V12.75H21V11.25H16.5V9.75ZM7.5 8.25H9V3.75H7.5V5.25H3V6.75H7.5V8.25ZM21 5.25H9.75V6.75H21V5.25ZM7.5 17.25H3V18.75H7.5V20.25H9V15.75H7.5V17.25ZM21 17.25H9.75V18.75H21V17.25Z"
                    fill="white"
                  />
                </svg>
                {{ localeText.filterMobileBtn }}
                <span v-show="commonSelectList.length > 0">
                  ({{ commonSelectList.length }})
                </span>
              </button>
            </div>

            <div
              v-if="isActive(TABS.TAB1)"
              class="great-filter great-filter-documents"
              :class="{'great-filter--active': pageFilter.isOpen}"
            >
              <div class="great-filter__header">
                <button
                  class="arrow-btn-back"
                  @click.prevent="pageFilter.isOpen = false"
                >
                  <svg
                    width="24"
                    height="24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.353 6.304 9.28 6.22a.75.75 0 0 0-.976-.073l-.084.073-4.997 4.997a.75.75 0 0 0-.073.976l.073.085L8.22 17.28a.75.75 0 0 0 1.133-.976l-.072-.084-3.711-3.717h14.68a.75.75 0 0 0 .743-.647l.007-.102a.75.75 0 0 0-.648-.743l-.102-.007-14.693-.001L9.281 7.28a.75.75 0 0 0 .072-.976L9.28 6.22l.073.084Z"
                      fill="#0F0F14"
                    />
                  </svg>
                  {{ localeText.backBtn }}
                </button>
              </div>

              <div class="page-control-panel page-control-panel--four-col">
                <div
                  class="page-control-panel__item page-control-panel__item--search-field"
                >
                  <search-form
                    v-model="searchValue"
                    :field-placeholder="localeText.filterFindDocument + '...'"
                    @clearField="clearSearch"
                  />
                </div>

                <div class="page-control-panel__item">
                  <multiselect
                    :id="arrDocYears"
                    v-model="commonSelectList"
                    class="vi-multiselect"
                    :options="arrDocYears.options"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :placeholder="localeText.filterYear"
                    label="label"
                    track-by="label"
                    :preselect-first="false"
                    @input="increaseCounterSelect"
                  >
                    <template
                      slot="selection"
                      slot-scope="{isOpen}"
                    >
                      <span
                        v-show="!isOpen"
                        class="multiselect__single"
                      >
                        {{ localeText.filterYear }}
                        <span v-if="arrDocYears.arrDocYears > 0">
                          ({{ arrDocYears.arrDocYears }})
                        </span>
                      </span>
                    </template>
                  </multiselect>
                </div>

                <div class="page-control-panel__item">
                  <multiselect
                    :id="arrTypesOfDocument"
                    v-model="commonSelectList"
                    class="vi-multiselect"
                    :options="arrTypesOfDocument.options"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :placeholder="localeText.filterDocType"
                    label="label"
                    track-by="label"
                    :preselect-first="false"
                    @input="increaseCounterSelect"
                  >
                    <template
                      slot="selection"
                      slot-scope="{isOpen}"
                    >
                      <span
                        v-show="!isOpen"
                        class="multiselect__single"
                      >
                        {{ localeText.filterDocType }}
                        <span v-if="arrTypesOfDocument.arrTypesOfDocument > 0">
                          ({{ arrTypesOfDocument.arrTypesOfDocument }})
                        </span>
                      </span>
                    </template>
                  </multiselect>
                </div>

                <div class="page-control-panel__item">
                  <multiselect
                    :id="arrMyProgram"
                    v-model="commonSelectList"
                    class="vi-multiselect"
                    :options="arrMyProgram.options"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :placeholder="localeText.title"
                    label="label"
                    track-by="label"
                    :preselect-first="false"
                    @input="increaseCounterSelect"
                  >
                    <template
                      slot="selection"
                      slot-scope="{isOpen}"
                    >
                      <span
                        v-show="!isOpen"
                        class="multiselect__single"
                      >
                        {{ localeText.title }}
                        <span v-if="arrMyProgram.arrMyProgram > 0"
                          >({{ arrMyProgram.arrMyProgram }})</span
                        >
                      </span>
                    </template>
                  </multiselect>
                </div>
              </div>

              <div class="great-filter__footer">
                <button
                  class="vi-btn vi-btn--hollow"
                  @click.prevent="clearFilter"
                >
                  {{ localeText.clearMobileBtn }}
                </button>
                <button
                  class="vi-btn"
                  @click.prevent="processFilter"
                >
                  {{ localeText.doneMobileBtn }}
                </button>
              </div>
            </div>
            <selectoin-point-box :option="commonSelectList">
              <selectoin-poin
                v-for="(item, index) in commonSelectList"
                :key="index"
                :sp-text="item.label"
                :select-name="item.selectName"
                @getText="clearFilterParameters"
              />
            </selectoin-point-box>
            <button
              v-show="commonSelectList.length > 0"
              class="selectoin-poin-box-toggle-slide-btn"
              :class="{
                'selectoin-poin-box-toggle-slide-btn--collapse':
                  !isOpenSelectoinPoinox
              }"
              @click.prevent="toggleSelectionPoinBox"
            >
              <span
                class="electoin-poin-box-toggle-slide-btn__caption-collapse"
              >
                {{ localeText.hideSidebar }}
              </span>
              <span class="electoin-poin-box-toggle-slide-btn__caption-expand">
                {{ localeText.expandBtn }}
              </span>
              <svg
                width="8"
                height="6"
                viewBox="0 0 8 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.06 5.4707L4 2.41737L0.94 5.4707L8.21774e-08 4.5307L4 0.530703L8 4.5307L7.06 5.4707Z"
                  fill="#6B7A99"
                />
              </svg>
            </button>

            <!--            <div class="decor-line" />-->

            <!--            <div-->
            <!--              v-show="isActive(TABS.TAB2)"-->
            <!--              class="wrap_flex_tag"-->
            <!--            >-->
            <!--              <div-->
            <!--                v-for="(oItem, i) in outputDocuments"-->
            <!--                :key="i"-->
            <!--                class="flex flex_tag_item"-->
            <!--              >-->
            <!--                <a-->
            <!--                  :href="oItem.url"-->
            <!--                  class="duplicate-document flex-center"-->
            <!--                >-->
            <!--                  <div class="icon_bg flex-center">-->
            <!--                    <img :src="oItem.image" />-->
            <!--                  </div>-->
            <!--                  <span class="header_info">-->
            <!--                    {{ oItem.name }}-->
            <!--                  </span>-->
            <!--                  <p />-->
            <!--                </a>-->
            <!--              </div>-->
            <!--            </div>-->

            <div class="result-panel">
              <div class="result-panel-item">
                <div class="result-panel-item__key">
                  {{ localeText.inTotal }}:
                </div>
                <div class="result-panel-item__value">
                  {{ itemsCount }}
                </div>
              </div>

              <div
                v-show="isActive(TABS.TAB1) || isActive(TABS.TAB2)"
                class="result-panel-item"
              >
                <div class="result-panel-item__key">
                  {{ localeText.sortLabel }}:
                </div>
                <div class="result-panel-item__value">
                  <psevdoselect
                    :default-value="sortInitial"
                    :ar-options="sortOptions"
                    @input="onChangeSort"
                  />
                </div>
              </div>
            </div>

            <div
              id="documentTabContent"
              class="tab-content"
            >
              <div
                id="tab1"
                class="tab-pane fade"
                :class="{'active show': isActive(TABS.TAB1)}"
              >
                <acts-component
                  v-if="actsFiltered.length"
                  :items="actsFiltered"
                  :localization="localization"
                  @refresh="getDocumentsAndFilters"
                />
                <empty-wrapper v-else>
                  <empty-data-message :text="localeHtml.emptyAgreeActs" />
                </empty-wrapper>
              </div>
              <div
                id="tab2"
                class="tab-pane fade"
                :class="{'active show': isActive(TABS.TAB2)}"
              >
                <doc-acts-educ-component
                  v-if="finalDocsFiltered.length > 0"
                  :items="finalDocsFiltered"
                  :localization="localization"
                />
                <empty-wrapper v-else>
                  <empty-data-message :text="localeHtml.emptyTrainingDocs" />
                </empty-wrapper>
              </div>
              <div
                id="tab3"
                class="tab-pane fade"
                :class="{'active show': isActive(TABS.TAB3)}"
              >
                <span v-if="metaInvoices && metaInvoices.total">
                  <InvoicesComponent
                    v-loading="isLoadInvoices"
                    :items="invoices"
                    :meta="metaInvoices"
                    :current-page="currInvoicePage"
                    :localization="localization"
                    @on-page="onPageInvoice"
                    @update-invoices="getInvoices"
                  />
                </span>
                <empty-wrapper v-else>
                  <empty-data-message :text="localeHtml.emptyTrainingDocs" />
                </empty-wrapper>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
    <page-footer />
  </div>
</template>

<script>
import iconLoader from '@/utils/iconLoader';
import Axios from '@/utils/Elk-Axios';
import {GET_SERVICES, V1_DOCUMENTS, V1_DOCUMENTS_FINAL, INVOICES} from '@/api';
import {mapGetters, mapState} from 'vuex';
import MenuWrapper from '@/components/menuWrapper';
import PageFooter from '@/components/PageFooter';
import ActsComponent from '@/components/actsDocuments/actsComponent';
import DocActsEducComponent from '@/components/actsDocuments/docActsEducComponent';
import InvoicesComponent from '@/components/actsDocuments/invoicesComponent';

import EmptyDataMessage from '@/components/common/elkEmptyDataMessage';
import EmptyWrapper from '@/components/common/elkEmptyWrapper';

import SearchForm from '@/components/forms/SearchForm.vue'; // форма поиска
import Psevdoselect from '@/components/formElements/Psevdoselect.vue'; //псевдоселект для сортировки
import SelectionPointBox from '@/components/selectionPoints/SelectionPointBox.vue'; //панель с элементами параметров выборки
import SelectionPoint from '@/components/selectionPoints/SelectionPoint.vue'; //элементы выборки
import Multiselect from 'vue-multiselect';

import {
  compareISODates,
  compareISODatesAsc
} from '@/utils/dates/compareISODates';
import ErrorPagePublic from '@/views/404/index.vue';
import localization from '@/i18n/localization';
import setNotification from '@/utils/setNotification';

const TABS = {
  TAB1: '#tab1',
  TAB2: '#tab2',
  TAB3: '#tab3'
};

export default {
  name: 'DocumentsPage',
  components: {
    ErrorPagePublic,
    DocActsEducComponent,
    InvoicesComponent,
    ActsComponent,
    PageFooter,
    MenuWrapper,
    EmptyWrapper,
    EmptyDataMessage,
    'search-form': SearchForm,

    'selectoin-point-box': SelectionPointBox,
    'selectoin-poin': SelectionPoint,
    Psevdoselect,
    Multiselect: Multiselect
  },
  data() {
    return {
      TABS,
      name: 'Мои документы',
      pageClass: 'documents-page',
      localization: localization('dpo.documents'),
      activeItem: TABS.TAB1,
      invoices: [],
      searchValue: '', //для формы поиска
      pageFilter: {
        //состояние фильтра (открыт или закрыт в мобильной версии)
        isOpen: false
      },
      // объекты селектов
      filtrItemsCountController: 0,
      isOpenSelectoinPoinox: false, // свойство отвечающее за свернутое и развернутое состояние блока с выборками фильтра
      commonSelectList: [], //копилка параметров поиска
      outputDocumentsTabs: [],
      outputDocumentsTabFinal: [],
      outputDocumentsTabActs: [],
      priceRunge: {
        priceRungElemName: 'Диапазон стоимости',
        rangeValue: [80000, 200000],
        priceRunge: 0
      },

      arrDocYears: {
        name: 'arrDocYears',
        arrDocYears: 0,
        options: []
      },
      arrTypesOfDocument: {
        name: 'arrTypesOfDocument',
        arrTypesOfDocument: 0,
        options: []
      },
      arrMyProgram: {
        name: 'arrMyProgram',
        arrMyProgram: 0,
        options: []
      },
      //объект селекта сортировки
      psevdoselectValue: '',
      url_dupl_doc: '',
      url_pap_doc: '',
      acts: [],
      actsFiltered: [],
      finalDocs: [],
      metaInvoices: null,
      currInvoicePage: 1,
      isLoadInvoices: false
    };
  },
  computed: {
    ...mapState(['menuActive']),
    ...mapGetters(['isPublicUser']),
    localeText() {
      return {
        title: this.localization.widgetCaption('myDocsTitle'),
        billsLabel: this.localization.widgetCaption('billsLabel'),
        agreementsActs: this.localization.widgetCaption('agreementsActs'),
        trainingDocs: this.localization.widgetCaption('trainingDocs'),
        filterFindDocument:
          this.localization.widgetCaption('filterFindDocument'),
        sortLabel: this.localization.commonCaption('sortBy'),
        filterMobileBtn: this.localization.commonCaption('filterLabel'),
        doneMobileBtn: this.localization.commonCaption('doneBtn'),
        clearMobileBtn: this.localization.commonCaption('clear'),
        backBtn: this.localization.commonCaption('back'),
        inTotal: this.localization.commonCaption('inTotal'),
        hideSidebar: this.localization.commonCaption('hideSidebar'),
        expandBtn: this.localization.commonCaption('expandBtn'),
        filterYear: this.localization.commonCaption('filterYear'),
        filterDocType: this.localization.commonCaption('filterDocType')
      };
    },
    localeHtml() {
      return {
        emptyAgreeActs: this.localization.widgetCaption('emptyAgreeActs'),
        emptyTrainingDocs: this.localization.widgetCaption('emptyTrainingDocs')
      };
    },
    sortOptions() {
      return [
        {
          label: this.localization.commonCaption('sortByDefault'),
          value: ''
        },
        {
          label:
            this.localization.commonCaption('sortByDate') +
            `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 13.5V2.5" stroke="#0F0F14" stroke-linecap="round" stroke-linejoin="round"/><path d="M3.5 7L8 2.5L12.5 7" stroke="#0F0F14" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
          value: 'date_asc'
        },
        {
          label:
            this.localization.commonCaption('sortByDate') +
            '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 2.5L8 13.5" stroke="#0F0F14" stroke-linecap="round" stroke-linejoin="round"/><path d="M12.5 9L8 13.5L3.5 9" stroke="#0F0F14" stroke-linecap="round" stroke-linejoin="round"/></svg>',
          value: 'date_desc'
        }
      ];
    },
    sortInitial() {
      return this.sortOptions[0];
    },
    itemsCount() {
      if (this.isActive(TABS.TAB1)) {
        return this.actsFiltered.length;
      }
      if (this.isActive(TABS.TAB2)) {
        return this.finalDocsFiltered.length;
      }
      if (this.isActive(TABS.TAB3)) {
        return this.metaInvoices?.total ?? 0;
      }
      return 0;
    },
    filters() {
      const filters = {
        sort: this.psevdoselectValue,
        search: this.searchValue.toLowerCase(),
        year: [],
        type: [],
        application: []
      };

      return this.commonSelectList.reduce((acc, item) => {
        const {value, selectName} = item;

        switch (selectName) {
          case 'arrDocYears':
            acc.year.push(value);
            break;
          case 'arrTypesOfDocument':
            acc.type.push(value);
            break;
          case 'arrMyProgram':
            acc.application.push(value);
            break;
          default:
            break;
        }

        return {...acc};
      }, filters);
    },
    finalDocsFiltered() {
      return this.psevdoselectValue.length > 0
        ? this.sortDocuments(this.finalDocs)
        : this.finalDocs;
    }
  },

  watch: {
    filters(val) {
      let acts = [...this.acts];

      acts = this.sortDocuments(acts);

      if (val.search.length) {
        acts = acts.filter(item =>
          item.description.toLowerCase().includes(val.search)
        );
      }

      acts = acts.filter(this.filterActs);

      this.actsFiltered = acts;
    }
  },

  async created() {
    this.switchTabByHash();
    await this.getDocumentsAndFilters();
    await this.getDocumentsFinal();
    await this.getInvoices();
  },
  mounted() {
    this.getServices();
    document.addEventListener('hashchange', this.switchTabByHash);
  },
  unmounted() {
    document.removeEventListener('hashchange', this.switchTabByHash);
  },

  methods: {
    iconLoader,
    switchTabByHash() {
      const {hash} = this.$route;

      if (!hash || hash === '#' || !Object.values(this.TABS).includes(hash)) {
        this.setActive(this.TABS.TAB1);
      }
    },
    isActive(menuItem) {
      return this.$route.hash === menuItem;
    },
    setActive(hash) {
      this.$router.replace({path: this.$route.path + hash});
      this.setServices();
    },
    async getServices() {
      await Axios.get(GET_SERVICES + '/documents')
        .then(res => {
          if (res.data.data.length) {
            this.outputDocumentsTabFinal = res.data.data.map(e => ({
              ...e,
              url: e.form ? `/dpo/get-help/${e.id}` : e.url
            }));
            this.setServices();
          }
        })
        .catch(() => {
          // Не удалось получить сервисы
        });
    },
    setServices() {
      if (this.isActive(TABS.TAB1)) {
        this.outputDocumentsTabs = this.outputDocumentsTabActs;
      } else {
        this.outputDocumentsTabs = this.outputDocumentsTabFinal;
      }
    },

    prepareDocument(document) {
      return {
        ...document,
        id: document.uuid,
        originalId: document.id,
        url: document.file_url,
        description: document.type_name
      };
    },
    prepareFinalDocument(document) {
      return {
        ...document,
        id: document.uuid,
        url: document.file_url,
        description: document.full_name
      };
    },
    //метод очистки параметров поиска (поштучно)
    clearFilterParameters(e) {
      this.commonSelectList.filter((item, index) => {
        if (item.label === e) {
          this.commonSelectList.splice(index, 1);
          this[item.selectName][item.selectName]--;
          this.filtrItemsCountController--;
        }
      });
    },
    //метод увеличивает счетчик выбранных пунктов селекта при выборе пунктов селекта
    increaseCounterSelect(value, id) {
      if (this.filtrItemsCountController < value.length) {
        id[id.name]++;
      } else {
        id[id.name]--;
      }
      this.filtrItemsCountController = value.length;
    },
    //метод очистки поля поиска
    clearSearch() {
      this.searchValue = '';
    },
    //установка диапазона стоимости
    setPriceRunge() {
      let double = -1;
      // ищем наличие данных диапазона стоимости в массиве параметров выборки (commonSelectList)
      this.commonSelectList.forEach((item, index) => {
        if (item.selectName === 'priceRunge') {
          double = index;
        }
      });
      //если в массиве параметров выборки (commonSelectList) имеется параметр диапазона стоимости, то удаляю его
      if (double >= 0) {
        this.commonSelectList.splice(double, 1);
        this.priceRunge.priceRunge--;
      }
      // добавляю в массиве параметров выборки (commonSelectList) параметр диапазона стоимости
      this.commonSelectList.push({
        selectName: 'priceRunge',
        value: {
          from: this.priceRunge.rangeValue[0],
          to: this.priceRunge.rangeValue[1]
        },
        label: `от ${this.formatPrice(
          this.priceRunge.rangeValue[0]
        )} ₽ до ${this.formatPrice(this.priceRunge.rangeValue[1])} ₽`
      });
      this.priceRunge.priceRunge++;
    },
    //метод очистки фильтра
    clearFilter() {
      this.filtrItemsCountController = 0;
      this.commonSelectList.splice(0, this.commonSelectList.length);
      this.priceRunge.priceRunge = 0;
      this.selectPrDirection.selectPrDirection = 0;
      this.selectPrDuration.selectPrDuration = 0;
      this.selectPrType.selectPrType = 0;
      this.selectPrStart.selectPrStart = 0;
      this.selectPrDocType.selectPrDocType = 0;
      this.selectPrTrainingForm.selectPrTrainingForm = 0;
    },
    //Метод служит для сворачивания блока с параметрами фильтра до первого элемента, а т.ж. за скрытие самого фильтра.
    processFilter() {
      if (this.commonSelectList.length > 0) {
        let selecPointBox = document.querySelector('.selection-points-box');
        selecPointBox.style.height =
          selecPointBox.firstElementChild.offsetHeight + 5 + 'px';
      }
      this.pageFilter.isOpen = false;
    },
    /*Метод отвечает за разворачивание и сворачивание блока с параметрами фильтра selection-points-box.
      Метод используется в мобильной версии*/
    toggleSelectionPoinBox() {
      this.isOpenSelectoinPoinox = !this.isOpenSelectoinPoinox;
      let selecPointBox = document.querySelector('.selection-points-box');
      let minHeight = selecPointBox.firstElementChild.offsetHeight;
      let selecPointBoxOldHeight = selecPointBox.offsetHeight;
      /*
        цифру 5 добавляю в условии, т.к. в методе processFilter задается высота контейнера selection-points-box
        которая ровна высоте первого дочернего элемента (первый параметр фильтра) + 5px.
       */
      if (selecPointBoxOldHeight === minHeight + 5) {
        //если свернут
        selecPointBox.style.height = 'auto';
        let selecPointBoxHeight = selecPointBox.offsetHeight;
        selecPointBox.style.height = selecPointBoxOldHeight + 'px';
        setTimeout(() => {
          selecPointBox.style.height = selecPointBoxHeight + 'px';
          setTimeout(() => {
            selecPointBox.style.height = 'auto';
          }, 200);
        }, 30);
      } else {
        //если развернут
        selecPointBox.style.height = selecPointBox.offsetHeight + 'px';
        setTimeout(() => {
          selecPointBox.style.height =
            selecPointBox.firstElementChild.offsetHeight + 5 + 'px';
        }, 100);
      }
    },
    onChangeSort([value]) {
      this.psevdoselectValue = value;
    },
    async fetchInvoices(payloadParams) {
      try {
        const response = await Axios.get(INVOICES, {params: payloadParams});
        return response?.data?.data;
      } catch (e) {
        setNotification({
          message: e.response?.data?.message ?? ''
        });
      }
      return null;
    },
    async getInvoices() {
      this.isLoadInvoices = true;
      const payloadParams = {page: this.currInvoicePage};
      const result = await this.fetchInvoices(payloadParams);
      this.isLoadInvoices = false;

      this.invoices = result?.data ?? [];
      this.metaInvoices = result?.meta;
    },
    async getDocumentsAndFilters() {
      try {
        /**
         * params:
         *   status       - статус договора integer
         *   program_name - имя программы string
         *   program_type - тип программы
         *   year         - год программы
         */
        const {data: response} = await Axios.get(
          V1_DOCUMENTS
          /** { params: {}, } */
        );
        if (response.code === 200 && response.data) {
          const res = response.data;
          const docs = res.docs.map(this.prepareDocument);
          const acts = res.acts.map(this.prepareDocument);
          this.acts = [...docs, ...acts];
          this.actsFiltered = [...docs, ...acts];

          if (res.filters) {
            this.arrDocYears.options =
              res.filters.year?.map(item => ({
                label: item,
                value: item,
                selectName: 'arrDocYears'
              })) ?? [];

            this.arrTypesOfDocument.options =
              res.filters.type?.map(item => ({
                ...item,
                selectName: 'arrTypesOfDocument'
              })) ?? [];

            this.arrMyProgram.options =
              res.filters.application?.map(item => ({
                ...item,
                selectName: 'arrMyProgram'
              })) ?? [];
          }
        }
      } catch (e) {
        console.error(e);
        /** */
      }
    },
    async getDocumentsFinal() {
      try {
        const {data: response} = await Axios.get(V1_DOCUMENTS_FINAL);

        if (response.code === 200 && response.data) {
          this.finalDocs = response.data.map(this.prepareDocument);
        }
      } catch (e) {
        console.error({e});
      }
    },
    filterActs(document) {
      return (
        (this.filters.year?.length === 0 ||
          this.filters.year.includes(document.year)) &&
        (this.filters.type?.length === 0 ||
          this.filters.type.includes(document.type_code)) &&
        (this.filters.application?.length === 0 ||
          this.filters.application.includes(document.program_id))
      );
    },
    sortDocuments(docs) {
      const documents = [...docs];

      switch (this.psevdoselectValue) {
        case 'date_asc':
          return documents.sort((pr1, pr2) =>
            compareISODates(pr1.created_at, pr2.created_at)
          );
        case 'date_desc':
          return documents.sort((pr1, pr2) =>
            compareISODatesAsc(pr1.created_at, pr2.created_at)
          );
        default:
          return documents;
      }
    },
    onPageInvoice(pageNum) {
      this.currInvoicePage = pageNum;
      this.getInvoices();
    }
  }
};
</script>

<style lang="scss">
.wrapper-list__documents .list__documents-title {
  display: none;
}

.wrapper-tag-documents a {
  line-height: 48px;
  text-decoration: none;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.header_info {
  font-size: 16px;
  color: #0f0f14;
  font-weight: 600;
  line-height: 20px;
}

.wrap_flex_tag {
  display: flex;
  background-color: #fff;
  padding: 24px;
  border-radius: 4px;
  margin-bottom: 24px;
}
.icon_bg {
  width: 60px;
  height: 60px;
  border-radius: 12px;
  background-color: #edeff3;
  margin-right: 15px;
}

.education-form {
  .col-input-wrapper {
    .wrapper-select__document {
      width: 100%;
      height: auto;
      border: unset;
      position: relative;
      display: flex;
      flex-direction: column;
      margin-bottom: 28px;

      .span__select-document {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #6b7a99;
        margin-bottom: 8px;
      }

      .el-select {
        width: 100%;
        height: 48px;

        .el-input {
          height: auto;

          .el-select__caret {
            color: $base-black-color;
          }

          .el-input__inner {
            height: 48px;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: $base-black-color;

            &::placeholder {
              font-weight: 400;
              font-size: 16px;
              line-height: 20px;
              color: $base-black-color;
            }
          }
        }
      }
    }
  }
}

.documents-page {
  .page-control-panel {
    margin-bottom: 24px;
  }
}

.section-tabs {
  padding-bottom: 50px;
}

.fade {
  display: none;
}

.show {
  display: block;
}
.flex_tag_item {
  margin-right: 100px;
}

.documents-page.content {
  padding-top: 48px;
}

.nav-tabs {
  margin-top: 24px;
  margin-bottom: 30px;

  ul {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    li {
      .nav-link__item {
        position: relative;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #6b7a99;
        padding-right: 23px;
        border-bottom: 2px solid transparent;

        span {
          display: flex;
          align-items: center;
          justify-content: center;
          width: auto;
          height: auto;
          padding: 3px 5px;
          font-weight: 600;
          font-size: 12px;
          line-height: 12px;
          color: #fff;
          background-color: #6b7a99;
          border-radius: 50%;
          position: absolute;
          top: 0;
          right: -3px;
        }
      }

      .nav-link__item.active {
        color: $blue-color;
        border-bottom: 2px solid $blue-color;

        span {
          background-color: $blue-color;
        }
      }
    }
  }
}
.great-filter-documents {
}
.section-tabs {
  .result-panel {
    flex-wrap: wrap;
    gap: 15px;
    .result-panel-item {
      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }
  .tab-content {
    margin-right: 0 !important;
  }
}

//стили из одного компанента, могут перебивать стили в другом компанента.
@media (max-width: 1400px) {
  .great-filter-documents {
    .page-control-panel--four-col {
      .page-control-panel__item--search-field {
        grid-column: 1/4;
      }
    }
  }
}

@media screen and (max-width: 920px) {
  .great-filter-documents {
    .page-control-panel--four-col {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  .wrap_flex_tag {
    display: block;
  }
  .flex_tag_item {
    margin-bottom: 20px;
    margin-right: unset;
  }
}

@media (max-width: 530px) {
  .content.documents-page {
    .result-panel {
      flex-direction: row;
      justify-content: space-between;

      .result-panel-item:not(:last-child) {
        margin-right: 7px;
      }
    }

    .title-number__list {
      display: none;
    }
  }

  .wrapper-tag-documents {
    display: block;
  }
}

@media (max-width: 480px) {
  .section-tabs .result-panel {
    flex-wrap: wrap;
    gap: 5px;
  }
}
</style>
