<template>
  <div :class="{ body: true, active: menuActive }">
    <menu-wrapper :page-name="name" />
    <div class="page-wrapper">
      <main
        class="content"
        :class="pageClass"
      >
        <section class="questions-answers-section">
          <div class="container">
            <h1 class="page-title">
              {{ name }}
            </h1>
            <search-form
              v-model="searchValue"
              :no-form="true"
              :field-placeholder="'Поиск по вопросам и ответам ...'"
              @clearField="clearSearch"
              @input="onSearch"
            />
            <quan-grid
              v-if="arQuAn.length"
              class="quan-grid--one-col"
            >
              <quan-grid-item
                v-for="item in arQuAn"
                :key="item.index"
                :qgi-id="item.qgiId"
                :qgi-caption="item.qgiCaption"
                :qgi-tags="item.tags"
                :qgi-text="item.qgiText"
                :qgi-link="item.link"
                @selectTag="onTag"
              />
            </quan-grid>
            <EmptySearchResultMessage v-else />
          </div>
        </section>
      </main>
    </div>
    <page-footer />
  </div>
</template>
<script>
    // import recoveryStart from "@/components/authComponent/recoveryStart.vue"
    import menuWrapper from "@/components/menuWrapper";
    import PageFooter from "@/components/PageFooter";
    import EmptySearchResultMessage from '@/components/common/elkEmptySearchResultMessage';
    import SearchForm from "@/components/forms/SearchForm.vue" // форма поиска
    import QuanGrid from "@/components/questions-answers/QuanGrid.vue" //контейнера для вопросов
    import QuanGridItem from "@/components/questions-answers/QuanGridItem.vue" //контейнера для вопросов
    import { mapState, mapGetters } from 'vuex';
    import { debounce } from '@/utils/debounce';
    import Axios from '@/utils/Elk-Axios';
    import { getFaq } from "@/api";
export default {
    name: 'QuestionsAndAnswersDetaled',
    components: {
        // recoveryStart,
        EmptySearchResultMessage,
        menuWrapper,
        PageFooter,
        'search-form': SearchForm,
        'quan-grid': QuanGrid,
        'quan-grid-item': QuanGridItem,
    },
    data(){
        return {
            pageClass: 'questions-and-answers',
            itemQA: null,
            name: '',
            arQuAn: [],
            searchValue: '',
            searchOldValue: '',
            slug: this.$route.params['slug'],
            isLoading: false,
            rootItems: [],
        }
    },
    computed: {
        ...mapState(['menuActive']),
        ...mapGetters(['isPublicUser']),
        currentQuery() {
            return this.$route.query.query ?? ''
        }
    },
    watch: {
        isLoading() {
            if (!this.isLoading && this.searchOldValue !== this.searchValue) {
                this.onSearch(this.searchValue)
            }
        },
    },
    mounted() {
        this.initFaq()
    },
    methods: {
        mapItems(items) {
            if (items.length) {
                return items.map((item, index) => ({
                    index: index,
                    qgiId: item?.qgiId ?? 0,
                    qgiCaption: item?.qgiCaption ?? '',
                    qgiText: item?.qgiText ?? '',
                    tags: item?.tags ?? [],
                    link: ''
                }))
            }
            return []
        },
      mapResponseFaq(response){
          const getTags = (strTag) => {
            return strTag.split('#').map((e) => e.trim()).filter((e) => e!== '')
          }

          return {
            index: response.id ?? '',
            qgiId: response.id ?? 0,
            tags: getTags(response.hash_tag ?? ''),
            qgiText: response.answer ?? '',
            qgiCaption: response.query ?? '',
            link: '',
          }
      },
        clearSearch() {
            this.searchValue = ''
            this.arQuAn = this.rootItems
        },
      getQuery(){
          let query = ''
          if (this.slug) {
            query+=`?url=/${this.slug}`
          }
          return query
        },
        async fetchFaq () {
            this.isLoading = true
            try {
              return (await Axios.get(`${getFaq}${this.getQuery()}`))?.data
            } catch {
                return Promise.resolve(null)
            } finally {
                this.isLoading = false
            }
        },
      async initFaq(){
          const response = await this.fetchFaq()
          if (response) {
            this.name = response.breadcrumbs.at(0)?.title ?? ''
            this.rootItems = response.faq?.map(this.mapResponseFaq)
            this.arQuAn = this.filterItems(this.searchValue)
          }
      },
        filterItems(query = ''){
          return this.mapItems(this.rootItems.filter((v) => {
            const caption = v.qgiCaption.toLocaleLowerCase()
            const lQuery = query.toLocaleLowerCase()
            let result = caption.includes(lQuery)
            if (!result) {
              result = !!v.tags.find((t) => t.toLocaleLowerCase().includes(lQuery))
            }
            return result
          }))
        },
        onTag(tagValue) {
            this.searchValue = tagValue
            this.onSearch(this.searchValue)
        },
        onSearch: debounce(async function (searchText){
            if (this.isLoading) {
                return
            }
            this.arQuAn = this.filterItems(searchText)
        }, 150),
    },
}
</script>
<style lang="scss">
    .questions-answers-section{
        .quan-grid{
            margin-top: 24px;

        }
    }
</style>
