<template>
  <div
    id="academic-degree-block"
    class="academic-form-block"
  >
    <h2 class="h4">
      {{ title }}
    </h2>
    <academic-degree-form-unit
      v-for="(degree, index) of degrees"
      :id="index"
      :key="index"
      :index="index"
      :item="degree"
      :rules="arrRules"
      :academic-degree="academicDegree"
      :document-type="documentType"
      @on-change="onChangeDegree"
    />
    <button-add
      v-if="!readonly"
      :title="label.button"
      @on-click="addDegree"
    />
  </div>
</template>

<script>
import AcademicDegreeFormUnit from '@/components/documentsForm/academicDegreeFormUnit';
import ButtonAdd from '@/components/profFormInputs/btnForm/buttonAdd';

const initialAcademicDegree = {
  degree: '',
  type: '',
  serial: '',
  number: '',
  date: '',
  issuedBy: '',
  files: []
};

export default {
  name: 'AcademicDegreeFormDocument',

  components: {
    AcademicDegreeFormUnit,
    ButtonAdd
  },

  props: {
    degrees: {type: Array, default: () => [initialAcademicDegree]},
    rules: {type: Map, required: true},
    academicDegree: {type: Array, default: () => []},
    documentType: {type: Array, default: () => []},
    readonly: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      title: 'Ученая степень',
      label: {
        button: 'Добавить ещё ученую степень'
      },
      forms: this.degrees
    };
  },

  computed: {
    arrRules() {
      return this.rules;
    }
  },

  watch: {
    degrees(val) {
      this.forms = val;
    }
  },

  methods: {
    onChangeDegree(id, $v) {
      this.$emit('on-change', id, $v);
    },
    addDegree() {
      this.forms.push(initialAcademicDegree);
      this.$emit('on-add-degree');
    }
  }
};
</script>

<style lang="scss">
.academic-form {
  margin-top: 20px;
}
.academic-form.common-wrapper__documents {
  .col-input-wrapper:nth-child(2) {
    display: grid;
    grid-template-columns: 2.5fr 1fr 1fr 1fr;
    gap: 24px;
  }
  .inputWrap {
    margin-left: 0;
  }
  .wrapper-select__document {
    width: 100%;
    height: auto;
    border: unset;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;
    .span__select-document {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #6b7a99;
      margin-bottom: 8px;
    }
    .el-select {
      width: 100%;
      height: 48px;
      .el-input {
        height: auto;
        .el-select__caret {
          color: $base-black-color;
        }
        .el-input__inner {
          height: 48px;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: $base-black-color;
          &::placeholder {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: $base-black-color;
          }
        }
      }
    }
  }
  .inputWrap {
    .input-form__prof::placeholder {
      color: $base-black-color;
    }
  }
}

.academic-form-block {
  margin-bottom: 48px;
}
.academic-form-block {
  .academic-form {
    margin-bottom: 16px;
  }
}
.academic-form {
  .wrapper-select__document {
    width: 100%;
  }
  .alert.alert--attention {
    margin-top: 24px;
    margin-bottom: 0;
  }
}

@media (max-width: 1440px) {
  .academic-form.common-wrapper__documents {
    .col-input-wrapper:nth-child(2) {
      grid-template-columns: repeat(3, 1fr);
      gap: 16px;
      .wrapper-select__document {
        grid-column-start: 1;
        grid-column-end: 4;
        grid-row-start: 1;
        grid-row-end: 3;
      }
    }
    .inputWrap:nth-child(4) {
      width: auto;
    }
  }
}

// @media (max-width: 1199px) {
//   .academic-form.common-wrapper__documents {
//     .inputWrap:nth-child(4) {
//       width: 346px;
//     }
//   }
// }

@media (max-width: 767px) {
  .academic-form-block {
    margin-bottom: 32px;
  }
  .academic-form.common-wrapper__documents {
    margin-bottom: 16px;
    .wrapper-select__document {
      margin-bottom: 16px;
    }
    .col-input-wrapper:nth-child(2) {
      display: grid;
      grid-template-rows: 1fr 1fr 1fr;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 16px;
      height: auto;
      width: 100%;
      .wrapper-select__document:nth-child(1) {
        grid-row-start: 1;
        grid-column-start: 1;
        grid-row-end: 2;
        grid-column-end: 5;
        margin-bottom: 0;
      }
      .inputWrap:nth-child(2) {
        grid-row-start: 2;
        grid-column-start: 1;
        grid-row-end: 3;
        grid-column-end: 3;
        margin-bottom: 0;
      }
      .inputWrap:nth-child(3) {
        grid-row-start: 2;
        grid-column-start: 3;
        grid-row-end: 3;
        grid-column-end: 5;
        margin-bottom: 0;
      }
      .inputWrap:nth-child(4) {
        width: 100%;
        grid-row-start: 3;
        grid-column-start: 1;
        grid-row-end: 4;
        grid-column-end: 5;
        margin-bottom: 16px;
      }
      .inputWrap {
        margin-bottom: 16px;
      }
    }
  }
}

@media (max-width: 530px) {
  .academic-form.common-wrapper__documents {
    .wrapper-select__document {
      .el-select {
        .el-input {
          .el-input__inner {
            font-size: 14px;
            line-height: 20px;
            &::placeholder {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }
  }

  .button-add__elem {
    font-size: 14px;
    line-height: 20px;
  }
}
@media (max-width: 480px) {
  .academic-form.common-wrapper__documents {
    margin-bottom: 8px;
  }
  .button-add__element {
    font-size: 14px;
    line-height: 20px;
  }
  .input-wrap-document {
    padding-right: 45px;
    .input-document__type {
      font-size: 14px;
      line-height: 20px;
    }
    .input-document__weight {
      font-size: 12px;
      line-height: 16px;
    }
  }
}
</style>
