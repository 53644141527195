<template>
  <ErrorPagePublic v-if="isPublicUser"/>
  <div v-else :class="{ body: true, active: menuActive }">
    <menu-wrapper :page-name="name"/>
    <div class="page-wrapper">
      <main class="content" :class="pageClass">
        <div class="container">
          <h1>
            {{ name }}
          </h1>
          <alert-danger v-if="profileFillErrors && this.$store.getters.userDpo" :message="profileFillErrors" />
          <section class="section-profile__card">
            <elk-profile-main
              :widget-data="profile"
              :additional-fields="forms.profile"
            />
          </section>
          <!-- <section class="section-agreement">
            <agreement-element :title="title" />
          </section> -->
          <!-- <section class="section-agreement">
            <agreement-element
              :title="title2"
            />
          </section> -->
<!--          ДОКУМЕНТЫ ДПО -->
          <template v-if="this.$store.getters.userDpo">
            <section class="section-all__documents">
              <documents-form :work="forms.work" @on-work-change="updateWork"/>
            </section>
            <section class="section-chips">
              <div class="chips-element">
                <h3>Интересы</h3>
                <div class="wrapper-chips">
                  <btn-chip/>
                </div>
              </div>
            </section>
          </template>

          <component
            v-if="security"
            :is="loader(security.signature)"
            :widgetData="security"
          />
          <component
            v-if="historyActions"
            :is="loader(historyActions.signature)"
            :widgetData="historyActions"
          />
          <!--          <section class="section-security">-->
          <!--            <div class="security-element">-->
          <!--              <h3>Безопасность</h3>-->
          <!--              <div class="wrapper-security">-->
          <!--                <security-item />-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </section>-->
          <!--          <section class="section-history">-->
          <!--            <div class="history-element">-->
          <!--              <h3>История действий</h3>-->
          <!--              <div class="wrapper-history">-->
          <!--                <history-item />-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </section>-->
          <!--          <section class="section-device">-->
          <!--            <div class="device-element">-->
          <!--              <h3>Ваши устройства</h3>-->
          <!--              <div class="wrapper-device">-->
          <!--                <item-device />-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </section>-->
        </div>
      </main>
    </div>
    <page-footer/>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Axios from '@/utils/Elk-Axios';
import { GET_PROFILE_REFERENCES, PROFILE_V1 } from '@/api';
import loader from '@/utils/widgetLoader.js';

import menuWrapper from '@/components/menuWrapper';
import PageFooter from '@/components/PageFooter';
import AlertDanger from '@/components/newAlerts/alertDanger';
// import ProfileCard from "@/components/cardPro/profileCard";
// import AgreementElement from '@/components/agreement/agreementElement';
import BtnChip from '@/components/componentChips/btnChip';
import SecurityItem from '@/components/componentSec/securityItem';
import HistoryItem from '@/components/HistoryAction/HistoryItem';
import ItemDevice from '@/components/componentDevice/itemDevice';
import ElkProfileMain from '@/components/widgets/elkProfileMain/index.vue';
import DocumentsForm from '@/components/documentsForm/documentsForm';
import ErrorPagePublic from "@/views/404/index.vue";

export default {
  name: 'ProfilePage',

  components: {
    ErrorPagePublic,
    ElkProfileMain,
    DocumentsForm,
    ItemDevice,
    HistoryItem,
    SecurityItem,
    BtnChip,
    AlertDanger,
    PageFooter,
    menuWrapper,
  },

  data() {
    return {
      title: 'Согласие на обработку персональных данных',
      title2: 'Согласие на электронное взаимодействие',
      name: 'Профиль',
      pageClass: 'profile-page',
      profileWidgetData: {
        always_visible: true,
        anchor_name: "Основная информация",
        block_name: "Основная информация",
        // captions: {},
        signature: "elk.profile.main",
        ui_item_type: "Widget",
        url: "#main",
        visible: true,
      },
      forms: {
        profile: {
          'category_listener': { name: '' },
        },
        work: {
          job: '',
          post: '',
        },
      },
      categoryListener: [],
      initialData: true,
    };
  },

  computed: {
    ...mapState(['menuActive']),
    ...mapGetters(['isPublicUser']),
    profileFillErrors() {
      if (!this.$store.getters.userDpo) return false;
      return this.$store.getters['profileFillCheck/profileFillErrors'].join(', ');
    },
    widgets() {
      return this.$store.getters['getWidgets'];
    },
    profile() {
      return this.widgets?.get('elk.profile.main') ?? this.profileWidgetData;
    },
    security() {
      return this.widgets?.get('elk.profile.security');
    },
    historyActions() {
      return this.widgets?.get('elk.profile.historyactions');
    },
  },
  methods: {
    loader,
    prepareList(list) {
      if (typeof list === 'object') {
        list = Object.values(list);
      }

      return list.length ? list.map(({ value: id, label: name }) => ({ name, id })) : [];
    },
    async getProfileReferences() {
      if (this.$store.getters.userDpo) {
        const { data } = await Axios.get(GET_PROFILE_REFERENCES);
        this.categoryListener = this.prepareList(data.data.category_listener);
      }
    },
    async updateProfileData(postData) {
      try {
        await Axios.patch(PROFILE_V1, postData);
      } catch {
        /** */
      }
    },
    /** начальные данные профиля */
    setProfileData(data) {
      const categoryListener = typeof data.category_listener === 'number' ? this.categoryListener.filter((item) => item.id === data.category_listener)[0] : {};
      this.forms.profile = {
        'category_listener': { ...categoryListener },
      };

      this.forms.work = {
        job: data.job,
        post: data.post,
      };
      this.initialData = false;
    },
    async getProfileData() {
      try {
        const { data } = await Axios.get(PROFILE_V1);

        if (data.code === 200 && data.data) {
          this.setProfileData(data.data);
        }
      } catch {
        /** */
      }
    },
    updateWork(value) {
      this.updateProfileData(value);
    },
  },

  async created() {
    if (this.$store.getters.userDpo) {
      await this.getProfileReferences();
      await this.$store.dispatch('profileData/getProfileListenerCategories');
      await this.$store.dispatch('profileFillCheck/getProfileFillCheck');
      await this.$store.dispatch('profileData/getProfileData');
      await this.getProfileData();
    }
  },
};
</script>

<style lang="scss">
html {
  scroll-behavior: smooth;
}

.inputWrap {
  .input-form__prof {
    height: 48px;
    padding: 0px 16px;
    font-size: 16px;
    border: 1px solid #d3d8e6;
  }

  margin-bottom: 28px;

  .input-form__prof::placeholder {
    color: $base-black-color;
  }
}

.common-wrapper__documents {
  background-color: #fff;
  box-shadow: 0 1px 2px rgba(106, 122, 152, 0.15);
  border-radius: 4px;
  padding: 24px;
  margin-bottom: 30px;
}

.agreement__mod {
  margin-top: 26px;

  h5 {
    font-size: 18px;
    line-height: 24px;
    color: $base-black-color;
  }

  .input-wrap-document {
    margin-bottom: 0;
  }
}

.content.profile-page {
  section {
    margin-bottom: 48px;
  }

  padding-top: 48px;

  h1 {
    margin-bottom: 24px;
  }

  h3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: $base-black-color;
    margin-bottom: 16px;
  }

  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.chips-element {
  .wrapper-chips {
    padding: 24px 24px 17px 24px;
  }
}

.wrapper-security,
.wrapper-history,
.wrapper-device,
.wrapper-document {
  padding: 24px;
}

.wrapper-chips,
.wrapper-security,
.wrapper-history,
.wrapper-device,
.wrapper-document {
  background-color: #ffffff;
  box-shadow: 0 1px 2px rgba(106, 122, 152, 0.15);
  border-radius: 4px;
}

.col-input-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .inputWrap {
    label:before {
      content: unset;
    }
  }
}

@media (max-width: 1199px) {
  .content.profile-page {
    h1 {
      font-size: 32px;
    }
  }

  .wrapper-all__documents {
    .documents-menu {
      margin-left: 16px;
    }
  }
}

@media (max-width: 530px) {
  .inputWrap {
    .input-form__prof {
      font-size: 14px;
      line-height: 20px;
    }
  }

  .content.profile-page {
    h3 {
      font-size: 20px;
      line-height: 28px;
    }

    section {
      margin-bottom: 32px;
    }
  }
}

@media (max-width: 480px) {
  .common-wrapper__documents {
    padding: 16px;
  }

  .wrapper-document {
    padding: 16px;
  }

  .input-file-doc+label {
    padding: 8px 15px 8px 40px;

    &:before {
      top: auto;
      left: 18px;
      width: 9px;
      height: 18px;
    }
  }
}
</style>
