<template>
  <el-dialog
    :title="'Изменить фото'"
    :modal-append-to-body="false"
    :close-on-click-modal="false"
    :destroy-on-close="true"
    top="5vh"
    :visible.sync="isShowDialog"
    class="elk-avatar-editor"
  >
    <div class="cropper-tools">
      <div
        class="flex w-full md:justify-between items-end flex-col md:flex-row"
      >
        <div class="w-full mb-2 md:mb-0 md:w-auto">
          <button
            class="btn"
            type="button"
            @click="rotateAvatar"
          >
            Повернуть
          </button>
        </div>
        <div class="w-full md:w-auto">
          <button
            class="btn"
            type="button"
            @click="saveAvatar"
          >
            Сохранить
          </button>
        </div>
      </div>
    </div>

    <vue-cropper
      ref="cropper"
      class="photo-editor"
      :src="imgSrc"
      :view-mode="2"
      :aspect-ratio="1"
      drag-mode="crop"
      :min-container-width="150"
      :min-container-height="180"
      :auto-crop-area="1"
      alt="Source Image"
    />
  </el-dialog>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import setNotification from '@/utils/setNotification';

export default {
  name: 'ElkAvatarEditor',
  components: {
    VueCropper
  },

  props: ['imgSrc', 'isAvatarEditorVisible'],

  computed: {
    isShowDialog: {
      get() {
        return this.isAvatarEditorVisible;
      },
      set() {
        this.$emit('hide-dialog');
      }
    }
  },

  beforeDestroy() {
    if (this.$refs.cropper) {
      this.$refs.cropper.destroy();
    }
  },

  methods: {
    setNotification,
    saveAvatar() {
      const cropper = this.$refs.cropper;

      const cropperSize = cropper.getData().width;
      const maxCropperSize = 3000;
      const minCropperSize = 380;

      if (cropperSize >= minCropperSize && cropperSize <= maxCropperSize) {
        const photo = cropper
          .getCroppedCanvas({
            width: 380,
            height: 380,
            fillColor: '#fff'
          })
          .toDataURL('image/jpeg', 0.5);

        this.$emit('save-avatar', photo);

        this.isShowDialog = false;
      } else {
        let message = '';
        if (cropperSize < minCropperSize) {
          message = `текущий размер (${Math.round(cropperSize)}x${Math.round(
            cropperSize
          )}) меньше минимального (380x380)`;
        }
        if (cropperSize > maxCropperSize) {
          message = `текущий размер (${Math.round(cropperSize)}x${Math.round(
            cropperSize
          )}) превышает максимальный (3000x3000)`;
        }
        this.setNotification({
          message: message
        });
      }
    },
    rotateAvatar() {
      const cropper = this.$refs.cropper;

      cropper.rotate(90);
    }
  }
};
</script>

<style lang="scss">
.cropper-tools {
  display: flex;
  justify-content: space-between;

  margin-bottom: 12px;

  button.btn {
    width: 49%;
  }
}

.photo-editor {
  max-height: 70vh;
}
</style>
<style lang="scss">
.el-dialog__wrapper.elk-avatar-editor {
  .el-dialog {
    @apply md:w-1/2 w-11/12;
  }
}
</style>
