<template>
  <menu class="menu-all__doc">
    <div class="doc--wrapper">
      <ul>
        <li>
          <a href="#passport-block">Паспортные данные</a>
        </li>
        <li>
          <a href="#education-block">Образование</a>
        </li>
        <li>
          <a href="#academic-degree-block">Ученая степень</a>
        </li>
        <li>
          <a href="#work-block">Место работы</a>
        </li>
        <li>
          <a href="#section-document">Дополнительные документы</a>
        </li>
      </ul>
    </div>
    <button
      class="btn-save__documents"
      :class="{ 'btn-disabled': buttonDisabled }"
      :disabled="buttonDisabled"
      @click.stop.prevent="onClickSave"
    >
      Сохранить изменения
    </button>
  </menu>
</template>

<script>
export default {
  name: 'MenuDocumentProfile',

  props: {
    buttonDisabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onClickSave() {
      if (this.buttonDisabled) return;

      this.$emit('on-save');
    },
  },
};
</script>

<style lang="scss">
.rating-stars-block {
  transition: all 0.3s ease-in-out;
}
.menu-all__doc {
  width: 376px;
  .btn-save__documents {
    width: 100%;
    border-radius: 4px;
    background-color: $blue-color;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    border: unset;
    padding: 14px;
    cursor: pointer;
    transition: opacity 0.3s;
    &:hover {
      opacity: 0.8;
    }
  }
}
.doc--wrapper {
  background: #ffffff;
  box-shadow: 0 1px 2px rgba(106, 122, 152, 0.15);
  border-radius: 4px;
  padding: 32px;
  margin-bottom: 24px;
  ul {
    li {
      a {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #6b7a99;
        transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
        &:hover {
          color: $blue-color;
        }
      }
      &:not(:last-child) {
        margin-bottom: 32px;
      }
    }
  }
}

@media (max-width: 1440px) {
  .menu-all__doc {
    width: 258px;
    .doc--wrapper {
      padding: 24px;
    }
  }
}

@media (max-width: 1199px) {
  .menu-all__doc {
    width: 215px;
    .doc--wrapper {
      ul {
        li {
          a {
            font-size: 14px;
          }
        }
      }
    }
    .btn-save__documents {
      font-size: 14px;
    }
  }
}

@media (max-width: 991px) {
  .wrapper-all__documents {
    .documents-menu {
      display: none;
    }
  }
}
</style>
