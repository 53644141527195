import Axios from '@/utils/Elk-Axios';
import { GET_NOTIFICATION_COUNT } from '@/api';

export default {
  namespaced: true,

  state: {
    notificationCount: null,
  },

  mutations: {
    updateNotificaionCount(state, notifCount) {
      if (notifCount < 100) {
        state.notificationCount = notifCount;
      } else {
        state.notificationCount = '99+';
      }
    },
  },
  actions: {
    async getNotificationCount(context) {
      try {
        let { data: notifCount } = await Axios.get(GET_NOTIFICATION_COUNT);
        context.commit('updateNotificaionCount', notifCount);
      } catch (err) {
        /* turned off temporarily
        if (!err.isHandled) {
          setNotification({
            message:
              'Не удалось загрузить количество уведомлений. Попробуйте позже или обратитесь в службу поддержки',
          });
        }
        */
      }
    },
  },
};
