import Axios from '@/utils/Elk-Axios';
import {GET_PAYMENTS} from '@/api';

export default {
  namespaced: true,
  state: {
    options: [],
    fetching: false
  },
  getters: {
    options: state => () => state.options
  },
  mutations: {
    set(state, options) {
      state.options = options ?? [];
    },
    setFetching(state, fetching) {
      state.fetching = fetching;
    }
  },
  actions: {
    async fetch({commit}) {
      commit('setFetching', true);

      const response = await Axios.get(GET_PAYMENTS);

      commit('set', response?.data?.data ?? []);
      commit('setFetching', false);
    }
  }
};
