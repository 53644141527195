<template>
  <div id="work-block" class="work-form-block">
    <h2 class="h4">
      {{ title }}
    </h2>
    <form id="work-form" class="work-form common-wrapper__documents">
      <div class="col-input-wrapper">
        <input-text
          id="job"
          label="Наименование организации"
          placeholder="Наименование организации"
          :value="$v.form.job"
        />
        <input-text
          id="post"
          label="Должность"
          placeholder="Должность"
          :value="$v.form.post"
        />
      </div>
      <div class="work_form_select_col region-place-of-work">
        <input-select
          id="region"
          label="Регион места работы"
          placeholder="Регион места работы"
          :options="type"
          :value="$v.form.region"
        />
      </div>
    </form>
  </div>
</template>

<script>
import {requiredIf} from 'vuelidate/lib/validators';

import InputText from '@/components/profFormInputs/inputText';
import InputSelect from '@/components/profFormInputs/inputSelect.vue';

export default {
  name: 'WorkFormDocument',

  components: {InputSelect, InputText},

  props: {
    job: {type: String, default: ''},
    post: {type: String, default: ''},
    region: {default: () => []},
    type: {type: Array, default: () => []},
    rules: {type: Map, required: true}
  },

  data() {
    return {
      title: 'Место работы',
      form: {
        job: this.job,
        post: this.post,
        region: this.region
      }
    };
  },

  watch: {
    job(val) {
      this.$v.form.job.$model = val;
      this.$v.$reset();
    },
    post(val) {
      this.$v.form.post.$model = val;
      this.$v.$reset();
    },
    region(val) {
      this.$v.form.region.$model = val;
      this.$v.$reset();
    }
  },

  validations() {
    if (this.rules.size) {
      const form = {};
      this.rules.forEach((value, key) => {
        form[key] = {
          required: requiredIf(function () {
            return value;
          })
        };
      });

      return {form};
    }

    return {
      form: {
        job: {},
        post: {},
        region: {}
      }
    };
  },

  created() {
    this.emitValidation();
  },

  methods: {
    emitValidation() {
      this.$emit('on-change', this.$v);
    }
  }
};
</script>

<style lang="scss">
.work-form.common-wrapper__documents {
  margin-top: 20px;

  .col-input-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;

    .inputWrap {
      width: 100%;
      margin-left: 0;
    }

    .work_form_select_col {
      margin-top: 80px;

      .el-input__inner {
        height: 48px;
        line-height: 48px;
      }
    }
  }
}

.work-form-block {
  .work-form {
    margin-bottom: 48px;
  }

  .inputWrap {
    width: 50%;
    margin-bottom: 0;

    input {
      width: 100%;
    }
  }
}

.work_form_select_col {
  .wrapper-select__document {
    width: 100%;
    height: auto;
    border: unset;
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 28px 0;

    .span__select-document {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #6b7a99;
      margin-bottom: 8px;
    }
  }
}

@media (max-width: 1440px) {
  .work-form.common-wrapper__documents {
    .col-input-wrapper {
      display: grid;
      grid-template-columns: 1fr;
      gap: 16px;

      .inputWrap {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}

@media (max-width: 767px) {
  .work-form.common-wrapper__documents {
    .col-input-wrapper {
      gap: 0;

      .inputWrap {
        margin-bottom: 16px;
      }
    }

    .col-input-wrapper {
      .inputWrap:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
