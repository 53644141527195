<template>
  <nav class="aside__nav nav">
    <ul class="nav__list nav__list-additional">
      <li
        v-for="link in consolidateMenuLinks"
        :key="link.slug"
        class="nav__item"
      >
        <!--        <AbsoluteRelativeLink
          v-if="link.slug == 'uvedomleniia'"
          :to="link.link ?? link.url"
          active-class="choosen"
          exact
          class="nav__link"
        >
          <el-popover
            v-if="!menuActive"
            trigger="hover"
            :content="link.anchor_name"
            placement="right"
          >
            <div
              slot="reference"
              class="noty-badge"
            >
              <div class="noty-badge__body">
                <div class="noty-badge__text">
                  <div class="noty-badge__image-wrapper">
                    <img
                      v-if="link.image"
                      :src="link.image"
                      :alt="link.image"
                    />
                    <component
                      :is="iconLoader(link.signature)"
                      v-else
                      class="status-icon"
                    />
                  </div>
                  <span class="nav__text">{{ link.anchor_name }}</span>
                </div>
                <div
                  v-if="notificationCount > 0"
                  class="noty-badge__noty"
                >
                  <div class="noty-badge__count js-notif-count">
                    {{ notificationCount }}
                  </div>
                </div>
              </div>
            </div>
          </el-popover>
          <div
            v-else
            class="noty-badge"
          >
            <div class="noty-badge__body">
              <div class="noty-badge__text">
                <div class="noty-badge__image-wrapper">
                  <img
                    :src="link.image"
                    :alt="link.image"
                  />
                </div>
                <span class="nav__text">{{ link.anchor_name }}</span>
              </div>
              <div
                v-if="notificationCount > 0"
                class="noty-badge__noty"
              >
                <div class="noty-badge__count js-notif-count">
                  {{ notificationCount }}
                </div>
              </div>
            </div>
          </div>
        </AbsoluteRelativeLink>-->

        <nav-menu-item-dropdown
          v-if="link.children?.length"
          :link="link"
        />
        <AbsoluteRelativeLink
          v-else
          :to="normalizeUrl(link.link ?? link.url)"
          active-class="choosen"
          exact
          class="nav__link"
        >
          <template v-if="!menuActive">
            <el-popover
              trigger="hover"
              :content="link.anchor_name"
              placement="right"
            >
              <div slot="reference">
                <div class="noty-badge__image-wrapper">
                  <img
                    v-if="link.image"
                    :src="link.image"
                    :alt="link.image"
                  />
                  <component
                    :is="iconLoader(link.signature)"
                    v-else
                    class="status-icon icon nav__icon"
                  />
                </div>
              </div>
            </el-popover>
          </template>
          <div class="noty-badge__image-wrapper">
            <img
              v-if="link.image"
              :src="link.image"
              :alt="link.image"
            />
            <component
              :is="iconLoader(link.signature)"
              v-else
              class="status-icon icon nav__icon"
            />
          </div>
          <span class="nav__text">{{ link.anchor_name }}</span>
        </AbsoluteRelativeLink>
      </li>

      <li
        v-if="isMobile"
        class="nav__item"
      >
        <template v-if="isPublicUser">
          <a
            class="nav__link"
            href="#3"
            @click.prevent="showSupportNotAuthorizedUser"
          >
            <iconFeedback class="icon nav__icon" />
            <span class="nav__text">{{ $t('supportCaption') }}</span>
          </a>
        </template>
        <template v-else>
          <a
            class="nav__link"
            :href="supportLink"
            target="_blank"
          >
            <iconFeedback class="icon nav__icon" />
            <span class="nav__text">{{ $t('supportCaption') }}</span>
          </a>
        </template>
      </li>
      <li v-if="isTablet" />
    </ul>
    <ElkModalSupportNotAuthorizedUser ref="modalSupport" />
  </nav>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
import {
  FEEDBACK_ROUTE,
  IT_SUPPORT,
  IT_SUPPORT_DPO,
  IT_SUPPORT_REPRESENT
} from '@/router/routes.js';
import {getCommonCaptionByName} from '@/i18n/utils';

const FEEDBACK_SIGNATURE = 'elk.feedback';
import iconFeedback from '@/assets/icons/menuIcons/feedbackLine.svg?inline';
import NavMenuItemDropdown from '@/components/elkSidebar/NavMwnuItemDropdown.vue';
import AbsoluteRelativeLink from '@/components/AbsoluteRelativeLink';
import iconLoader from '@/utils/iconLoader';
import ElkModalSupportNotAuthorizedUser from '@/components/elkHeader/elkModalSupportNotAuthorizedUser.vue';

export default {
  name: 'ElkNavAdditional',
  components: {
    AbsoluteRelativeLink,
    NavMenuItemDropdown,
    iconFeedback,
    ElkModalSupportNotAuthorizedUser
  },
  data() {
    return {
      FEEDBACK_ROUTE,
      consolidateLinks: [],
      additionalMenu: [],
      IT_SUPPORT,
      IT_SUPPORT_DPO,
      IT_SUPPORT_REPRESENT
    };
  },
  computed: {
    ...mapState({
      menuActive: state => state.menuActive,
      notificationCount: state => state.notificationCounter.notificationCount
    }),
    ...mapGetters([
      'isTablet',
      'isMobile',
      'userRepresent',
      'consolidateMenuLinks',
      'userRoles',
      'isPublicUser'
    ]),
    getFeedbackWidgetTitle() {
      const feedbackPage = this.$store.state.user.pageTree.find(
        elem => elem.signature === FEEDBACK_SIGNATURE
      );
      return feedbackPage && feedbackPage.block_name;
    },
    isOnlyDdoRole() {
      return this.userRoles.length === 1 && this.userRoles.includes('dpo');
    },
    userRolesLength() {
      return this.userRoles.length;
    },
    isRepresentRole() {
      const availableRoles = [['represent'], ['represent', 'company_admin']];

      return availableRoles.some(roles => {
        return roles.every(
          role =>
            this.userRoles.includes(role) &&
            roles.length === this.userRolesLength
        );
      });
    },
    supportLink() {
      switch (true) {
        case this.isOnlyDdoRole:
          return IT_SUPPORT_DPO;
        case this.isRepresentRole:
          return IT_SUPPORT_REPRESENT;
        default:
          return IT_SUPPORT;
      }
    }
  },
  methods: {
    iconLoader,
    normalizeUrl(url) {
      if (!url) return '';
      if (url?.includes('https')) {
        return url;
      }
      return url.startsWith('/') ? url : '/' + url;
    },
    getCommonCaptionByName,
    showSupportNotAuthorizedUser() {
      this.$refs.modalSupport.openModal();
    }
  },
  i18n: {
    messages: {
      en: {
        supportCaption: 'Technical help center'
      },
      ru: {
        supportCaption: 'Единое окно техподдержки'
      }
    }
  }
};
</script>

<style>
.nav__list-additional {
  padding-right: 16px;
}

.noty-badge__image-wrapper {
  text-align: center;
  width: 54px;
  min-width: 54px;
}

.noty-badge__image-wrapper img {
  width: 24px;
  height: 55px;
  object-fit: contain;
}
</style>
