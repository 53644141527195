<template>
  <div class="page-control-panel__item">
    <multiselect
      v-model="selected"
      class="vi-multiselect"
      :options="optionList"
      :multiple="true"
      :close-on-select="false"
      :clear-on-select="false"
      :preserve-search="true"
      :placeholder="label"
      label="label"
      track-by="label"
      :preselect-first="false"
      @select="handleSelect"
      @input="handleInput"
    >
      <template
        slot="selection"
        slot-scope="{values, search, isOpen}"
      >
        <span class="multiselect__single">
          <span v-if="selected && !selected.length">
            {{ label }}
          </span>
          <span
            v-else
            class="multiselect__list"
          >
            <span
              v-for="(item, index) in selected"
              :key="index"
            >
              <span v-if="index < selected.length - 1">
                {{ item.label }},
              </span>
              <span v-else>
                {{ item.label }}
              </span>
            </span>
          </span>
        </span>
      </template>
    </multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import {filterBus} from '@/main';

export default {
  name: 'MultipleSelectFilter',
  components: {
    Multiselect
  },
  props: {
    label: {
      type: String,
      required: true
    },
    value: {
      type: Array,
      default: null
    },
    options: {
      type: Array,
      default: () => []
    },
    param: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selected: this.value,
      optionList: this.options
    };
  },
  watch: {
    value: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.selected = newValue;
        }
      },
      deep: true,
      immediate: true
    },
    options: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.optionList = newValue;
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    filterBus.$on('removeFilter', value => {
      if (this.selected) {
        this.selected = this.selected.filter(
          el => el.label.trim().toLowerCase() !== value.trim().toLowerCase()
        );
      }
    });
  },
  methods: {
    handleSelect(value) {
      this.$emit('select', value);
    },
    handleInput(values) {
      this.$emit('input', values);
    },
    getValue() {
      return this.selected?.map(val => {
        return {
          label: val.label,
          value: val.value,
          param: this.param
        };
      });
    },
    toParam() {
      return {
        [this.param]: this.selected?.map(({value}) => value)
      };
    },
    toLabel() {
      return this.selected?.map(({label}) => label);
    }
  }
};
</script>

<style>
.multiselect__list {
  white-space: nowrap;
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 4px;
}
</style>
