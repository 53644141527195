<template>
  <div class="auth-form-block auth-wrap">
    <div class="auth-form__wrapper">
      <button @click.prevent="logger">
        logger
      </button>
      <h2>
        Авторизация
      </h2>
      <form
        id="form-authorization"
        class="form-auth"
      >
        <!-- step email -->
        <div
          v-if="STEP === EMAIL_AUTH"
          class="form-auth__inputs-wrapper"
        >
          <custom-input
            id="email"
            type="email"
            :label="label.email"
            :value="$v.form.email"
          />
          <input-password
            :label="label.password"
            :value="$v.form.password"
          >
            <template #errors>
              <div
                v-if="!$v.form.password.minLength"
                class="error"
              >
                Минимум 8 символов.
              </div>
            </template>
          </input-password>
        </div>

        <!-- step phone -->
        <div
          v-if="STEP === PHONE_AUTH"
          class="auth-form__phone-element"
        >
          <input-phone
            :label="label.phone"
            :value="$v.form.phone"
            @get-masked-phone="setPhoneString"
          />
        </div>

        <!-- step phone-code -->
        <template v-if="STEP === PHONE_CODE">
          <div class="form-information__element">
            <p>
              На номер <span>{{ maskedPhone }}</span> отправлено SMS c кодом,
              введите его ниже,
              <a
                href=""
                class="link-change__phone"
                @click.stop.prevent="STEP = PHONE_AUTH"
              >
                изменить номер
              </a>
            </p>
          </div>

          <div class="form-block__confirmation">
            <confirm-message
              :value="$v.form.codePhone"
              @on-reset-timer="sendPhoneConfirmationCode"
            />
          </div>
        </template>
        <div
          v-if="STEP === EMAIL_AUTH || STEP === PHONE_CODE"
          class="inputCheckbox"
        >
          <input
            id="checkbox"
            class="custom-checkbox"
            type="checkbox"
            name="checkbox"
            checked
          >
          <label for="checkbox">Оставаться в системе</label>
        </div>
        <div class="btn-block__form">
          <button
            v-if="STEP === PHONE_AUTH"
            :disabled="$v.form.$invalid"
            class="btn btn-checkout"
            @click.stop.prevent="changeStepToPhoneVerification"
          >
            Отправить код
          </button>
          
          <button
            v-if="STEP === EMAIL_AUTH || STEP === PHONE_CODE"
            :disabled="isAuthButtonDisabled"
            class="btn btn-checkout"
            @click.stop.prevent="authUser"
          >
            Войти
          </button>
          <button
            v-if="STEP === EMAIL_AUTH"
            class="btn btn-checkout-phone"
            @click.prevent="STEP = PHONE_AUTH"
          >
            Войти по номеру телефона
          </button>

          <button
            v-if="STEP !== EMAIL_AUTH"
            class="btn btn-checkout-phone"
            @click.prevent="STEP = EMAIL_AUTH"
          >
            Войти по почте
          </button>
        </div>
        <div class="recovery-password">
          <router-link
            to="/dev/recoveryPage"
            class="link-router__recovery"
          >
            <span>
              Забыли пароль?
            </span>
            Восстановить
          </router-link>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { email, minLength, required } from 'vuelidate/lib/validators'
import Axios from '@/utils/Elk-Axios';
import { REGISTRATION_STEP_1 } from '@/api';

import CustomInput from "@/components/inputComponent/CustomInput";
import InputPhone from "@/components/inputComponent/inputPhone";
import InputPassword from "@/components/inputComponent/inputPassword";
import ConfirmMessage from "@/components/confMessage/ConfirmMessage";

const STEPS = {
  EMAIL_AUTH: 'step-0_authentication_email',
  PHONE_AUTH: 'step-0_authentication_phone',
  PHONE_CODE: 'step-1_phone_get_code',
}
export default {
  name: "AuthStart",
  components: {ConfirmMessage, InputPassword, InputPhone, CustomInput},

  setup() {
    return {
      ...STEPS,
    }
  },

  data() {
    return {
      STEP: STEPS.EMAIL_AUTH,
      label: {
        phone:'Номер телефона',
        email: 'Email',
        password: 'Введите пароль',
      },
      form: {
        email: '',
        phone:'',
        password: '',
        codePhone: '',
      },
      state: {
        isPhoneConfirmationCodeSend: false,
      },
      maskedPhone: '',
      stayLoggedIn: false,
    }
  },

  computed: {
    isAuthButtonDisabled() {
      return this.$v.form.$invalid;
    },
  },

  validations() {
    if (this.STEP === this.EMAIL_AUTH) {
      return {
        form: {
          email: {
            required,
            email,
          },
          password: {
            required,
            minLength: minLength(8),
          },
        },
      };  
    } else if (this.STEP === this.PHONE_AUTH) {
      return {
        form: {
          phone: {
            required,
            minLength: minLength(10),
          },
        },
      };  
    } else {
      return {
        form: {
          phone: {
            required,
            minLength: minLength(10),
          },
          codePhone: {
            required,
            minLength: minLength(4),
          },
        },
      };  

    }
  },

  methods: {
    logger() {
      console.log(this.$v.form)
    },
    setPhoneString(value) {
      this.maskedPhone = value;
      this.state.isPhoneConfirmationCodeSend = false;
    },
    async changeStepToPhoneVerification() {
      if (!this.state.isPhoneConfirmationCodeSend) {
        try {
          await this.sendPhoneConfirmationCode();
          this.state.isPhoneConfirmationCodeSend = true;
        } catch (error) {
          //
        }
      }
      this.STEP = this.PHONE_CODE;
    },
    async sendPhoneConfirmationCode() {
      try {
        await console.log(this.state.isPhoneConfirmationCodeSend, 'getPhoneVerificationCode   ', this.form.phone);
      } catch (error) {
        //
      }
    },
    async authUser() {
      try {
        if (this.STEP === this.EMAIL_AUTH) {
          await console.log('authUser  ', 'email');
        } else {
          console.log('authUser  ', 'phone');
        }
      } catch {
        //
      }
    },
  }
}
</script>

<style lang="scss">
.auth-form-block.auth-wrap{
  .form-block__confirmation{
    .inputWrap.error-system{
      margin-left: 0;
    }
  }
  .inputWrap{
    margin-left: 0;
  }
}

.icon-phone__input{
  position: absolute;
  top: 38px;
  right: 15px;
  width: 24px;
  height: 24px;
  background: url("@/assets/icons/icon-rus.svg") 100% 100% no-repeat;
}
  .form-block__confirmation{
    margin-bottom: 24px;
  }
 .resend-message__inform{
   font-weight: 400;
   font-size: 14px;
   line-height: 20px;
   margin-top: 4px;
   color: $dusk-gray-color;
   span{
     color: $base-black-color;
   }
 }

  .link-change__phone{
    color: $blue-color;
  }

 .form-information__element{
   p{
     text-align: center;
     font-weight: 400;
     font-size: 18px;
     line-height: 24px;
     margin-bottom: 0;
   }

   margin-bottom: 24px;
 }
  .recovery-password{
    margin-top: 40px;
    .link-router__recovery{
      width: 100%;
      text-align: center;
      display: inline-block;
      color: $blue-color;
      span{
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #6b7a99;
        display: inline-block;
      }
    }
  }

  .btn-block__form{
    .btn.btn-checkout, .btn.btn-checkout-phone{
      padding: 14px;
      width: 100%;
      height: 48px;
      margin-bottom: 24px;
    }
    .btn.btn-checkout:disabled{
      opacity: 0.5;
    }
    .btn.btn-checkout:disabled:hover{
      background-color: $blue-color;
      cursor: default;
    }
    .btn.btn-checkout-phone{
      background-color: $light-gray;
      color: $blue-color;
    }
    .btn.btn-checkout-phone:hover{
      color: #fff;
      background-color: $blue-color;
    }
  }

 .inputCheckbox{
   position: relative;
   margin-bottom: 24px;
   .custom-checkbox{
     position: absolute;
     z-index: -1;
     opacity: 0;
     width: 0;
     height: 0;
     margin: 0;
   }
   .custom-checkbox+label{
     display: inline-flex;
     align-items: center;
     user-select: none;
   }
   .custom-checkbox+label::before {
     content: '';
     display: inline-block;
     width: 16px;
     height: 16px;
     flex-shrink: 0;
     flex-grow: 0;
     border: 1px solid $border-color;
     border-radius: 4px;
     margin-right: 0.5em;
     background-repeat: no-repeat;
     background-position: center center;
     background-size: 100%;
   }
   .custom-checkbox:checked+label::before {
     border-color: $blue-color;
     background-color: $blue-color;
     background-image: url("@/assets/icons/icon-check.svg");
   }
   label{
     font-weight: 400;
     font-size: 16px;
     line-height: 20px;
     color: $base-black-color;
     cursor: pointer;
   }

 }
 .auth-form-block{
   width: 576px;
   height: auto;
   background-color: #fff;
   border-radius: 4px;
   padding: 60px 100px;
   h2{
     font-weight: 600;
     font-size: 32px;
     line-height: 40px;
     color: $base-black-color;
     text-align: center;
     margin-bottom: 48px;
   }
   .inputWrap{
     margin-bottom: 28px;
   }
 }
 .inputWrap{
   position: relative;
   display: flex;
   flex-direction: column;
   label{
     font-weight: 400;
     font-size: 14px;
     line-height: 20px;
     color: $dusk-gray-color;
     margin-bottom: 8px;
     position: relative;
     display: inline-flex;
     width: fit-content;
   }
   label:before{
     content: '*';
     position: absolute;
     top: 0;
     right: -6px;
     width: 5px;
     height: 5px;
     display: block;
   }
   .input-form__auth{
     width: 100%;
     height: 48px;
     border-radius: 4px;
     border: 1px solid $border-color;
     padding: 14px 45px 12px 14px;

     font-weight: 400;
     font-size: 16px;
     line-height: 20px;
     color: $base-black-color;
     transition: all 0.3s;
     box-sizing: border-box;
   }
   .input-form__auth:focus-visible{
     border: 1px solid $blue-color;
   }
   .show-password{
     width: 45px;
     height: 48px;
     padding: 0;
     position: absolute;
     top: 28px;
     right: 0;
     border: none;
     cursor: pointer;
     background: url("@/assets/icons/icon-eye.svg")no-repeat center center;
   }
   .show-password.active{
     background: url("@/assets/icons/icon-eye-open.svg")no-repeat center center ;
   }
 }


.recovery-password{
  margin-top: 0;
  a.link-router__recovery{
    font-size: 16px;
    line-height: 20px;
    span{
      font-size: 16px;
      line-height: 20px;
    }
  }
}

//media screen

@media (max-width: 1024px) {
  .auth-form-block{
    padding: 30px 49px;
    h2{
      font-size: 24px;
      margin-bottom: 24px;
    }
    .form-information__element {
      p{
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
}

@media (max-width: 530px) {
  .auth-form-block{
    padding: 16px 24px;
    h2{
      font-size: 20px;
      margin-bottom: 16px;
    }
    .inputWrap .input-form__auth{
      font-size: 14px;
      line-height: 20px;
    }
    .btn-block__form{
      .btn.btn-checkout{
        margin-bottom: 16px;
      }
      .btn.btn-checkout-phone{
        margin-bottom: 16px;
      }
    }
    .form-information__element {
      p{
        font-size: 14px;
        line-height: 20px;
      }
    }
    .inputCheckbox label{
      font-size: 14px;
    }
    .form-information__element{
      .link-change__phone{
        font-size: 14px;
      }
    }
    .form-block__confirmation{
      .confirm__message{
        flex-direction: column;
        .inputWrap, .resend-message{
          width: 100%;
        }

      }
    }
  }
  .recovery-password{
    margin-top: 0;
    a.link-router__recovery{
      font-size: 14px;
      line-height: 20px;
      span{
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
</style>
