<template>
  <div class="item-comparison custom_el-col">
    <div class="item-comparison-title item-comparison-content">
      <span class="item-subtitle__courses">
        {{ localeText.programComparison }}
      </span>
      <el-button
        class="close__item--direction"
        @click="removeItem"
      />
      <h6 v-if="visible('title')">
        <a
          :href="program.detail_url"
          class="link !font-bold"
          target="_blank"
        >
          {{ program.title }}
        </a>
      </h6>
    </div>
    <div
      v-if="visible('dateStart')"
      class="start__courses item-comparison-content"
    >
      <span class="item-subtitle__courses">
        {{ localeText.compareStartCourse }}
      </span>
      {{ dateStart }}
    </div>
    <div
      v-if="visible('edu_format')"
      class="format__courses item-comparison-content"
    >
      <span class="item-subtitle__courses">
        {{ localeText.compareTrainingFormat }}
        <tooltip-edu-format />
      </span>
      {{ program.edu_format }}
    </div>
    <!--кампус-->
    <div
      v-if="visible('edu_format')"
      class="format__courses item-comparison-content"
    >
      <span class="item-subtitle__courses">
        {{ localeText.campusComparison }}
      </span>
      {{ program.campus }}
    </div>
    <div
      v-if="visible('final_document')"
      class="item-comparison-content"
    >
      <span class="item-subtitle__courses">
        {{ localeText.compareIssuedDocument }}
      </span>
      {{ program.final_document }}
    </div>
    <div
      v-if="false && visible('duration') && visible('duration_value')"
      class="duration__courses item-comparison-content"
    >
      <span class="item-subtitle__courses">
        {{ localeText.compareDuration }}
      </span>
      {{ declOfNum(program.duration_value, program.duration) }}
    </div>
    <div
      v-if="visible('hours')"
      class="times__courses item-comparison-content"
    >
      <span class="item-subtitle__courses">
        {{ localeText.compareTotalHours }}
      </span>
      {{ program.hours }}
    </div>
    <!--    цена -->
    <div
      v-if="visible('price')"
      class="prices__courses item-comparison-content"
    >
      <span class="item-subtitle__courses">
        {{ localeText.comparePrice }}
      </span>
      {{ program.price.toLocaleString() }} ₽
    </div>
    <div
      v-if="visible('department')"
      class="item--caption item-comparison-content"
    >
      <span class="item-subtitle__courses">
        {{ localeText.compareDirection }}
      </span>
      {{ program.training_areas.join(', ') }}
    </div>

    <!--    <div class="prices__courses&#45;&#45;old item-comparison-content">-->
    <!--      <span class="item-subtitle__courses">-->
    <!--        Скидка-->
    <!--      </span>-->
    <!--      {{ pcOldPrice }}-->
    <!--    </div>-->

    <div class="times__courses item-comparison-content span_unset">
      <div class="wrap_compare">
        <div
          class="compare_btn"
          @click="onAddBasket"
        >
          <el-button
            v-if="isBasket"
            size="small"
            type="primary"
            disabled="disabled"
          >
            {{ localeText.addedToCart }}
          </el-button>
          <el-button
            v-else
            size="small"
            type="primary"
          >
            {{ localeText.addToCart }}
          </el-button>
        </div>
        <div
          class="compare_favorite"
          @click="toggleFavorite"
        >
          <span
            v-if="program.favorite"
            class="btn-with-hint__trigger"
          >
            <heart-icon
              fill="#FF564E"
              stroke="#FF564E"
              :height="32"
              :width="33"
            />
          </span>
          <span
            v-else
            class="btn-with-hint__trigger"
          >
            <heart-icon
              fill="none"
              :height="32"
              :width="33"
            />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Program} from '@/types/typedefs';
import {isoToLocalDate} from '@/utils/formatDates';
import TooltipEduFormat from '@/components/tooltips/eduFormat.vue';
import HeartIcon from '@/components/icons/HeartIcon.vue';
import setNotification from '@/utils/setNotification';
import Axios from '@/utils/Elk-Axios';
import {FAVORITE_PROGRAM_ADD, FAVORITE_PROGRAM_REMOVE} from '@/api';
import {mapActions, mapMutations} from 'vuex';

export default {
  name: 'ComparisonItem',
  components: {HeartIcon, TooltipEduFormat},
  props: {
    /** @type {Program} */
    program: Program,
    localization: {
      type: Object,
      default: () => {}
    },
    showProps: {
      type: Array,
      default() {
        return [
          'title',
          'department',
          'price',
          'dateStart',
          'final_document',
          'edu_format',
          'duration',
          'hours',
          'training_areas',
          'duration_value'
        ];
      }
    }
  },
  data() {
    return {
      show: true,
      titles: {
        день: ['день', 'дня', 'дней'],
        неделя: ['неделя', 'недели', 'недель'],
        месяц: ['месяц', 'месяца', 'месяцев'],
        год: ['год', 'года', 'лет']
      },
      localeText: {
        programComparison: this.localization.widgetCaption('programComparison'),
        campusComparison: this.localization.widgetCaption('campusComparison'),
        compareDirection: this.localization.widgetCaption('compareDirection'),
        comparePrice: this.localization.widgetCaption('comparePrice'),
        compareStartCourse:
          this.localization.widgetCaption('compareStartCourse'),
        compareIssuedDocument: this.localization.widgetCaption(
          'compareIssuedDocument'
        ),
        compareTrainingFormat: this.localization.widgetCaption(
          'compareTrainingFormat'
        ),
        compareDuration: this.localization.widgetCaption('compareDuration'),
        compareTotalHours: this.localization.widgetCaption('compareTotalHours'),
        addToCart: this.localization.commonCaption('addToCart'),
        addedToCart: this.localization.commonCaption('addedToCart')
      }
    };
  },
  computed: {
    isBasket() {
      return !!this.$store.getters['cart/cartItem'](this.program.id);
    },
    dateStart: function () {
      const date = this.program?.schedule?.[0]?.date_start;

      if (date) {
        return isoToLocalDate(date);
      }

      return null;
    }
  },
  methods: {
    ...mapActions({
      fetch: 'programs/fetchFavorites'
    }),
    ...mapMutations({
      setFavorites: 'programs/setFavorites'
    }),
    async onAddBasket() {
      try {
        await this.$store.dispatch('cart/add', this.program.id);
      } catch (e) {
        let message = '';
        if (e.response.status === 422 && e.response?.data?.message) {
          message = e.response.data.message;
        } else if (e.response?.data?.message) {
          message = e.response.data.message;
        }

        this.$notify({
          title: '',
          type: 'error',
          message: message
        });
      }
    },
    async toggleFavorite() {
      try {
        if (this.program.favorite) {
          await Axios.post(FAVORITE_PROGRAM_REMOVE, {
            program_id: this.program.id
          });
        } else {
          await Axios.post(FAVORITE_PROGRAM_ADD, {program_id: this.program.id});
        }
        this.program.favorite = !this.program.favorite;
        this.setFavorites(this.program.id);
        await this.fetch();
      } catch (e) {
        setNotification({
          message: e.response?.data?.message ?? ''
        });
      }
    },
    removeItem() {
      this.$store.dispatch('compares/remove', this.program.id);
    },
    visible(prop) {
      return !!this.showProps.includes(prop);
    },
    declOfNum(number, title) {
      const cases = [2, 0, 1, 1, 1, 2];
      title = title.toLowerCase();
      return `${number} ${
        this.titles[title][
          number % 100 > 4 && number % 100 < 20
            ? 2
            : cases[number % 10 < 5 ? number % 10 : 5]
        ]
      }`;
    }
  }
};
</script>

<style lang="scss">
.compare_favorite {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 12px;
  cursor: pointer;
}

.compare_btn {
  width: 40%;
}

.wrap_compare {
  display: flex;
}

.span_unset span {
  display: unset !important;
}

.el-tooltip__popper.is-light.custom-tooltip {
  box-shadow: 0 4px 24px rgb(106 122 152 / 20%);
  border: none;

  .popper__arrow {
    border: unset;
  }
}

.comp_title {
  color: #0050cf;
  font-weight: bold;
}

.el-row.wrapper-table__comparison {
  &:before {
    content: unset;
  }

  &:after {
    content: unset;
  }
}

.wrapper-table__comparison {
  display: flex;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));

  .custom_el-col {
    max-width: 398px;
  }

  .btn.btn__show-column {
    position: absolute;
    top: calc(55%);
    right: 28px;
    width: 20px;
    height: 20px;
    background-color: transparent;
    border: none;

    &:hover {
      background-color: transparent;
    }

    &:before {
      content: '';
      width: 18px;
      height: 18px;
      display: block;
      background: url('../../assets/icons/icon-arrow-default.svg') 100% 100%
        no-repeat;
    }
  }

  .item-comparison-title.item-comparison-content {
    padding: 24px 65px 24px 0;
    border-right: 1px solid #edeff3;
    border-bottom: 1px solid #edeff3;
    position: relative;
    height: 177px;
    justify-content: flex-start;

    h6 {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
    }

    .close__item--direction {
      background-color: transparent;
      position: absolute;
      width: 20px;
      top: 13px;
      right: 24px;

      &:before {
        content: '';
        width: 14px;
        height: 14px;
        display: block;
        background: url('../../assets/icons/icon-close-gray.svg') 100% 100%
          no-repeat;
        opacity: 0.7;
      }
    }
  }

  .format__courses.item-comparison-content {
    .item-subtitle__courses {
      position: relative;
      width: max-content;

      .el-button.el-tooltip.el-button--default {
        position: absolute;
        top: 3px;
        right: -20px;
        width: 15px;
        min-height: 15px;
        padding: 0;
        background-color: transparent;

        &:before {
          content: '';
          width: 14px;
          height: 14px;
          display: block;
          background: url('../../assets/icons/icon-inform-gray.svg') 100% 100%
            no-repeat;
        }
      }
    }
  }

  .custom_el-col:not(:first-child) {
    .item-comparison-title,
    .item-comparison-content {
      padding-left: 24px;
    }
  }

  .custom_el-col:not(:first-child) {
    .item--caption.item-comparison-content {
      padding-top: 39px;
    }
  }

  .item--caption.item-comparison-content {
    height: 120px;
    padding-top: 16px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .item-comparison-content {
    height: 80px;
    padding-bottom: 16px;
    border-right: 1px solid #edeff3;
    border-bottom: 1px solid #edeff3;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .prices__courses,
  .prices__courses--old,
  .start__courses,
  .duration__courses {
    border-right: 1px solid #edeff3;
    border-bottom: 1px solid #edeff3;
  }

  .custom_el-col:first-child {
    .item-comparison-content:not(:first-child) {
      display: flex;
      flex-direction: column;
      padding-bottom: 16px;
      justify-content: flex-end;
    }

    .item--caption.item-comparison-content {
      justify-content: start;
    }
  }

  .custom_el-col {
    .item--caption.item-comparison-content {
      justify-content: start;
    }

    .item-comparison-content {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: var(--font-color);
    }

    .prices__courses.item-comparison-content,
    .prices__courses--old {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: var(--font-color);
    }
  }

  .custom_el-col:last-child {
    .item-comparison-content {
      border-right: unset;
    }
  }

  .custom_el-col:last-child {
    .item-comparison-title {
      padding-right: 45px;
      border-right: unset;

      .close__item--direction {
        right: 0;
      }
    }
  }

  .item-subtitle__courses {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--font-color-alt);
    margin-bottom: 4px;
  }
}
</style>
