<template>
  <a
    :href="url"
    class="btn__programs btn-prog__document"
  >
    <span>Документ об обучении</span>
  </a>
</template>

<script>
export default {
  name: "LinkGraduationDoc",
  props: {
    url: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
.btn__programs.btn-prog__document{
  width: 220px;
  height: 44px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border-radius: 4px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0;
  position: relative;
  transition: all .3s ease-in-out;
  background-color: #0050CF;
  &:before{
    content: '';
    width: 20px;
    height: 20px;
    margin-right: 11px;
    background: url("@/assets/icons/icon-doc-pdf.svg")100% 100% no-repeat;
  }
  &:hover{
  background-color: rgb(0 142 246 / 85%);
  }
}
</style>
