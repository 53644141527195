<template>
  <div class="page-control-panel__item">
    <vi-drop-down-list
      :price-rung-elem-name="rangeLabel"
      :price-rung-seleected-parameters="0"
    >
      <template slot="inner">
        <div class="ddl-box">
          <div class="ddl-box__head">
            <the-mask
              v-model="selected[0]"
              :type="'text'"
              name="begin-interval"
              :mask="['###', '# ###', '## ###', '### ###', '# ### ###']"
              class="vi-input"
            />
            <the-mask
              v-model="selected[1]"
              :type="'text'"
              name="end-interval"
              :mask="['###', '# ###', '## ###', '### ###', '# ### ###']"
              class="vi-input"
            />
          </div>
          <el-slider
            ref="changeInputs"
            v-model="selected"
            :range="true"
            :min="min"
            :max="max"
            :show-tooltip="false"
          />
          <button
            class="ddl-box__btn"
            type="button"
            @click="handleInput"
          >
            Применить
          </button>
        </div>
      </template>
    </vi-drop-down-list>
  </div>
</template>

<script>
import { TheMask } from 'vue-the-mask'
import ViDropDownList from "@/components/formElements/ViDropDownList.vue";
import {filterBus} from "@/main";

export default {
  name: 'RangeFilter',
  components: {
    'the-mask': TheMask,
    'vi-drop-down-list': ViDropDownList,
  },
  props: {
    label: {
      type: String,
      required: true
    },
    param: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      selected: this.range,
    }
  },
  mounted(){
    filterBus.$on('removeFilter', (value) => {
      if(value.includes(this.label)) {
        this.selected = [this.min, this.max];
      }
    });
  },
  computed: {
    getSelected() {
      if(this.range.length === 2) {
        return [this.range[0].toLocaleString(), this.range[1].toLocaleString()];
      }
      return this.label;
    },
    rangeLabel() {
      let result = '';
      if (this.verifyDefValue()) {
        if (this.selected[1] > 0) {
          result += `${this.selected[0].toLocaleString()} - ${this.selected[1].toLocaleString()}`;
        }
      }

      return result || this.label;
    }
  },
  methods: {
    handleInput() {
      this.$emit('input', this.value)
    },
    getValue() {
      if (this.selected[1] || null) {
        return {
          label: 'От ' + this.selected[0] + ' до ' + this.selected[1],
          value: {
            from: this.selected[0] || 0,
            to: this.selected[1]
          },
          param: this.param
        }
      }

      return null
    },
    verifyDefValue() {
      return !(this.selected.length === 2 && Number(this.selected[0]) === Number(this.min) && Number(this.selected[1]) === Number(this.max));
    },
    toParam() {
      return {
        [this.param + '[from]']: this.selected[0] || (this.selected[1] ? 0 : null),
        [this.param + '[to]']:   this.selected[1] || null
      }
    },
    toLabel() {
      if(this.verifyDefValue()) {
        return [this.label + ' c ' +   this.selected[0].toLocaleString() + ' по ' + this.selected[1].toLocaleString()];
      }
      return false;
    }
  }
}
</script>
