<script>
import IconSvg from "@/components/icons/IconSvg.vue"

export default {
  name: 'ArrowLeftIcon',
  extends: IconSvg,
  props: {
    d: {
      type: String,
      default: 'M9.353 6.304 9.28 6.22a.75.75 0 0 0-.976-.073l-.084.073-4.997 4.997a.75.75 0 0 0-.073.976l.073.085L8.22 17.28a.75.75 0 0 0 1.133-.976l-.072-.084-3.711-3.717h14.68a.75.75 0 0 0 .743-.647l.007-.102a.75.75 0 0 0-.648-.743l-.102-.007-14.693-.001L9.281 7.28a.75.75 0 0 0 .072-.976L9.28 6.22l.073.084Z'
    }
  }
}
</script>
