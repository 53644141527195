<template>
  <div class="info-noty">
    <div class="info-noty__img">
      <errorSearch class="errorSearch" />
    </div>
    <div class="info-noty__block">
      <div class="info-noty__title">
        {{
          $t(getCommonCaptionByName('search_searchedDataEmpty'), {
            query: getShortText,
          })
        }}
      </div>
      <div class="info-noty__subtitle">
        {{ $t(getCommonCaptionByName('search_checkEnteredQuery')) }}
      </div>
    </div>
  </div>
</template>

<script>
import { shortText } from '@/utils/shortText';
import errorSearch from '@/assets/icons/error-search.svg?inline';
import { getCommonCaptionByName } from '@/i18n/utils';

export default {
  components: {
    errorSearch,
  },
  props: {
    text: String,
    maxLength: {
      default: 10,
      type: Number || String,
    },
  },
  computed: {
    getShortText() {
      if (this.text) {
        return `«${shortText(this.text, this.maxLength)}»`;
      }
      return null;
    },
  },
  methods: {
    getCommonCaptionByName,
  },
};
</script>
