<template>
  <div class="aside__bottom">
    <a v-if="!isPublicUser" class="aside__exit" @click="logOut">
      <component :is="iconLoader('elk.logout')" class="icon aside__exit-icon" />
      <span class="aside__exit-text">{{
        $t(getCommonCaptionByName('auth_toLogout'))
      }}</span>
    </a>
    <localeChanger />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import iconLoader from '@/utils/iconLoader';
import localeChanger from '@/components/common/localeChanger';
import logOut from '@/utils/logOut';
import { getCommonCaptionByName } from '@/i18n/utils';

export default {
  name: 'mob-sidebar-bottom',
  components: {
    localeChanger,
  },
  computed: {
    ...mapGetters(['isPublicUser']),
  },
  methods: {
    getCommonCaptionByName,
    logOut,
    iconLoader,
  },
};
</script>
