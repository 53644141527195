<template>
  <div
    id="elk-feedback"
    class="page"
  >
    <div class="page__title">
      <h2 class="center-text">Ошибка авторизации</h2>
    </div>
    <p class="err-404__text center-text u-m-t-20">
      Просмотр этой страницы требует авторизации или такой страницы не
      существует. Авторизуйтесь или перейдите на
      <a
        class="link"
        href="/"
      >
        главную страницу
      </a>
    </p>
    <div class="err-404__btns u-centrilize">
      <div class="err-404__btns-item">
        <a
          :href="getAdfsUrl"
          class="btn"
        >
          Войти
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: 'ElkErrorPagePublic',
  computed: {
    ...mapGetters(['getAdfsUrl'])
  }
};
</script>
