import store from '@/store';
import { LOCALE_ID_EN } from '@/i18n/utils';

export default function () {
  //tests
  switch (store.state.locale.localeId) {
    case LOCALE_ID_EN:
      return 7;
    default:
      return 1;
  }
}
