<template>
  <div class="c-list-checkbox">
    <ul>
      <li
        v-for="(item, i) in items"
        :key="i"
        class="c-list-checkbox__item"
      >
        <CascadeGroup
          v-if="item.group"
          ref="group"
          :group="item.group"
          :items="item.items"
          v-model="form[i]"
          @on-change-group="onChange"
        />
        <CascadeCheckbox
          v-else
          v-model="form[i]"
          :index="i"
          @on-change="onChange"
        >
          {{ item.name }}
        </CascadeCheckbox>
      </li>
    </ul>
  </div>
</template>

<script>
import CascadeCheckbox from '@/components/formElements/CascadeSelect/CascadeCheckbox';
import checkboxStatuses from '@/components/formElements/CascadeSelect/checkboxStatuses';
import {boolToStatus} from '@/components/formElements/CascadeSelect/statusToBool';

export default {
  name: 'CascadeListCheckbox',
  components: {
    CascadeCheckbox,
    CascadeGroup: () => import('./CascadeGroup')
  },
  model: {
    prop: 'valueModel',
    event: 'change-status'
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    valueModel: {
      type: String,
      default: checkboxStatuses.notActive
    }
  },
  data() {
    let listState = {};

    this.items.forEach((e, i) => {
      listState[i] = boolToStatus();
    });

    return {
      statuses: checkboxStatuses,
      statusCheckbox: this.valueModel,
      form: listState
    };
  },
  watch: {
    valueModel(v) {
      if (v !== this.statusCheckbox) {
        this.statusCheckbox = v;
        this.updateFormState(v);
      }
    },
    statusCheckbox(v) {
      if (this.valueModel !== v) {
        this.$emit('change-status', v);
      }
    },
    items() {
      this.resetStates();
    }
  },
  methods: {
    resetStates() {
      let listState = {};

      this.items.forEach((e, i) => {
        listState[i] = boolToStatus();
      });

      this.form = listState;
      this.updateStatusCheckbox();
    },
    updateFormState(v = this.statuses.notActive) {
      for (const vElement of Object.keys(this.form)) {
        this.form[vElement] = v;
      }
    },
    setActiveByCode(code, state = false) {
      const i = this.items.findIndex(e => e.code === code);
      if (i < 0) return false;
      const childIndex = this.$children.findIndex(e => e?.index === i);
      if (childIndex < 0 || this.$children[childIndex]?.checkbox === state) {
        return false;
      }
      this.$children[childIndex].checkbox = state;
      this.$children[childIndex].onChange();
      return true;
    },
    getActive() {
      const result = [];
      for (const i of Object.keys(this.form)) {
        if (this.form[i] === this.statuses.active && this.items[i]?.code) {
          result.push({
            code: this.items[i]?.code ?? '',
            name: this.items[i]?.name ?? ''
          });
        }
      }
      return result;
    },
    getNewStatus() {
      const arrValues = Object.values(this.form);
      let tempStatus = arrValues[0] ?? '';

      if (arrValues.find(e => e !== tempStatus)) {
        tempStatus = this.statuses.partially;
      }

      return tempStatus;
    },
    updateStatusCheckbox() {
      const newStatus = this.getNewStatus();

      if (newStatus !== this.statusCheckbox) {
        this.statusCheckbox = newStatus;
      }
    },
    onChange() {
      this.updateStatusCheckbox();
    }
  }
};
</script>

<style scoped>
.c-list-checkbox__item:not(:last-child) {
  margin-bottom: 8px;
}
</style>
