<template>
  <el-alert
    class="alert alert--danger"
  >
    {{ `У вас не заполнены данные: ${message}. \n Пожалуйста, заполните их чтобы полноценно
      пользоваться личным кабинетом.` }}
  </el-alert>
</template>

<script>
export default {
  name: "alertDanger",

  props: {
    message: { type: String, default: ''}
  },

  data() {
    return {
    }
  }
}
</script>

<style lang="scss">
  .el-alert.alert.alert--danger.el-alert--info.is-light{
    padding: 18px 50px 18px 56px;
    border-radius: 8px;
    width: 100%;
    height: auto;
    margin-bottom: 24px;
    background-color: $fair-pink;
    p{
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: $color-system-error;
      margin: 0;
      position: relative;
      &:before{
        content: '';
        position: absolute;
        top: calc(50% - 9px);
        left: -27px;
        display: block;
        width: 18px;
        height: 18px;
        background: url("../../assets/icons/icon-danger.svg") 100% 100% no-repeat;
      }
    }
    .el-alert__closebtn{
      font-size: 24px;
      top: 15px;
      right: 21px;
      cursor: pointer;
      &:before{
        color: #6B7A99;
      }
    }
  }
  //.alert.alert--danger{
  //
  //  position: relative;
  //  .btn__close-alert{
  //    width: 21px;
  //    height: 21px;
  //    padding: 0;
  //    position: absolute;
  //    top: calc(50% - 10px);
  //    right: 21px;
  //    border: none;
  //    background-color: transparent;
  //    cursor: pointer;
  //    &:before{
  //      content: '';
  //      display: block;
  //      width: 14px;
  //      height: 14px;
  //      position: absolute;
  //      top:3px;
  //      left: 3px;
  //      background: url("../../assets/icons/icon-close-gray.svg");
  //    }
  //  }
  //
  //}

  @media (max-width: 480px) {
    el-alert.alert.alert--danger.el-alert--info.is-light{
      padding: 18px 40px 18px 40px;
      .btn__close-alert{
        right: 10px;
      }
    }
  }

  @media (max-width: 425px) {
    el-alert.alert.alert--danger.el-alert--info.is-light {
      p{
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

</style>
