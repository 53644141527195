import { isProd } from '@/utils/constants';

function sentryMessage(message, extra) {
  if (isProd()) {
    import('@/sentry').then((Sentry) => {
      Sentry.withScope((scope) => {
        scope.setExtras(extra);
        Sentry.captureMessage(message);
      });
    });
  }
}
export { sentryMessage };
