export default {
  groups: [],
  programs: [],
  corpPrograms: [],
  corpGroups: [],
  corpUser: null,
  user: null,
  isInit: false,
  isCorpInit: false,
  isLoading: false,
  serviceManager: {}
};
