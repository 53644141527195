<template>
  <router-link
    v-if="isVueLink"
    :to="computedTo"
    :tag="tag"
    :custom="custom"
    :exact="exact"
    :exact-path="exactPath"
    :append="append"
    :replace="replace"
    :active-class="activeClass"
    :exact-active-class="exactActiveClass"
    :aria-current-value="ariaCurrentValue"
    :event="event"
    v-slot="{ href, route, navigate, isActive, isExactActive }"
  >
    <slot :href="href" :route="route" :navigate="navigate" :isActive="isActive" :isExactActive="isExactActive"/>
  </router-link>
  <a
    v-else
    :href="computedTo"
  >
    <slot />
  </a>
</template>

<script>
export default {
  name: 'AbsoluteRelativeLink',
  props: {
    to: {
      type: [Object, String],
      required: true
    },
    tag: {
      type: String,
      default: 'a'
    },
    custom: Boolean,
    exact: Boolean,
    exactPath: Boolean,
    append: Boolean,
    replace: Boolean,
    activeClass: String,
    exactActiveClass: String,
    ariaCurrentValue: {
      type: String,
      default: 'page'
    },
    event: {
      type: [String, Array],
      default: 'click'
    }
  },
  computed: {
    isVueLink() {
      return this.to instanceof String && this.to.startsWith('http')
    },
    computedTo() {
      if(this.to instanceof String) {
        if(this.to.startsWith('/') || this.to.startsWith('http')) {
          return this.to
        } else {
          return  '/' + this.to;
        }
      }
      return this.to
    }
  }
}
</script>
