<template>
  <div class="info-noty">
    <div
      v-if="!withoutImg"
      class="info-noty__img"
    >
      <errorSearch class="errorSearch" />
    </div>
    <div class="info-noty__block">
      <div class="info-noty__title">
        {{ message }}
      </div>
      <i18n
        :path="getCommonCaptionByName('error_tryLaterOrContactTo')"
        tag="div"
        class="info-noty__subtitle"
      >
        <template #service>
          <a
            class="link"
            :href="IT_SUPPORT"
            target="_blank"
          >
            {{ $t(getCommonCaptionByName('error_userSupport_linked')) }}
          </a>
        </template>
      </i18n>
    </div>
  </div>
</template>

<script>
import errorSearch from '@/assets/icons/error-search.svg?inline';
import {IT_SUPPORT} from '@/router/routes.js';
import {getCommonCaptionByName} from '@/i18n/utils';
import I18N from '@/i18n';

export default {
  name: 'elk-error-message',
  components: {
    errorSearch
  },
  props: {
    withoutImg: {
      type: Boolean,
      default: false
    },
    message: {
      type: String,
      default: I18N.t(getCommonCaptionByName('error_fetchData'))
    }
  },
  data: function () {
    return {
      IT_SUPPORT
    };
  },
  methods: {
    getCommonCaptionByName
  }
};
</script>
