<template>
    <div :class="{ body: true, active: menuActive }">
      <menu-wrapper :pageName="name" />
      <div class="page-wrapper">
        <main class="content" :class="pageClass">
            <section class="notifications-section">
                <div class="container">
                    <h1 class="page-title">{{ name }}</h1>
                    <div class="filter-panel">
                        <search-form
                          :noForm="true"
                          v-model="searchValue"
                          :fieldPlaceholder="'Поиск по уведомлениям...'"
                          @clearField="clearSearch"
                          @input="onSearchNotifications"
                        ></search-form>

                        <switch-item
                            v-model="switchValue"
                            :captionOff="'Непрочитанные'"
                            :captionOn="'Все'"
                        ></switch-item>
                    </div>
                    <notification-spoiler
                        v-for="item in notifications"
                        :key="item.id"
                        :elId="item.id"
                        :fresh="!item.is_read"
                        :status="item.is_important"
                        :date="item.date"
                        :caption="item.title"
                        :bodyContent="item.text"
                        @read="(...arg)=>{markAsRead(...arg, notifications)}"
                    ></notification-spoiler>
                </div>
            </section>
        </main>
      </div>
      <page-footer/>
    </div>
</template>
<script>
    import recoveryStart from "@/components/authComponent/recoveryStart.vue"
    import menuWrapper from "@/components/menuWrapper";
    import PageFooter from "@/components/PageFooter";

    import { debounce } from '@/utils/debounce';
    import SearchForm from "@/components/forms/SearchForm.vue" // форма поиска
    import SwitchCheckbox from "@/components/formElements/SwitchCheckbox" //переключатель (на основе input:checkbox)
    import NorificationSpoiler from "@/components/spoilers/NotificationSpoiler"

    import { mapState, mapGetters } from 'vuex';
    import Axios from "@/utils/Elk-Axios";

export default{
    name: 'notifications',
    components: {
        recoveryStart,
        menuWrapper,
        PageFooter,
        'search-form': SearchForm,
        'switch-item': SwitchCheckbox,
        'notification-spoiler': NorificationSpoiler,
    },
    data(){
        return{
            name: 'Уведомления',
            pageClass: 'notifications-page',
            switchValue: false,
            searchValue: '',
            notifications: [],
        }
    },
    computed: {
            ...mapState(['menuActive']),
            ...mapGetters(['isPublicUser']),
    },
  watch: {
    switchValue() {
      this.fetchNotify()
    }
  },
  mounted() {
    this.fetchNotify()
  },
  methods: {
        //метод очистки поля поиска
        clearSearch() {
            this.searchValue = ''
            this.fetchNotify()
        },
        //метод, который делает сообщение прочитанным
        async markAsRead(id){

          try {
            await Axios.post(`set-notification-read`, { id: id }, );
            await this.fetchNotify()
            this.$store.dispatch('notificationCounter/getNotificationCount');
          } catch {
            // Не удалось отправить
          }
        },
        getQuery () {
          let q = ''

          q += `?is_no_read=${!this.switchValue}`
          q += `&limit=8`
          q += '&offset=0'

          if (this.searchValue) {
            q += `&query=${this.searchValue}`
          }

          return q
        },
        // Получает уведомления
        async fetchNotify() {
          try {
            const { data } = await Axios.get(`notifications${this.getQuery()}`);
            if (data.data.length) {
              this.notifications = data.data
              this.notifications.sort((a, b) => {
                return (a.is_read === b.is_read) ? 0 : a.is_read ? 1 : -1
              })
            }
          } catch {
            // Не удалось получить уведомления
          }
        },
        onSearchNotifications: debounce(function () {
            this.fetchNotify()
        }, 500)
    }
}
</script>
<style lang="scss">

    .notifications-section{
        .filter-panel{
            display: flex;
            margin-bottom: 24px;
            .search-form{
                max-width: 575px;
                width: 60%;
            }
            .switch{
                margin-left: auto;
            }
        }
        .notifications-spoiler{
            &:not(:last-child){
                margin-bottom: 8px;
            }
        }
    }
    @media screen and (max-width: 670px){
        .notifications-section {
            .filter-panel{
                display: block;
                .search-form{
                    max-width: 100%;
                    width: 100%;
                    margin-bottom: 24px;
                }
            }
        }
    }
</style>
