<template>
  <div class="breadcrumbs">
    <div class="breadcrumbs__container">
      <router-link
        :to="{ name: this.$route.name, params: { pathMatch: '' } }"
        class="breadcrumbs__link"
        >{{ pageName }}</router-link
      >
      <span class="breadcrumbs__split">/</span>
    </div>
    <template v-if="breadcrumbs.length">
      <div
        class="breadcrumbs__container"
        v-for="(breadcrumb, index) in breadcrumbs"
        :key="`breadcrumb-${index}`"
      >
        <router-link
          :to="`${mainPath}/${breadcrumb.url}`"
          class="breadcrumbs__link"
        >
          {{ breadcrumb.name }}
        </router-link>
        <span class="breadcrumbs__split">/</span>
      </div>
    </template>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('breadcrumbs');

export default {
  name: 'elk-breadcrumbs',
  props: ['pageName'],
  computed: {
    ...mapState(['breadcrumbs']),
    mainPath() {
      return this.$route.matched[0].path.split('*')[0];
    },
  },
};
</script>
