<template>
  <div class="change-request">
    <el-alert
      title=""
      type="primary"
      :description="localeText.infoChangeRequest"
      show-icon
      :center="false"
      :closable="false"
    />
    <div class="change-request__from-container">
      <el-form
        ref="ruleFormRef"
        label-position="top"
        size="large"
        :rules="rules"
        :model="form"
      >
        <el-form-item
          :label="localeText.describeChanges"
          prop="text"
        >
          <el-input
            v-model="form.text"
            type="textarea"
            :placeholder="localeText.startWritingText + '...'"
          />
        </el-form-item>
        <el-form-item>
          <input-file
            id="id_file"
            text-align="center"
            :required="true"
            :accept-extensions="arrImageExtensions"
            :label="localeText.attachDocument"
            :show-alert-info="true"
            :value="form.file"
          />
        </el-form-item>
        <el-form-item class="change-request__buttons">
          <el-button
            type="primary"
            @click="onSubmit"
          >
            {{ localeText.toSend }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import InputFile from '@/components/inputComponent/InputFile';
import Axios from '@/utils/Elk-Axios';
import {V1_DOCUMENTS_FINAL_CORRECTION} from '@/api';
import setNotification from '@/utils/setNotification';

export default {
  name: 'ChangeRequestForm',
  components: {InputFile},
  props: {
    resetForm: {
      type: Boolean,
      default: false
    },
    documentId: {
      type: Number,
      default: 0
    },
    programId: {
      type: Number,
      default: 0
    },
    localization: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      arrImageExtensions: ['jpg', 'jpeg', 'pdf', 'png'],
      localeText: {
        infoChangeRequest: this.localization.widgetCaption('infoChangeRequest'),
        describeChanges: this.localization.widgetCaption('describeChanges'),
        startWritingText: this.localization.widgetCaption('startWritingText'),
        notifyProgramID: this.localization.widgetCaption('notifyProgramID'),
        notifyDocumentID: this.localization.widgetCaption('notifyDocumentID'),
        attachDocument: this.localization.commonCaption('attachDocument'),
        toSend: this.localization.commonCaption('toSend')
      },
      form: {
        docId: this.documentId ?? 0,
        programId: this.programId ?? 0,
        text: '',
        file: {
          $model: []
        }
      },
      rules: {
        text: [{required: true, message: 'Обязательно для заполнения'}]
      }
    };
  },
  watch: {
    resetForm(v) {
      if (v === false) {
        this.onResetForm();
      } else {
        this.form.docId = this.documentId ?? 0;
        this.form.programId = this.programId ?? 0;
      }
    }
  },
  methods: {
    async onSubmit() {
      if (!this.$refs.ruleFormRef) return;
      const isValid = await this.validate();
      if (!isValid) {
        return;
      }
      try {
        await Axios.post(V1_DOCUMENTS_FINAL_CORRECTION, this.getPayload());
        this.$emit('formSuccess');
      } catch {
        // Не удалось отправить данные
      }
    },
    onResetForm() {
      this.form = {
        docId: 0,
        programId: 0,
        text: '',
        file: {
          $model: []
        }
      };
    },
    getPayload() {
      const fd = new FormData();
      if (this.form.programId) {
        fd.append('program_id', this.form.programId);
      }
      if (this.form.docId) {
        fd.append('id', this.form.docId);
      }
      if (this.form.text) {
        fd.append('message', this.form.text);
      }
      if (this.form.file.$model.length > 0) {
        fd.append('file', this.form.file.$model.at(0));
      }
      return fd;
    },
    async validate() {
      let isValid = false;
      await this.$refs.ruleFormRef.validate(valid => {
        isValid = valid;
      });

      if (isValid) {
        isValid = this.validatePayload();
      }

      return isValid;
    },
    validatePayload() {
      let tempMessage = '';
      if (!this.form.programId) {
        tempMessage = 'Не указан идентификатор программы';
      } else if (!this.form.docId) {
        tempMessage = 'Не указан идентификатор документа';
      }
      setNotification({
        message: tempMessage,
        duration: 5000
      });

      return !tempMessage;
    }
  }
};
</script>

<style lang="scss">
.change-request {
  .el-alert--primary {
    background-color: $color-brand-5;
  }
  .el-alert {
    align-items: start;
  }
  .el-alert--primary .el-alert__icon {
    color: $primary-brand-1;
  }
  .el-alert--primary .el-alert__title {
    color: $color-brand-dark;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
  }
  .el-alert .el-alert__description {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $color-base-black;
    word-break: break-word;
  }
  .el-alert__icon.is-big {
    font-size: 18px;
    margin-top: 4px;
  }
  .el-alert__icon:before {
    font-size: 18px;
  }

  /* el-form */

  &__from-container {
    margin-top: 24px;
  }

  .el-form {
    width: 100%;
    padding: 0px;
  }

  .el-form .el-form-item__label {
    padding: 0px;
    color: $color-gray-dusk;
  }

  .el-input__inner,
  .el-input.is-disabled .el-input__inner,
  .el-textarea__inner {
    font-size: 16px;
    color: $color-base-black;
  }

  .el-textarea__inner:focus {
    border-color: $blue-color;
  }

  &__buttons .el-form-item__content {
    display: flex;
  }

  .change-request__button-back {
    background-color: $color-background-gray-2;
    color: $color-gray-dusk;
  }
  .change-request__button-back:hover {
    background-color: $color-gray-morn-2;
    color: $color-gray-dusk;
  }
}

@media (max-width: 480px) {
  .change-request {
    &__buttons .el-form-item__content {
      flex-direction: column;
      gap: 8px;
    }
    &__buttons .el-button + .el-button {
      margin: 0px;
    }
  }
}
</style>
