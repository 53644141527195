export default (localization = {}) => {
  return {
    required: localization.commonCaption('errRequired'),
    digits: localization.commonCaption('errDigits'),
    email: localization.commonCaption('errIncorrectEmail'),
    numeric: localization.commonCaption('errNumeric'),
    min: localization.commonCaption('errMin'),
    max: localization.commonCaption('errMax'),
    date: localization.commonCaption('errDate'),
    after_or_equal: localization.commonCaption('errDateAfterOrEqual'),
    before_or_equal: localization.commonCaption('errDateBeforeOrEqual'),
    after: localization.commonCaption('errDateAfter'),
    before: localization.commonCaption('errDateBefore'),
    minValue: localization.commonCaption('errMinValue'),
    maxValue: localization.commonCaption('errMaxValue')
  };
};
