<template>
  <el-alert
    type="success"
    class="alert alert--success" v-if="false"
  >
    Вы загрузили согласие на обработку персональных данных
  </el-alert>
</template>

<script>
export default {
  name: "alertSuccess"
}
</script>

<style lang="scss">
  .el-alert.alert.alert--success.el-alert--success.is-light{
    background-color: #E9F9EF;
    position: fixed;
    top: 50%;
    max-width: 976px;
    left: calc(50% - 400px);
    z-index: 100;
    padding: 18px 56px;
    p{
      color: #0E603D;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      margin: 0;
      &:before{
        content: '';
        position: absolute;
        top: calc(50% - 9px);
        left: 27px;
        display: block;
        width: 18px;
        height: 18px;
        background: url("../../assets/icons/icon-check-green.svg") 100% 100% no-repeat;
      }
    }
    .el-alert__closebtn{
      font-size: 21px;
      top: calc(50% - 11px);
      right: 21px;
      color:  #6B7A99;
    }

  }
</style>
