import locale from 'element-ui/lib/locale';
import { LOCALE_NAME_RU, LOCALE_NAME_EN } from '@/i18n/utils';
import store from '@/store';
const locales = {
  [LOCALE_NAME_RU]: import(
    /* webpackChunkName: "ui-locales" */ 'element-ui/lib/locale/lang/ru-RU'
  ),
  [LOCALE_NAME_EN]: import(
    /* webpackChunkName: "ui-locales" */ 'element-ui/lib/locale/lang/en'
  ),
};

const setCurrentLang = async () => {
  try {
    const lang = await locales[store.getters['locale/getLocaleName']];
    locale.use(lang.default);
  } catch (e) {
    const lang = await locales.ru;
    locale.use(lang.default);
  }
};

export default setCurrentLang;
