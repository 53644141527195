<template>
  <el-form class="print-form-service">
    <el-form-item>
      <p class="print-form-service__info">
        {{ localeText.infoPrintFormService }}
      </p>
    </el-form-item>
    <el-form-item>
      <a
        class="print-form-service__upload"
        :href="formPath"
        download
        target="_blank"
      >
        <span> {{ localeText.generatedForm }} </span>
      </a>
    </el-form-item>
    <el-form-item>
      <input-file
        id="id_file"
        text-align="center"
        :required="true"
        :accept-extensions="arrImageExtensions"
        :show-alert-info="true"
        :label="localeText.uploadDocument"
        :value="$v.file"
      />
    </el-form-item>
    <hr />
    <el-form-item>
      <el-button
        type="primary"
        :disabled="$v.$invalid"
        @click="onSubmit"
      >
        {{ localeText.sendApplication }}
      </el-button>
    </el-form-item>
    <el-form-item>
      <el-button
        type="secondary"
        @click="$emit('back')"
      >
        {{ localeText.comeBack }}
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import InputFile from '@/components/inputComponent/InputFile';
import {validationMixin} from 'vuelidate';
import {required} from 'vuelidate/lib/validators';
import setNotification from '@/utils/setNotification';

export default {
  name: 'PrintFormService',
  components: {InputFile},
  mixins: [validationMixin],
  props: {
    formPath: {
      type: String,
      default: ''
    },
    payload: {
      type: Object,
      default: () => {}
    },
    submitHandler: {
      type: Function,
      default: () => {}
    },
    localization: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      arrImageExtensions: ['jpg', 'jpeg', 'pdf', 'png'],
      file: {},
      localeText: {
        infoPrintFormService: this.localization.commonCaption(
          'infoPrintFormService'
        ),
        generatedForm: this.localization.commonCaption('generatedForm'),
        sendApplication: this.localization.commonCaption('sendApplication'),
        uploadDocument: this.localization.commonCaption('uploadDocument'),
        comeBack: this.localization.commonCaption('comeBack')
      }
    };
  },
  methods: {
    async onSubmit() {
      try {
        await this.submitHandler({...this.payload, file: this.file});
      } catch (e) {
        setNotification({message: e.response?.data?.message ?? ''});
      }
    }
  },
  validations: {
    file: {
      required
    }
  }
};
</script>

<style lang="scss">
.print-form-service {
  &__info {
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    color: $color-gray-dusk;
  }
  &__upload {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    display: flex;
    justify-content: flex-start;
    padding: 12px 36px;
    background-color: $primary-brand-0-1;
    position: relative;
  }
  &__upload:after {
    content: '';
    position: absolute;
    top: calc(50% - 10px);
    right: 25px;
    display: block;
    width: 12px;
    height: 18px;
    background: url('@/assets/icons/icon-download-file.svg') 100% 100% no-repeat;
  }
  &__upload:hover {
    background-color: rgba(0, 80, 207, 0.2);
  }
  &__upload span {
    position: relative;
  }
  &__upload span:before {
    content: '';
    position: absolute;
    top: calc(50% - 10px);
    left: -25px;
    display: block;
    width: 12px;
    height: 20px;
    background: url('@/assets/icons/icon-clip-add.svg') 100% 100% no-repeat;
  }
  .input-wrap-document {
    background: $color-gray-morn-4;
  }
  .input-wrap-document .btn-document {
    right: 8px;
  }
}
</style>
