<template>
  <div class="input-profile-contact input-profile-contact--no-wrap">
    <el-select
      ref="category_select"
      v-model="chosenCategory"
      @change="setCategory"
      filterable
      placeholder=""
      :disabled="isLoading"
      id="category"
      value-key="id"
      class="input-profile-contact__value"
    >
      <el-option
        v-for="category in categories"
        :key="category.id"
        :label="category.name"
        :value="category"
      >
      </el-option>
    </el-select>
    <loading v-if="isLoading" class="loader loader--input" />
    <div v-else class="input-profile-contact__btn">
      <button
        @click="setCategorySettingClose"
        class="btn btn--secondary btn--icon"
      >
        {{ $t(getCommonCaptionByName('cancel')) }}
      </button>
    </div>
  </div>
</template>

<script>
import { getCategories as getCategoriesApi, setCategory as setCategoryApi } from './api';
import { getCommonCaptionByName, getUiItemCaption } from '@/i18n/utils';
import Loading from '@/components/common/elkLoading';

import { isArrayNotEmpty } from '@/utils/types';
import setNotification from '@/utils/setNotification';

export default {
  name: 'elk-profile-info-select-category',
  inject: ['signature'],
  components: {
    Loading,
  },
  data() {
    return {
      categories: null,
      isLoading: false,
      chosenCategory: null,
    };
  },
  computed: {
    category() {
      return this.$store.getters['profileData/getCategoryListener'];
    }
  },
  methods: {
    getCommonCaptionByName,
    async getCategories() {
      this.isLoading = true;
      try {
        const categoryList = await getCategoriesApi();
        if (isArrayNotEmpty(categoryList)) {
          this.categories = categoryList;
          this.$nextTick(() => this.$refs.category_select.focus());
        } else {
          //sentry
          throw new Error();
        }
      } catch (e) {
        this.setCategorySettingClose();
        setNotification({
          message: this.$t(getCommonCaptionByName('error_fetchingCategories')),
        });
      } finally {
        this.isLoading = false;
      }
    },
    async setCategory(category) {
      this.isLoading = true;
      try {
        await setCategoryApi(category.id);
        this.$emit('set-category', category);
      } catch (e) {
        this.$emit('failed-setting-category');
        setNotification({
          message: this.$t(getUiItemCaption(this.signature, 'setCategoryError')),
        });
      } finally {
        this.isLoading = false;
      }
    },
    setCategorySettingClose() {
      this.$emit('failed-setting-category');
    },
  },
  created() {
    this.chosenCategory = this.category;
    this.getCategories();
  },
};
</script>

<style></style>
