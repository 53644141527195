/**
 * Функция для перевода ISO-даты в локальную дату
 *
 * @param {String} isoDateString Строка iso-даты формата `YYYY-MM-DD`
 * @param {String} locale Строка локализации [Подробнее тут]({@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat#using_locales})
 *
 * @returns {String} Возвращает строку с датой в зависимости от локали
 *
 * @example isoDateToLocale('2022-06-22') // '22.06.2022'
 * @example isoDateToLocale('2022-06-22', 'en-US') // '6/24/2022'
 */
export const isoToLocalDate = (isoDateString, locale = 'ru') => {
  const prepareDate = Date.parse(new Date(isoDateString));

  return Intl.DateTimeFormat(locale).format(prepareDate);
};
