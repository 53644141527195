<template>
  <aside
    :class="{ 'js-aside-mobile': true, hide: !active }"
    class="aside aside--mobile"
  >
<!--    <NavMenu />-->
    <nav-menu-additional />
    <mobSidebarBottom />
  </aside>
</template>

<script>
import { mapState } from 'vuex';
import NavMenu from '@/components/elkSidebar/NavMenu.vue';
import NavMenuAdditional from '@/components/elkSidebar/NavMenuAdditional.vue';
import mobSidebarBottom from '@/components/elkMobileSidebar/mobileSidebarBottom';

export default {
  name: 'elk-mob-sidebar',
  components: {
    NavMenu,
    NavMenuAdditional,
    mobSidebarBottom,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      active: 'menuActive',
    }),
  },
};
</script>
