import Axios from '@/utils/Elk-Axios';
import {GET_RECOMMENDED_PROGRAMS, FAVORITE_PROGRAM, GET_LEGAL_PROGRAMS} from '@/api';

export default {
  namespaced: true,
  state: {
    /** @type {Program[]} */
    recommendations: [],
    /** @type {Program[]} */
    favorites: [],
    /** @type {Program[]} */
    legalPrograms: [],
    isLoading: false,
    isInitFavorite: false,
    isInitRecommendations: false,
    favoriteCount: 0,
    metaFavorites: {}
  },

  getters: {
    isLoading(state) {
      return state.isLoading;
    },
    /** @returns {Program[]} */
    recommendations: state => () => state.recommendations,
    /** @returns {Program[]} */
    legalPrograms: state => () => state.legalPrograms,
    /** @returns {Program[]} */
    legalMeta: state => () => state.legalPrograms?.meta,
    /** @returns {Program[]} */
    favorites: state => () => state.favorites,
    /** @returns number */
    favoritesCount: state => state.favoriteCount ?? 0,
  },
  mutations: {
    setRecommendations(state, items) {
      state.recommendations = items ?? [];
    },
    setFavorites(state, items) {
      state.favorites = items ?? [];
    },
    setLegalPrograms(state, items) {
      state.legalPrograms = items ?? [];
    },
    setCountFavorite(state, value) {
      state.favoriteCount = value ?? 0;
    },
    setMetaFavorites(state, meta) {
      state.metaFavorites = meta;
    },
    loading(state, value) {
      state.isLoading = !!value ?? false;
    },
    setIitRecommended(state, value) {
      state.isInitRecommendations = value ?? false;
    },
    setInitFavorite(state, value) {
      state.isInitFavorite = value ?? false;
    }
  },
  actions: {
    async fetchRecommendations({commit}, params) {
      commit('loading', true);
      try {
        const response = await Axios.get(GET_RECOMMENDED_PROGRAMS, {params});
        commit('setRecommendations', response?.data?.data ?? []);
      } catch {
        // не удалось загрузить рекомендации
      } finally {
        commit('loading', false);
      }
    },
    async fetchLegalPrograms({commit}, params) {
      commit('loading', true);
      try {
        const response = await Axios.get(GET_LEGAL_PROGRAMS, {params});
        commit('setLegalPrograms', response?.data?.data ?? []);
      } catch {
        // не удалось загрузить рекомендации
      } finally {
        commit('loading', false);
      }
    },
    async fetchFavorites({commit}, params) {
      commit('loading', true);

      try {
        const response = await Axios.get(FAVORITE_PROGRAM, {params});
        commit('setFavorites', response.data?.data?.data ?? []);
        commit('setMetaFavorites', response.data?.data?.meta);
        commit('setCountFavorite', response.data?.data?.meta?.total ?? 0);
      } catch {
        // не удалось загрузить избранное
      } finally {
        commit('loading', false);
      }
    },
    async initRecommend({dispatch, commit, state}) {
      if (state.isInitRecommendations) {
        return;
      }
      commit('setIitRecommended', true);
      await dispatch('fetchRecommendations');
    },
    async initFavorite({dispatch, commit, state}) {
      if (state.isInitFavorite) {
        return;
      }
      commit('setInitFavorite', true);
      await dispatch('fetchFavorites');
    },
    async init({dispatch}) {
      await dispatch('initRecommend');
      await dispatch('initFavorite');
    }
  }
};
