<template>
  <nav class="nav-tabs">
    <ul v-if="items?.length">
      <li
        v-for="tab in items"
        :key="tab.value"
        class="nav-item"
      >
        <a
          :href="'#' + tab.value"
          class="nav-link__item"
          :class="{ active: isActive(tab.value)}"
          :style="{color: tab.color, borderBottom: isActive(tab.value) ? '2px solid ' + tab.color : 'none'}"
          @click.prevent="setActive(tab.value)"
        >
          {{ tab.label }}
          <span :style="{ color: '#fff', backgroundColor: tab.color}">
            {{ tab.count }}
          </span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'ProgramTabs',

  props: {
    selected: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },

  methods: {
    isActive (code) {
      return this.selected === code;
    },
    setActive (code) {
      this.$emit('select', code)
    },

  },
}
</script>

<style lang="scss">
 .nav-tabs{
   ul{
     li{
       a.nav-link__item{
         display: inline-block;
         padding-right: 27px;
         span{
           right: 0;
           border-radius: 10px;
         }
       }
     }
   }
 }
</style>
