<template>
  <div :class="{body: true, active: menuActive}">
    <menu-wrapper :page-name="name" />
    <div class="page-wrapper">
      <main
        class="content"
        :class="pageClass"
      >
        <div class="container">
          <div class="auth-component__section">
            <el-form
              ref="form"
              :model="form"
              :rules="rules"
              :label-position="labelPosition"
              class="dpo-corp__form"
            >
              <el-row class="register__step-one" style="display: none">
                <h2>Регистрация юридического лица</h2>
                <el-row>
                  <el-col :span="22">
                    <p class="subtitle-register">Данные представителя</p>
                  </el-col>
                  <el-col :span="2">
                  <div class="form__step-reg">
                    <div class="step__count">1</div>
                    <div class="step__count">/2</div>
                  </div>
                </el-col>
                </el-row>
                <el-form-item
                label="Фамилия"
                prop="lastname"
                class="el-form-item__corp"
              >
                <el-input
                  v-model="form.lastname"
                  class="el-input__corp"
                />
              </el-form-item>
                <el-form-item
                label="Имя"
                prop="firstname"
                class="el-form-item__corp"
              >
                <el-input
                  v-model="form.firstname"
                  class="el-input__corp"
                />
              </el-form-item>
                <el-form-item
                label="Отчество (при наличии)"
                class="el-form-item__corp"
              >
                <el-input
                  v-model="form.surname"
                  class="el-input__corp"
                />
              </el-form-item>
                <el-form-item
                  label="Должность"
                  prop="jobtitle"
                  class="el-form-item__corp"
                >
                  <el-input
                    v-model="form.jobtitle"
                    class="el-input__corp"
                  />
                </el-form-item>
                <el-row :gutter="24">
                <el-col :span="12">
                  <el-form-item
                    label="E-mail"
                    prop="email"
                    class="el-form-item__corp"
                  >
                    <el-input
                      v-model="form.email"
                      class="el-input__corp"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item
                    label="Телефон"
                    prop="phone"
                    class="el-form-item__corp"
                  >
                    <el-input
                      v-model="form.phone"
                      class="el-input__corp"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
                <el-form-item class="el-form-item__checkbox">
                <el-checkbox v-model="checked1">
                  Согласен с
                  <a
                    href="https://www.hse.ru/data_protection_regulation"
                    target="_blank"
                  >Положением о защите конфиденциальной информации</a>
                </el-checkbox>

                <el-checkbox v-model="checked2">
                  Ознакомился с документами, в том числе с
                  <a
                    href="https://busedu.hse.ru/official_docs"
                    target="_blank"
                  >
                    федеральными </a>,
                  <a
                    href="https://www.hse.ru/org/hse/aup/addedu/new_rules"
                    target="_blank"
                  >
                    локальными </a>,
                  <a
                    href="https://www.hse.ru/docs/175456374.html"
                    target="_blank"
                  >
                    уставом НИУ ВШЭ
                  </a>
                  <a
                    href="https://www.hse.ru/docs/206618260.html"
                    target="_blank">и лицензией НИУ ВШЭ
                  </a>
                </el-checkbox>

                <el-checkbox v-model="checked3">
                  Я подтверждаю, что лично ознакомился с Положением об обработке
                  персональных данных НИУ ВШЭ, вправе предоставлять свои
                  персональные данные и давать согласие на их обработку.
                  Предоставляя свои персональные данные, принимаю условия этого
                  Положения, подтверждаю что они относятся ко мне, являются
                  точными и актуальными, тем самым свободно, своей волей и в
                  своем интересе распоряжаюсь ими, осознаю последствия их
                  предоставления и выражаю свое согласие на их обработку в
                  соответствии с пунктом 3.7 Положения об обработке персональных
                  данных НИУ ВШЭ.
                </el-checkbox>
              </el-form-item>
              </el-row>
              <el-row class="register__step-two">
                <h2>Подтвердите контактные данные</h2>
                <el-row>
                  <el-col :span="22">
                    <p>Данные представителя</p>
                  </el-col>
                  <el-col :span="2">
                    <div class="form__step-reg">
                      <div class="step__count">1</div>
                      <div class="step__count">/2</div>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <div class="divider"></div>
                </el-row>
                <el-row>
                  <p>
                    На почту <a href="">Konstantinopolskii@gmail.com</a> было отправлено письмо с кодом.
                  </p>
                </el-row>
                <el-row :gutter="24">
                  <el-col :span="13">
                    <el-form-item
                      label="Код из письма *"
                    >
                      <el-input>
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="11">
                    <el-button class="btn btn-repeat">
                      Выслать код повторно
                    </el-button>
                  </el-col>
                </el-row>
              </el-row>
              <el-form-item
                class="dpo-form__btns"
                @click="submitForm('form')">
                <el-button
                  class="btn"
                  disabled
                >Далее</el-button>
                <a
                  href="/"
                  class="btn"
                >Авторизация</a>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </main>
    </div>
    <page-footer />
  </div>
</template>

<script>
import menuWrapper from '@/components/menuWrapper';
import PageFooter from '@/components/PageFooter';
import {mapState, mapGetters} from 'vuex';
export default {
  name: 'RegistrationCorpPage',
  components: {
    PageFooter,
    menuWrapper
  },
  props: {
    widgets: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      name: 'Регистрация юридического лица',
      pageClass: 'registration-page-wrapper',
      labelPosition: 'top',
      checked1: false,
      checked2: false,
      checked3: false,
      form: {
        lastname: '',
        firstname: '',
        surname: '',
        jobtitle: '',
        email: '',
        phone: '',
        code: '',
        message: ''
      },
      rules: {
        lastname: [
          {
            required: true, message: 'Пожалуйста заполните поле Фамилия', trigger: 'blur'
          }
        ],
        firstname: [
          {
            required: true, message: 'Пожалуйста заполните поле Имя', trigger: 'blur'
          }
        ],
        jobtitle: [
          {
            required: true, message: 'Пожалуйста заполните поле Должность', trigger: 'blur'
          }
        ],
        email: [
          {
            required: true, message: 'Пожалуйста заполните поле E-mail', trigger: 'blur'
          }
        ],
        phone: [
          {
            required: true, message: 'Пожалуйста заполните поле Телефон', trigger: 'blur'
          }
        ]
      }
    };
  },
  methods: {
    submitForm(form) {
      this.$ref[form].validate(( valid) => {
        if (valid) {
          alert('submite');
        } else {
          return false;
        }
      });
    }
  },
  computed: {
    ...mapState(['menuActive']),
    ...mapGetters(['isPublicUser'])
  }
};
</script>
<style lang="scss">
.el-form.dpo-corp__form {
  max-width: 776px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 100px;
  //padding: 40px 100px;
  h2 {
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    text-align: center;
    margin-bottom: 24px;
  }
  .subtitle-register {
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
  }
  .form__step-reg {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 24px;
    .step__count {
      font-weight: 400;
      line-height: 28px;
      font-style: normal;
    }
    .step__count:first-child {
      color: #0050cf;
    }
    .step__count:last-child {
      color: #6b7a99;
    }
  }
  .el-form-item.el-form-item__corp {
    margin-bottom: 24px;
    .el-form-item__label {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #6b7a99;
      padding: 0 0 8px;
    }
    .el-input__inner {
      height: 48px !important;
    }
  }
  .el-input__corp.el-input {
    .el-input__inner {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #0f0f14;
      padding: 0 12px;
    }
  }
  .el-form-item.el-form-item__checkbox {
    margin-bottom: 24px;
    .el-checkbox {
      white-space: pre-wrap;
      margin-right: 0;
      display: flex;
      margin-bottom: 14px;
    }
    .el-checkbox__label {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #0f0f14;
      padding-left: 8px;
      a {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        color: #0050cf;
      }
    }
    .el-checkbox__input.is-checked + .el-checkbox__label {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #0f0f14;
    }
    .el-checkbox__inner {
      width: 16px;
      height: 16px;
      border-radius: 4px;
      border: 1px solid #6b7a99;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #0050cf;
      border-color: #0050cf;
      &:after {
        height: 8px;
        left: 5px;
      }
    }
  }
  .dpo-form__btns {
    margin-bottom: 0;
    .el-form-item__content {
      .btn {
        height: auto;
        padding: 14px 0;
      }
      .btn:first-child {
        margin-bottom: 24px;
      }
      .btn.el-button:disabled {
        opacity: 0.2;
      }
    }
  }
  .el-form-item.el-form-item__corp.is-required {
    .el-form-item__label:before {
      content: unset;
    }
    .el-form-item__label:after {
      content: '*';
      margin-left: 4px;
    }
  }
  .el-form-item.el-form-item__corp.is-error.is-required {
    .el-form-item__label:after {
      color: #f56c6c;
    }
  }
  .divider {
    width: 100%;
    max-width: 100%;
    height: 1px;
    background: #D3D8E5;
    margin: 24px 0;
  }
  .register__step-two.el-row {
    p {
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
}
</style>
