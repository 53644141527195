import { createEvents } from 'ics'
import { intervalToDuration } from 'date-fns'

export default (arr = []
  // {
  //   start,
  //   end,
  //   title,
  //   date,
  //   description,
  //   location,
  //   url,
  //   nameOrganizer,
  //   categories
  // }
) => {
  const mapDateArr = (date, time) => {
    const tempDate = new Date(date)
    const result = []
    if (tempDate) {
      result.push(...[tempDate.getFullYear(), tempDate.getMonth() + 1, tempDate.getDate()])
      let timeMap = time.split(':').map((v) => +v)
      timeMap = timeMap.length > 2 ? timeMap.slice(0, 2) : timeMap
      result.push(...timeMap)
    }

    return result
  }

  const mapDuration = (date, startTime, endTime) => {
    const arrStart = mapDateArr(date, startTime)
    const arrEnd = mapDateArr(date, endTime || startTime)
    const dateStart = new Date(...arrStart)
    const dateEnd = new Date(...arrEnd)    
    if (dateStart && dateEnd) {
      const duration = intervalToDuration({
        start: dateStart,
        end: dateEnd
      })

      let result = {};

      for(const [key, value] of Object.entries(duration)) {
        if (value) {
          result[key] = value
        }
      }
      
      return result
    }
    return ''
  }

  const getEvent = (value) => {
    const tempStart = mapDateArr(value.date, value.start)
    const tempDuration = mapDuration(value.date, value.start, value.end)    
    if (tempStart.length) {      
      const result = {
        start: tempStart,
        startInputType: 'local',
        endInputType: 'local',
        startOutputType: 'local',
        endOutputType: 'local',
        duration: tempDuration,
        title: value.title ?? '',
        description: value.description ?? '',
        // url: value.url === '#' ? '' : value.url ?? '',
        categories: value.categories ?? [],        
        location: value.location ?? '',
      }

      if (value.email) {
        result.organizer = { name: value.nameOrganizer ?? '', email: '' }
      }

      return result
    }
    return null       
  }

  const getEvents = () => {
    const result = []    
    arr.forEach((e) => {
      const event = getEvent(e)      
      if (event) {
        result.push(event)
      }
    })

    return result
  }

  let events = []

  try {
    events = getEvents()
  } catch(e) {       
    // Не удалось получить события
  }
  
  
  if (!events.length) {
    return null 
  }  
  try {
    const evn = createEvents(events)?.value    
    return evn
  } catch(e) {      
    return null
  }
}