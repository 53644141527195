<template>
  <div class="schedule-of-events">
    <div class="schedule-box">
      <calendar
        v-model="value"
        :class="'vi-calendar'"
        :disabled-days-of-week="disabled"
        :format="format"
        :clear-button="clear"
        :placeholder="placeholder"
        :has-input="false"
        :pane="1"
        :on-day-click="onDayClick"
        :change-pane="onChangePane"
        :first-day-of-week="1"
      >
        <template v-for="(dey, index) in eventSchedule">
          <div
            :key="index"
            :slot="dey.date"
            class="vi-calendar__events"
          >
            <div
              v-for="event, indx in dey.events"
              :key="indx"
            >
              <template v-if="timeLine(dey.date, event.eventEnd)">
                <div class="vi-calendar__event-marker vi-calendar__event-marker--past-e"/>
              </template>
              <template v-else>
                <div
                  v-if="event.typeOfEvent == 'he'"
                  class="vi-calendar__event-marker vi-calendar__event-marker--hee"
                />
                <div
                  v-else-if="event.typeOfEvent == 'dpos'"
                  class="vi-calendar__event-marker vi-calendar__event-marker--bdpotp"
                />
                <div
                  v-else-if="event.typeOfEvent == 'pub'"
                  class="vi-calendar__event-marker vi-calendar__event-marker--public-e"
                />
                <div
                  v-else-if="event.typeOfEvent == 'dpo'"
                  class="vi-calendar__event-marker vi-calendar__event-marker--pdo-e"
                />
              </template>
            </div>
          </div>
        </template>
      </calendar>
      <div
        v-if="1"
        class="schedule-box__exporter schedule-box-exporter"
      >
        <span class="schedule-box-exporter__caption">Экспортировать в</span>
        <!--        <a-->
        <!--          class="schedule-box-exporter__link"-->
        <!--          href="#"-->
        <!--          @click.prevent="onExport(exportTypes.google)"-->
        <!--        >-->
        <img
          class="schedule-box-exporter__link"
          src="/img/tmp/icons/google-calendar-icon.svg"
          aria-hidden="true"
          alt="" @click="showModalExport('google')"
        >
        <!--        </a>-->
        <!--        <a-->
        <!--          class="schedule-box-exporter__link"-->
        <!--          href="#"-->
        <!--          @click.prevent="onExport(exportTypes.yandex)"-->
        <!--        >-->
        <img
          class="schedule-box-exporter__link"
          src="/img/tmp/icons/yandex-calendar-icon.svg"
          aria-hidden="true"
          alt=""
          @click="showModalExport('yandex')"
        >
        <!--        </a>-->
      </div>
      <ul class="schedule-box__description schedule-box-description">
        <li>
          <span class="schedule-box-description__marker schedule-box-description__marker--hee"/>
          <span class="schedule-box-description__caption">События высшего образования</span>
        </li>
        <li>
          <span class="schedule-box-description__marker schedule-box-description__marker--bdpotp"/>
          <span class="schedule-box-description__caption">Начало учебной программы ДПО</span>
        </li>
        <li>
          <span class="schedule-box-description__marker schedule-box-description__marker--public-e"/>
          <span class="schedule-box-description__caption">Общественные события</span>
        </li>
        <li>
          <span class="schedule-box-description__marker schedule-box-description__marker--pdo-e"/>
          <span class="schedule-box-description__caption">События ДПО</span>
        </li>
        <li>
          <span class="schedule-box-description__marker schedule-box-description__marker--past-e"/>
          <span class="schedule-box-description__caption">Прошедшие события</span>
        </li>
      </ul>
    </div>
    <schedule-today
      :current-day-schedule="currentDaySchedule"
      :class="{'schedule-today-classes--hide':currentDaySchedule.events.length == 0 }"
    />
    <div
      class="no-venent-box"
      :class="{'no-venent-box--hide': currentDaySchedule.events.length > 0}"
    >
      <img
        class="no-venent-box__img"
        src="/img/tmp/icons/telescope.svg"
        alt=""
        aria-hidden="true"
      >
      <span class="no-venent-box__caption">На эту дату нет запланированных программ</span>
      <span class="no-venent-box__descrip">
        Выберите другую дату, чтобы посмотреть расписание, или какой-то такой текст.
      </span>
    </div>
    <modal-calendar-sync ref="sync"/>
  </div>

</template>

<script>
import ScheduleToday from "@/components/schedule/ScheduleToday.vue" //расписаине занятий на день
import ModalCalendarSync from "@/components/schedule/CalendarSync.vue" //расписаине занятий на день
import Calendar from 'vue2-slot-calendar';
import getEventsIcs from '@/utils/getEventsIcs';
import {formatISO} from 'date-fns'

export default {
  name: 'ScheduleOfEvents',
  components: {
    'calendar': Calendar,
    'schedule-today': ScheduleToday,
    'modal-calendar-sync': ModalCalendarSync,

  },
  // props: {
  //     eventSchedule: Array
  // },
  data() {
    return {
      disabled: [],
      value: new Date(),
      format: "yyyy-MM-dd",
      clear: false,
      placeholder: "Start Date",
      panMonth: new Date().getMonth() + 1,
      panYear: new Date().getFullYear(),
      exportTypes: {
        google: 'google-calendar',
        yandex: 'ya-calendar',
      },
      //объект с мероприятиями конкретного дня
      currentDaySchedule: {
        date: '',
        events: []
      },
    }
  },
  computed: {
    getCurrentDate() {
      return Date.now();
    },
    eventSchedule() {
      return this.$store.state.schedule.schedule ?? [];
    }
  },
  beforeMount() {
    this.$store.dispatch('schedule/init')
  },
  mounted() {
    document.addEventListener('click', (e) => {
      let arrCell = document.querySelectorAll('.day-cell');
      arrCell.forEach(cell => {
        cell.classList.remove('selected-cell');
      });
      e.target.classList.add('selected-cell')
    });
    this.fillScheduleForSelectedDay(this.getToday());

    this.$nextTick(function () {
      let cellOfTheCurrentDay = document.querySelector('.datepicker-dateRange-item-active');
      cellOfTheCurrentDay.classList.add('selected-cell');
    });

    this.setCurrentDay()
  },
  methods: {
    showModalExport(type) {
      this.$refs.sync.show(type);
    },
    mapScheduleToEvents(arrSchedule = []) {
      const result = []
      arrSchedule.forEach((e) => {
        result.push(...e.events.map((ev) => ({
          start: ev.eventBegin ?? '',
          end: ev.eventEnd ?? '',
          title: ev.caption ?? '',
          date: e.date ?? '',
          description: '',
          location: ev.venue ? `${ev.venue}, ${ev.auditorium}` : ev.venue ?? '',
          url: ev.venueLink ?? '',
          nameOrganizer: ev.teacherName ?? '',
          categories: [
            ev.type, ev.direction
          ]
        })))
      })

      return result
    },
    getLinkIcs() {
      const ics = getEventsIcs(this.mapScheduleToEvents([this.currentDaySchedule]))
      const blob = new Blob([ics], {type: "text/plain;charset=utf-8"});
      const url = window.URL.createObjectURL(blob)
      return url
    },
    // функция возвращает сегодняшнюю дату
    getToday() {
      let today = new Date();
      let year = today.getFullYear();
      let month = today.getMonth() + 1;
      let dey = today.getDate();
      if (month < 10) {
        month = '0' + month;
      }
      if (dey < 10) {
        dey = '0' + dey;
      }
    },
    /**
     *
     * Функция проверяет закончилось ли мероприятие. Если да то вернет true, если нет, то false
     * @param {*} date - строка с датой в формате yyyy-MM-dd
     * @param {*} eventEnd - строка со временем окончания мероприятия в формате hh:mm
     */
    timeLine(date, eventEnd) {
      let currentTime = Date.now();
      // let computedDate = new Date('2023-04-12, 11:00:00.001');
      let computedDate = new Date(`${date}, ${eventEnd}:00.001`);
      computedDate = computedDate.getTime();
      if (currentTime > computedDate) {
        return true;
      } else {
        return false;
      }
    },
    /**
     * стандартная функция компонента vue-calendar вызывается при клике на ячейку с датой в календаре
     * @param {*} e  - строка с датой в формате yyyy-MM-dd
     */
    onDayClick(e) {
      let year = e.getFullYear();
      let month = Number(e.getMonth() + 1);
      let dey = Number(e.getDate());
      if (month < 10) {
        month = '0' + month;
      }
      if (dey < 10) {
        dey = '0' + dey;
      }
      let fullDate = `${year}-${month}-${dey}`;

      this.getToday();
      this.fillScheduleForSelectedDay(fullDate);
    },
    onChangePane(year, month) {
      this.panMonth = month + 1
      this.panYear = year
    },
    onExport(type) {
      const url = this.getLinkIcs()
      if (!url) {
        return
      }
      const a = document.createElement('a')
      // Passing the blob downloading url
      a.setAttribute('href', url)

      // Setting the anchor tag attribute for downloading
      // and passing the download file name
      a.setAttribute('download', `${type}.ics`);

      // Performing a download with click
      a.click()
    },
    /**
     * fillScheduleForSelectedDay - метод, который получая в аттрибуты строку с датой ищет дату в массиве eventSchedule, очищает объект currentDaySchedule и если в массиве eventSchedule есть совпадение по дате, то заполняет объект с мероприятиями конкретного дня currentDaySchedule
     * @param {*} selectedDate - строка с датой в формате yyyy-MM-dd
     */
    fillScheduleForSelectedDay(selectedDate) {
      this.currentDaySchedule.date = '';
      this.currentDaySchedule.events = [];

      this.eventSchedule.forEach((item, index) => {
        if (item.date == selectedDate) {
          this.currentDaySchedule.date = item.date;
          this.currentDaySchedule.events = item.events;
        }
      });
    },
    setCurrentDay() {
      const date = formatISO(new Date(), {representation: 'date'})
      const schedule = this.eventSchedule.find((e) => {
        return e.date === date
      })

      if (schedule) {
        this.currentDaySchedule.date = schedule.date
        this.currentDaySchedule.events = schedule.events
      }
    }
  }
}

</script>

<style lang="scss">
.schedule-box {
  position: relative;
  box-shadow: 0px 1px 2px rgba(106, 122, 152, 0.15);
  background: #ffffff;
  padding: 22px;
  border-radius: 4px;
  box-sizing: border-box;

  .vi-calendar {
    margin-bottom: 16px;
  }

  .schedule-box__description {
    border-top: 1px solid #EDEFF3;
  }
}

.vi-calendar {
  font-size: 14px;
  font-family: 'HSE sans';
  box-sizing: border-box;
  color: #0F0F14;
  position: relative;
  display: block;

  .datepicker-popup {
    border: none;
    border-radius: 4px;
    background: #ffffff;
    margin-top: 0;
    box-shadow: none;

  }

  .datepicker-wrapper {
    width: 100% !important;

    .datepicker-popup {
      &:first-child {
        // display: block !important;
      }

      &:nth-child(2) {

      }
    }
  }

  .datepicker-inner {
    width: 100%;
    float: none;
  }

  .datepicker-body {
    padding: 0;
    text-align: center;

    p {
      display: block;
      max-width: 127px;
      width: 100%;
      margin: 0;
      margin-left: 74px;
      padding-top: 7px;
      margin-bottom: 36px;
      box-sizing: border-box;
      text-align: justify;
      -moz-text-align-last: justify;
      text-align-last: justify;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.2;
      color: #6B7A99;
      cursor: pointer;
    }
  }

  .datepicker-weekRange {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 4px;
    border-top: 1px solid #EDEFF3;

    span {
      display: flex;
      align-items: center;
      padding: 6px 9px;
      width: 100%;
      text-align: left;
      font-size: 14px;
      line-height: 1;
      color: #6B7A99;
      font-weight: 600;
      box-sizing: content-box;
    }
  }

  .datepicker-dateRange-item-active {
    background-color: #ffffff !important;
    color: #0050CF !important;

    &.selected-cell {
      background-color: #F0F5FF !important;
    }
  }

  .datepicker-dateRange-item-active:hover {
    background-color: #F0F5FF !important;
  }

  .datepicker-monthRange span:hover, .datepicker-ctrl p:hover, .datepicker-ctrl i:hover, .datepicker-dateRange span:hover, .datepicker-dateRange-item-hover {
    background-color: #F0F5FF;
  }

  .datepicker-dateRange {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 4px;

    .day-cell {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-size: 20px;
      line-height: 1;
      width: 100%;
      min-height: 77px;
      border: 1px solid #F5F6FA;
      border-radius: 4px;
      padding: 5px;
      box-sizing: border-box;
      transition: all 200ms ease;

      &.selected-cell {
        background-color: #F0F5FF;
      }

      & > div {
        display: flex;
        flex-direction: column;

        div {
          order: -1;
        }

        .vi-calendar__events {
          display: flex;
          justify-content: center;
          left: 0;
          width: 100%;
          margin-bottom: 3px;
        }

        .vi-calendar__event-marker {
          width: 7px;
          height: 7px;
          border-radius: 50%;
          margin: 0 2px;
          margin-bottom: 2px;

          &--hee {
            background-color: #0050CF;
          }

          &--bdpotp {
            background-color: #E03A15;
          }

          &--public-e {
            background-color: #00B86E;
          }

          &--pdo-e {
            background-color: #E9A21D;
          }

          &--past-e {
            background-color: #D3D8E5;
          }
        }
      }
    }
  }

  .datepicker-ctrl {
    width: 277px;
  }

  .datepicker-preBtn,
  .datepicker-nextBtn {
    top: -3px;
    width: 32px;
    height: 32px;
    border-radius: 12px;
    background-color: #F8F9FB;
    transition: all 100ms ease;

    &::before {
      color: #0F0F14;
      font-size: 22px;
      line-height: 32px;
      text-align: center;
    }

    &:hover {
      background-color: #0050CF;

      &::before {
        color: #ffffff;
      }
    }
  }

  .datepicker-preBtn {
    left: 3px;
  }

}

.schedule-box__exporter {
  cursor: pointer;
  position: absolute;
  top: 26px;
  right: 24px;
}

.schedule-box-exporter {
  display: flex;
  // max-width: 198px;
  .schedule-box-exporter__caption {
    display: block;
    margin-right: 11px;
    font-size: #6B7A99;
    line-height: 1.5;
    color: #6B7A99;
  }

  .schedule-box-exporter__link {
    display: block;
    width: 24px;
    height: 24px;
    font-size: 0;
    background-size: contain;
    background-position: cenetr center;
    background-repeat: no-repeat;

    img {
      display: block;
      width: 100%;
      height: auto;
    }

    &:last-child {
      margin-left: 12px;
    }
  }
}

.schedule-box-description {
  display: flex;
  flex-wrap: wrap;
  padding-top: 24px;
  box-sizing: border-box;

  li {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 24px;
    }
  }

  .schedule-box-description__marker {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 8px;

    &--hee {
      background-color: #0050CF;
    }

    &--bdpotp {
      background-color: #E03A15;
    }

    &--public-e {
      background-color: #00B86E;
    }

    &--pdo-e {
      background-color: #E9A21D;
    }

    &--past-e {
      background-color: #D3D8E5;
    }
  }

  .schedule-box-description__caption {
    font-size: 16px;
    line-height: 1.25;
    color: #6B7A99;
  }
}


.no-venent-box {
  margin-top: 24px;
  background-color: #ffffff;
  padding: 47px 30px;
  border-radius: 4px;
  box-shadow: 0px 1px 2px rgba(106, 122, 152, 0.15);
  box-sizing: border-box;

  .no-venent-box__img {
    display: block;
    margin: 0 auto;
    width: 160px;
    height: auto;
  }

  .no-venent-box__caption {
    display: block;
    margin-top: 24px;
    font-size: 24px;
    line-height: 1.33;
    font-weight: 600;
    text-align: center;
  }

  .no-venent-box__descrip {
    display: block;
    margin-top: 5px;
    text-align: center;
    color: #6B7A99;
  }

  &--hide {
    display: none;
  }
}

.shedule-today {
  margin-top: 24px;
}


@media screen and (max-width: 1030px) {
  .vi-calendar {
    .datepicker-dateRange {
      .day-cell {
        font-size: 18px;
        width: 100%;
        min-height: 66px;
        padding: 5px;
      }
    }
  }
  .no-venent-box {
    .no-venent-box__img {
      width: 140px;
    }

    .no-venent-box__caption {
      margin-top: 24px;
      font-size: 22px;
    }

    .no-venent-box__descrip {
      margin-top: 10px;
    }
  }
}

@media screen and (max-width: 770px) {
  .vi-calendar {
    .datepicker-dateRange {
      .day-cell {
        font-size: 16px;
        min-height: 60px;
        padding: 5px;

        & > div .vi-calendar__event-marker {
          width: 5px;
          height: 5px;
          border-radius: 50%;
          margin: 0 1px;
          margin-bottom: 1px;
        }
      }
    }

    .datepicker-body {
      p {
        margin-bottom: 26px;
      }
    }
  }
  .no-venent-box,
  .shedule-today {
    margin-top: 16px;
  }
}

@media screen and (max-width: 600px) {
  .schedule-box-description {
    display: block;
    padding-top: 10px;

    .schedule-box-description__marker {
      width: 8px;
      height: 8px;
      margin-right: 5px;
    }

    .schedule-box-description__caption {
      font-size: 14px;
    }
  }
  .schedule-box {
    padding: 15px;
  }
  .schedule-box__exporter {
    position: absolute;
    top: 20px;
    right: 24px;
  }
  .vi-calendar {
    .datepicker-ctrl {
      width: 232px;
    }

    .datepicker-body {
      p {
        max-width: 115px;
        margin-left: 55px;
        padding-top: 10px;
        margin-bottom: 18px;
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 515px) {
  .schedule-box__exporter {
    position: relative;
    top: 0;
    right: 0;
    padding-top: 5px;
    font-size: 15px;

    .schedule-box-exporter__link:first-child {
      margin-left: auto;
    }
  }
  .schedule-box-description {
    display: block;
    padding-top: 5px;
  }
  .datepicker-popup:after {
    clear: both;
    height: 1px ba;
    height: 1px;
    background: #EDEFF3;
    display: block;
    position: relative;
    top: 11px;
  }
  .schedule-box {
    .schedule-box__description {
      border-top: none;
    }
  }
  .vi-calendar {
    .datepicker-body {
      p {
        margin: 0 auto;
        margin-bottom: 18px;
      }
    }

    .datepicker-weekRange {
      span {
        padding: 0;
        padding-left: 8px;
        font-size: 12px;
      }
    }

    .datepicker-ctrl {
      width: 100%;
    }

    .datepicker-dateRange {
      grid-gap: 2px;

      .day-cell {
        font-size: 12px;
        min-height: 42px;
        padding: 3px;

        & > div {
          .vi-calendar__event-marker {
            width: 4px;
            height: 4px;
          }
        }
      }
    }

  }
  .no-venent-box {
    padding: 32px 32px;

    .no-venent-box__caption {
      margin-top: 20px;
      font-size: 20px;
      line-height: 1.2;
    }
  }

}
</style>
