<template>
    <div class="quan-grid"> 
        <slot></slot>
    </div>
</template>

<script>
export default{
    name: 'QuanGrid', 
    data(){
        return{

        }
    }
}
</script>

<style lang="scss">
    .quan-grid{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 24px;
        &--one-col{
            grid-template-columns: repeat(1, 1fr) !important;
            grid-gap: 8px !important;
        }
    }

    @media screen and (max-width: 1100px){
       .quan-grid{
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 16px;
       }
    }
    @media screen and (max-width: 700px){
       .quan-grid{
            grid-template-columns: repeat(1, 1fr);
            grid-gap: 10px;
       }
    }
</style>