<template>
  <div class="page" id="elk-feedback">
    <div class="lock-order-container">
        <div class="page__title">
          <h2 class="center-text">Заполните профиль</h2>
        </div>
        <p class="err-404__text center-text u-m-t-20">
          Просмотр этой страницы требует заполненной информации <router-link class="link" :to="linkProfile">в профиле.</router-link>
        </p>
      </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import { PROFILE_ROUTE } from "@/router/routes";

export  default {
  name: 'ElkErrorOrderPagePublic',
  data(){
    return {
      linkProfile: PROFILE_ROUTE,
      name: 'Блокировка оформления'
    }
  },
  computed: {
    ...mapState(['menuActive']),
  }
}
</script>

<style scoped lang="scss">
  //.lock-order-container {
  //  min-height: 50vh;
  //  display: flex;
  //  align-items: center;
  //  flex-flow: column;
  //  justify-content: center;
  //}
</style>
