export function checkMobileViewTokenInterceptors(configAxios) {
  const webviewToken = localStorage.getItem('webview_access_token');
  const webviewTokenApp = localStorage.getItem('webview_app');
  const accessToken = localStorage.getItem('access_token');

  if (webviewToken && webviewTokenApp) {
    configAxios.headers['Mobile-Web-View'] = 'true';
    configAxios.headers['Mobile-Web-View-App'] = webviewTokenApp;

    if (accessToken === 'public') {
      localStorage.setItem('access_token', webviewToken);
      window.location.reload();
    }
  }

  return configAxios;
}

export function checkMobileViewTokenTimer() {
  let lastToken = localStorage.getItem('webview_access_token');
  let tokenWeb = localStorage.getItem('access_token');
  let timerId;

  function checkToken() {
    const currentToken = localStorage.getItem('webview_access_token');
    const webviewTokenApp = localStorage.getItem('webview_app');

    if (
      currentToken !== lastToken &&
      tokenWeb === 'public' &&
      webviewTokenApp
    ) {
      lastToken = currentToken;
      if (currentToken) {
        clearInterval(timerId);
        localStorage.setItem('access_token', currentToken);
        window.location.reload();
      }
    }
  }

  checkToken();

  if (!lastToken) {
    timerId = setInterval(checkToken, 1000);
  }
}
