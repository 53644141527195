<template>
  <div
    v-loading="isLoadData"
    class="payment-information-modal"
  >
    <div class="payment-information-modal__agreement">
      {{ localeText.agreement }} № {{ contractNum }}
    </div>
    <p
      v-if="description"
      class="payment-information-modal__description"
    >
      {{ description }}
    </p>
    <div v-if="!isLoadData">
      <div class="payment-information-modal__grid p-info-grid">
        <div
          v-if="cost"
          class="p-info-grid__item"
          :class="{'p-info-grid__item_long': !nextDate}"
        >
          <div class="p-info-grid__label">{{ localeText.contractCost }}</div>
          <div class="p-info-grid__value">{{ costLocale }} ₽</div>
        </div>
        <div
          v-if="nextDate"
          class="p-info-grid__item"
        >
          <div class="p-info-grid__label">{{ localeText.nextPaymentDate }}</div>
          <div class="p-info-grid__value">
            {{ nextDate }}
          </div>
        </div>
        <div
          v-if="totalPaid"
          class="p-info-grid__item"
        >
          <div class="p-info-grid__label">{{ localeText.totalPaid }}</div>
          <div class="p-info-grid__value">
            {{ totalPaid }}
          </div>
        </div>
        <div
          v-if="totalRemaining"
          class="p-info-grid__item"
        >
          <div class="p-info-grid__label">{{ localeText.residualAmount }}</div>
          <div class="p-info-grid__value">
            {{ totalRemaining }}
          </div>
        </div>
      </div>
      <div class="payment-information-modal__label">
        {{ localeText.paymentPeriod }}
      </div>
      <ul
        v-if="items.length"
        class="payment-information-modal__items p-info-items"
      >
        <li
          v-for="(item, index) in items"
          :key="index"
          class="p-info-items__item"
        >
          <div
            class="payment-information-modal__card p-info-card"
            :class="{'p-info-card_red': !item.isPaid}"
          >
            <div class="p-info-card__content">
              <div>
                <div class="p-info-card__label">{{ item.dateFormat }}</div>
                <div
                  v-if="item.isPartPaid"
                  class="p-info-card__value"
                >
                  {{ item.paid }} ₽ / {{ item.price.toLocaleString('ru') }} ₽
                </div>
                <div
                  v-else
                  class="p-info-card__value"
                >
                  {{ item.price.toLocaleString('ru') }} ₽
                </div>
              </div>
              <div>
                <div class="p-info-card__tag">
                  {{
                    item.isPaid
                      ? localeText.statusPaid
                      : item.isPartPaid
                      ? localeText.statusPartiallyPaid
                      : localeText.statusNotPaid
                  }}
                </div>
              </div>
            </div>
            <el-button
              v-show="payButton && !item.isPaid"
              class="p-info-card__button"
              @click="handlePayButton(contractId, item.id)"
            >
              {{ localeText.btnPay }}
            </el-button>
          </div>
        </li>
      </ul>
      <div v-else>
        <p class="payment-information-modal__empty">
          {{ localeText.noPaymentInfo }}
        </p>
      </div>
    </div>
    <div
      v-else
      class="payment-information-modal__loading"
    />
  </div>
</template>

<script>
import Axios from '@/utils/Elk-Axios';
import {V1_CONTRACT_PAYMENTS} from '@/api';
import {format, isAfter, isBefore} from 'date-fns';

export default {
  name: 'PaymentInformation',
  props: {
    contractId: {
      type: Number,
      default: 0
    },
    contractNum: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    cost: {
      type: String,
      default: ''
    },
    modalVisible: {
      type: Boolean,
      default: false
    },
    payButton: {
      type: Boolean,
      default: false
    },
    localization: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isLoadData: false,
      items: [],
      localeText: {
        contractCost: this.localization.widgetCaption('contractCost'),
        nextPaymentDate: this.localization.widgetCaption('nextPaymentDate'),
        totalPaid: this.localization.widgetCaption('totalPaid'),
        residualAmount: this.localization.widgetCaption('residualAmount'),
        noPaymentInfo: this.localization.widgetCaption('noPaymentInfo'),
        agreement: this.localization.commonCaption('agreement'),
        paymentPeriod: this.localization.widgetCaption('paymentPeriod'),
        btnPay: this.localization.widgetCaption('btnPay'),
        statusPaid: this.localization.widgetCaption('statusPaid'),
        statusPartiallyPaid: this.localization.widgetCaption(
          'statusPartiallyPaid'
        ),
        statusNotPaid: this.localization.widgetCaption('statusNotPaid')
      }
    };
  },
  computed: {
    costLocale() {
      return parseFloat(this.cost).toLocaleString('ru');
    },
    totalPaid() {
      let result = 0;
      if (this.items.length) {
        this.items.forEach(e => {
          result += e.paid || 0;
        });
      }
      return result ? `${result.toLocaleString('ru')} ₽` : '';
    },
    totalRemaining() {
      let paid = 0;
      let payment = 0;

      if (this.items.length) {
        this.items.forEach(e => {
          paid += e.paid ?? 0;
          payment += e.price ?? 0;
        });
      }
      return payment > paid ? `${(payment - paid).toLocaleString('ru')} ₽` : '';
    },
    nextDate() {
      return this.getNextDate(this.items);
    }
  },
  watch: {
    modalVisible(v) {
      if (v) {
        if (!this.isLoadData) {
          this.fetchData();
        }
      } else {
        this.items = [];
        this.activeItem = {};
      }
    }
  },
  mounted() {
    if (!this.isLoadData) {
      this.fetchData();
    }
  },
  methods: {
    async fetchData() {
      this.isLoadData = true;
      try {
        const response = await Axios.get(
          V1_CONTRACT_PAYMENTS + '?contract_id=' + this.contractId
        );
        this.items = this.mapItems(response.data.data);
      } catch {
        // Не удалось получить данные
      }
      this.isLoadData = false;
    },
    mapItem(item) {
      const tempPayment = Number.parseFloat(item?.payment);
      const tempPaid = Number.parseFloat(item?.paid);
      const tempDate = item?.date ? new Date(item?.date) : '';
      let tempIsPaid = false;
      if (tempPayment >= 0 && tempPaid >= 0) {
        tempIsPaid = tempPayment - tempPaid <= 0;
      }
      return {
        id: item.id,
        paid: tempPaid,
        price: tempPayment,
        dateFormat: tempDate ? format(tempDate, 'dd.MM.yyyy') : '',
        date: tempDate,
        isPaid: tempIsPaid,
        isPartPaid: !tempIsPaid && tempPaid > 0
      };
    },
    mapItems(items = []) {
      return items.map(this.mapItem);
    },
    getNextDate(items = []) {
      const currentDate = new Date();
      let dateResult = null;
      let findIndex = -1;
      for (let i = 0; i < items.length; i += 1) {
        const date = new Date(items[i].date);
        if (!items[i].isPaid && date) {
          let after = isAfter(date, currentDate);
          if (after) {
            let before = dateResult ? isBefore(date, dateResult) : true;
            if (before) {
              dateResult = date;
              findIndex = i;
            }
          }
        }
      }
      return findIndex >= 0 ? items[findIndex].dateFormat : '';
    },
    handlePayButton(contract, payId) {
      this.$emit('click-pay-btn', contract, payId);
    }
  }
};
</script>

<style lang="scss">
.payment-information-modal {
  color: $color-base-black;
  &__agreement {
    color: $primary-brand-1;
    font-weight: 600;
  }
  &__label {
    font-weight: 600;
  }
  &__agreement,
  &__description {
    font-size: 14px;
    line-height: 20px;
  }
  &__agreement,
  &__description,
  &__label {
    margin-bottom: 16px;
  }
  &__grid {
    margin-bottom: 24px;
  }
  &__empty {
    font-size: 14px;
    color: $color-gray-dusk;
  }
  &__loading {
    min-height: 200px;
  }
}
.p-info-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  &__item {
    min-width: calc(50% - 4px);
    flex: 1 1 auto;
    padding: 16px 24px;
    border-radius: 4px;
    background-color: $color-background-gray-2;
  }
  &__item_long {
    flex: 1 1 100%;
  }
  &__label {
    color: $color-gray-dusk;
    font-size: 14px;
    line-height: 20px;
  }
  &__value {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
  }
  &__label,
  &__value {
    margin-bottom: 8px;
  }
}

.p-info-items {
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-height: 320px;
  overflow-y: auto;
}

.p-info-card {
  padding: 16px 16px 24px 16px;
  background-color: $color-accent-dodger-5;

  &__content {
    display: flex;
    align-items: flex-start;
    gap: 8px;
  }
  &__content div:first-child {
    flex: 1 1 auto;
  }
  &__label {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
  }
  &__value {
    font-size: 20px;
    line-height: 28px;
    font-weight: 600;
  }

  &__tag {
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    color: $primary-light-1;
    border-radius: 4px;
    padding: 6px 8px;
    background-color: $color-accent-dodger-deepdark;
  }

  .p-info-card__button {
    margin-top: 24px;
  }

  &_red {
    background-color: $color-system-error-light;
  }
  &_red &__tag {
    background-color: $color-system-error;
  }
}

@media (max-width: 940px) {
  .p-info-items {
    max-height: initial;
  }
}

@media (max-width: 480px) {
  .p-info-card__content {
    flex-direction: column-reverse;
  }
}
</style>
