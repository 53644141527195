<template>
  <div class="input-profile-contact input-profile-contact--no-wrap">

      <el-autocomplete
        :id="id"
        v-model="chosenAddress.name"
        class="input-profile-contact__value"
        popper-class="input-profile-contact-popover"
        :fetch-suggestions="searchAddress"
        @select="selectAddress"
      />
    <loading v-if="isLoading" class="loader loader--input" />
    <div v-else class="input-profile-contact__btn d_flex">
      <button class="btn btn--secondary btn--icon" >
        <check class="edit-icon" @click="saveAddress"/>
      </button>
      <button
        class="btn btn--secondary btn--icon"
        @click="setAddressSettingClose"
      >
        <cross class="edit-icon" />
      </button>
    </div>
  </div>
</template>

<script>
import {getCommonCaptionByName} from '@/i18n/utils';
import Loading from '@/components/common/elkLoading';
import {getSuggestions} from '../api';
import Check from '@/assets/icons/check.svg?inline';
import Cross from '@/assets/icons/cross.svg?inline';
import setNotification from '@/utils/setNotification';

export default {
  name: 'elk-profile-info-select-register-address',
  inject: ['signature'],
  components: {
    Check, Cross,
    Loading,
  },
  props: {
    id: {type: String, required: true },
    address: Object,
  },
  data() {
    return {
      isLoading: false,
      chosenAddress: null,
      name: '',
      dadata: '',
    };
  },
  methods: {
    getCommonCaptionByName,
    setAddressSettingClose() {
      this.$emit('failed-setting-address');
    },
    async searchAddress(queryString, cb) {
      if (queryString) {
        await getSuggestions(queryString, cb, this.id);
      } else {
        cb([]);
      }
    },
    profileFillCheck() {
      this.$store.dispatch('profileFillCheck/getProfileFillCheck');
    },
    saveAddress() {
      if (!this.dadata.house) {
        setNotification({
          message: this.$t(getCommonCaptionByName('setAddressInfo'))
        });
        return;
      }
      this.$store.dispatch('profileData/updateRegisterAddress', { name: this.name, dadata: this.dadata})
        .then(setTimeout(this.profileFillCheck, 100));
      this.$emit('set-address');
    },
    selectAddress({ data , value}) {
      this.name   = value;
      this.dadata = data;
    },
  },
  created() {
    this.chosenAddress = this.address;
  },
};
</script>

<style lang="scss">
.d_flex {
  display: flex;
  gap: 5px;
}
.el-autocomplete-suggestion__list li {
  white-space: pre-line!important;
  line-height: normal!important;
}
.input-profile-contact.input-profile-contact--no-wrap {
  .el-autocomplete.input-profile-contact__value {
    width: 100%;
  }
}
.input-profile-contact-popover {
  width: fit-content;
}
</style>
