<template>
  <div>
    <footer
      v-if="userHasAccessFooter"
      class="footer"
    >
      <div class="container">
        <ElkLoader
          v-if="isLoading"
          class="primary-light-1-fill"
        />
        <ErrorMessage
          v-else-if="isError"
          without-img
        />
        <EmptyDataMessage
          v-else-if="!groupLinks?.length"
          without-img
        />
        <template v-else>
          <el-collapse
            v-if="isMobile"
            class="footer__wrapper"
            accordion
          >
            <el-collapse-item
              v-for="(linksBlock, id) in groupLinks"
              :key="id"
              class="accordion__block"
            >
              <div
                slot="title"
                class="footer__title"
              >
                <a
                  v-if="linksBlock.group_link"
                  class="link"
                  :href="linksBlock.group_link"
                  target="_blank"
                >
                  {{ linksBlock.group_name }}
                </a>
                <template v-else>
                  {{ linksBlock.group_name }}
                </template>
              </div>

              <a
                v-for="(link, idLink) in linksBlock.links"
                :key="idLink"
                class="footer__list-link"
                :href="link.url"
                target="_blank"
              >
                {{ link.name }}
              </a>
            </el-collapse-item>
          </el-collapse>
          <div
            v-else
            class="footer__wrapper"
          >
            <div
              v-for="(linksBlock, id) in groupLinks"
              :key="id"
              class="footer__item"
            >
              <div class="footer__title">
                <a
                  v-if="linksBlock.group_link"
                  class="link"
                  :href="linksBlock.group_link"
                  target="_blank"
                >
                  {{ linksBlock.group_name }}
                </a>
                <template v-else>
                  {{ linksBlock.group_name }}
                </template>
              </div>
              <ul class="footer__list">
                <li
                  v-for="(link, idLink) in linksBlock.links"
                  :key="idLink"
                  class="footer__list-item"
                >
                  <a
                    class="footer__list-link"
                    :href="link.url"
                    target="_blank"
                  >
                    {{ link.name }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </template>
        <div class="footer__bottom">
          <ul
            v-if="false"
            class="footer__media"
          >
            <li class="footer__media-item">
              <a
                class="link link--external link--icon"
                href="#"
                target="_blank"
              >
                <FacebookIcon />
              </a>
            </li>
            <li class="footer__media-item">
              <a
                class="link link--external link--icon"
                href="#"
                target="_blank"
              >
                <InstagramIcon />
              </a>
            </li>
            <li class="footer__media-item">
              <a
                class="link link--external link--icon"
                href="#"
                target="_blank"
              >
                <VkIcon />
              </a>
            </li>
            <li class="footer__media-item">
              <a
                class="link link--external link--icon"
                href="#"
                target="_blank"
              >
                <VimeoIcon />
              </a>
            </li>
            <li class="footer__media-item">
              <a
                class="link link--external link--icon"
                href="#"
                target="_blank"
              >
                <YoutubeIcon />
              </a>
            </li>
          </ul>

          <div class="footer__copyright">
            {{ $t(getCommonCaptionByName('copyright')) }} {{ version }}
          </div>
        </div>
      </div>
    </footer>
    <FooterDpo v-else-if="showDpoFooter" />
  </div>
</template>

<script setup>
import {GET_FOOTER} from '@/api';
import {getCommonCaptionByName} from '@/i18n/utils';
import EmptyDataMessage from '@/components/common/elkEmptyDataMessage';
import ElkLoader from '@/components/common/elkLoading';
import FacebookIcon from '@/assets/icons/media/facebook.svg?inline';
import InstagramIcon from '@/assets/icons/media/instagram.svg?inline';
import YoutubeIcon from '@/assets/icons/media/youtube.svg?inline';
import VkIcon from '@/assets/icons/media/vk.svg?inline';
import VimeoIcon from '@/assets/icons/media/vimeo.svg?inline';
import ErrorMessage from '@/components/common/elkErrorMessage';
import {HOME_ROUTE} from '@/router/routes';
import store from '@/store';
import {computed, onMounted, ref} from 'vue';
import FooterDpo from '@/components/Footer/FooterDpo.vue';

const URL_FOOTER_SIGNATURE = 'elk.footer.widget';
const isLoading = ref(false);
const isError = ref(false);

const groupLinks = computed(() => store.state.footer.groupLinks);
const version = computed(() => store.state.version);
const isMobile = computed(() => store.state.width < 480);
const userHasAccessFooter = computed(() => {
  const homePage = store.state.user.pageTree.find(
    page => page.url === HOME_ROUTE
  );
  return !!homePage?.widgets.some(
    widget => widget.signature === URL_FOOTER_SIGNATURE
  );
});

const userRoles = computed(() => store.getters.userRoles);
const userRolesLength = computed(() => userRoles.value.length);

const showDpoFooter = computed(() => {
  const dpoRoles = [
    ['dpo'],
    ['dpo', 'represent'],
    ['dpo', 'represent', 'company_admin']
  ];

  const isUserInDpoRole = dpoRoles.some(roles => {
    return roles.every(
      role =>
        userRoles.value.includes(role) && roles.length === userRolesLength.value
    );
  });

  return !userHasAccessFooter.value && isUserInDpoRole;
});

onMounted(() => {
  if (!groupLinks.value) fetchData();
});

async function getFooter() {
  await store.dispatch(`footer/${GET_FOOTER}`);
}

async function fetchData() {
  isLoading.value = true;
  try {
    await getFooter();
  } catch (e) {
    isError.value = true;
  } finally {
    isLoading.value = false;
  }
}
</script>
