import { AUTHORIZATION } from "../routes";
import store from '@/store/index.js';

export default async (_, __, next) => {
  const tempRedirect = {
    path: AUTHORIZATION
  };

  if (!store.getters['isAuth']) {
    next(tempRedirect)
  } else {
    next()
  }

};

