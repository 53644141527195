<template>
  <div class="security-elements">
    <a
      href="#"
      class="tag-security security__push"
    >
      <p>
        Отписаться от push-уведомлений
      </p>
      <span>
        Пояснительный текст
      </span>
    </a>
    <a
      href="#"
      class="tag-security"
    >
      <p>
        Сменить пароль
      </p>
      <span>
        Пояснительный текст
      </span>
    </a>
    <a
      href="#"
      class="tag-security"
    >
      <p>
        Сменить номер ПЭП телефона
      </p>
      <span>
        Пояснительный текст
      </span>
    </a>
    <a
      href="#"
      class="tag-security"
    >
      <p>
        Сменить номер потерянного ПЭП телефона
      </p>
      <span>
        Пояснительный текст
      </span>
    </a>
  </div>
</template>

<script>
export default {
  name: "securityItem"
}
</script>

<style lang="scss">
.security-elements{
  display: grid;
  gap: 48px;
  grid-template-columns: auto auto auto;
}
.tag-security{
  position: relative;
  margin-left: 76px;
  p{
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 8px;
  }
  span{
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }
  p,span{
    color: $base-black-color;
  }
  &:before{
    content: '';
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 12px;
    background-color: #EDEFF3;
    position: absolute;
    top:-3px;
    left: -76px;
    background-image: url("../../assets/icons/icon-change-pass.svg");
    background-repeat: no-repeat;
    background-position: center center;
  }
}
.tag-security.security__push:before{
  background-image: url("../../assets/icons/icon-push.svg");
}

@media (max-width: 1199px) {
  .security-elements{
    grid-template-columns: auto auto;
  }
}

@media (max-width: 530px) {
  .wrapper-security{
    padding: 16px;
    .security-elements{
      grid-template-columns: 1fr;
    }
  }
}

</style>
