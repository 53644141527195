<template>
  <div
    :class="{
      inputWrap: true,
      'error-system': hasError
    }"
  >
    <span
      v-if="label"
      class="demonstration"
    >
      {{ label }}
    </span>
    <el-date-picker
      :id="id"
      v-model="value.$model"
      format="dd.MM.yyyy"
      :name="id"
      :placeholder="placeholder"
      :disabled="disabled"
      type="date"
      class="date__picker-default"
      @input.native="handleDateByMask"
    />
  </div>
</template>

<script>
import {getCommonCaptionByName} from '@/i18n/utils';

export default {
  name: 'InputDatePass',

  props: {
    label: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: () => ({$model: ''})
    }
  },

  computed: {
    hasError() {
      return this.value?.$error || (this.value?.$dirty && this.value?.$invalid);
    }
  },

  methods: {
    onBlur(event) {
      this.$emit('blur', event.target.value);
    },
    maskit(
      value,
      mask = '##.##.####',
      tokens = {
        '#': {pattern: /\d/},
        X: {pattern: /[0-9a-zA-Z]/},
        S: {pattern: /[a-zA-Z]/},
        A: {pattern: /[a-zA-Z]/, transform: v => v.toLocaleUpperCase()},
        a: {pattern: /[a-zA-Z]/, transform: v => v.toLocaleLowerCase()},
        '!': {escape: true}
      }
    ) {
      value = value || '';
      mask = mask || '';
      let iMask = 0;
      let iValue = 0;
      let output = '';
      while (iMask < mask.length && iValue < value.length) {
        let cMask = mask[iMask];
        let masker = tokens[cMask];
        let cValue = value[iValue];
        if (masker && !masker.escape) {
          if (masker.pattern.test(cValue)) {
            output += masker.transform ? masker.transform(cValue) : cValue;
            iMask++;
          }
          iValue++;
        } else {
          if (masker && masker.escape) {
            iMask++; // take the next mask char and treat it as char
            cMask = mask[iMask];
          }
          output += cMask;
          if (cValue === cMask) iValue++; // user typed the same char
          iMask++;
        }
      }

      // fix mask that ends with a char: (#)
      let restOutput = '';
      while (iMask < mask.length) {
        // eslint-disable-next-line no-redeclare
        let cMask = mask[iMask];
        if (tokens[cMask]) {
          restOutput = '';
          break;
        }
        restOutput += cMask;
        iMask++;
      }

      return output + restOutput;
    },
    handleDateByMask(e) {
      e.target.value = this.maskit(
        e.target.value,
        this.$t(getCommonCaptionByName('dateFormatForDatePicker'))
      );
    }
  }
};
</script>

<style lang="scss">
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.inputWrap {
  .demonstration {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6b7a99;
    margin-bottom: 8px;
  }
  .date__picker-default {
    .el-input__inner {
      border: 1px solid #d3d8e6;
      height: 48px;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #0f0f14;
      &::placeholder {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #0f0f14;
      }
    }
    .el-input__prefix {
      .el-input__icon.el-icon-date {
        &:before {
          content: '';
          display: block;
          width: 24px;
          height: 48px;
          background: url('@/assets/icons/icon-small-calendar.svg') center
            center no-repeat;
        }
      }
    }
  }
}
@media (max-width: 530px) {
  .inputWrap {
    .el-date-editor.date__picker-default {
      input {
        font-size: 14px;
        line-height: 20px;
        &::placeholder {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}

.inputWrap.error-system .date__picker-default .el-input__inner {
  border: 1px solid $error-system-red;
}
</style>
