<template>
  <div class="opts-container">
    <div class="opts-container__header">
      <slot name="header" />
    </div>
    <simplebar class="opts-container__simplebar">
      <slot />
    </simplebar>
  </div>
</template>

<script>
import simplebar from 'simplebar-vue';

export default {
  name: 'OptionsContainer',
  components: {simplebar}
};
</script>

<style scoped>
.opts-container {
}
.opts-container__simplebar {
  padding: 12px;
  max-height: 280px;
}
</style>
