import Vue from 'vue';
import VueLazyload from 'vue-lazyload';

// Vue.use(VueLazyload);

// const loadimage = require('./assets/loading.gif');
// const errorimage = require('./assets/error.gif');

// import loader from '@/assets/icons/loader.svg?inline';

Vue.use(VueLazyload, {
  preLoad: 1.3,
  // error: require('@/assets/icons/loading.svg'),
  // loading: require('@/assets/icons/loading.svg'),
  attempt: 5
});
