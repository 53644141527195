<template>
  <div>
    <template v-for="(item, idx) in parsedText">
      <template v-if="typeof item === 'string'">{{ item }}</template>
      <a
        v-else
        class="link"
        :href="item.url"
        :key="idx"
        target="_blank"
        rel="noopener noreferrer"
        >{{ item.text }}</a
      >
    </template>
  </div>
</template>

<script>
export default {
  name: 'asav-markdown-text',
  props: ['text'],
  computed: {
    parsedText() {
      const result = this.text.split(/(\[.*?\]\(.*?\))/g);
      return result.map((item) => {
        if (/(\[.*?\]\(.*?\))/.test(item)) {
          const res = item.match(/\[(.*?)\]\((.*?)\)/);
          return {
            text: res[1],
            url: res[2],
          };
        }
        return item;
      });
    },
  },
};
</script>
