<template>
  <div class="col-search">
    <div class="col-search-head">
      <div class="inputWrap">
        <label for="search">
          <input
            id="search"
            class="input-form__auth"
            type="text"
            name="search"
            :placeholder="localeText.filterFindProgram + '...'"
            @input="searchInputHandler"
          />
        </label>
        <button
          class="btn-search"
          :title="localeText.filterFindProgram"
        />
      </div>
    </div>
    <div class="filter-field">
      <div class="filter-program__header">
        <button
          class="arrow-btn-back"
          @click="heightFilter"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.353 6.304 9.28 6.22a.75.75 0 0 0-.976-.073l-.084.073-4.997 4.997a.75.75 0 0 0-.073.976l.073.085L8.22 17.28a.75.75 0 0 0 1.133-.976l-.072-.084-3.711-3.717h14.68a.75.75 0 0 0 .743-.647l.007-.102a.75.75 0 0 0-.648-.743l-.102-.007-14.693-.001L9.281 7.28a.75.75 0 0 0 .072-.976L9.28 6.22l.073.084Z"
              fill="#0F0F14"
            />
          </svg>
          {{ localeText.backBtn }}
        </button>
      </div>
      <div class="filter-program__main">
        <div class="col-search-body">
          <div class="inputWrap">
            <label for="search">
              <input
                id="search"
                class="input-form__auth"
                type="text"
                name="search"
                :placeholder="localeText.filterFindProgram + '...'"
                @input="searchInputHandler"
              />
            </label>
            <button
              class="btn-search"
              :title="localeText.filterFindProgram"
            />
          </div>
          <div class="wrapper-select">
            <multiselect
              id="direction"
              v-model="selectedDirections"
              class="vi-multiselect"
              :options="directions"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :placeholder="localeText.filterDirections"
              label="label"
              track-by="label"
              :preselect-first="false"
              @input="directionHandler"
            >
              <template
                slot="selection"
                slot-scope="{isOpen}"
              >
                <span
                  v-show="!isOpen"
                  class="multiselect__single"
                >
                  {{ localeText.filterDirections }}
                  <span v-if="selectedDirections.length > 0">
                    ({{ selectedDirections.length }})
                  </span>
                </span>
              </template>
            </multiselect>
          </div>
          <div class="wrapper-select">
            <multiselect
              id="type"
              v-model="selectedTypes"
              class="vi-multiselect"
              :options="types"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :placeholder="localeText.filterProgramType"
              label="label"
              track-by="label"
              :preselect-first="false"
              @input="typeHandler"
            >
              <template
                slot="selection"
                slot-scope="{isOpen}"
              >
                <span
                  v-show="!isOpen"
                  class="multiselect__single"
                >
                  {{ localeText.filterProgramType }}
                  <span v-if="selectedTypes.length > 0">
                    ({{ selectedTypes.length }})
                  </span>
                </span>
              </template>
            </multiselect>
          </div>
          <div class="wrapper-select">
            <multiselect
              id="year"
              v-model="selectedYears"
              class="vi-multiselect"
              :options="years"
              :multiple="true"
              :close-on-select="false"
              :clear-on-select="false"
              :preserve-search="true"
              :placeholder="localeText.filterYear"
              label="label"
              track-by="label"
              :preselect-first="false"
              @input="yearHandler"
            >
              <template
                slot="selection"
                slot-scope="{isOpen}"
              >
                <span
                  v-show="!isOpen"
                  class="multiselect__single"
                >
                  {{ localeText.filterYear }}
                  <span v-if="selectedYears.length > 0">
                    ({{ selectedYears.length }})
                  </span>
                </span>
              </template>
            </multiselect>
          </div>
        </div>
      </div>
      <div class="filter-program__footer">
        <button
          class="vi-btn vi-btn--hollow"
          @click="onClearFilter"
        >
          {{ localeText.clearMobileBtn }}
        </button>
        <button
          class="vi-btn"
          @click="heightFilter"
        >
          {{ localeText.doneMobileBtn }}
        </button>
      </div>
    </div>

    <button
      class="vi-btn vi-btn-filter"
      type="button"
      @click="showFilter"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.25 11.25H3V12.75H14.25V11.25ZM16.5 9.75H15V14.25H16.5V12.75H21V11.25H16.5V9.75ZM7.5 8.25H9V3.75H7.5V5.25H3V6.75H7.5V8.25ZM21 5.25H9.75V6.75H21V5.25ZM7.5 17.25H3V18.75H7.5V20.25H9V15.75H7.5V17.25ZM21 17.25H9.75V18.75H21V17.25Z"
          fill="white"
        />
      </svg>
      {{ localeText.filterLabel }}
    </button>
    <!--
    <button
      type="button"
      class="selectoin-poin-box-toggle-slide-btn"
      @click.prevent="toggleSlider('.col-search-body')"
    >
      <span class="electoin-poin-box-toggle-slide-btn__caption-collapse">свернуть</span>
      <span class="electoin-poin-box-toggle-slide-btn__caption-expand">развернуть</span>
      <chevron-up-icon
        :width="8"
        :height="6"
        fill="#6B7A99"
      />
    </button> -->
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  name: 'SearchFilterDocument',
  components: {
    Multiselect: Multiselect
  },
  props: {
    filter: Object,
    localization: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      selectedDirections: [],
      selectedTypes: [],
      selectedYears: []
    };
  },
  computed: {
    localeText() {
      return {
        filterFindProgram: this.localization.commonCaption('filterFindProgram'),
        filterYear: this.localization.commonCaption('filterYear'),
        filterProgramType: this.localization.commonCaption('filterProgramType'),
        filterDirections: this.localization.commonCaption('filterDirections'),
        filterLabel: this.localization.commonCaption('filterLabel'),
        doneMobileBtn: this.localization.commonCaption('doneBtn'),
        clearMobileBtn: this.localization.commonCaption('clear'),
        backBtn: this.localization.commonCaption('back')
      };
    },
    directions() {
      return (
        this.filter?.direction?.map(e => ({...e, selectName: 'direction'})) ??
        []
      );
    },
    types() {
      return this.filter?.type?.map(e => ({...e, selectName: 'type'})) ?? [];
    },
    years() {
      return Array.isArray(this.filter?.year)
        ? this.filter.year.map(e => ({
            selectName: 'year',
            value: e,
            label: e.toString()
          }))
        : [];
    }
  },
  methods: {
    showFilter() {
      let filter = document.querySelector('.filter-field');
      if (filter) {
        filter.classList.add('filter-field--show');
      }
    },
    heightFilter() {
      let filter = document.querySelector('.filter-field');
      if (filter) {
        filter.classList.remove('filter-field--show');
      }
    },
    directionHandler(event) {
      this.selectedDirections = event;
      this.$emit(
        'selectDirectionIds',
        this.selectedDirections.map(e => e.value)
      );
    },
    typeHandler(event) {
      this.selectedTypes = event;
      this.$emit(
        'selectTypeIds',
        this.selectedTypes.map(e => e.value)
      );
    },
    yearHandler(event) {
      this.selectedYears = event;
      this.$emit(
        'selectYearValues',
        this.selectedYears.map(e => e.value)
      );
    },
    searchInputHandler(event) {
      this.$emit('inputSearch', event.target.value);
    },
    onClearFilter() {
      this.directionHandler([]);
      this.typeHandler([]);
      this.yearHandler([]);
    }
  }
};
</script>

<style lang="scss">
.filter-field {
  .filter-program__header {
    display: none;
  }
  .filter-program__main {
  }
  .filter-program__footer {
    display: none;
  }
}

.content.programs-page {
  .search-wrapper-component {
    .col-search {
      display: block;
      .col-search-head {
        display: none;
      }
      .col-search-body {
        position: relative;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 24px;
        transition: all 200ms ease;
      }
      .vi-btn-filter {
        display: none;
      }
      .wrapper-select {
        margin-right: 0;
        width: 100%;
      }
      .inputWrap {
        margin: 0 0 24px 0;
        width: 100%;
      }
    }
  }
}
@media screen and (max-width: 1200px) {
  .content.programs-page {
    .search-wrapper-component {
      .col-search {
        padding-bottom: 25px;
        .col-search-body {
          display: grid;
          grid-template-columns: repeat(6, 1fr);
          gap: 20px;
          .inputWrap {
            grid-column: 1/7;
            margin: 0;
          }
          .wrapper-select {
            margin-bottom: 0;
            &:nth-of-type(2) {
              grid-column: 1/3;
            }
            &:nth-of-type(3) {
              grid-column: 3/5;
            }
            &:nth-of-type(4) {
              grid-column: 5/7;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 900px) {
  .content.programs-page {
    .search-wrapper-component {
      .col-search {
        .col-search-body {
          display: grid;
          grid-template-columns: repeat(6, 1fr);
          gap: 20px;
          .inputWrap {
            grid-column: 1/7;
            margin: 0;
          }
          .wrapper-select {
            &:nth-of-type(2) {
              grid-column: 1/7;
            }
            &:nth-of-type(3) {
              grid-column: 1/4;
            }
            &:nth-of-type(4) {
              grid-column: 4/7;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 550px) {
  .filter-field {
    position: fixed;
    top: 0;
    right: -1000px;
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    background-color: #eeeff4;
    padding: 16px;
    box-sizing: border-box;
    z-index: 600;
    transition: all 200ms ease;
    &--show {
      right: 0;
    }
    .filter-program__header {
      display: block;
      margin-bottom: 16px;
    }
    .filter-program__main {
    }
    .filter-program__footer {
      margin-top: auto;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
      padding-top: 16px;
    }
  }
  .content.programs-page {
    .search-wrapper-component {
      .col-search {
        .col-search-head {
          width: 100%;
          display: block;
          .inputWrap {
            margin-bottom: 20px;
          }
        }
        .col-search-body {
          width: 100%;
          margin-bottom: 0;
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          box-sizing: border-box;
          .inputWrap {
          }
        }
        .vi-btn-filter {
          display: flex;
          width: 100%;
          // margin-top: 20px;
        }
      }
    }
  }
}
</style>
