<template>
  <el-date-picker
    v-model="dates"
    type="daterange"
    :start-placeholder="startPlaceholder"
    :end-placeholder="endPlaceholder"
    range-separator=""
    value-format="yyyy-MM-dd"
    :format="$t(getCommonCaptionByName('date_format'))"
    @change="searchByDate"
  />
</template>

<script>
import {getCommonCaptionByName} from '@/i18n/utils';
import {filterBus} from '@/main';
import moment from 'moment/moment';

export default {
  name: 'PeriodRange',
  components: {},
  props: {
    param: {
      type: String,
      default: 'false'
    },
    startPlaceholder: {
      type: String,
      default: 'Старт программ с'
    },
    endPlaceholder: {
      type: String,
      default: 'по'
    }
  },
  data() {
    return {
      dates: false,
      label: this.startPlaceholder
    };
  },
  mounted() {
    filterBus.$on('removeFilter', value => {
      if (value.includes(this.label)) {
        this.dates = false;
      }
    });
  },
  methods: {
    getCommonCaptionByName,
    searchByDate() {
      if (this.dates || this.dates === null) {
        filterBus.$emit('dateRange');
      }
    },
    toParam() {
      return {
        ['date_start[from]']: this.dates ? this.dates[0] : null,
        ['date_start[to]']:   this.dates ? this.dates[1] : null
      }
    },
    toLabel() {
      if (this.dates && this.dates[0] && this.dates[1]) {
        return [
          this.label +
            ' ' +
            moment(this.dates[0], 'YYYY-MM-DD').format('DD.MM.YYYY') +
            ` ${this.endPlaceholder} ` +
            moment(this.dates[1], 'YYYY-MM-DD').format('DD.MM.YYYY')
        ];
      }
      return false;
    }
  }
};
</script>

<style scoped>
.el-date-editor--daterange.el-input__inner {
  width: 100% !important;
  min-height: 48px;
}
</style>
