<template>
  <div
    class="switch"
    :class="{'switch-active': switchValue}"
  >
    <label>
      <input
        id="fresh-input"
        type="checkbox"
        name="fresh"
        :checked="switchValue"
        @input="inputHandler"
      />
      <span class="switch__elem" />
    </label>
    <label
      class="switch__label"
      for="fresh-input"
    >{{ label }}</label
    >
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
    },
    param: {
      type: String,
    }
  },
  data() {
    return {
      innerValue: this.value,
      switchValue: this.value,
    };
  },
  methods: {
    inputHandler(event) {
      this.switchValue = event.target.checked;
      this.$emit('input', event.target.checked);
    },
    toParam() {
      return {
        [this.param]: Number(this.switchValue),
      };
    },
    toLabel() {
      return false;
    }
  }
};
</script>
<style lang="scss" scoped>
.switch {
  display: flex;
  align-items: center;

  span.switch__label {
    color: #0f0f14;
  }

  label.switch__label {
    color: #6b7a99;
    cursor: pointer;
  }

  .switch__elem {
    order: 2;
    position: relative;
    display: block;
    margin: 0 16px;
    width: 56px;
    height: 28px;
    background-color: $border-color;
    border-radius: 14px;
    padding: 6px;
    box-sizing: border-box;
    transition: all 200ms ease;
    cursor: pointer;

    &::before {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: #ffffff;
      transform: translateX(0);
      transition: all 200ms ease;
    }
  }

  &.switch-active .switch__elem {
    background-color: $blue-color;
  }

}
</style>
