const PASSWORD = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}/g;
const PHONE = /^[^a-zа-яё]+$/;
const EMAIL = /.+@.+\..+/i;
const CONFIRMATION_CODE = /^\d{4}/;
const INTERNAL_EMPLOYEE_EMAIL = '@hse.ru';
const INTERNAL_STUDENT_EMAIL = '@edu.hse.ru';
const PASSPORT_SERIES_CODE = /^\d{6}/;
const FRONTEND_VERSION = /^\d{1,2}.\d{1,2}.\d{1,2}$/;
const PHONE_TEST_CODE = [
  '+',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

const checkPassword = (text) => PASSWORD.test(text);
const checkPhone = (text) => PHONE.test(text);
const checkEmail = (text) => EMAIL.test(text);
const checkConfirmationCode = (text) => CONFIRMATION_CODE.test(text);
const checkPassportSeries = (text) => PASSPORT_SERIES_CODE.test(text);
const isInternalEmployeeEmail = (text) =>
  text.endsWith(INTERNAL_EMPLOYEE_EMAIL);
const isInternalStudentEmail = (text) => text.endsWith(INTERNAL_STUDENT_EMAIL);
const isFrontendVersion = (value) => FRONTEND_VERSION.test(value);
const isFrontendVersionOld = (currentVersion, newVersion) => {
  if (isFrontendVersion(newVersion)) {
    const currentVersionArray = currentVersion.split('.');
    const newVersionArray = newVersion.split('.');

    if (currentVersionArray[2].length === 1) {
      const lastValue = currentVersionArray[2];
      currentVersionArray[2] = lastValue + '0';
    }

    if (newVersionArray[2].length === 1) {
      const lastValue = newVersionArray[2];
      newVersionArray[2] = lastValue + '0';
    }

    const newVersionSum = Number(
      newVersionArray[0] * 10000 + newVersionArray[1] * 100 + newVersionArray[2]
    );

    const currentVerionsSum = Number(
      currentVersionArray[0] * 10000 +
        currentVersionArray[1] * 100 +
        currentVersionArray[2]
    );

    return newVersionSum > currentVerionsSum;
  }
  return false;
};

const BIRTH_DATE_FORMAT = 'dd.MM.yyyy';

const CONFIRMATION_CODE_MASK = [/\d/, /\d/, /\d/, /\d/];
const PASSPORT_SERIES_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

export {
  checkPassword,
  checkPhone,
  checkEmail,
  checkConfirmationCode,
  isInternalEmployeeEmail,
  isInternalStudentEmail,
  checkPassportSeries,
  isFrontendVersion,
  isFrontendVersionOld,
  CONFIRMATION_CODE_MASK,
  PASSPORT_SERIES_MASK,
  BIRTH_DATE_FORMAT,
  PHONE_TEST_CODE,
};
