export const SET_GROUPS = 'setGroups';
export const SET_LOADING = 'setLoading';
export const SET_INIT_STATE = 'setInitState';
export const SET_CORP_INIT_STATE = 'setCorpInitState';
export const SET_USER_DATA = 'setUserData';
export const SET_PROGRAMS = 'setPrograms';
export const SET_SERVICE_MANAGER = 'setServiceManager';
export const SET_CORP_PROGRAMS = 'setCorpPrograms';
export const SET_CORP_GROUPS = 'setCorpGroups';
export const SET_CORP_USER = 'setCorpUser';
