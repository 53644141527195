<template>
  <ErrorPagePublic v-if="isPublicUser" />
  <div
    v-else
    :class="{body: true, active: menuActive}"
  >
    <menu-wrapper :page-name="labels.title" />
    <div class="page-wrapper">
      <main
        class="content"
        :class="pageClass"
      >
        <section class="selected-programs-section">
          <div class="container">
            <div class="wrap_favorites page-title">
              <h1>
                {{ labels.title }}
              </h1>
              <div class="flex-center">
                <el-button
                  v-if="favoritePrograms.length"
                  size="small"
                  type="primary"
                  style="width: unset"
                  @click="clearFavorites"
                >
                  {{ labels.clearFavorites }}
                </el-button>
              </div>
            </div>
            <program-preset-filter
              :result-count="meta?.total ?? 0"
              :load-method="fetchPrograms"
              :loaded="onLoadData"
            />

            <template v-if="favoritePrograms.length">
              <!-- name="program-grid"  -->
              <transition-group
                tag="div"
                name="program-grid"
                class="program-grid"
              >
                <program-card
                  v-for="program in favoritePrograms"
                  :key="program.pcId"
                  :pc-id="program.pcId"
                  :pc-active="program.pcActive"
                  :pc-direct="program.pcDirect"
                  :pc-caption="program.pcCaption"
                  :pc-start="program.pcStart"
                  :pc-duration="program.pcDuration"
                  :pc-price="program.pcPrice"
                  :pc-old-price="program.pcOldPrice"
                  :pc-type="program.pcType"
                  :pc-favorites="program.pcFavorites"
                  :pc-comparison="program.pcComparison"
                  :pc-detail-url="program.pcDetailUrl"
                  :pc-campus="program.pcCampus"
                  :edu-format="program.eduFormat"
                  :pc-duration-value="program.pcDurationValue"
                  :pc-training-areas="program.pcTrainingAreas"
                  @returnFavoriteId="toggleProgramFavorite"
                  @returnComparisonId="toggleProgramComparison"
                />
              </transition-group>
              <dpo-element-pagination
                v-if="meta"
                :total="meta.total"
                :page-size="meta.per_page"
                :current-page="currentPage"
                @change-page="onChangePage"
              />
            </template>
            <empty-wrapper v-else>
              <empty-data-message :text="labels.emptyFavorites" />
            </empty-wrapper>
<!--            <h2-->
<!--              class="title-section"-->
<!--              style="margin-bottom: unset"-->
<!--            >-->
<!--              <a href="#">{{ labels.programsSuitable }}</a>-->
<!--            </h2>-->
            <component
              :is="loader('dpo.recommendations.widget')"
              key="dpo.favorites.widget"
            />
          </div>
        </section>
      </main>
    </div>
    <page-footer />
  </div>
</template>

<script>
import loader from '@/utils/widgetLoader';
import {mapActions, mapState, mapGetters} from 'vuex';
import setNotification from '@/utils/setNotification';
import {prepareProgramCardRecommendedView} from '@/utils/prepareProgramCardData';
import menuWrapper from '@/components/menuWrapper';
import PageFooter from '@/components/PageFooter';
import ProgramSlider from '@/components/sliders/ProgramSl.vue'; //слайдер с программами
import ProgramCard from '@/components/programs/Program-card.vue'; //карточка программы
import EmptyDataMessage from '@/components/common/elkEmptyDataMessage';
import EmptyWrapper from '@/components/common/elkEmptyWrapper';
import ProgramPresetFilter from '@/components/filters/presets/ProgramPresetFilter.vue';
import ErrorPagePublic from '../404/index.vue';
import {RECOMMENDED_PROGRAMS} from '@/router/routes';
import Axios from '@/utils/Elk-Axios';
import {FAVORITE_PROGRAM_CLEAR} from '@/api';
import {filterBus} from '@/main';
import {sortFilterDrop} from '@/utils/sortDate';
import ShowMoreProgram from '@/components/programs/ShowMoreProgram.vue';
import dpoElementPagination from '@/components/dpoCorpComponents/dpoElementPagination';
import localization from '@/i18n/localization';

export default {
  name: 'SelectedPrograms',
  components: {
    dpoElementPagination,
    ShowMoreProgram,
    ProgramPresetFilter,
    menuWrapper,
    PageFooter,
    ProgramSlider,
    EmptyWrapper,
    ProgramCard,
    EmptyDataMessage,
    ErrorPagePublic
  },
  data() {
    return {
      name: 'Избранные программы',
      pageClass: 'selected-programs',
      localization: localization('dpo.favorites.widget'),
      filterParams: {},
      currentPage: 1,
      sortParams: {},
      arFavorites: [],
      recommendPrograms: [],
      favoritePrograms: [],
      linkRecommended: RECOMMENDED_PROGRAMS
    };
  },

  computed: {
    ...mapState(['menuActive']),
    ...mapGetters(['isPublicUser']),
    ...mapActions(['getOfferedPrograms']),
    ...mapGetters({
      programs: 'programs/favorites'
    }),
    meta() {
      return this.$store.state.programs.metaFavorites;
    },
    labels() {
      return {
        programsSuitable: this.localization.commonCaption('programsSuitable'),
        title: this.localization.commonCaption('favoritePrograms'),
        clearFavorites: this.localization.widgetCaption('clearFavorites'),
        emptyFavorites: this.localization.widgetCaption('emptyFavorites')
      };
    },
    isLoadFavRec() {
      return this.$store.getters['programs/isLoading'];
    }
  },
  watch: {
    isLoadFavRec() {
      this.initFavoritesRec();
    }
  },
  mounted() {
    filterBus.$on('change', data => {
      let tmp = Array.from(this.favoritePrograms);
      tmp = sortFilterDrop(tmp, data);
      this.favoritePrograms = Array.from(tmp);
    });
    filterBus.$on('fetchFavorite', data => {
      this.fetchFavorites().then(() => {
        this.favoritePrograms = this.$store.getters['programs/favorites']().map(
          prepareProgramCardRecommendedView
        );
      });
    });
  },
  methods: {
    ...mapActions([
      'addProgramToComparison',
      'deleteProgramFromComparison',
      'addProgramToFavorites',
      'deleteProgramFromFavorites'
    ]),
    ...mapActions({
      fetchPrograms: 'programs/fetchFavorites',
      fetchFavorites: 'programs/fetchFavorites',
      fetchRecommendations: 'programs/fetchRecommendations',
    }),
    loader,
    initFavoritesRec() {
      if (!this.isLoadFavRec) {
        if (
          this.$store.state.programs.isInitFavorite &&
          this.$store.state.programs.isInitRecommendations
        ) {
          if (!this.recommendPrograms.length) {
            this.recommendPrograms = this.$store.getters[
              'programs/recommendations'
            ]().map(prepareProgramCardRecommendedView);
          }
          if (!this.favoritePrograms.length) {
            this.favoritePrograms = this.$store.getters[
              'programs/favorites'
            ]().map(prepareProgramCardRecommendedView);
          }
        }
      }
    },

    async clearFavorites() {
      if (this.favoritePrograms.length) {
        try {
          const {data: res} = await Axios.delete(FAVORITE_PROGRAM_CLEAR);
          if (res?.data) {
            this.favoritePrograms = [];
            this.fetchPrograms();
          }
        } catch (e) {
          return Promise.reject(e);
        }
      }
    },

    toggleFavoriteParent(returnFavoriteId, state, arr) {
      let favoriteIndex = this.favoritePrograms.findIndex(
        e => e.pcId === returnFavoriteId
      );
      if (favoriteIndex >= 0) {
        this.favoritePrograms[favoriteIndex].pcFavorites = state;
        if (!state) {
          this.favoritePrograms =
            this.favoritePrograms.filter(e => e.pcId !== returnFavoriteId) ??
            [];
        }
      }
      let recProgram = this.recommendPrograms.find(
        e => e.pcId === returnFavoriteId
      );
      if (recProgram) {
        recProgram.pcFavorites = state;
        if (favoriteIndex < 0 && state) {
          this.favoritePrograms.push(recProgram);
        }
      }
      // this.$store.dispatch('programs/fetchFavorites')
    },
    toggleComparisonParent(returnFavoriteId, state, arr) {
      let program = this.favoritePrograms.find(
        e => e.pcId === returnFavoriteId
      );

      if (program) {
        program.pcComparison = state;
      }

      program = this.recommendPrograms.find(e => e.pcId === returnFavoriteId);

      if (program) {
        program.pcComparison = state;
      }
    },
    toggleProgramComparison(id, isComparison) {
      for (let program of this.arFavorites) {
        if (program.pcId === id) return (program.pcComparison = isComparison);
      }
    },
    async toggleProgramFavorite(id) {
      this.favoritePrograms = this.favoritePrograms.filter(
        item => item.pcId !== id
      );
      this.fetchFavorites();
      this.fetchRecommendations();
      filterBus.$emit('fetchFavoriteSelected', "");
    },
    onLoadData() {
      try {
        this.favoritePrograms = this.$store.getters['programs/favorites']().map(
          prepareProgramCardRecommendedView
        );
      } catch {
        setNotification({
          message: 'Не удалось загрузить рекомендованные программы'
        });
      }
    },
    onChangePage(v) {
      this.currentPage = v || 1;
      filterBus.$emit('changePage', {page: this.currentPage});
    }
  }
};
</script>

<style lang="scss">
.wrap_favorites {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.selected-programs {
  .program-grid {
    margin-bottom: 64px;
  }
}

.wrap_favorites {
  display: flex;
  justify-content: space-between;
  transition: all 1s ease;
}

.program-grid-enter-active,
.program-grid-leave-active {
  transition: all 1s ease;
}

.program-grid-enter-from,
.program-grid-leave-to {
  opacity: 0;
  transform: translate(50px, 50px);
}

.program-grid-enter-active,
.program-grid-leave-active {
  transition: all 1s ease;
}

.program-grid-enter-from,
.program-grid-leave-to {
  opacity: 0;
  transform: translate(50px, 50px);
}

@media screen and (max-width: 768px) {
  .vi-drop-down-list__window {
    height: 0;
  }

  .vi-drop-down-list__active .vi-drop-down-list__window {
    position: relative;
    height: 160px;
  }
}
@media screen and (max-width: 576px) {
  .flex-center span {
    font-size: 12px;
  }
  .flex-center button {
    padding: 10px;
  }
}

@media screen and (max-width: 768px) {
  .vi-drop-down-list__window {
    height: 0;
  }

  .vi-drop-down-list__active .vi-drop-down-list__window {
    position: relative;
    height: 160px;
  }
}
</style>
