import {ref} from 'vue';
import _apiRequest from '@/api/taskRequest/_apiRequest';

export function apiTask() {
  const isRunning = ref(false);
  const isError = ref(false);
  const data = ref(null);
  const error = ref(null);
  let cancelTokenSource = null;

  const performRequest = async (method, url, config, postData) => {
    return await httpMethods[method](url, config, postData);
  };

  const httpMethods = {
    get: (url, config) => _apiRequest.get(url, config),
    post: (url, config, postData) => _apiRequest.post(url, config, postData),
    delete: (url, config) => _apiRequest.delete(url, config)
  };

  const perform = async (url, options = {}, postData = null) => {
    isRunning.value = true;
    isError.value = false;
    error.value = null;
    data.value = null;

    cancelTokenSource = _apiRequest.createRequestToken();

    const method = options.method?.toLowerCase() || 'get';
    const config = {...options?.config, cancelToken: cancelTokenSource.token};

    if (!httpMethods[method]) {
      throw new Error(`Неизвестный метод запроса: ${method}`);
    }

    try {
      data.value = await performRequest(method, url, config, postData);
    } catch (e) {
      isError.value = true;
      error.value = e;
      console.error('Ошибка запроса:', e);
    } finally {
      isRunning.value = false;
      cancelTokenSource = null;
    }
  };

  const drop = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Запрос отменен');
      isRunning.value = false;
      cancelTokenSource = null;
    }
  };

  return {isRunning, isError, data, error, perform, drop};
}
