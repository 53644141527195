<template>
  <div class="inputWrap">
    <label for="patronymic">
      {{ label }}
    </label>
    <input
      id="patronymic"
      v-model.trim="value.$model"
      class="input-form__auth"
      type="text"
      name="patronymic"
      required
    />
  </div>
</template>

<script>
export default {
  name: "inputPatronymic",
  props: {
    label: {
      type: String,
      default: 'Отчество (при наличии)',
    },
    value: {
      type: Object,
      default: () => ({ $model: ''}),
    },
  }
}
</script>

<style>

</style>
