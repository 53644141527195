import Vue from 'vue';
import VueRouter from 'vue-router';
import {Notification} from 'element-ui';
// import { isProd } from '@/utils/constants';
import {
  LOGOUT,
  ERROR_ROUTE,
  SERVER_ERROR,
  AUTHORIZATION,
  RECOVERYPAGE,
  REGISTRATIONPAGE,
  MAINPAGE,
  SELECTED_PROGRAMS,
  RECOMMENDED_PROGRAMS,
  PROGRAM_VIEWING_HISTORY,
  PROFILEPAGE,
  DOCUMENTSPAGE,
  PROGRAMSPAGE,
  SERVICES,
  QUESTIONS_AND_ANSWERS,
  QUESTIONS_AND_ANSWERS_DETAILED,
  NEWBASKET,
  ORDER,
  NOTIFICATIONS,
  REVIEWPAGE,
  REVIEWFINISH,
  MYSCHEDULE,
  REFERENCEPAGE,
  COMPARISONPAGE,
  COMPARISON,
  CONGRATULATIONONPAYMENT,
  DPO_CORP_REG,
  DPO_CORP_SERVICES,
  ELK_PROFILE_MAIN_ALT,
  GRANTS_ROUTE
  // STYLEGUIDE_ROUTE,
} from '@/router/routes';
import middleware from './middleware/index';
import {clearBreadcrumbs} from '@/store/mutationsName';
import store from '../store';
import {haveBot, toggleBot} from '@/utils/bot.js';
// import logoutAction from '@/utils/logOut.js';
// import checkExternalRedirect from '@/utils/checkExternalRedirect';
import localStorageService from '@/utils/localStorageService';
import authorization from '@/views/auth/authorization';
import recoveryPage from '@/views/recovery/recoveryPage';
import registrationPage from '@/views/reg/registrationPage';
import mainPage from '@/views/mainPage/mainPage';
import selectedPrograms from '@/views/programs/SelectedPrograms';
import recommendedPrograms from '@/views/programs/RecommendedPrograms';
import programViewingHistory from '@/views/programs/ProgramViewingHistory';
import profilePage from '@/views/mainProfile/profilePage';
import documentsPage from '@/views/mainDocuments/documentsPage';
import programsPage from '@/views/mainPrograms/programsPage';
import services from '@/views/Services';
import questionsAndAnswers from '@/views/QuestionsAndAnswers/QuestionsAndAnswers';
import questionsAndAnswersDetailed from '@/views/QuestionsAndAnswers/QuestionsAndAnswersDetailed';
import NewBasket from '@/views/mainBasket/NewBasket.vue';
import Order from '@/views/mainOrder/Order.vue';
import Notifications from '@/views/Notifications.vue';
import reviewPage from '@/views/mainReview/reviewPage.vue';
import reviewFinish from '@/views/mainReview/reviewFinish.vue';
import MySchedule from '@/views/schedule/MySchedule';
import referencePage from '@/views/mainReference/referencePage.vue';
import comparisonPage from '@/views/mainComparison/comparisonPage.vue';
import comparison from '@/views/comparison.vue';
import congratulationOnPayment from '@/views/congratulations/CongratulationOnPayment.vue';
import registrationCorpPage from '@/views/dpoCorpReg/registrationCorpPage.vue';
import dpoCorpServices from '@/views/dpoCorp/dpoCorpServices.vue';
import elkGrants from '@/views/elkGrants.vue';
/*import elkProfileMainAlt from '@/views/elkProfileMainAlt.vue';*/
Vue.use(VueRouter);

const routes = [
  {
    path: ERROR_ROUTE,
    name: 'error',
    component: () =>
      import(/* webpackChunkName: "pages" */ '@/views/elkErrorPage'),
    props: {name: 'Error'}
  },
  {
    path: LOGOUT,
    name: 'logout',
    meta: {requiresLogout: true}
  },
  {
    path: SERVER_ERROR,
    name: 'serverError',
    component: () =>
      import(
        /* webpackChunkName: "pages" */ '@/views/errorServerPage/index.vue'
      )
  }
  /*,
  {
    path: ELK_PROFILE_MAIN_ALT,
    name: 'elkProfileMainAlt',
    component: elkProfileMainAlt,
    props: {name: 'elkProfileMainAlt'}
  }*/
];

// if (!isProd()) {
//   routes.push({
//     path: STYLEGUIDE_ROUTE,
//     name: 'styleguide',
//     component: () =>
//       import(/* webpackChunkName: "styleguide" */ '@/views/Styleguide'),
//     props: { name: 'styleguide', widgets: [] },
//   });
// }

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  if (to.meta?.requiresLogout) {
    if (localStorageService.getExternalRedirect() === null) {
      const externalRedirect =
        to.query?.redirect ?? process.env.VUE_APP_BASE_LOGOUT_REDIRECT_URL;
      localStorageService.setExternalRedirect(externalRedirect);
      next('/');
    }
  }

  if (to.name !== from.name) {
    store.commit(`breadcrumbs/${clearBreadcrumbs}`);
  }

  if (haveBot(to.path)) {
    toggleBot(true);
  }

  if (haveBot(from.path) && from.path !== to.path) {
    toggleBot(false);
  }

  window.scrollTo(0, 0);
  Notification.closeAll();

  if (to.path === '/profile' && store.getters['userRepresent']) {
    next('/represent/profile');
    return;
  }

  if (typeof window !== 'undefined' && window.ym) {
    window.ym(97383787, 'hit', to.fullPath, {title: to?.name});
  }

  next();
});

export default router;
