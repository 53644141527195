<template>
  <cascader
    v-model="selected"
    :options="cascadeOptions"
    :props="{multiple}"
    collapse-tags
    clearable
    :placeholder="placeholder"
    popper-class="filter-cascader__panel"
    class="filter-cascader"
    @change="onChange"
  >
    <template #default="{data}">
      <el-tooltip
        :content="data.label"
        placement="bottom"
        effect="light"
        popper-class="el-tooltip__custom filter-cascader__tooltip"
      >
<!--      <el-popover-->
<!--        placement="top-start"-->
<!--        title=""-->
<!--        :width="200"-->
<!--        trigger="hover"-->
<!--        :content="data.label"-->
<!--      >-->
          <span>{{ data.label }}</span>
      </el-tooltip>
    </template>
  </cascader>
</template>

<script>
import {Cascader} from 'element-ui';
import {filterBus} from '@/main';
export default {
  name: 'CascadeFilterItem',
  components: {Cascader},
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    param: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selected: [],
      options: null
    };
  },
  computed: {
    cascadeOptions() {
      return this.mapOptions(this.options);
    }
  },
  mounted() {
    filterBus.$on('removeFilter', value => {
      if (this.selected.length) {
        let code = '';
        this.options.find(e => {
          let temp = null;
          if (Array.isArray(e.items)) {
            temp = e.items.find(el => el.name === value);
          } else {
            temp = e.name === value ? e : null;
          }
          if (temp && this.selected.find(el => el.includes(temp.code))) {
            code = temp.code;
            return true;
          }
          return false;
        });
        if (code) {
          this.selected = this.selected.filter(e =>
            e.length === 1 ? code !== e[0] : code !== e[1]
          );
        }
      }
    });
  },
  methods: {
    onChange(v) {
      if (this.multiple) {
        this.$emit(
          'input',
          v.map(e => ({
            label: e.length > 1 ? e[1] : e[0],
            value: e.length > 1 ? e[1] : e[0]
          }))
        );
      } else {
        this.$emit(
          'input',
          v.map(e => ({
            value: e
          }))
        );
      }
    },
    mapOptions(items) {
      const result = [];
      if (!items) return result;
      let count = 0;
      for (const entryItem of items) {
        if (entryItem.items && Array.isArray(entryItem.items)) {
          result.push({
            label: entryItem.group,
            value: count,
            children:
              entryItem.items.map(e => ({
                label: e.name,
                value: e.code
              })) ?? []
          });
        } else {
          result.push({
            label: entryItem.name,
            value: entryItem.code
          });
        }

        count += 1;
      }
      return result;
    },
    getValue() {
      return this.selected?.map(val => {
        return {
          label: val.length === 1 ? val[0] : val[1],
          value: val.length === 1 ? val[0] : val[1],
          param: this.param
        };
      });
    },
    toParam() {
      return {
        [this.param]: this.selected?.map(e => (e.length === 1 ? e[0] : e[1]))
      };
    },
    toLabel() {
      return this.selected.map(e => {
        let temp = null;
        if (e.length === 1) {
          temp = this.cascadeOptions.find(el => el.value == e[0]);
          return temp?.label ?? '';
        } else {
          temp = this.cascadeOptions[e[0]];
          if (temp) {
            const result = temp.children.find(el => el.value == e[1]);
            return result?.label ?? '';
          }
        }
        return e[0];
      });
    }
  }
};
</script>

<style lang="scss">
.el-tooltip__popper.filter-cascader__tooltip {
  z-index: 2050 !important;
}
.filter-cascader {
  height: 48px;

  .el-input__inner {
    height: 48px;
    font-size: 16px;
    color: $border-color;
    border-color: $border-color;
  }
  .el-input__inner::placeholder {
    color: $dusk-gray-color;
  }
  .el-cascader__tags {
    flex-wrap: nowrap;
    overflow: hidden;
  }
  .el-input.is-focus .el-input__inner,
  .el-input .el-input__inner:hover {
    border-color: $blue-color;
  }
  .el-input .el-icon-arrow-down.is-reverse {
    transform: rotate(45deg);
  }
  .el-input__suffix {
    right: 12px;
  }
  .el-icon-arrow-down:before {
    content: url('data:image/svg+xml,%3Csvg width=%2712%27 height=%2712%27 viewBox=%270 0 12 12%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M12 5H7V0H5V5H0V7H5V12H7V7H12V5Z%27 fill=%27%236B7A99%27/%3E%3C/svg%3E');
  }
}

.filter-cascader__panel {
  z-index: 2040 !important;
  .el-cascader-menu__wrap {
    height: 220px;
  }
  .el-cascader-menu__list {
    max-width: 320px;
  }
  .el-cascader-node.in-active-path,
  .el-cascader-node.is-active,
  .el-cascader-node.is-selectable.in-checked-path {
    color: $blue-color;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner,
  .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: $blue-color;
    border-color: $blue-color;
  }

  .el-checkbox__input .el-checkbox__inner:hover,
  .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: $blue-color;
  }
}
@media screen and (max-width: 768px) {
  .filter-cascader__panel {
    .el-cascader-menu__list {
      max-width: 240px;
    }
  }
}

@media screen and (max-width: 580px) {
  .filter-cascader__panel .el-scrollbar.el-cascader-menu {
    max-width: 154px;
  }
}
</style>
