<template>
  <a
    name="Оплатить"
    href="javascript:void(0);"
    class="btn__programs btn-prog__review"
  >
    <span>Оставить отзыв</span>
  </a>
</template>

<script>
export default {
  name: "LinkReview",
}
</script>

<style lang="scss">
.btn__programs.btn-prog__review{
  width: 220px;
  height: 44px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border-radius: 4px;
  color: #0050CF;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0;
  position: relative;
  transition: all .3s ease-in-out;
  background-color: #F0F5FF;
  &:before{
    content: '';
    width: 20px;
    height: 20px;
    margin-right: 11px;
    background: url("@/assets/icons/icon-prize.svg")100% 100% no-repeat;
  }
  &:hover{
    /* background-color: rgb(235 109 0 / 85%); */
  }
}
</style>
