import Axios from '@/utils/Elk-Axios';
import setNotification from '@/utils/setNotification';
import { GET_MYCONTACTS } from '@/api';
import I18N from '@/i18n';
import { getUiItemCaption } from '@/i18n/utils';

const MY_CONTACTS_SIGNATURE = 'elk.main.mycontacts';

export default {
  namespaced: true,

  state: {
    contacts: [],
  },

  mutations: {
    addContacts(state, contacts) {
      state.contacts = contacts;
    },
    removeContacts(state, id) {
      state.contacts = state.contacts.filter((service) => service.id !== id);
    },
  },
  actions: {
    async getContacts(context) {
      try {
        let { data: contacts } = await Axios.get(GET_MYCONTACTS);
        context.commit('addContacts', contacts);
      } catch (err) {
        if (!err.isHandled) {
          setNotification({
            message: I18N.t(
              getUiItemCaption(MY_CONTACTS_SIGNATURE, 'fetchDataError')
            ),
          });
        }
      }
    },
  },
};
