<template>
  <div class="form-service-container">
    <el-row>
      <div class="form-service-container__title">
        <h1 class="form-service-container__title">{{ formData.name }}</h1>
      </div>
    </el-row>
    <el-row v-if="formData.description">
      <p
        class="form-service-container__description"
        v-html="formData.description"
      />
    </el-row>
    <el-row v-loading="isLoading">
      <form-service-v
        v-show="currentStep === steps.form"
        :fields="formData.fields"
        :is-printed-form="isPrintedForm"
        :localization="localization"
        :submit-handler="onFormSubmit"
        @back="onBack"
      />
      <print-form-service
        v-if="currentStep === steps.print_form"
        :payload="formPayload"
        :form-path="printedFormPath"
        :localization="localization"
        :submit-handler="onFormSubmit"
        @back="onBack"
      />
    </el-row>
  </div>
</template>

<script>
import Axios from '@/utils/Elk-Axios';
import FormServiceV from '@/components/formService/FormServiceV';
import PrintFormService from '@/components/formService/PrintFormService';
import {format, isDate} from 'date-fns';
import {GET_PF, GET_SERVICES} from '@/api';

export default {
  name: 'FormServiceContainer',
  components: {
    FormServiceV,
    PrintFormService
  },
  props: {
    formData: {
      type: Object,
      default: () => {}
    },
    idService: {
      type: String,
      default: ''
    },
    localization: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isLoading: false,
      printedFormPath: '',
      currentStep: 'form',
      formPayload: null,
      steps: {
        form: 'form',
        print_form: 'print_form'
      },
      localeText: {
        failedGenerateForm:
          this.localization.commonCaption('failedGenerateForm')
      }
    };
  },
  computed: {
    isPrintedForm() {
      return !!this.formData.printed_form;
    }
  },
  methods: {
    getFormatDate(date) {
      return format(date, 'dd.MM.yyyy');
    },
    getKeysPayload(key, value, isMultiple = false) {
      let newKeys = [];

      if (Array.isArray(value)) {
        if (isDate(value[0])) {
          value.forEach((e, i) => {
            if (i === 0) newKeys.push(`${key}_start`);
            else if (i === value.length - 1) newKeys.push(`${key}_end`);
            else newKeys.push(`${key}_${i}`);
          });
        } else if (isMultiple) {
          value.forEach((_, i) => newKeys.push(`${key}[${i}]`));
        } else {
          newKeys = key;
        }
      } else {
        newKeys = key;
      }
      return newKeys;
    },
    getPayload() {
      const result = new FormData();
      result.append('service', this.idService);

      for (const [key, value] of Object.entries(this.formPayload)) {
        const field = this.formData?.fields?.find(e => e.code === key);
        const keys = this.getKeysPayload(key, value, field?.multiple);

        if (Array.isArray(keys)) {
          keys.forEach((e, i) => {
            result.append(
              e,
              isDate(value[i]) ? this.getFormatDate(value[i]) : value[i]
            );
          });
        } else if (Array.isArray(value) && !field?.multiple) {
          result.append(keys, value[0]);
        } else {
          result.append(
            keys,
            isDate(value) ? this.getFormatDate(value) : value
          );
        }
      }

      if (this.$route.query['contract_id']) {
        result.append('contract_id', this.$route.query['contract_id']);
      }

      return result;
    },
    async onFormSubmit(payload) {
      this.formPayload = payload;
      if (this.isPrintedForm && this.currentStep === this.steps.form) {
        await this.getPrintedForm();
        this.currentStep = this.steps.print_form;
      } else {
        await this.sendForm();
        this.$emit('success');
      }
    },
    onBack() {
      if (this.currentStep === this.steps.print_form) {
        this.currentStep = this.steps.form;
      } else {
        this.$router.back();
      }
    },
    async sendForm() {
      try {
        this.isLoading = true;
        await Axios.post(GET_SERVICES, this.getPayload());
      } finally {
        this.isLoading = false;
      }
    },
    async getPrintedForm() {
      try {
        this.isLoading = true;
        const response = await Axios.post(
          GET_PF + this.formData.printed_form,
          this.getPayload()
        );
        this.printedFormPath = response.data?.data?.file ?? '';
        if (!this.printedFormPath) {
          throw new Error(this.localeText.failedGenerateForm);
        }
      } finally {
        this.isLoading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.form-service-container {
  width: 778px;
  background-color: $primary-light-1;
  box-shadow: 0 1px 2px rgba(106, 122, 152, 0.15);
  padding: 48px 101px;

  &__title {
    font-size: 32px;
    font-weight: 600;
    line-height: 40px;
    text-align: center;
  }

  &__description {
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  hr {
    height: 1px;
    border: none;
    background-color: $color-gray-morn-3;
    margin-bottom: 24px;
  }

  /* redefine element styles */
  .el-form {
    width: 100%;
    padding: 0px;
    box-shadow: none;
  }
  .el-row:not(:last-child) {
    margin-bottom: 24px;
  }
  .el-select {
    width: 100%;
  }

  .el-picker-panel__footer .el-button {
    width: auto;
  }

  .el-button--primary.is-disabled,
  .el-button--primary.is-disabled:active,
  .el-button--primary.is-disabled:focus,
  .el-button--primary.is-disabled:hover {
    background-color: $primary-brand-1;
    border-color: $primary-brand-1;
  }
  .el-textarea__inner:focus,
  .el-range-editor.is-active,
  .el-range-editor.is-active:hover {
    border-color: $primary-brand-1;
  }
  .el-button--secondary {
    background-color: $color-background-gray-2;
    color: $color-gray-dusk;
  }
  .el-button--secondary:hover {
    background-color: $color-gray-morn-2;
    color: $color-gray-dusk;
  }
}

@media (max-width: 940px) {
  .form-service-container {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .form-service-container {
    padding: 24px;

    &__title {
      font-size: 24px;
    }

    .el-row:not(:last-child) {
      margin-bottom: 18px;
    }
  }
}
</style>
