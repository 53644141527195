import Axios from '@/utils/Elk-Axios';
import {FETCH_REPRESENT_PROFILE, REPRESENT_PROFILE_EDIT} from '@/api';

export default {
  namespaced: true,
  state: {
    initLoaded: false,
    profile: {}
  },
  getters: {
    getProfile(state) {
      return state.profile;
    },
    getInitLoaded(state) {
      return state.initLoaded;
    },
    isAdmin(state) {
      return !!state.profile?.isAdmin;
    }
  },
  mutations: {
    setProfile(state, payload) {
      state.profile = {
        ...state.profile,
        ...payload
      };
    }
  },
  actions: {
    async fetchProfile({commit, state}) {
      const {data} = await Axios.get(FETCH_REPRESENT_PROFILE);

      commit('setProfile', {
        id: data.data.id,
        lastName: data.data.last_name,
        firstName: data.data.first_name,
        patronymic: data.data.patronymic,
        email: data.data.email,
        phone: data.data.phone,
        post: data.data.post,
        createdAt: data.data.created_at,
        isAdmin: data.data.is_admin
      });

      state.initLoaded = true;
    },
    async updateProfile({commit}, payload) {
      const {data} = await Axios.post(REPRESENT_PROFILE_EDIT, payload);

      commit('setProfile', {
        id: data.data.id,
        lastName: data.data.last_name,
        firstName: data.data.first_name,
        patronymic: data.data.patronymic,
        email: data.data.email,
        phone: data.data.phone,
        post: data.data.post,
        createdAt: data.data.created_at,
        isAdmin: data.data.is_admin
      });
    }
  }
};
