<template>
  <div class="device-elements">
    <div class="wrapper-table__device">
      <table>
        <thead>
          <tr>
            <th>Устройство</th>
            <th>Браузер</th>
            <th>Местоположение</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, i) in items"
            :key="i"
          >
            <td>
              {{ item.device }}
            </td>
            <td>
              <div :class="getBrowserIconClass(item.browsername)">
                {{ item.browser }}
              </div>
            </td>
            <td>
              <div class="device-location">
                {{ item.ip }}
                <span>{{ item.date }}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
const initialItems =  [
  {device: 'Unknow', browser: 'YaBrowser 22.11.3.818',browsername: 'YaBrowser', ip: 'Россия, Москва, IP 123.45.678.144', date: 'Последний вход: 20 дек. 2022 г., 10:47'},
  {device: 'Unknow', browser: 'Firefox 106.0',browsername: 'Firefox', ip: 'Россия, Москва, IP 123.45.678.144', date: 'Последний вход: 20 дек. 2022 г., 10:47'},
  {device: 'Unknow', browser: 'Chrome 22.11.3.818',browsername: 'Chrome', ip: 'Россия, Москва, IP 123.45.678.144', date: 'Последний вход: 20 дек. 2022 г., 10:47'},
  {device: 'Mac OS X', browser: 'Safari 22.11.3.818',browsername: 'Safari', ip: 'Россия, Москва, IP 123.45.678.144', date: 'Последний вход: 20 дек. 2022 г., 10:47'},
  {device: 'Unknow', browser: 'Opera 22.11.3.818',browsername: 'Opera', ip: 'Россия, Москва, IP 123.45.678.144', date: 'Последний вход: 20 дек. 2022 г., 10:47'},
];

export default {
  name: "itemDevice",

  props: {
    items: {
      type: Array,
      default: () => initialItems,
    },
  },

  data() {
    return {
    }
  },

  methods: {
    getBrowserIconClass(browsername) {
      return {
        'browser-type': true,
        'browser-ya': browsername === 'YaBrowser',
        'browser-firefox': browsername === 'Firefox',
        'browser-safari': browsername === 'Safari',
        'browser-chrome': browsername === 'Chrome',
        'browser-opera': browsername === 'Opera',
      };
    }
  },
}
</script>

<style lang="scss">
 .wrapper-table__device{
   table{
     width: 100%;
     thead{
       th{
         border-bottom: 1px solid #EDEFF3;
       }
     }
     tr{
       &:not(:first-child){
         border-top: 1px solid #EDEFF3;
       }
       th{
         text-align: left;
         font-weight: 400;
         font-size: 14px;
         line-height: 20px;
         color: #6B7A99;
         padding-bottom: 16px;
       }
       td{
         font-weight: 400;
         font-size: 16px;
         line-height: 20px;
         color: $base-black-color;
         padding: 16px 0;
         .device-location{
           span{
             font-weight: 400;
             font-size: 14px;
             line-height: 20px;
             color: #6B7A99;
             display: inline-block;
             margin-left: 15px;
           }
         }
       }

     }
   }
   .browser-type{
     padding-left: 29px;
     position: relative;
     &:before{
       content: '';
     }
   }
 }
 .browser-ya:before{
   content: '';
   display: block;
   width: 24px;
   height: 24px;
   position: absolute;
   top: -5px;
   left: 0;
   background: url("../../assets/icons/browsers/yandex.svg") 100% 100% no-repeat;
 }
 .browser-firefox:before{
   content: '';
   display: block;
   width: 24px;
   height: 24px;
   position: absolute;
   top: -5px;
   left: 0;
   background: url("../../assets/icons/icon-firefox.svg") 100% 100% no-repeat;
 }
 .browser-safari:before{
   content: '';
   display: block;
   width: 24px;
   height: 24px;
   position: absolute;
   top: -4px;
   left: 0;
   background: url("../../assets/icons/browsers/safari.svg") 100% 100% no-repeat;
 }
 .browser-chrome:before{
   content: '';
   display: block;
   width: 24px;
   height: 24px;
   position: absolute;
   top: -4px;
   left: 0;
   background: url("../../assets/icons/browsers/chrome.svg") 100% 100% no-repeat;
 }
 .browser-opera:before{
   content: '';
   display: block;
   width: 24px;
   height: 24px;
   position: absolute;
   top: -4px;
   left: 0;
   background: url("../../assets/icons/browsers/opera.svg") 100% 100% no-repeat;
 }

</style>
