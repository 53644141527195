export default {
  namespaced: true,
  state: {
    /** @type {Reference[]} */
    items: [],
  },
  getters: {
    /** @returns {Reference[]} */
    items: (state) => () => state.items,
  },
  mutations: {
    set(state, items) {
      state.items = items ?? []
    },
  },
  actions: {

    async fetch({ commit }) {
      // const response = await Axios.get(REF_DURATION_TYPES)
      commit('set', [
        {
          "name": 'До 68 часов',
          "code": "0,68",
        },
        {
          "name": "От 68 до 250 часов",
          "code": "68,250"
        },
        {
          "name": "От 250 часов",
          "code": "250,9999",
        }
      ] ?? []
      )
    },
  }
}
