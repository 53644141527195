<template>
  <a
    class="profile__error"
    :href="IT_SUPPORT"
    target="_blank"
  >
    <iconError />
    <span>{{ $t(getCommonCaptionByName('reportError')) }}</span>
  </a>
</template>

<script setup>
import iconError from '@/assets/icons/error.svg?inline';
import {IT_SUPPORT} from '@/router/routes';
import {getCommonCaptionByName} from '@/i18n/utils';
</script>

<script>
export default {
  name: 'ProfileError'
};
</script>
