<template>
  <form id="chip-elements" class="chip-elements">
    <div v-for="item in items" :key="item.id" class="btn-btn_chip">
      <label>
        <input v-model="checked" type="checkbox" :value="item.id" />
        <span>{{ item.name }}</span>
      </label>
    </div>
  </form>
</template>

<script>
import { debounce } from 'throttle-debounce';
import Axios from '@/utils/Elk-Axios';
import { PROFILE_TAGS } from '@/api';

export default {
  name: 'BtnChip',

  data() {
    return {
      items: [],
      checked: [],
    };
  },

  watch: {
    checked(val) {
      this.$emit('on-change', val);
      this.debouncedOnInterestsChange();
    },
  },
  async created() {
    this.getProfileTags();
  },

  methods: {
    async getProfileTags() {
      try {
        const { data } = await Axios.get(PROFILE_TAGS);

        if (data.code === 200 && data.data) {
          this.items = data.data.tags;
          this.checked = data.data.selected;
        }
      } catch {
        /** */
      }
    },
    async updateProfileTags() {
      try {
        await Axios.post(PROFILE_TAGS, { tags: this.checked });
      } catch {
        /** */
      }
    },
    debouncedOnInterestsChange: debounce(900, function () {
      this.updateProfileTags();
    }),
  },
};
</script>

<style lang="scss">
.chip-elements {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.btn-btn_chip {
  border-radius: 40px;
  background-color: #f5f6fa;
  box-sizing: border-box;
  margin: 0 8px 8px 0;
  cursor: pointer;

  overflow: hidden;
  float: left;
  label {
    float: left;
    input {
      position: absolute;
      display: none;
      & + span {
        color: #6b7a99;
      }
    }
    input:checked + span {
      color: $blue-color;
      border-color: $blue-color;
    }
    span {
      text-align: center;
      color: #6b7a99;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      display: block;
      padding: 12px 20px;
      border: 1px solid transparent;
      border-radius: 40px;
      transition: all 0.3s;
      &:hover {
        color: $blue-color;
        border: 1px solid $blue-color;
      }
    }
  }
  label,
  input,
  span {
    cursor: pointer;
  }
}
@media (max-width: 530px) {
  .section-chips {
    margin-bottom: 32px;
    .chips-element {
      .wrapper-chips {
        padding: 16px 16px 8px 16px;
      }
    }
  }
  .btn-btn_chip {
    label {
      span {
        font-size: 12px;
        line-height: 16px;
        padding: 10px 12px;
      }
    }
  }
}
</style>
