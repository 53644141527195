const ACCESS_TOKEN = 'access_token';
const ID_TOKEN = 'id_token';
const REDIRECT_URL = 'redirect_url';
const LOCALE_ID = 'locale_id';
const CHECK_ADFS_SESSIO = 'check_adfs_session';
const URL = 'url';

const LocalStorageService = (function () {
  function setToken(access_token) {
    localStorage.setItem(ACCESS_TOKEN, access_token);
  }

  function getToken() {
    return localStorage.getItem(ACCESS_TOKEN);
  }

  function clearToken() {
    localStorage.removeItem(ACCESS_TOKEN);
  }

  function getIdToken() {
    return localStorage.getItem(ID_TOKEN);
  }

  function setRedirectUrl(redirect_url) {
    localStorage.setItem(REDIRECT_URL, redirect_url);
  }

  function clearRedirectUrl() {
    localStorage.removeItem(REDIRECT_URL);
  }

  function getLocaleId() {
    return localStorage.getItem(LOCALE_ID);
  }

  function setLocaleId(localeId) {
    localStorage.setItem(LOCALE_ID, localeId);
  }

  function getCheckAdfsSession() {
    return localStorage.getItem(CHECK_ADFS_SESSIO);
  }

  function setCheckAdfsSession(val) {
    localStorage.setItem(CHECK_ADFS_SESSIO, val);
  }

  function isCheckAdfsSessionTrue() {
    return localStorage.getItem(CHECK_ADFS_SESSIO) === 'true';
  }

  function setUrl(val) {
    return localStorage.setItem(URL, val);
  }

  function clear() {
    localStorage.clear();
  }

  function setExternalRedirect(val) {
    return localStorage.setItem('externalRedirect', val);
  }

  function getExternalRedirect(val) {
    return localStorage.getItem('externalRedirect');
  }

  function removeExternalRedirect() {
    return localStorage.removeItem('externalRedirect');
  }

  function getTestUser() {
    return localStorage.getItem('test');
  }

  return {
    clear,
    getToken,
    setToken,
    clearToken,
    clearRedirectUrl,
    setRedirectUrl,
    getLocaleId,
    setLocaleId,
    getIdToken,
    getCheckAdfsSession,
    setCheckAdfsSession,
    isCheckAdfsSessionTrue,
    setUrl,
    setExternalRedirect,
    getExternalRedirect,
    removeExternalRedirect,
    getTestUser
  };
})();

export default LocalStorageService;
