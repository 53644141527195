import { render, staticRenderFns } from "./registrationCorpPage.vue?vue&type=template&id=80ca17ce"
import script from "./registrationCorpPage.vue?vue&type=script&lang=js"
export * from "./registrationCorpPage.vue?vue&type=script&lang=js"
import style0 from "./registrationCorpPage.vue?vue&type=style&index=0&id=80ca17ce&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports