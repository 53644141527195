import Vue from 'vue';
import setElementUiLocale from './setElementUiLocale';
import {
  Button,
  Badge,
  Carousel,
  CarouselItem,
  Tag,
  Input,
  DatePicker,
  Switch,
  Pagination,
  Popover,
  Image,
  Form,
  FormItem,
  Checkbox,
  Collapse,
  CollapseItem,
  Step,
  Steps,
  Select,
  Option,
  Dialog,
  TimeSelect,
  Autocomplete,
  Radio,
  RadioGroup,
  Col,
  Row,
  Alert,
  Tabs,
  TabPane,
  MessageBox,
  Table,
  TableColumn,
  Message,
  Notification,
  Slider,
  Loading,
  Tooltip,
  Upload,
  Descriptions,
  DescriptionsItem,
  CheckboxGroup,
  Container
} from 'element-ui';

import lang from 'element-ui/lib/locale/lang/ru-RU';
import locale from 'element-ui/lib/locale';

// import { isProd } from '@/utils/constants';

// if (!isProd()) {
//   let VueHighlightJS = () =>
//     import(/* webpackChunkName: "vue-highlightjs" */ 'vue-highlightjs');
//   Vue.use(VueHighlightJS);
// }

locale.use(lang);

Vue.use(Loading.directive);
Vue.prototype.$loading = Loading.service;

Vue.use(Radio);
Vue.use(TimeSelect);
Vue.use(Autocomplete);
Vue.use(Button);
Vue.use(Badge);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Tag);
Vue.use(Input);
Vue.use(DatePicker);
Vue.use(Switch);
Vue.use(Pagination);
Vue.use(Popover);
Vue.use(Image);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Checkbox);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Select);
Vue.use(Option);
Vue.use(Dialog);
Vue.use(RadioGroup);
Vue.use(Col);
Vue.use(Row);
Vue.use(Alert);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Slider);
Vue.use(Tooltip);
Vue.use(Upload);
Vue.use(Descriptions);
Vue.use(DescriptionsItem);
Vue.use(CheckboxGroup);
Vue.use(Container);

setElementUiLocale();
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$notify = Notification;
Vue.prototype.$message = Message;
