<template>
  <div>
    <template v-if="!menuActive">
      <el-popover
        :content="this.link.block_name"
        placement="right"
        trigger="hover"
      >

        <div slot="reference" v-if="!this.link.image">
          <component
            :is="iconLoader(this.link.signature)"
            class="icon nav__icon"
          />
        </div>
        <div slot="reference" v-else>
          <div class="noty-badge__image-wrapper">
            <img :src="this.link.image" :alt="this.link.image">
          </div>
        </div>
      </el-popover>
    </template>
    <template v-else>
      <template v-if="!this.link.image && iconLoader(this.link.signature)">
        <div class="d_center">
          <component
            :is="iconLoader(this.link.signature)"
            class="icon nav__icon"
          />
          <span class="nav__text"> {{ this.link.anchor_name }}</span>
        </div>
      </template>
      <template v-else-if="link.image">
        <div class="noty-badge__text">
          <div class="noty-badge__image-wrapper">
            <img :src="this.link.image" :alt="this.link.image">
          </div>
          <span class="nav__text">{{ this.link.title }}</span>
        </div>
      </template>
    </template>
  </div>
</template>
<script>
import iconLoader from '@/utils/iconLoader';
import {mapState, mapGetters} from 'vuex';
import {FEEDBACK_ROUTE} from '@/router/routes.js';
import {getCommonCaptionByName} from '@/i18n/utils';

export default {
  name: 'RouterTmpl',
  components: {},
  props: ["link"],
  data() {
    return {
      FEEDBACK_ROUTE,
      consolidateLinks: [],
      additionalMenu: [],
    };
  },
  created() {
  },
  computed: {
    ...mapState({
      menuActive: state => state.menuActive,
      notificationCount: state => state.notificationCounter.notificationCount
    }),
    ...mapGetters([
      'isTablet',
      'isMobile',
      'isPublicUser',
      'userHasAccessSuggestions'
    ]),
  },
  methods: {
    iconLoader,
    getCommonCaptionByName,

  },
};
</script>

<style>
.d_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
