<template>
  <div
    class="part-container"
    :class="{'is-error': vuelidateData && vuelidateData.$error}"
  >
    <div
      v-if="isLabel || label"
      class="part-form__label form__label"
      :class="{'part-form__label_large': labelSize === 'large'}"
    >
      {{ labelPart }}
    </div>
    <slot />
    <div
      v-if="!message && vuelidateData && vuelidateData.$error"
      class="el-form-item__error"
    >
      <span v-if="vuelidateData.required === false && vuelidateData.$dirty">
        {{ errors.required }}
      </span>
      <span v-else-if="vuelidateData.req_if === false">
        {{ errors.required }}
      </span>
      <span v-else-if="vuelidateData.numeric === false">
        {{ errors.numeric }}
      </span>
      <span v-else-if="vuelidateData.min === false">
        {{ `${errors.min} ${vuelidateData.$params.min.min}` }}
      </span>
      <span v-else-if="vuelidateData.max === false">
        {{ `${errors.max} ${vuelidateData.$params.max.max}` }}
      </span>
      <span v-else-if="vuelidateData.digits === false">
        {{ `${errors.digits} ${vuelidateData.$params.digits?.value}` }}
      </span>
      <span v-else-if="vuelidateData.date === false">
        {{ errors.date }}
      </span>
      <span v-else-if="vuelidateData.after_or_equal === false">
        {{
          `${errors.after_or_equal} ${vuelidateData.$params.after_or_equal.value}`
        }}
      </span>
      <span v-else-if="vuelidateData.before_or_equal === false">
        {{
          `${errors.before_or_equal} ${vuelidateData.$params.before_or_equal.value}`
        }}
      </span>
      <span v-else-if="vuelidateData.after === false">
        {{ `${errors.after} ${vuelidateData.$params.after.value}` }}
      </span>
      <span v-else-if="vuelidateData.before === false">
        {{ `${errors.before} ${vuelidateData.$params.before.value}` }}
      </span>
      <span v-else-if="vuelidateData.minValue === false">
        {{ `${errors.minValue} ${vuelidateData.$params.minValue.min}` }}
      </span>
      <span v-else-if="vuelidateData.maxValue === false">
        {{ `${errors.maxValue} ${vuelidateData.$params.maxValue.max}` }}
      </span>
      <span v-else-if="vuelidateData.email === false">
        {{ errors.email }}
      </span>
    </div>
    <div
      v-if="message"
      class="el-form-item__message"
    >
      <small>{{ message }}</small>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PartContainer',
  props: {
    code: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    isLabel: {
      type: Boolean,
      default: false
    },
    labelSize: {
      type: String,
      default: 'default' // default | large
    },
    vuelidateData: {
      type: Object,
      default: () => {}
    },
    requiredSymbol: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    externalErrors: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      errors: {
        required: 'Обязательно для заполнения',
        digits: 'Количество символов должно быть ',
        email: 'Некорректный email',
        numeric: 'Значения должны быть числовыми',
        min: 'Количество символов не менее ',
        max: 'Количество символов не более',
        date: 'Некорректная дата',
        after_or_equal: 'Дата должна быть от ',
        before_or_equal: 'Дата должна быть до ',
        after: 'Дата должна быть позже',
        before: 'Дата должна быть раньше ',
        minValue: 'Значение должно быть не меньше',
        maxValue: 'Значение должно быть не больше',
        ...this.externalErrors
      }
    };
  },
  computed: {
    labelPart() {
      if (this.requiredSymbol && this.vuelidateData) {
        if (Object.hasOwn(this.vuelidateData, 'required')) {
          return this.label + this.requiredSymbol;
        }
      }

      return this.label;
    }
  }
};
</script>

<style lang="scss">
.part-form__label {
  display: block;
  min-height: 14px;
}
.part-form__label.part-form__label_large {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-bottom: 18px;
  color: $primary-midnight-1;
  display: block;
}
.el-form-item__message {
  position: absolute;
  font-size: 16px;
  color: $primary-dusk-1;
}
</style>
