<template>
  <div :class="{'is-error': vuelidateData && vuelidateData.$error}">
    <slot />
    <div
      v-if="vuelidateData && vuelidateData.$error && vuelidateData.$dirty"
      class="el-form-item__error"
    >
      <span v-if="errorText">
        {{ errorText }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormElementContainer',
  props: {
    vuelidateData: {
      type: Object,
      default: () => ({})
    },
    errors: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      innerErrors: {
        required: 'Обязательно для заполнения',
        email: 'Некорректный email',
        ...this.errors
      }
    };
  },
  computed: {
    errorText() {
      for (const key in this.innerErrors) {
        const val = key.split('.').reduce((o, i) => o[i], this.vuelidateData);

        if (val === undefined) {
          continue;
        }

        if (!val) {
          return this.innerErrors[key];
        }
      }
      return null;
    }
  }
};
</script>
