<template>
  <div
    class="status-time"
    :style="{color: colorText}"
  >
    {{ timeRemaining }}
  </div>
</template>

<script>
import {differenceInDays, parse, startOfDay} from 'date-fns';

export default {
  name: 'ProgramTimeStatus',

  props: {
    localeCourseStatus: {
      type: Object,
      default: () => {}
    },
    dateStart: {
      type: String,
      default: '01.01.1970'
    },
    dateEnd: {
      type: String,
      default: '01.01.1970'
    }
  },
  data() {
    return {
      COLOR: {
        DEFAULT: '#000000',
        BLUE: '#0050cf',
        GRAY: '#6c7c98',
        RED: '#ff564e'
      },
      colorText: '#000000'
    };
  },

  computed: {
    start() {
      return parse(this.dateStart, 'dd.MM.yyyy', new Date());
    },
    end() {
      return parse(this.dateEnd, 'dd.MM.yyyy', new Date());
    },
    now() {
      return startOfDay(new Date());
    },
    daysFromStart() {
      return differenceInDays(this.start, this.now, {addSuffix: true});
    },
    daysFromEnd() {
      return differenceInDays(this.end, this.now, {addSuffix: true});
    },
    timeRemaining() {
      switch (true) {
        case this.daysFromEnd < 0:
          this.setColor(this.COLOR.GRAY);
          return this.localeCourseStatus.courseCompleted;
        case this.daysFromStart < 0:
          return this.localeCourseStatus.courseInProgress;
        case this.daysFromStart === 0:
          return this.localeCourseStatus.courseStartsToday;
        case this.daysFromStart > 7:
          this.setColor(this.COLOR.BLUE);
          return `${this.localeCourseStatus.courseDaysLeft}: ${this.daysFromStart}`;
        case this.daysFromStart > 0:
          this.setColor(this.COLOR.RED);
          return `${this.localeCourseStatus.courseDaysLeft}: ${this.daysFromStart}`;
        default:
          return '';
      }
    }
  },

  methods: {
    setColor(color) {
      this.colorText = color;
    }
  }
};
</script>

<style lang="scss">
.status-time {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin: 5px 0;
}
</style>
