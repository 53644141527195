<template>
  <div>
    <p class="profile__table-title">
      {{ $t(getCommonCaptionByName('contacts_gender')) }}
    </p>
    <div class="profile__table-info">
      <div class="input-profile-contact" v-if="!isGenderEditable" :class="{profile_el_empty: profileFillErrors}">
        <div class="input-profile-contact__value">
          <span v-if="gender && gender.name">{{ gender.name }}</span>
          <span v-else>
            -
          </span>
        </div>
        <div class="input-profile-contact__btn">
          <button
            @click="setGenderFieldEditable"
            class="btn btn--secondary btn--icon"
          >
            <edit class="edit-icon" />
          </button>
        </div>
      </div>
      <select-gender
        :gender="gender"
        @set-gender="setGender"
        @failed-setting-gender="closeGenderEditable"
        v-else
      />
    </div>
  </div>
</template>

<script>
import { getCommonCaptionByName } from '@/i18n/utils';
import Edit from '@/assets/icons/edit.svg?inline';
import SelectGender from './selectGender';

export default {
  name: 'elk-profile-info-gender',
  components: {
    Edit,
    SelectGender,
  },
  computed: {
    profileFillErrors() {
      let errors = this.$store.getters['profileFillCheck/profileFillErrors'].map(function(obj) {
        return obj.code;
      });
      if(errors?.includes('sex')) return true;
    },
  },
  props: {
    gender: Object,
  },
  data() {
    return {
      isGenderEditable: false,
    };
  },
  methods: {
    getCommonCaptionByName,
    setGender(nationalityInfo) {
      this.closeGenderEditable();
      this.$emit('set-gender', nationalityInfo);
    },
    setGenderFieldEditable() {
      this.isGenderEditable = true;
    },
    closeGenderEditable() {
      this.isGenderEditable = false;
    },
  },
};
</script>
