<template>
  <div class="f-contract-types">
    <div class="f-contract-types__label">
      <i class="el-icon-info" />
      {{ title }}
    </div>
    <el-radio-group v-model="type">
      <el-radio
        v-for="opts in contractTypes"
        :key="opts.id"
        :label="opts.code"
      >
        {{ opts.name }}
      </el-radio>
    </el-radio-group>
  </div>
</template>

<script>
export default {
  name: 'ContractTypes',
  model: {
    prop: 'contractType',
    event: 'change'
  },
  props: {
    title: {
      type: String,
      default: 'Выберите тип договора'
    },
    contractType: {
      type: String,
      default: ''
    },
    contractTypes: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      type: this.contractType
    };
  },
  watch: {
    type(v) {
      this.$emit('change', v);
    },
    contractType(v) {
      if (v !== this.type) {
        this.type = v;
      }
    }
  }
};
</script>

<style lang="scss">
.f-contract-types .el-radio-group {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
}

.f-contract-types .f-contract-types__label {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 16px;
}

.f-contract-types .f-contract-types__label i {
  color: $color-gray-dusk;
}
</style>
