<template>
  <div class="auth-form-block rec-wrap">
    <div v-if="STEP !== SUCCESS" class="auth-form__wrapper">
      <button @click.prevent="logger">logger</button>
      <h2>Восстановление пароля</h2>

      <div v-if="STEP === CHOOSE_TYPE" class="form-wrapper__radio-button">
        <p class="form-title__recovery">
          Выберите один из нижеперечисленных способов
        </p>
        <div class="inputRadio">
          <input
            id="radioEmail"
            v-model="form.recoveryMethod"
            :value="BY_EMAIL"
            class="custom-radio"
            type="radio"
            name="recovery"
          />
          <label for="radioEmail"> Восстановить пароль по E-mail </label>
        </div>
        <div class="inputRadio">
          <input
            id="radioPhone"
            v-model="form.recoveryMethod"
            :value="BY_PHONE"
            class="custom-radio"
            type="radio"
            name="recovery"
          />
          <label for="radioPhone">
            Восстановить пароль по номеру телефона
          </label>
        </div>
      </div>

      <form id="form-recovery" class="form-auth">
        <div v-if="STEP === VALIDATION" class="wrap-input__custom">
          <custom-input
            v-if="isCurrentStep(BY_EMAIL, VALIDATION)"
            id="email"
            type="email"
            :label="label.email"
            :value="$v.form.email"
          >
            <!-- @blur="handleExistEmailCheck" -->
            <template #errors>
              <!-- <div
                v-if="!$v.form.email.isUnique"
                class="error-message"
              >
                Данный Email занят.
              </div> -->
            </template>
          </custom-input>

          <input-phone
            v-if="isCurrentStep(BY_PHONE, VALIDATION)"
            :label="label.phone"
            :value="$v.form.phone"
            @get-masked-phone="setPhoneString"
          />
        </div>

        <template v-if="STEP === GET_CONFIRMATION_CODE">
          <p
            v-if="isCurrentStep(BY_EMAIL, GET_CONFIRMATION_CODE)"
            class="form-title__recovery"
          >
            На почту <span class="link--default">{{ form.email }}</span>
            было отправлено письмо с кодом:
          </p>

          <p
            v-if="isCurrentStep(BY_PHONE, GET_CONFIRMATION_CODE)"
            class="form-title__recovery"
          >
            На номер телефона <span>{{ maskedPhone }}</span>
            отправлено SMS c кодом, введите его ниже.
          </p>

          <confirm-message
            :label="confirmLabelText"
            :value="$v.form.confirmationCode"
            @on-reset-timer="sendConfirmationCode"
          />
        </template>

        <div v-if="STEP === CHANGE_PASSWORD" class="recovery-password__block">
          <h5>Введите новый пароль</h5>
          <input-password :label="label.password" :value="$v.form.password">
            <template #errors>
              <div v-if="!$v.form.password.minLength" class="error">
                Минимум 8 символов.
              </div>
            </template>
          </input-password>

          <input-password
            :label="label.passwordConfirm"
            :value="$v.form.passwordConfirm"
          >
            <template #errors>
              <div v-if="!$v.form.passwordConfirm.sameAsPassword" class="error">
                Пароли должны совпадать.
              </div>
            </template>
          </input-password>
          <form-alert />
        </div>
        <div class="btn-block__form">
          <button
            :disabled="$v.form.$invalid"
            class="btn btn-checkout"
            @click.stop.prevent="changeStep"
          >
            {{ nextButtonText }}
          </button>
          <button
            class="btn btn-checkout-phone"
            @click.stop.prevent="changeStep(false)"
          >
            {{ prevButtonText }}
          </button>
        </div>
      </form>
    </div>
    <div v-if="STEP === SUCCESS" class="block-finish">
      <div class="img-finish" />
      <h2>
        {{ titleInfo }}
      </h2>
      <p>
        {{ infoFinish }}
      </p>
    </div>
  </div>
</template>

<script>
import { email, minLength, required, sameAs } from 'vuelidate/lib/validators';
import Axios from '@/utils/Elk-Axios';
import {
  RESET_PASSWORD_STEP1,
  RESET_PASSWORD_STEP2,
  RESET_PASSWORD_STEP3,
} from '@/api';

import setNotification from '@/utils/setNotification';
import { AUTHORIZATION, HOME_ROUTE } from '@/router/routes';

import customInput from '@/components/inputComponent/CustomInput';
import ConfirmMessage from '@/components/confMessage/ConfirmMessage';
import FormAlert from '@/components/FormAlerts/formAlert';
import InputPhone from '@/components/inputComponent/inputPhone';
import InputPassword from '@/components/inputComponent/inputPassword';

const RECOVERY_METHOD = {
  BY_EMAIL: 'recovery-by-email',
  BY_PHONE: 'recovery-by-phone',
};

const RECOVERY_STEP = {
  CHOOSE_TYPE: 'choose-recovery-type',
  VALIDATION: 'validation',
  GET_CONFIRMATION_CODE: 'get-confirmation-code',
  CHANGE_PASSWORD: 'change-password',
  SUCCESS: 'success',
};

export default {
  name: 'RecoveryStart',

  components: {
    InputPassword,
    InputPhone,
    ConfirmMessage,
    customInput,
    FormAlert,
  },

  data() {
    return {
      isActive: false,
      label: {
        email: 'E-mail',
        password: 'Пароль',
        passwordConfirm: 'Повторите пароль',
        phone: 'Номер телефона',
      },
      titleInfo: 'Пароль изменён!',
      infoFinish:
        'В течение пяти секунд вас\n' +
        '        переадресует на главную страницу сайта. Удачи!',
      maskedPhone: '',
      form: {
        recoveryMethod: '',
        email: 'fetisov_fd@inbox.ru',
        phone: '9232880808',
        password: 'Aa!1aaaa',
        passwordConfirm: 'Aa!1aaaa',
        confirmationCode: '',
      },
      STEP: RECOVERY_STEP.CHOOSE_TYPE,
      ...RECOVERY_METHOD,
      ...RECOVERY_STEP,
    };
  },

  computed: {
    nextButtonText() {
      switch (this.STEP) {
        case this.VALIDATION:
          return 'Отправить код';
        case this.CHANGE_PASSWORD:
          return 'Сохранить изменения';
        default:
          return 'Далее';
      }
    },
    prevButtonText() {
      return this.form.recoveryMethod ? 'Назад' : 'Авторизация';
    },
    confirmLabelText() {
      return this.form.recoveryMethod === this.BY_PHONE
        ? 'Код из СМС'
        : 'Код из письма';
    },
  },

  validations() {
    if (this.STEP === this.CHOOSE_TYPE) {
      return {
        form: {
          recoveryMethod: {
            required,
          },
        },
      };
    } else if (
      this.STEP === this.VALIDATION &&
      this.form.recoveryMethod === this.BY_EMAIL
    ) {
      return {
        form: {
          email: {
            required,
            email,
          },
        },
      };
    } else if (
      this.STEP === this.VALIDATION &&
      this.form.recoveryMethod === this.BY_PHONE
    ) {
      return {
        form: {
          phone: {
            required,
            minLength: minLength(10),
          },
        },
      };
    } else if (this.STEP === this.GET_CONFIRMATION_CODE) {
      return {
        form: {
          confirmationCode: {
            required,
            minLength: minLength(4),
          },
        },
      };
    } else if (this.STEP === this.CHANGE_PASSWORD) {
      return {
        form: {
          password: {
            isValid(value) {
              /* проверка на (нижний регистр)(верхний регистр)(цифру)(спецсимвол)[разрешенные символы] */
              const passwordCheck = new RegExp(
                '(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]',
                'gm'
              );
              return passwordCheck.test(value);
            },
            minLength: minLength(8),
          },
          passwordConfirm: {
            sameAsPassword: sameAs('password'),
          },
        },
      };
    }
  },

  methods: {
    logger() {
      console.log(this.form.recoveryMethod);
      console.log(this.$v.form);
    },
    isCurrentStep(method, step) {
      return this.form.recoveryMethod === method && this.STEP === step;
    },
    async changeStep(isForward = true) {
      switch (this.STEP) {
        case this.CHOOSE_TYPE:
          if (!isForward) return this.$router.push({ path: AUTHORIZATION });
          return (this.STEP = this.VALIDATION);
        case this.VALIDATION:
          await this.sendConfirmationCode();
          return (this.STEP = isForward
            ? this.GET_CONFIRMATION_CODE
            : this.CHOOSE_TYPE);
        case this.GET_CONFIRMATION_CODE:
          return (this.STEP = isForward
            ? this.CHANGE_PASSWORD
            : this.VALIDATION);
        case this.CHANGE_PASSWORD:
          if (isForward) {
            this.STEP = this.SUCCESS;
            return setTimeout(() => {
              this.$router.push({ path: HOME_ROUTE });
            }, 5000);
          }
          return (this.STEP = this.GET_CONFIRMATION_CODE);
        default:
          break;
      }
    },
    setPhoneString(value) {
      this.maskedPhone = value;
      // this.state.isPhoneConfirmationCodeSend = false;
    },
    async sendConfirmationCode() {
      try {
        console.log('sendConfirmationCode   ', this.form.recoveryMethod);

        const postData = {
          g_recaptcha_response: '',
        };
        if (this.form.recoveryMethod === this.BY_EMAIL) {
          postData.email = this.$v.form.$model.email;
        } else {
          postData.phone = this.$v.form.$model.phone;
        }

        const { data } = await Axios.post(RESET_PASSWORD_STEP1, postData);

        console.log({ data });
      } catch (error) {
        /** */
      }
      try {
        console.log('sendConfirmationCode   ', this.form.recoveryMethod);
        const { data } = await Axios.post(RESET_PASSWORD_STEP2, {
          email: this.$v.form.$model.email,
          source: '',
        });

        console.log({ data });
      } catch (error) {
        /** */
      }
      try {
        console.log('sendConfirmationCode   ', this.form.recoveryMethod);
        const { data } = await Axios.post(RESET_PASSWORD_STEP3, {
          email: this.$v.form.$model.email,
          code: '',
          source: '',
          g_recaptcha_response: '',
        });

        console.log({ data });
      } catch (error) {
        /** */
      }
    },
  },
};
</script>

<style lang="scss">
.auth-form-block.rec-wrap {
  .inputWrap {
    margin-left: 0;
  }
  .wrap-input__custom {
    .inputWrap {
      margin-left: 0;
    }
  }
  .recovery-password__block {
    .inputWrap {
      margin-left: 0;
    }
  }
  .btn-block__form {
    margin-top: 16px;
  }
}

.icon-phone__input {
  position: absolute;
  top: 38px;
  right: 15px;
  width: 24px;
  height: 24px;
  background: url('../../assets/icons/icon-rus.svg') 100% 100% no-repeat;
}
.block-finish {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .img-finish {
    width: 200px;
    height: 200px;
    margin-bottom: 24px;
    background: url('../../assets/icons/icon-succes.svg') 100% 100% no-repeat;
  }
  h2 {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: $base-black-color;
    margin-bottom: 24px;
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $base-black-color;
    text-align: center;
    margin-bottom: 0;
  }
}

.recovery-password__block {
  h5 {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: $base-black-color;
    text-align: center;
    margin-bottom: 24px;
  }
}

.auth-form-block.rec-wrap {
  h2 {
    margin-bottom: 24px;
  }
}
.form-wrapper__radio-button {
  margin-bottom: 48px;
}
.form-title__recovery {
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: $base-black-color;
  margin-bottom: 24px;
}
.inputRadio {
  margin-bottom: 16px;
  .custom-radio {
    position: absolute;
    z-index: -1;
    opacity: 0;
  }
  .custom-radio + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    cursor: pointer;
  }
  .custom-radio + label::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid $dusk-gray-color;
    border-radius: 50%;
    margin-right: 8px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
  }
  .custom-radio:checked + label::before {
    border-color: $blue-color;
    background-color: $blue-color;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
    background-size: 6px;
    background-position: center;
  }
}

@media (max-width: 768px) {
  .auth-form-block.rec-wrap {
    .form-auth {
      .confirm__message {
        flex-direction: column;
        .inputWrap.error-system,
        .resend-message {
          transition: all 0.2s ease;
          width: 100%;
        }
      }
    }
  }
}
</style>
