<template>
  <el-pagination
    background
    layout="prev, pager, next"
    :total="total"
    :pager-count="5"
    :page-size="pageSize"
    :hide-on-single-page="true"
    class="dpo-element-pagination"
    :current-page="currentPage"
    @update:
    @current-change="v => $emit('change-page', v)"
  />
</template>

<script>
export default {
  name: 'DpoElementPagination',
  props: ['currentPage', 'total', 'pageSize']
};
</script>

<style lang="scss">
.dpo-element-pagination {
  margin-top: 24px;

  &.el-pagination {
    padding: 2px 0px;
  }
  &.el-pagination.is-background .el-pager li {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    padding: 5px;
    background: $primary-light-1;
    border-radius: 8px;
    font-size: 16px;
    line-height: 1.25;
    font-weight: 600;
    color: $primary-midnight-1;
    box-sizing: border-box;
  }

  &.el-pagination.is-background .el-pager li:hover:not(.disabled) {
    background-color: $primary-brand-0-4;
    color: $primary-light-1;
  }

  &.el-pagination.is-background .el-pager li.active:not(.disabled) {
    background-color: $primary-brand-1;
    color: $color-background-white;
  }

  &.el-pagination.is-background :is(.btn-prev, .btn-next) {
    background: $primary-light-1;
    color: $primary-midnight-1;
    width: 40px;
    height: 40px;
    border-radius: 8px;

    .el-icon {
      font-size: 16px;
      font-weight: 600;
    }
    &:first-child {
      margin-left: 0px;
    }
    &:last-child {
      margin-right: 0px;
    }
  }
  &.el-pagination.is-background :is(.btn-prev, .btn-next):not(:disabled):hover {
    background-color: $primary-brand-0-4;
    color: $color-background-white;
  }
}

@media screen and (max-width: 640px) {
  .dpo-element-pagination {
    &.el-pagination.is-background .el-pager li {
      height: 32px;
      width: 32px;
      margin: 0 2px;
      font-size: 14px;
    }
    &.el-pagination.is-background :is(.btn-prev, .btn-next) {
      width: 32px;
      height: 32px;
    }
  }
}
</style>
