var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isError)?_c('div',{staticClass:"avatar__dummy",style:({
    width: _vm.getAvatarSize,
    height: _vm.getAvatarSize,
    fontSize: _vm.getFontSize
  })},[_vm._v(" "+_vm._s(_vm.getTheFirstLetters)+" ")]):_c('el-image',{staticClass:"avatar",style:({width: _vm.getAvatarSize, height: _vm.getAvatarSize}),attrs:{"src":_vm.urlAvatar,"alt":"Аватар"}},[_c('template',{slot:"error"},[(_vm.genderAvatar === _vm.getGenderCodes.male)?_c('img',{style:({width: _vm.getAvatarSize, height: _vm.getAvatarSize}),attrs:{"src":require(`@/assets/icons/staff/male-icon.svg`)}}):(_vm.genderAvatar === _vm.getGenderCodes.female)?_c('img',{style:({width: _vm.getAvatarSize, height: _vm.getAvatarSize}),attrs:{"src":require(`@/assets/icons/staff/female-icon.svg`)}}):_c('div',{staticClass:"avatar__dummy",style:({
        width: _vm.getAvatarSize,
        height: _vm.getAvatarSize,
        fontSize: _vm.getFontSize
      })},[_vm._v(" "+_vm._s(_vm.getTheFirstLetters)+" ")])])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }