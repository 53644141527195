<template>
    <div class="notifications-spoiler" :class="{'notifications-spoiler--fresh': fresh}" :data-id="elId">
        <button class="notifications-spoiler__header" @click="toggleBody($event, elId)">

            <span class="marker marker--important" v-if="status">Важно</span>

            <span>
                {{ caption }}
            </span>
            <time>
                {{ date }}
            </time>
        </button>
        <div class="notifications-spoiler__body" v-html="bodyContent">
        </div>
    </div>
</template>
<script>

export default {
    name: 'NotificationSpoiler',
    props: {
        elId: Number,
        fresh: Boolean,
        status: Boolean,
        date: String,
        caption: String,
        bodyContent: String,
    },
    data(){
        return{

        }
    },
    methods: {
        /**
         * toggleBody - метод раскрывает и скрвыает текс уведомления и при закрытии отправляет родителю id данного уведомления для того, что бы сделать установить его как прочитанное.
         * @param {$event} e - объект события
         * @param {Number} id - id текущего спойлера сообщения
         */
        toggleBody(e, id){

            let currentBody  = e.currentTarget.nextSibling;

            if(currentBody.offsetHeight > 0){
                let heightBody = currentBody.offsetHeight;
                currentBody.style.height = heightBody + 'px';
                setTimeout(()=>{
                    currentBody.style.height = '0';
                    setTimeout(()=>{
                      currentBody.removeAttribute('style');
                      currentBody.style.display = 'none';
                      if (this.fresh) {
                        this.$emit('read', id);
                      }
                    }, 200);
                }, 50);


            }else{
                currentBody.style.display = 'block';
                let heightBody = currentBody.offsetHeight;
                currentBody.style.height = '0';
                setTimeout(() => {
                    currentBody.style.height = heightBody + 'px';
                    setTimeout(() => {
                        currentBody.style.height = 'auto';
                        if (this.fresh) {
                          this.$emit('read', id);
                        }
                    }, 300);
                }, 50);
            }

        }
    }
}
</script>
<style lang="scss">
    .notifications-spoiler{
        position: relative;
        padding: 0 24px;
        border-radius: 4px;
        background-color: #ffffff;
        box-sizing: border-box;
        box-shadow: 0px 1px 2px rgba(106, 122, 152, 0.15);
        overflow: hidden;
        &--fresh{
            .notifications-spoiler__header{
                .marker{
                    filter: grayscale(0);
                }
                span{
                    color: #0F0F14;
                }
            }
        }
    }
    .notifications-spoiler__header{
        display: flex;
        align-items: center;
        width: 100%;
        padding: 24px 0;
        background-color: transparent;
        text-align: left;
        border: none;
        outline: none;
        cursor: pointer;
        span{
            color: #6B7A99;
            font-size: 16px;
            line-height: 1.25;
            font-weight: 600;
            transition: all 200ms ease;
            margin-right: 20px;
        }
        time{
            margin-left: auto;
            font-size: 16px;
            line-height: 1.25;
            font-weight: 400;
        }
        .marker{
            display: block;
            padding: 6px 10px;
            border-radius: 4px;
            margin-right: 10px;
            box-sizing: border-box;
            transition: all 200ms ease;
            font-size: 12px;
            line-height: 1;
            filter: grayscale(100%);
            &--important{
                background-color: #FFEEED;
                color: #FF564E !important;
            }
        }
    }

    .notifications-spoiler__body{
        display: none;
        border-top: 1px solid #D3D8E5;
        transition: all 300ms ease;
        &>*:first-child{
            margin-top: 24px;
        }
        &>*:last-child{
            padding-bottom: 24px;
        }
    }
    @media screen and (max-width: 768px){
        .notifications-spoiler__header{
            span {
                font-size: 14px;
            }
        }
    }
    @media screen and (max-width: 670px){
        .notifications-spoiler {
            .notifications-spoiler__header {
                flex-wrap: wrap;
                span{
                    order: 3;
                    width: 100%;
                    margin-top: 8px;
                }
                time{
                    order: 2;
                    margin-left: 0;
                    font-size: 12px;
                }
                .marker{
                    width: auto;
                    order: 1;
                    margin-top: 0;
                }
            }
        }
        .notifications-spoiler__body{
            font-size: 14px;
        }
    }
</style>
