<template>
  <div>
    <div ref="smartCaptcha" class="smart-captcha" />
  </div>
</template>

<script>
export default {
  name: 'SmartcaptchaYandex',

  data() {
    return {
      token: null,
      widgetId: null,
    }
  },

  mounted() {
    const smartcaptchaScript = document.createElement('script');

    smartcaptchaScript.setAttribute(
      'src',
      'https://smartcaptcha.yandexcloud.net/captcha.js?render=onload&onload=onloadFunction'
    );
    smartcaptchaScript.setAttribute('defer', 'defer');

    document.head.appendChild(smartcaptchaScript);

    setTimeout(this.onloadFunction, 0);
  },

  methods: {
    async onloadFunction() {
      if (window.smartCaptcha) {
        Promise
          .resolve()
          .then(() => {
            const container = this.$refs.smartCaptcha;

            const widgetId = window.smartCaptcha.render(container, {
              sitekey: process.env.VUE_APP_SMARTCAPTCHA_YANDEX,
              callback: (token) => {
                console.log({ token });
                this.token = token;
                this.$emit('on-verify', token);
              },
              // test: true /** Пользователь всегда будет получать задание. */,
              invisible: true,
              shieldPosition: 'bottom-right',
              hl: this.$i18n.locale ?? 'ru',
            });

            this.widgetId = widgetId;
            this.$emit('on-get-widget-id', widgetId);

            return widgetId;
          })
          .then((id) => {
            window.smartCaptcha.execute(id);
          });

      } else {
        requestAnimationFrame(this.onloadFunction);
      }
    },
  },
};
</script>
