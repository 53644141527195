<template>
  <div class="profile__born-editor">
    <elk-date-picker
      class="date-picker"
      v-model="newBirthDate"
      :placeholder="placeholder"
      value-format="yyyy-MM-dd"
      :clearable="false"
      :default-mask="defaultMask"
      @input="onChangeDate"
    />
    <div>
      <el-button
        class="btn btn--add"
        :class="{ 'btn--primary-disabled': !newBirthDate }"
        @click="setProfileBirthdate"
        :loading="isLoading"
        :disabled="!newBirthDate"
      >
        {{ $t(getUiItemCaption(signature, 'btnChangeEditorBirthDate')) }}
      </el-button>
      <button class="btn btn--cancel" @click="toggleEditorBirthDate">
        {{ $t(getUiItemCaption(signature, 'btnCancelEditorBirthDate')) }}
      </button>
    </div>
  </div>
</template>

<script>
import ElkDatePicker from '@/components/common/elkDatePicker';
import { getUiItemCaption } from '@/i18n/utils';
import { apiSetProfileBirthdate } from './api';
import setNotification from '@/utils/setNotification';
export default {
  name: 'elk-editor-birthDate',
  inject: ['signature'],
  components: { ElkDatePicker },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    defaultMask: {
      type: Boolean
    }
  },
  data() {
    return {
      newBirthDate: null,
      isLoading: false,
    };
  },
  methods: {
    getUiItemCaption,
    setNotification,
    apiSetProfileBirthdate,
    toggleEditorBirthDate() {
      this.newBirthDate = null;
      this.$emit('toggle-editor-birthDate');
    },
    async setProfileBirthdate() {
      this.$emit('update-info', {
        type: 'new-birthdate',
        value: this.newBirthDate,
      });
      this.toggleEditorBirthDate()
    },
    showNotify(message, typeMessage = 'info') {
      const h = this.$createElement;

      this.$notify({
        title: 'Сообщение',
        type: typeMessage,
        message: h('i', { }, message)
      });
    },
    validBirthDate(dateStr) {

      const date = new Date(dateStr)
      const currentDate = new Date()
      const min = 14;
      const max = 100
      const diff = currentDate.getFullYear() - date?.getFullYear() ?? 0
      if (diff < min) {
        return 'Возраст не должен быть меньше 14 лет'
      }
      if (diff > max) {
        return 'Возраст не должен превышать 100 лет'
      }

      return true
    },
    onChangeDate(value){
      const result = this.validBirthDate(value)
      if (typeof result === 'string') {
        this.newBirthDate = null
        this.showNotify(result, 'error')
      }
    }
  },
};
</script>
