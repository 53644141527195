import { setBreadcrumbs, clearBreadcrumbs } from '../mutationsName';
import Vue from 'vue';

export default {
  namespaced: true,

  state: {
    breadcrumbs: [],
  },

  mutations: {
    [setBreadcrumbs](state, newBreadcrumbs) {
      Vue.set(state, 'breadcrumbs', newBreadcrumbs);
    },
    [clearBreadcrumbs](state) {
      Vue.set(state, 'breadcrumbs', []);
    },
  },
};
