<template>
  <el-badge
    class="link-badge__btn"
    :value="count"
  >
    <router-link
      class="link__badge-menu"
      :class="{'header-mobile__link': mobile}"
      :style="{
        width: mobile ? '32px' : '18px',
        height: mobile ? '30px' : '16px',
        'background-size': mobile ? 'contain' : 'inherit'
      }"
      :to="link"
    />
  </el-badge>
</template>

<script>
import {COMPARISONPAGE} from '@/router/routes';
export default {
  name: 'LinkBtnMenuHeader',
  props: {
    mobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      link: COMPARISONPAGE
    };
  },
  computed: {
    count() {
      return this.$store.getters['compares/counter'] || '';
    }
  }
};
</script>

<style lang="scss">
.el-badge.link-badge__btn {
  .el-badge__content {
    z-index: 5;
    background-color: var(--dark-blue);
    font-size: 10px;
    line-height: 15px;
    border: 2px solid #fff;
    @media screen and (max-width: 768px) {
      top: 5px !important;
      right: unset !important;
    }
  }
  .link__badge-menu {
    /*width: 18px;
    height: 16px;*/
    display: block;
    background: url('./../../../assets/icons/icon-menu-header.svg') 100% 100%
      no-repeat;
  }
  .router-link-active.link__badge-menu {
    background: url('./../../../assets/icons/icon-menu-header-active.svg') 100%
      100% no-repeat;
  }
}
</style>
