<template>
  <form
    class="form form--profile-contact"
    @submit.prevent="setNewPhone"
    @keyup.esc="closeEdit"
  >
    <div class="form__item input-profile-contact">
      <div class="input-profile-contact__value">
        <div class="data-wrap__inputs">
          <input-phone
            label=""
            :value="$v.phoneLocal"
            :required="false"
            class="input-phone__change"
            @get-masked-phone="setPhone"
          />
        </div>
        <div
          v-if="$v.phoneLocal.$invalid"
          class="form__error"
        >
          {{ $t(getCommonCaptionByName('validation_inputValidValue')) }}
        </div>
      </div>
      <div class="input-profile-contact__btn">
        <button
          type="button"
          class="btn btn--secondary"
          @click.prevent="closeEdit"
        >
          {{ $t(getCommonCaptionByName('cancel')) }}
        </button>
      </div>
    </div>
    <div
      v-if="isPhoneValid && wasCurrentPhoneChanged"
      class="form__item input-confirmation pt-6"
    >
      <div class="form__item-row">
        <div class="form__item-col">
          <div class="form__item-container">
            <input
              v-if="!isSendingPhoneConfirmationCodeLoading"
              class="btn btn--secondary u-entire-width"
              :class="{
                'btn--secondary-disabled': isSendingPhoneCodeBlocked
              }"
              :value="$t(getCommonCaptionByName('confirmationCode_sendCode'))"
              type="button"
              :disabled="isSendingPhoneCodeBlocked"
              @click="sendConfrimationCode"
            />
            <div
              v-else
              class="form__loader"
            >
              <div class="btn btn--secondary btn--secondary-disabled">
                <div class="loader-container">
                  <loading class="loader loader--input" />
                </div>
              </div>
            </div>
            <p
              v-if="isSendingPhoneCodeBlocked"
              class="form__item-message"
            >
              {{
                $t(
                  getCommonCaptionByName('confirmationCode_canRequestAgainIn'),
                  {
                    seconds: phoneConfirmationTimerCount
                  }
                )
              }}
            </p>
          </div>
        </div>

        <div
          class="form__item-col"
          :class="{
            'form__item--error': $v.phoneConfirmationCode.$error
          }"
        >
          <label
            class="form__label"
            for="code"
          >
            {{ $t(getCommonCaptionByName('confirmationCode_name')) }} *</label
          >
          <div class="form__input--with-action">
            <masked-input
              id="code"
              v-model.trim="phoneConfirmationCode"
              class="input"
              type="tel"
              autocomplete="off"
              :mask="CONFIRMATION_CODE_MASK"
              placeholder="* * * *"
            />
            <button
              v-if="!isSettingNewPhoneLoading"
              type="submit"
              class="btn btn--secondary btn--icon"
            >
              <Checkmark class="edit-icon" />
            </button>
            <div
              v-else
              class="loader-container"
            >
              <loading class="loader loader--input" />
            </div>
          </div>
          <div
            v-if="$v.phoneConfirmationCode.$error"
            class="form__error"
          >
            * {{ $t(getCommonCaptionByName('validation_mustBeInputed')) }}
          </div>
        </div>
      </div>
    </div>
    <div class="form__item">
      <p
        v-if="isWrongPhoneConfirmationCode"
        class="form__error--common"
      >
        {{ WRONG_CODE.message }}
      </p>
    </div>
  </form>
</template>

<script>
import MaskedInput from 'vue-text-mask';
import Axios from '@/utils/Elk-Axios';
import Checkmark from '@/assets/icons/check-mark.svg?inline';
import setNotification from '@/utils/setNotification';
import {SET_PHONE_MOBILE_STEP2, SET_PHONE_MOBILE_STEP1} from '@/api';
import {required} from 'vuelidate/lib/validators';
import {
  CONFIRMATION_CODE_MASK,
  checkConfirmationCode,
  PHONE_TEST_CODE
} from '@/utils/regExps';
import loading from '@/components/common/elkLoading';
import {SUCCESS, WRONG_CODE} from '@/api/responceMessages';
import {getUiItemCaption, getCommonCaptionByName} from '@/i18n/utils';
import InputPhone from '@/components/inputComponent/inputPhone';

export default {
  name: 'ProfilePersonalPhoneChangingForm',
  components: {
    InputPhone,
    Checkmark,
    MaskedInput,
    loading
  },
  inject: ['signature'],
  props: {
    phone: String,
    savedPhone: String
  },
  data() {
    return {
      WRONG_CODE,
      PHONE_TEST_CODE,
      isPhoneConfirmationBlockShowing: true,
      phoneConfirmationTimerSeconds: 60,
      phoneConfirmationTimerCount: 0,
      phoneConfirmationCodeTimerStorage: null,
      CONFIRMATION_CODE_MASK,
      isPhoneValid: true,
      isSendingPhoneConfirmationCodeLoading: false,
      phoneConfirmationCode: null,
      isWrongPhoneConfirmationCode: false,
      isSettingNewPhoneLoading: false,

      phoneLocal: null
    };
  },
  computed: {
    wasCurrentPhoneChanged() {
      const savedPhone = this.savedPhone.startsWith('+')
        ? this.savedPhone
        : '+' + this.savedPhone;

      const currentPhone = this.phone.startsWith('+')
        ? this.phone
        : '+' + this.phone;

      return savedPhone != currentPhone;
    },
    isSendingPhoneCodeBlocked() {
      return this.phoneConfirmationTimerCount !== 0;
    },
    inputClass() {
      return `input input--new-value ${this.isPhoneValid ? '' : 'not-valid'}`;
    }
  },
  created() {
    this.phoneLocal = this.phone;
  },
  validations: {
    phoneLocal: {
      phoneCheck: value => (value ? value.length >= 10 : true)
    },
    phoneConfirmationCode: {
      required,
      alpha: val => (val ? checkConfirmationCode(val) : true)
    }
  },
  beforeDestroy() {
    clearInterval(this.phoneConfirmationCodeTimerStorage);
  },
  mounted() {
    try {
      this.$refs.input.$el.focus();
    } catch (e) {
      //sentry
    }
  },
  methods: {
    getUiItemCaption,
    getCommonCaptionByName,
    setPhone(phone) {
      console.log(phone);
      const value = phone.replace(/\s+/g, '');
      this.$emit('change-phone', value);
      // if (value && value.length >= 9) {
      //   this.isPhoneValid = true;
      // } else {
      //   this.isPhoneValid = false;
      // }
    },
    setNotification,
    async sendConfrimationCode() {
      try {
        this.isSendingPhoneConfirmationCodeLoading = true;

        let {data} = await Axios.post(SET_PHONE_MOBILE_STEP1, {
          phone: this.phone
        });

        if (data && data.result === SUCCESS) {
          this.setPhoneConfirmationTimer();
        } else {
          //sentry
          throw new Error();
        }
      } catch (e) {
        if (!e.isHandled) {
          setNotification({
            message: this.$t(
              this.getCommonCaptionByName('confirmationCode_sendingError')
            )
          });
        }
      } finally {
        this.isSendingPhoneConfirmationCodeLoading = false;
      }
    },
    closeEdit() {
      this.$emit('cancel');
    },
    async setNewPhone() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isSettingNewPhoneLoading = true;
        if (this.isWrongPhoneConfirmationCode) {
          this.isWrongPhoneConfirmationCode = false;
        }
        try {
          let {data} = await Axios.post(SET_PHONE_MOBILE_STEP2, {
            phone: this.phone,
            code: this.phoneConfirmationCode
          });
          if (
            (data && data.result === SUCCESS) ||
            data.result === WRONG_CODE.code
          ) {
            if (data.result === SUCCESS) {
              this.$emit('success');
            } else if (data.result === WRONG_CODE.code) {
              this.isWrongPhoneConfirmationCode = true;
            }
          } else {
            //sentry
            throw new Error();
          }
        } catch (err) {
          if (!err.isHandled) {
            this.setNotification({
              message: this.$t(
                this.getUiItemCaption(
                  this.signature,
                  'failSettingNewPersonalPhone'
                )
              )
            });
          }
        } finally {
          this.isSettingNewPhoneLoading = false;
        }
      }
    },
    setPhoneConfirmationTimer() {
      if (this.phoneConfirmationTimerStorage) {
        clearInterval(this.phoneConfirmationTimerStorage);
      }
      this.phoneConfirmationTimerCount = this.phoneConfirmationTimerSeconds;
      this.phoneConfirmationTimerStorage = setInterval(() => {
        this.phoneConfirmationTimerCount -= 1;
        if (this.phoneConfirmationTimerCount === 0) {
          clearInterval(this.phoneConfirmationTimerStorage);
        }
      }, 1000);
    }
  }
};
</script>
<style>
.input-phone__change .icon-phone__input {
  top: 8px;
}

.input-phone__change.inputWrap-phone {
  margin: 0;
}

.input-phone__change.inputWrap-phone .input-form__auth {
  height: 42px;
}

.input-phone__change.inputWrap-phone label {
  display: none;
}
</style>
