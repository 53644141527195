<template>
  <div class="service-success">
    <div class="service-success--congratulation">
      <img
        src="@/assets/img/DPO_Logo_Main.svg"
        class="float-panel__decor-img"
      />
      <div class="service-success__content">
        <h2>{{ localeText.yourApplicationSent }}</h2>
        <p>
          {{ localeText.infoContactManager }}
        </p>
      </div>
      <a
        class="btn"
        href="/"
        role="button"
      >
        {{ localeText.toMain }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ServiceSuccess',
  props: {
    localization: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      localeText: {
        toMain: this.localization.commonCaption('toMainPage'),
        yourApplicationSent: this.localization.commonCaption(
          'yourApplicationSent'
        ),
        infoContactManager:
          this.localization.commonCaption('infoContactManager')
      }
    };
  }
};
</script>
<style lang="scss">
.service-success {
  width: 772px;
  margin: 0 auto;
  padding: 42px 109px;
  background-color: $color-background-white;
  text-align: center;

  .hse-svg-icon {
    display: block;
    max-width: 177px;
    width: 100%;
    height: auto;
    margin: 0 auto;
  }

  &__content {
    margin: 36px 0;
  }

  h2 {
    margin: 24px 0;
  }

  p {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: $color-gray-dusk;
  }

  .btn {
    max-width: 240px;
  }
}

@media (max-width: 940px) {
  .service-success {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .service-success {
    padding: 24px 32px;

    &__content {
      margin: 24px 0;
    }
  }
}
</style>
