<template>
  <el-badge
    class="link-badge__btn"
    :value="count"
  >
    <router-link
      class="link__badge-basket"
      :class="{ 'header-mobile__link': mobile }"
      :style="{
        width: mobile ? '32px' : '24px',
        height: mobile ? '32px' : '24px',

      }"
      :to="link"
    />
    <!-- :style="{
        width: mobile ? '32px' : '18px',
        height: mobile ? '32px' : '21px',
        'background-size': mobile ? 'contain' : 'inherit'
      }" -->
  </el-badge>
</template>

<script>
import { NEWBASKET } from '@/router/routes';

export default {
  name: 'LinkBasketHeader',
  props: {
    mobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      link: NEWBASKET ?? '/'
    };
  },
  computed: {
    count() {
      return this.$store.getters['cart/counterCartItems'];
    }
  }
};
</script>

<style lang="scss">
.el-badge.link-badge__btn {
  .el-badge__content {
    z-index: 5;
    background-color: var(--dark-blue);
    font-size: 10px;
    line-height: 15px;
    border: 2px solid #fff;

    @media screen and (max-width: 768px) {
      top: 5px !important;
      right: unset !important;
    }
  }

  .link__badge-basket {
    /*width: 18px;
    height: 21px;*/
    display: block;
    background: url('./../../../assets/icons/icon-basket-header.svg') 100% 100% no-repeat;
    background-size: contain;
  }

  .link__badge-basket.router-link-active {
    background: url('./../../../assets/icons/icon-basket-header-active.svg') 100% 100% no-repeat;
    background-size: contain;
  }

  .el-badge__content.is-fixed {
    top: -2px;
    right: 6px;
  }
}
</style>
