<script>
import IconSvg from "@/components/icons/IconSvg.vue"

export default {
  name: 'FilterIcon',
  extends: IconSvg,
  props: {
    d: {
      type: String,
      default: 'M14.25 11.25H3V12.75H14.25V11.25ZM16.5 9.75H15V14.25H16.5V12.75H21V11.25H16.5V9.75ZM7.5 8.25H9V3.75H7.5V5.25H3V6.75H7.5V8.25ZM21 5.25H9.75V6.75H21V5.25ZM7.5 17.25H3V18.75H7.5V20.25H9V15.75H7.5V17.25ZM21 17.25H9.75V18.75H21V17.25Z'
    }
  }
}
</script>
