import Axios from '@/utils/Elk-Axios';
import {REF_TRAINING_AREAS} from '@/api';

export default {
  namespaced: true,
  state: {
    /** @type {Reference[]} */
    items: []
  },
  getters: {
    /** @returns {Reference[]} */
    items: state => () => state.items,
    itemByCode(state) {
      return code => {
        if (!code) {
          return null;
        }

        const execute = (arr = []) => {
          for (const arrElement of arr) {
            let elem = null;
            if (arrElement.code === code) {
              elem = arrElement;
            } else if (Array.isArray(arrElement.items)) {
              elem = execute(arrElement.items);
            }
            if (elem) {
              return elem;
            }
          }
        };

        return execute(state.items);
      };
    }
  },
  mutations: {
    set(state, items) {
      state.items = items ?? [];
    }
  },
  actions: {
    async fetch({commit}) {
      const response = await Axios.get(REF_TRAINING_AREAS);
      commit('set', response?.data?.data ?? []);
    }
  }
};
