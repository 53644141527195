
export function debounce (callee, timeoutMs) {
  let lastCall
  let lastCallTimer
  
  return function(...args) {
    let previousCall = lastCall    
    lastCall = Date.now()
    
    if (previousCall && lastCall - previousCall <= timeoutMs) {
      clearTimeout(lastCallTimer)
    }
    
    lastCallTimer = setTimeout(() => callee.call(this,...args), timeoutMs)

  }
}
