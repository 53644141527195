import Axios from "@/utils/Elk-Axios";
import { GET_DOCUMENT_FIELDS_RULES } from "@/api";

export default {
  namespaced: true,
  state: {
    /** @type {FieldRule[]} */
    rules: [],
  },
  getters: {
    get: (state) => () => state.rules,
    getByDocumentTypeId: (state) => (id) => {
      return state.rules[id] || [];
    },
  },
  mutations: {
    set(state, rules) {
      state.rules = rules ?? [];
    },
  },
  actions: {
    async fetch({ commit }) {
      const { data } = await Axios.get(GET_DOCUMENT_FIELDS_RULES);

      if (data.code === 200 && data.data) {
        commit("set", data.data);
      }

      return data?.data || [];
    },
  },
};
