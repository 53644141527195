<template>
  <div
    :class="{
      'wrapper-select__document': true,
      'error-system': hasError
    }"
  >
    <span class="span__select-document">{{ label }}</span>
    <el-select
      filterable
      v-model="value.$model"
      :placeholder="placeholder"
      :disabled="disabled"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      />
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'InputSelect',

  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: () => ({
        $dirty: false,
        $error: false,
        $invalid: false,
        $model: ''
      })
    }
  },

  computed: {
    hasError() {
      return this.value.$error || (this.value.$dirty && this.value.$invalid);
    }
  },

  methods: {
    onBlur(event) {
      this.$emit('blur', event.target.value);
    }
  }
};
</script>
