<template>
  <div
    :class="{
      inputWrap: true,
      'error-system': hasError,
    }"
  >
    <label :for="id">
      {{ label }}
    </label>
    <input
      :id="id"
      v-model.trim="value.$model"
      class="input-form__prof issued-passport-input"
      :disabled="disabled"
      :type="type"
      :name="id"
      :required="required"
      @blur="onBlur"
    />
  </div>
</template>

<script>
export default {
  name: 'InputIssuedPass',

  props: {
    label: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    required: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Object,
      default: () => ({ $model: '' }),
    },
  },

  computed: {
    hasError() {
      return this.value.$error || (this.value.$dirty && this.value.$invalid);
    },
  },

  methods: {
    onBlur(event) {
      this.$emit('blur', event.target.value);
    },
  },
};
</script>

<style lang="scss">
.issued-passport-input {
  width: 825px;
}
</style>
