import Axios from '@/utils/Elk-Axios';
import { SET_ACTIVITY_ROLE } from '@/api';

async function setActivityRole(role) {
  try {
    await Axios.post(SET_ACTIVITY_ROLE, {
      signature: role,
    });
    return Promise.resolve();
  } catch (e) {
    return Promise.reject();
  }
}

export { setActivityRole };
