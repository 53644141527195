import Axios from '@/utils/Elk-Axios';
import {REF_FOR_WHOM, REF_MANAGEMENT_LEVELS} from '@/api';
import {get_codes} from "@/store/utils";
export default {
  namespaced: true,
  state: {
    /** @type {Reference[]} */
    items: [],
  },
  getters: {
    /** @returns {Reference[]} */
    items: (state) => () => state.items,
  },
  mutations: {
    set(state, items) {
      state.items = items ?? []
    },
  },
  actions: {
    async fetch({ commit }) {
      const response = await Axios.get(REF_MANAGEMENT_LEVELS);
      commit('set', response.data?.data ?? []);
      // commit('set', [
      //   {
      //     "name": 'Начало карьеры',
      //     "code": get_codes('начало', response.data.data),
      //   },
      //   {
      //     "name": "Линейный менеджмент",
      //     "code": get_codes('линейный', response.data.data),
      //   },
      //   {
      //     "name": "Средний менеджмент",
      //     "code": get_codes('средний', response.data.data),
      //   },
      //   {
      //     "name": "Владельцы бизнеса",
      //     "code": get_codes('владельцы', response.data.data),
      //   },
      //   {
      //     "name": "Высший менеджмент",
      //     "code": get_codes('высший', response.data.data),
      //   }
      // ] ?? []
      // )
    },
  }
}


