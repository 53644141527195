<template>
  <div class="item-list__document list-program__mod">
    <div class="list-program__number">
      <div class="status-item">{{ startCourseLabel }}</div>
      <div class="status-start__date">
        {{ program.pcStart }}
      </div>
      <program-time-status
        :date-start="program.pcStart"
        :date-end="program.pcEnd"
        :locale-course-status="localeCourseStatus"
      />
      <span class="status-finish"> {{ endCourseLabel }} </span>
      <p>
        {{ program.pcEnd }}
      </p>
    </div>

    <div class="list-program__content">
      <div class="list-program__badges">
        <program-badge-status
          v-if="program.legal && program.legal_corp"
          :status="{
            label: `${affiliatedWith} \`${program.legal_corp}\``,
            iconColor: getIconColorByStatus(PROGRAM_STATUS.OTHER)
          }"
        />
        <program-badge-status :status="program.status" />

        <div
          v-if="program.status_comment"
          @click="showStatusComment = true"
        >
          <program-badge-status-comment />
        </div>
      </div>
      <h6>
        {{ program.pcDirect }}
      </h6>
      <p>
        {{ program.pcCaption }}
      </p>
      <program-specialization
        :specialization="program.pcType"
        class="list-program__content-program-specialization"
      />
      <div class="list-program__content-program-title">
        {{
          program.program_full_name
            ? program.program_full_name
            : program.program_name
        }}
      </div>
    </div>

    <div class="list-program__buttons">
      <a
        v-if="program.pcStatus !== PROGRAM_STATUS.END && !program.legal"
        :href="serviceLink"
        class="link__contact"
        target="_blank"
      >
        {{ contactManagerLabel }}
      </a>
      <slot name="buttons" />
    </div>

    <el-dialog
      :title="managerComment"
      :visible.sync="showStatusComment"
      class="dialog-cancel-app"
    >
      <div>{{ program.status_comment }}</div>
    </el-dialog>
  </div>
</template>

<script>
import ProgramBadgeStatus from './programBadgeStatus.vue';
import ProgramSpecialization from './programSpecialization.vue';
import ProgramTimeStatus from './programTimeStatus.vue';
import {
  getIconColorByStatus,
  PROGRAM_STATUS
} from '@/utils/getProgramDataByStatus';
import {REFERENCEPAGE} from '@/router/routes';
import ProgramBadgeStatusComment from '@/components/listDocuments/DocumentsPrograms/common/programBadgeStatusComment.vue';

export default {
  name: 'ProgramListComponentReviewStatus',

  components: {
    ProgramBadgeStatusComment,
    ProgramBadgeStatus,
    ProgramSpecialization,
    ProgramTimeStatus
  },

  props: {
    localeCourseStatus: {
      type: Object,
      default: () => {}
    },
    startCourseLabel: {
      type: String,
      default: 'Старт курса'
    },
    endCourseLabel: {
      type: String,
      default: 'Окончание'
    },
    contactManagerLabel: {
      type: String,
      default: 'Связаться с менеджером'
    },
    affiliatedWith: {
      type: String,
      default: 'Аффилировано с'
    },
    managerComment: {
      type: String,
      default: 'Комментарий менеджера программы'
    },
    program: {
      /**
        pcId: Number,
        pcRequestId: Number,  //активность программы
        pcDirect: String,     //направление программы
        pcCaption: String,    //название программы
        pcStart: String,      //дата начала программы
          '01.01.1970'
        pcEnd: String,        //дата окончания программы
        pcStatus: String      //статус программы
          'review'                - На рассмотрении
          'signing-agreement'     - Подписание договора
          'payment'               - Оплата
          'start'                 - Ожидание старта
          'education'             - Обучение
          'signing-act'           - Подписание акта
          'end'                   - Завершено
        pcType: String,       //тип программ
          'prof-retraining'       - Профессиональная переподготовка
          'prof-dev'              - Повышение квалификации
          'additional-education'  - Дополнительное образование для взрослых
          'spec-master'           - Магистратура
          'mba'                   - MBA
       */
      type: Object,
      required: true
    }
  },

  setup() {
    return {
      PROGRAM_STATUS
    };
  },

  data() {
    return {
      showStatusComment: false
    };
  },

  computed: {
    serviceLink() {
      const service = this.$store.state.services.serviceManager;

      return `${REFERENCEPAGE}/${service.id}?application_id=${this.program.id}`;
    }
  },
  methods: {getIconColorByStatus}
};
</script>

<style lang="scss">
.list-program__content-program-specialization {
  font-family: 'HSE sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}
.list-program__content-program-title {
  font-family: 'HSE sans';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #0f0f14;
  margin-top: 4px;
}

.item-list__document.list-program__mod {
  display: flex;
  align-items: stretch;
  position: relative;

  padding: 20px 0;

  .list-program__number {
    min-width: 153px;
    padding-right: 0;
    .status-item {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #6b7a99;
      margin-bottom: 4px;
    }
    .status-start__date {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $base-black-color;
    }
    .status-finish {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #6b7a99;
      display: inline-block;
      margin-bottom: 4px;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $base-black-color;
      margin-bottom: 0;
      border-radius: 4px;
    }
  }

  .list-program__content {
    width: 100%;
    padding: 0 24px;
    border-left: 1px solid #edeff3;
    h6 {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: $base-black-color;
      margin-bottom: 6px;
    }
    p {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: $base-black-color;
      margin-bottom: 6px;
    }
    .item-subtitle__specialization {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      &.specialized-master {
        color: #d82e2e;
      }
    }
    .list-program__badges {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      grid-gap: 10px;
    }
  }

  .list-program__buttons {
    margin-left: auto;
    min-width: 220px;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
    gap: 8px;
    .link__contact {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $blue-color;
      margin-bottom: 16px;
    }
    .list-program__review-btn {
      background-color: #f0f5ff;
    }
    .list-program__review-btn span,
    .list-program__review-btn .el-icon-present {
      color: #0050cf;
    }
  }
}
</style>
