<template>
  <div class="inputFile-wrapper">
    <div v-show="hasAttachedFiles">
      <div
        v-for="(file, key) of value.$model"
        :key="file.name"
        class="input-wrap-document"
      >
        <div class="input-document__type">
          {{ file.name }}
        </div>
        <div class="input-document__weight">
          {{ stringifyFileSize(file.size) }}
        </div>
        <div class="btn-document">
          <a
            :href="getUrl(file, key)"
            class="btn-document__download"
            target="_blank"
            :download="file.name"
            @click="onDownloadFile(key)"
          />
          <button
            v-if="!disabled"
            class="btn-document__close"
            @click.prevent="onDeleteFile(key)"
          />
        </div>
      </div>
    </div>

    <div
      v-show="!hasAttachedFiles"
      :class="{
        inputDocument: true,
        'error-system': hasError
      }"
      @dragover.prevent="onDragover"
      @dragleave="onDragleave"
      @drop.prevent="onDrop"
    >
      <input
        :id="id"
        ref="inputFile"
        :class="{[classInput]: true, disabled: disabled}"
        :disabled="disabled"
        :name="id"
        :multiple="multiple"
        :required="required"
        type="file"
        @change="onChange"
      />
      <label
        :class="{required: required}"
        :for="id"
        :style="{justifyContent: textAlign}"
      >
        <span :class="classLabel">
          {{ label }}
        </span>
      </label>
      <slot name="errors" />
      <div
        v-if="showAlertInfo"
        class="inputFile-wrapper__info-container"
      >
        <alert-attention :text="alertInformation" />
      </div>
    </div>
  </div>
</template>

<script>
import AlertAttention from '@/components/newAlerts/alertAttention';
import localization from '@/i18n/localization';

export default {
  name: 'InputFile',
  components: {AlertAttention},
  props: {
    label: {type: String, default: ''},
    id: {type: String, required: true},
    disabled: {type: Boolean, default: false},
    multiple: {type: Boolean, default: false},
    required: {type: Boolean, default: false},
    maxSize: {type: Number, default: 10 * 1024 * 1024},
    acceptExtensions: {
      type: Array,
      default: () => ['pdf', 'doc', 'docx', 'rtf', 'odt']
    },
    value: {type: Object, default: () => ({$model: []})},
    initUrls: {type: Object, default: () => ({})},
    textAlign: {type: String, default: 'left'},
    classInput: {type: String, default: 'inputWrap-file'},
    classLabel: {type: String, default: 'file-label-text'},
    showAlertInfo: {type: Boolean, default: false}
  },

  data() {
    return {
      localization: localization(),
      isDragging: false,
      urls: this.initUrls,
      isUploadedByUser: false
    };
  },

  computed: {
    hasAttachedFiles() {
      return this.value.$model.length > 0;
    },
    hasError() {
      return this.value.$error || (this.value.$dirty && this.value.$invalid);
    },
    acceptExtensionsDescription() {
      return this.acceptExtensions.map(type => '.' + type).join(', ');
    },
    alertInformation() {
      if (this.showAlertInfo) {
        let temp = this.localization.commonCaption('acceptableFileFormats');
        temp = temp.replace('{formats}', this.acceptExtensions.join(', '));
        temp += `, ${this.localization
          .commonCaption('addingFiles_fileSizeRequirement')
          .replace('{number}', this.getHumanSize(this.maxSize))}`;
        return temp;
      }
      return '';
    }
  },

  mounted() {
    this.emitOnChange();
  },

  methods: {
    getHumanSize(bytes, decimals = 2) {
      if (!+bytes) return '0';

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      // const sizes = ['Б', 'КБ', 'МБ', 'ГБ', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      // return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
    },
    getUrl(file, key) {
      return file.url ? file.url : this.urls[key];
    },
    stringifyFileSize(size) {
      if (typeof size !== 'number') return '';

      const toFixed = (size, divider, unit) =>
        `${(size / divider).toFixed(1)} ${unit}`;

      const KB = 1024;
      const MB = 1024 * KB;

      switch (true) {
        case size > MB:
          return toFixed(size, MB, 'MB');
        case size > KB:
          return toFixed(size, KB, 'КБ');
        default:
          return toFixed(size, 1, 'Б');
      }
    },
    checkIsAcceptedExtension(array, file) {
      const fileExtension = file.name.split('.').pop();

      return array.includes(fileExtension);
    },
    checkIsAcceptedFileAndNotifyError(file) {
      if (!this.checkIsAcceptedExtension(this.acceptExtensions, file)) {
        this.$setNotification({
          message: this.$t(
            this.$getCommonCaptionByName('addingFiles_incorrectFileFormat', {
              file: file.name
            })
          )
        });

        return false;
      }

      if (file.size > this.maxSize) {
        this.$setNotification({
          message: this.$t(
            this.$getCommonCaptionByName('addingFiles_fileSizeExceeded'),
            {
              file: file.name
            }
          )
        });
        return false;
      }

      return true;
    },
    emitOnChange() {
      this.$emit('on-change', this.value.$model);
    },
    emitOnFileAction(actionName, key) {
      if (typeof actionName !== 'string' && !key) return;

      this.$emit(actionName, {
        file: this.value.$model[key],
        url: this.urls[key]
      });
    },
    onChange() {
      const {files} = this.$refs?.inputFile;

      if (files && this.multiple) {
        const acceptedFiles = [];

        Object.keys(files).map(key => {
          const file = files[key];

          if (this.checkIsAcceptedFileAndNotifyError(file)) {
            acceptedFiles.push(file);
            this.urls[key] = URL.createObjectURL(file);
          }
        });

        this.value.$model = acceptedFiles;
        this.isUploadedByUser = true;
      } else if (files) {
        const file = files[0];

        if (this.checkIsAcceptedFileAndNotifyError(file)) {
          this.value.$model = [file];
          this.urls[0] = URL.createObjectURL(file);
          this.isUploadedByUser = true;
        }
      } else {
        this.value.$model = [];
      }

      this.emitOnChange();
    },
    onDownloadFile(key) {
      this.emitOnFileAction('on-download', key);
    },
    onDeleteFile(key) {
      this.emitOnFileAction('on-delete-file', key);
      this.emitOnChange();

      this.value.$model.splice(key, 1);
      delete this.urls[key];

      if (!this.value.$model.length) {
        this.$refs.inputFile.value = null;
        this.isUploadedByUser = false;
      }
    },
    onDragover() {
      this.isDragging = true;
    },
    onDragleave() {
      this.isDragging = false;
    },
    onDrop(e) {
      if (this.disabled) return;
      this.$refs.inputFile.files = e.dataTransfer.files;
      this.onChange();
      this.isDragging = false;
    }
  }
};
</script>

<style lang="scss">
.inputWrap-file {
  margin-bottom: 28px;
}

.inputFile-wrapper {
  &__info-container {
    margin-top: 20px;
  }
  &__info-container .el-alert.alert.alert--attention.el-alert--info.is-light {
    margin-bottom: 0px;
  }
}

.inputWrap-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
  & + label {
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $blue-color;
    display: flex;
    position: relative;
    padding: 12px 24px 12px 50px;
    border: 1px dashed $blue-color;
    cursor: pointer;
    border-radius: 4px;
    .file-label-text {
      position: relative;
    }
    .file-label-text:before {
      content: '';
      position: absolute;
      top: calc(50% - 10px);
      left: -25px;
      display: block;
      width: 12px;
      height: 20px;
      background: url('@/assets/icons/icon-clip-add.svg') 100% 100% no-repeat;
    }
  }
  &.disabled + label {
    border-color: $primary-morn-0-4;
    color: $primary-dusk-0-4;
    cursor: not-allowed;
  }
}
.inputDocument.error-system > .inputWrap-file + label {
  border: 1px dashed $error-system-red;
}

.input-wrap-document {
  position: relative;
  padding: 10px 0 10px 50px;
  margin-bottom: 24px;
  padding-right: 40px;
  box-sizing: border-box;
  &:before {
    content: '';
    position: absolute;
    top: calc(50% - 15px);
    left: 11px;
    display: block;
    width: 26px;
    height: 31px;
    background: url('@/assets/icons/icon-type-file.svg') 100% 100% no-repeat;
  }
  .btn-document {
    position: absolute;
    top: 10px;
    right: 0;
    width: auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    button {
      border: none;
      background-color: transparent;
      padding: 0;
      width: 15px;
      height: 15px;
      position: relative;
      display: block;
      cursor: pointer;
      &:before {
        content: '';
      }
    }
    .btn-document__close {
      width: 10px;
      height: 10px;
      &:before {
        content: '';
        width: 10px;
        height: 10px;
        display: block;
        background: url('@/assets/icons/icon-close-gray.svg') 100% 100%
          no-repeat;
      }
    }
    .btn-document__download {
      margin-right: 17px;
      &:before {
        content: '';
        width: 12px;
        height: 12px;
        display: block;
        background: url('@/assets/icons/icon-download-file.svg') 100% 100%
          no-repeat;
      }
    }
  }
  .input-document__type {
    position: relative;
    display: inline-block;
    width: 99.9%;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #6b7a99;
    margin-bottom: 4px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .input-document__weight {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6b7a99;
  }
}
</style>
