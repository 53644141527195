<template>
  <div class="wrapper-acts-documents">
    <div class="wrapper-list__documents">
      <doc-list-component
        :items="items"
        :localization="localization"
        @refresh="$emit('refresh')"
      />
    </div>
  </div>
</template>

<script>
import DocListComponent from '@/components/listDocuments/docListComponent';
export default {
  name: 'ActsComponent',

  components: {DocListComponent},

  props: {
    items: {type: Array, default: () => []},
    localization: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style lang="scss">
.wrapper-list__documents {
  .list__documents-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .sort-date {
    display: flex;
    p {
      margin: 0;
    }
  }
  .sort-select {
    margin-left: 10px;
    color: $base-black-color;
  }
  .count-documents {
    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      color: #6b7a99;
      margin: 0;
      span {
        color: $base-black-color;
        margin-left: 15px;
      }
    }
  }
}
.search-wrapper-component {
  margin-bottom: 24px;
}
.col-search {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #d3d8e6;
  .wrapper-select {
    width: calc(25% - 25px);
    height: 48px;
    border-radius: 4px;
    border: 1px solid #d3d8e6;
    margin-bottom: 24px;
    background-color: #fff;
  }
  .wrapper-select:not(:last-child) {
    margin-right: 25px;
  }
  .inputWrap {
    width: calc(25% - 25px);
    margin-bottom: 24px;
    margin-right: 25px;
    label {
      width: 100%;
      margin: 0;
      input {
        width: 100%;
        &::placeholder {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #6b7a99;
        }
      }
      &:before {
        content: unset;
      }
    }
    .btn-search {
      position: absolute;
      top: 0;
      right: 0;
      width: 50px;
      height: 48px;
      display: block;
      border: none;
      background-color: transparent;
      cursor: pointer;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      &:before {
        content: '';
        display: block;
        position: absolute;
        top: calc(50% - 8px);
        left: calc(50% - 8px);
        width: 16px;
        height: 16px;
        background: url('../../assets/icons/icon-search-grey.svg');
      }
    }
  }
}
</style>
