<template>
  <div>
    <p class="profile__table-title">
      {{
        $t(
          getCommonCaptionByName(
            `${
              isAdm ? 'contacts_personalPhone' : 'contacts_phoneForConnecting'
            }`
          )
        )
      }}
    </p>
    <div v-show="!edit" class="profile__table-info">
      <div class="input-profile-contact" :class="{profile_el_empty: profileFillErrors}">
        <div class="input-profile-contact__value">
          <a :href="`tel:+${personalPhone}`" v-if="personalPhone" class="link">
            {{ personalPhone }}</a
          >
          <span v-else>
            {{ $t(getCommonCaptionByName('isNotIndicated')) }}
          </span>
        </div>
        <div class="input-profile-contact__btn">
          <btn-show
            :btnVisibility="btnVisibility"
            :iconVisibility="iconVisibility"
            :titleBtn="titleBtn"
            :loaderBtn="loader"
            @edit-show="editShow"
          />
          <button @click="startEdit" class="btn btn--secondary btn--icon">
            <Edit class="edit-icon" />
          </button>
        </div>
      </div>
    </div>
    <ChangingPhoneForm
      class="form form--profile-contact"
      v-if="edit"
      :phone="phone"
      :savedPhone="personalPhone"
      @cancel="cancelEdit"
      @change-phone="phone = $event"
      @success="setNewPhoneHandler"
    />
  </div>
</template>

<script>
import Axios from '@/utils/Elk-Axios';
import Edit from '@/assets/icons/edit.svg?inline';
import setNotification from '@/utils/setNotification';
import btnShow from '@/components/widgets/elkProfileMain/btnShow';
import { SET_PHONE_VISIBLE } from '@/api';
import ChangingPhoneForm from './changingPhoneForm';
import { getUiItemCaption, getCommonCaptionByName } from '@/i18n/utils';

export default {
  name: 'personal-phone',
  inject: ['signature'],
  components: {
    Edit,
    btnShow,
    ChangingPhoneForm,
  },
  props: {
    personalPhone: {
      type: String,
      default: '',
    },
    showPhone: Boolean,
    isAdm: Boolean,
  },
  data() {
    return {
      edit: false,
      phone: this.personalPhone,
      iconVisibility: this.showPhone,
      loader: false,
      titleBtnOff: this.$t(
        this.getUiItemCaption(
          this.signature,
          'turnOffVisibilityOfPersonalPhone'
        )
      ),
      titleBtnOn: this.$t(
        this.getUiItemCaption(this.signature, 'turnOnVisibilityOfPersonalPhone')
      ),
    };
  },
  computed: {
    profileFillErrors() {
      let errors = this.$store.getters['profileFillCheck/profileFillErrors'].map(function(obj) {
        return obj.code;
      });
      if(errors?.includes('phone_number')) return true;
    },
    btnVisibility: function () {
      if (this.phone === '+7') {
        return false;
      }
      return !!this.phone;
    },
    titleBtn() {
      const { iconVisibility, titleBtnOn, titleBtnOff } = this;
      return iconVisibility ? titleBtnOff : titleBtnOn;
    },
  },
  methods: {
    getUiItemCaption,
    getCommonCaptionByName,
    setNewPhoneHandler() {
      this.edit = false;
      this.$emit('update-info', { type: 'phone', value: this.phone });
    },
    setNotification,
    startEdit() {
      this.edit = true;
    },
    cancelEdit() {
      this.phone = this.personalPhone;
      this.edit = false;
    },
    async editShow() {
      this.loader = true;
      try {
        const value = { is_phone_mobile_show: !this.iconVisibility };
        let res = await Axios.post(SET_PHONE_VISIBLE, value);
        if (res.status === 200) {
          this.$emit('update-mobile-show', { value: !this.iconVisibility });
          this.iconVisibility = !this.iconVisibility;
        }
      } catch (err) {
        if (!err.isHandled) {
          this.setNotification({
            message: this.$t(
              getUiItemCaption(
                this.signature,
                'failSettingVisibialityOfPersonalPhone'
              )
            ),
          });
        }
      } finally {
        this.loader = false;
      }
    },
  },
};
</script>
