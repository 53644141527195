<template>
  <div class="wrapper-all__documents">
    <div class="all__documents">
      <passport-form-document
        :item="forms.passport"
        :rules="rules.passport"
        :type="types.passport"
        :readonly="readonly.passportForm"
        @on-change="setFormPassport"
        @on-select-register-address="setRegisterAddressDadata"
      />
      <alert-success v-if="true" />
      <snils-form-document
        :item="forms.snils"
        :rules="rules.snils"
        :readonly="readonly.snils"
        @on-change="setFormSnils"
        @on-remove-file="onRemoveSnilsFile"
      />
      <education-form-document
        :educations="forms.education"
        :rules="rules.education"
        :type="types.education"
        :readonly="readonly.educationFormDoc"
        @on-change="setFormEducation"
        @on-add-education="addEducation"
        @on-remove="onRemoveEducation"
      />
      <academic-degree-form-document
        :degrees="forms.academicDegree"
        :rules="rules.academicDegree"
        :type="types.academicDegree"
        :academic-degree="types.academicDegree"
        :document-type="types.academicDocument"
        :readonly="readonly.academicDegree"
        @on-change="setFormAcademicDegree"
        @on-add-degree="addAcademicDegree"
      />
      <work-form-document
        :rules="rules.work"
        :job="forms.work.job"
        :post="forms.work.post"
        :region="forms.work.region"
        :type="types.regions"
        @on-change="setFormWork"
      />

<!--      <agreement-element-->
<!--        id="pd"-->
<!--        :disabled="!isProfileFilled || documents.pd?.readonly"-->
<!--        title="Согласие на обработку персональных данных"-->
<!--        template="Заявление о согласии на обработку персональных данных"-->
<!--        info="согласия на обработку персональных данных"-->
<!--        :is-load="status.isFetchingTemplatePD"-->
<!--        :attachment="$v.forms.documents.pd"-->
<!--        :error-text="documents.pd?.errorText"-->
<!--        @on-get-document="getPersonalDataAgreementDocument"-->
<!--      />-->

<!--      <agreement-element-->
<!--        id="ei"-->
<!--        :disabled="!isProfileFilled || documents.ei?.readonly"-->
<!--        title="Согласие на электронное взаимодействие"-->
<!--        template="Соглашение между участниками электронного взаимодействия"-->
<!--        info="согласия на электронное взаимодействие"-->
<!--        :is-load="status.isFetchingTemplateEI"-->
<!--        :attachment="$v.forms.documents.ei"-->
<!--        :error-text="documents.ei?.errorText"-->
<!--        @on-get-document="getElectronicInteractionDocument"-->
<!--      />-->

      <div
        id="section-document"
        class="document-element"
      >
        <h2 class="h4">Дополнительные документы</h2>
        <div class="wrapper-document add-documents">
          <input-file
            v-if="getDocumentReferenceBySlug('change_fio')"
            id="change_fio"
            :label="label.attachmentNameChange"
            :accept-extensions="arrImageExtensions"
            :value="$v.forms.documents.change_fio"
            :disabled="documents.change_fio?.readonly"
            @on-delete-file="deleteDocument(documents.change_fio.id)"
          />
          <!--            @on-change="onChangeDocumentNameChange"-->
          <input-file
            v-if="getDocumentReferenceBySlug('disability')"
            id="disability"
            :label="label.attachmentDisability"
            :accept-extensions="arrImageExtensions"
            :value="$v.forms.documents.disability"
            :disabled="documents.disability?.readonly"
            @on-delete-file="deleteDocument(documents.disability.id)"
          />
          <input-file
            v-if="getDocumentReferenceBySlug('lingo')"
            id="lingo"
            :label="label.documentForeignLanguage"
            :accept-extensions="arrImageExtensions"
            :value="$v.forms.documents.lingo"
            :disabled="documents.lingo?.readonly"
            @on-delete-file="deleteDocument(documents.lingo.id)"
          />
          <el-alert
            v-if="documents.employment_record?.errorText"
            class="alert alert--danger"
          >
            {{ documents.employment_record?.errorText }}
          </el-alert>
          <input-file
            v-if="getDocumentReferenceBySlug('employment_record')"
            id="employment_record"
            :label="label.attachEmploymentHistory"
            :accept-extensions="arrImageExtensions"
            :value="$v.forms.documents.employment_record"
            :disabled="documents.employment_record?.readonly"
            @on-delete-file="deleteDocument(documents.employment_record.id)"
          />
          <alert-attention :text="alertInformation" />
        </div>
      </div>
    </div>
    <div
      ref="scrollContainer"
      class="documents-menu"
    >
      <menu-document-profile
        ref="scrollElement"
        :button-disabled="isSendButtonDisabled"
        @on-save="onClickSave"
      />
    </div>
    <alert-success />
  </div>
</template>

<script>
import {mapActions, mapState, mapGetters, mapMutations} from 'vuex';
import {format} from 'date-fns';
import {requiredIf} from 'vuelidate/lib/validators';
import alertAttention from '@/components/newAlerts/alertAttention';
import Axios from '@/utils/Elk-Axios';
import {
  GET_DOCUMENT,
  GET_DOCUMENT_REFERENCES,
  GET_PF_EI,
  GET_PF_PD,
  MAX_FILE_SIZE_TEXT
} from '@/api';
import setNotification from '@/utils/setNotification';
import AgreementElement from '@/components/agreement/agreementElement';
import MenuDocumentProfile from '@/components/profMenu/menuDocumentProfile';
import PassportFormDocument from '@/components/documentsForm/passportFormDocument';
import SnilsFormDocument from '@/components/documentsForm/snilsFormDocument';
import EducationFormDocument from '@/components/documentsForm/educationFormDocument';
import AcademicDegreeFormDocument from '@/components/documentsForm/academicDegreeFormDocument';
import WorkFormDocument from '@/components/documentsForm/workFormDocument';
import InputFile from '@/components/inputComponent/InputFile.vue';
import AlertSuccess from '@/components/newAlerts/alertSuccess';


function stickyMenu(container, sticky) {
  const header = document.querySelector('header.header');
  const headerHeight = header
    ? header.getBoundingClientRect().height
    : 54; /* размер хэдера страницы */

  return () => {
    const {y: containerY, height: containerHeight} =
      container.getBoundingClientRect();
    const {height: stickyHeight} = sticky.getBoundingClientRect();
    const {pageYOffset} = window;
    const containerTop = containerY + pageYOffset;

    if (pageYOffset < containerTop) {
      sticky.style.marginTop = null;
    } else if (
      pageYOffset > containerTop &&
      pageYOffset < containerTop + containerHeight - stickyHeight - headerHeight
    ) {
      const marginTop =
        Math.round(pageYOffset - containerTop + headerHeight) + 'px';
      sticky.style.marginTop = marginTop;
    }
  };
}

export default {
  name: 'DocumentsForm',

  components: {
    alertAttention,
    WorkFormDocument,
    AgreementElement,
    AcademicDegreeFormDocument,
    EducationFormDocument,
    SnilsFormDocument,
    PassportFormDocument,
    MenuDocumentProfile,
    AlertSuccess,
    InputFile
  },

  props: {
    work: {
      type: Object,
      default: () => ({
        job: '',
        post: '',
        region: []
      })
    }
  },

  data() {
    return {
      arrImageExtensions: ['jpg', 'jpeg', 'pdf', 'png'],
      label: {
        attachmentNameChange:
          'Прикрепить документ, подтверждающий изменение фамилии, имени, отчества',
        attachmentDisability:
          'Прикрепить документ, подтверждающий инвалидность и (или) ограниченные возможности здоровья (при наличии)',
        documentForeignLanguage:
          'Прикрепить документы, подтверждающие уровень владения иностранным языком',
        attachEmploymentHistory:
          'Прикрепить копию трудовой книжки и (или) копия трудового договора, заверенные нотариально или по месту работы (если условиями реализации ДПП предусмотрено требование к наличию у поступающего опыта профессиональной деятельности)',
        attachPDAgreement:
          'Прикрепить согласие на обработку персональных данных'
      },
      status: {
        isPassportDataChanger: false,
        isSendProfileData: false,
        isFetchingPatchProfileData: false,
        isFetchingTemplatePD: false,
        isFetchingTemplateEI: false
      },
      readonly: {
        passportForm: false,
        snils: false,
        educationFormDoc: false,
        academicDegree: false
      },
      validation: /** $v формы vuelidate */ {
        passport: null,
        snils: null,
        education: [],
        academicDegree: [],
        work: null
      },
      rules: /** правила валидации для форм */ {
        passport: new Map(),
        snils: new Map(),
        education: new Map(),
        academicDegree: new Map(),
        work: new Map()
      },
      fields: /** поля форм */ {
        passport: [
          'id',
          'type',
          'serial',
          'number',
          'date',
          'issuedBy',
          'birthPlace',
          'divisionCode',
          'registerAddress',
          'files',
          'isSameRegAddress',
          'citizenship_id'
        ],
        snils: ['id', 'snils' /** number + serial */ /*, 'date'*/],
        education: [
          'id',
          'speciality',
          'document_type_id',
          'serial',
          'number',
          'issuedBy',
          'date',
          'files'
        ],
        academicDegree: [
          'id',
          'degree',
          'type',
          'serial',
          'number',
          'issuedBy',
          'date',
          'files'
        ],
        work: ['job', 'post', 'region']
      },
      types: /** выпадающие списки */ {
        passport: [],
        snils: [],
        education: [],
        academicDegree: [],
        academicDocument: [],
        regions: [],
        other: []
      },
      forms: {
        passport: {
          id: '',
          type: '',
          serial: '',
          number: '',
          date: '',
          birthPlace: '',
          registerAddress: '',
          registerDaData: '',
          files: [],
          isSameRegAddress: false,
          citizenship_id: null
        },
        snils: {
          id: '',
          snils: '',
          file: ''
          // date: ''
        },
        education: [
          {
            id: '',
            emptyField: true /** для проверки при получении данных с бэка */,
            speciality: '',
            document_type_id: '',
            serial: '',
            number: '',
            date: '',
            issuedBy: '',
            files: []
          }
        ],
        academicDegree: [
          {
            id: '',
            emptyField: true /** для проверки при получении данных с бэка */,
            degree: '',
            type: '',
            serial: '',
            number: '',
            issuedBy: '',
            date: '',
            files: ''
          }
        ],
        work: {
          job: this.work.job,
          post: this.work.post,
          region: this.work.region
        },
        documents: {
          pd: [],
          ei: [],
          employment_record: [],
          disability: [],
          change_fio: [],
          lingo: []
        }
      },
      documents: {
        pd: null,
        ei: null,
        change_fio: null,
        employment_record: null,
        lingo: null,
        disability: null
      },
      registerAddressDadata: '',
      documentTypeId: {}
    };
  },

  computed: {
    ...mapState(['menuActive', 'profileFillCheck']),
    ...mapGetters(['isPublicUser']),
    ...mapGetters({
      fieldRules: 'fieldRules/getByDocumentTypeId'
    }),
    alertInformation() {
      return `Вы можете загрузить документы любого формата из доступных: ${this.arrImageExtensions.join(
        ', '
      )} Максимальный размер: ${MAX_FILE_SIZE_TEXT}`;
    },
    citizenshipAvailable() {
      const documentTypeId = this.forms.passport.type;

      if (documentTypeId) {
        return this.fieldRules(documentTypeId)?.some(
          rule => rule.code === 'citizenship' && rule.blocked === false
        );
      }

      return false;
    },
    isSendButtonDisabled() {
      return this.isSendProfileData || this.status.isFetchingPatchProfileData;
    },
    isProfileFilled() {
      return this.$store.getters['profileFillCheck/isProfileFilled'];
    }
  },

  validations() {
    return {
      forms: {
        snils: {
          id: {},
          snils: {},
          file: {}
        },
        documents: {
          pd: {
            required: requiredIf(() => true)
          },
          ei: {
            required: requiredIf(() => false)
          },
          change_fio: {
            required: requiredIf(() => false)
          },
          employment_record: {
            required: requiredIf(() => false)
          },
          lingo: {
            required: requiredIf(() => false)
          },
          disability: {
            required: requiredIf(() => false)
          }
        }
      }
    };
  },

  watch: {
    work: {
      handler(v) {
        this.forms.work = v;
      },
      deep: true
    }
  },

  async created() {
    await this.getDocumentReferences(); /** это должно быть первым, заполняется this.documentTypeId и данные для выпадающих списков  */
    await this.fetchFieldRules().then(rules => {
      /** должно быть перед this.getDocument  */
      Object.entries(rules).forEach(([key, value]) => {
        const formName = this.getFormNameByDocumentTypeId(key);
        if (formName) {
          this.setFieldsRules(key, formName, value);
        }
      });
    });
    await this.getDocument();
  },

  mounted() {
    document.addEventListener(
      'scroll',
      stickyMenu(this.$refs.scrollContainer, this.$refs.scrollElement.$el)
    );
  },

  unmounted() {
    document.removeEventListener('scroll', stickyMenu);
  },

  methods: {
    ...mapMutations({
      setCountries: 'countries/set'
    }),
    ...mapActions({
      fetchFieldRules: 'fieldRules/fetch'
    }),
    deleteDocument(id) {
      Axios.delete(GET_DOCUMENT, {
        data: {
          id
        }
      })
        .then(response => {
          this.$notify({
            type: response.status ? 'success' : 'error',
            message: response.status
              ? 'Документ успешно удален'
              : response.message
          });
        })
        .catch(() => {
          this.$notify({
            type: 'error',
            message: 'Не удалось удалить документ'
          });
        });
    },

    /** данные при первичной загрузке */
    prepareFormFiles(file) {
      return file ? [{...file}] : [];
    },
    setInitialOtherDocumentsData(document) {
      const slug = document.type.slug;
      const allowedSlugs = [
        'change_fio', // Изменение ФИО
        'lingo', // Подтверждение уровня владения иностранным языком
        'pd', // Обработка персональных данных
        'ei', // Согласие на электронное взаимодействие
        'employment_record' // Трудовой договор
      ];

      if (allowedSlugs.find(el => el === document.type.slug)) {
        this.documents[slug] = {
          ...document,
          errorText:
            document.verify === false && document.verify_message
              ? document.verify_message
              : ''
        };
        this.forms.documents[slug] = this.prepareFormFiles(document.file);
      }
    },
    prepareFormDataByName(formName, data) {
      const attachment = this.prepareFormFiles(data.file);

      switch (formName) {
        case 'passport':
          this.registerAddressDadata = data.register_dadata ?? '';
          this.readonly.passportForm = data.readonly ?? false;
          return {
            ...data,
            type: data.type.id ?? '',
            serial: data.serial ?? '',
            number: data.number ?? '',
            date: data.date ?? null,
            issuedBy: data.issued_by ?? '',
            divisionCode: data.division_code ?? '',
            birthPlace: data.birthplace ?? '',
            registerAddress: data.register_address ?? '',
            registerDaData: data.register_dadata
              ? JSON.parse(data.register_dadata)
              : null,
            isSameRegAddress: data.is_same_reg_address ?? false,
            citizenship_id: Number(data.citizenship_id),
            files: attachment,
            errorText:
              data.verify === false && data.verify_message
                ? data.verify_message
                : ''
          };
        case 'snils':
          this.readonly.snils = data.readonly ?? false;
          return {
            ...data,
            file: [data.file],
            snils: data.number + data.serial ?? '',
            errorText:
              data.verify === false && data.verify_message
                ? data.verify_message
                : ''
          };
        case 'education':
          return {
            ...data,
            document_type_id: +data?.document_type_id ?? '',
            initialData: true,
            speciality: data.speciality,
            serial: data.serial,
            number: data.number,
            date: data.date,
            issuedBy: data.issued_by,
            files: attachment,
            errorText:
              data.verify === false && data.verify_message
                ? data.verify_message
                : ''
          };
        case 'academicDegree':
          return {
            ...data,
            initialData: true,
            degree: data.degree_id ?? '',
            type: data.type.id ?? '',
            number: data.number,
            date: data.date,
            issuedBy: data.issued_by,
            files: attachment,
            errorText:
              data.verify === false && data.verify_message
                ? data.verify_message
                : ''
          };
        default:
          return;
      }
    },
    setInitialData(data) {
      let item = {};

      const code = data.type.group.code ?? 'exception';

      switch (code) {
        case 'BITVUZ.ДокументыУдостоверяющиеЛичность' /** passport */:
          return (this.forms.passport = this.prepareFormDataByName(
            'passport',
            data
          ));
        case 'BITVUZ.СНИЛС' /** СНИЛС */:
          return (this.forms.snils = this.prepareFormDataByName('snils', data));
        case 'BITVUZ.ДокументыОбОбразовании' /** education */:
          item = this.prepareFormDataByName('education', data);
          if (
            this.forms.education.length <= 1 &&
            this.forms.education[0].emptyField === true
          ) {
            this.forms.education = [item];
          } else {
            this.forms.education.push(item);
          }
          this.readonly.educationFormDoc = this.forms.education.every(
            e => e.readonly === true
          );
          return;
        case 'BITVUZ.ДокументыОНаучныхСтепенях' /** academicDegree */:
          item = this.prepareFormDataByName('academicDegree', data);
          if (
            this.forms.academicDegree.length <= 1 &&
            this.forms.academicDegree[0].emptyField === true
          ) {
            this.forms.academicDegree = [item];
          } else {
            this.forms.academicDegree.push(item);
          }
          this.readonly.academicDegree = this.forms.academicDegree.every(
            e => e.readonly === true
          );
          break;
        case 'BITVUZ.ПрочиеДокументы' /** документы по slug искать */:
        case 'BITVUZ.ДокументОСменеФИО':
          this.setInitialOtherDocumentsData(data);
          break;
        case 'BITVUZ.УдостоверенияИнвалидности':
          this.documents.disability = {
            ...data
          };
          this.forms.documents.disability = this.prepareFormFiles(data.file);
          break;
        default:
          break;
      }
    },
    async createDocument(formData, {slug, formName, index}) {
      try {
        const {data} = await Axios.post(GET_DOCUMENT, formData);
        if (data.code === 200) {
          this.$notify({
            type: 'success',
            message: 'Документы успешно отправлены'
          });

          await this.$store.dispatch('profileFillCheck/getProfileFillCheck');
        }
        if (data.code === 200 && data.data) {
          if (slug) {
            this.documents[slug] = data.data;
          }
          if (formName && index >= 0) {
            if (formName === 'education') {
              this.forms[formName].splice(
                index,
                1,
                this.prepareFormDataByName(formName, data.data)
              );
            } else {
              const form = this.forms[formName];
              form[index] = this.prepareFormDataByName(formName, data.data);
            }
          } else if (formName) {
            this.forms[formName] = this.prepareFormDataByName(
              formName,
              data.data
            );
          }
        }
        if (data.code === 400 && data.message) {
          setNotification({
            message: data.message,
            type: 'error'
          });
        }

        return true;
      } catch {
        return false;
      }
    },
    validateDocumentAndSend(slug, files) {
      const [file] = files;
      if (file instanceof Blob === false) return;

      const documentReference = this.getDocumentReferenceBySlug(slug);

      if (documentReference?.value && files.length) {
        const notification = {type: 'create', label: documentReference.label};
        const formData = new FormData();
        formData.append('document_type_id', documentReference.value);
        formData.append('file', file, file.name);

        if (this.documents?.[slug]?.id) {
          formData.append('id', this.documents?.[slug]?.id);
          formData.append('_method', 'PATCH');
          notification.type = 'edit';
        }

        this.createDocument(formData, {slug, notification});
      }
    },
    async getDocument() {
      try {
        const {data} = await Axios.get(GET_DOCUMENT);
        if (data.code === 200 && data.data?.length) {
          data.data.forEach(document => {
            this.setInitialData(document);
          });
        }
        if (data.code === 400 && data.message) {
          setNotification({
            message: data.message,
            type: 'error'
          });
        }
      } catch {
        /** */
      }
    },
    /** поля выпадающих списков */
    setDocumentTypeId(types) {
      types.forEach(document => {
        this.documentTypeId[document.value] = document;
      });
    },
    setTypeList(value) {
      if (!value.length) return;

      const code = value[0].group;
      switch (code) {
        case 'BITVUZ.ДокументыУдостоверяющиеЛичность':
          this.types.passport = value;
          break;
        case 'BITVUZ.ДокументыОбОбразовании':
          this.types.education = value;
          break;
        case 'BITVUZ.ДокументыОНаучныхСтепенях':
          this.types.academicDocument = value;
          break;
        case 'BITVUZ.СНИЛС':
          this.types.snils = value[0]; /** СНИЛС только один */
          break;
        case 'BITVUZ.ПрочиеДокументы':
          this.types.other = value; // логика для id прочих документов
          break;
        case 'BITVUZ.УдостоверенияИнвалидности':
          this.types.disability = value;
          break;
        default:
          break;
      }
    },
    async getDocumentReferences() {
      try {
        const {data} = await Axios.get(GET_DOCUMENT_REFERENCES);
        if (data.code === 200 && data.data) {
          const {types} = data.data;

          types?.forEach(value => {
            if (value.length) {
              this.setTypeList(value);
              this.setDocumentTypeId(value);
            }
          });

          if (data.data.degree) {
            this.types.academicDegree = data.data.degree;
          }
          if (data.data.countries) {
            this.setCountries(data.data.countries);
          }
          if (data.data.regions) {
            this.types.regions = data.data.regions;
          }
        }
      } catch {
        /** */
      }
    },
    /** правила валидации */
    setFieldsRules(documentTypeId, formName, arrRules) {
      const formRules = new Map();
      const rules = {};

      arrRules.forEach(rule => {
        rules[rule.code] = rule.require;
      });

      this.fields[formName]?.forEach(field => {
        if (field === 'files') {
          formRules.set(
            field,
            !['passport', 'education'].find(v => v === formName)
          );
        } else if (field in rules) {
          formRules.set(field, rules[field]);
        } else {
          formRules.set(field, false);
        }
      });
      this.rules[formName] = formRules;
    },
    getDocumentReferenceBySlug(slug) {
      return Object.values(this.documentTypeId).find(
        document => document.slug === slug
      );
    },
    getDocumentGroupById(id) {
      /**
       * this.documentTypeId = {
            code: string,
            group: string,
            label: string,
            slug: string,
            value: number,
          }
       */
      return this.documentTypeId[id]?.group ?? '';
    },
    getFormNameByDocumentTypeId(id) {
      const FORM_NAME_BY_GROUP = {
        'BITVUZ.ДокументыОбОбразовании': 'education',
        'BITVUZ.ДокументыОНаучныхСтепенях': 'academicDegree',
        'BITVUZ.ДокументыУдостоверяющиеЛичность': 'passport',
        'BITVUZ.ПрочиеДокументы': 'other',
        'BITVUZ.СНИЛС': 'snils'
        // 'BITVUZ.УдостоверенияИнвалидности': ,
      };

      const group = this.getDocumentGroupById(id);
      return FORM_NAME_BY_GROUP[group] ?? null;
    },
    setLoadingStatus(slug, status) {
      switch (slug) {
        case 'ei':
          return (this.status.isFetchingTemplateEI = status);
        case 'pd':
          return (this.status.isFetchingTemplatePD = status);
        default:
          break;
      }
    },

    // Current blob size limit is around 500MB for browsers
    downloadResource(url) {
      const link = document.createElement('a');
      link.target = '_blank';
      link.href = url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    async getPersonalDataAgreementDocument() {
      try {
        const {data} = await Axios.post(GET_PF_PD);

        if (data.code === 200 && data.data.file) {
          this.downloadResource(data.data.file);
        }
      } catch (e) {
        /** */
        console.error('getPersonalDataAgreementDocument ', {e});
      }
    },
    async getElectronicInteractionDocument() {
      try {
        const {data} = await Axios.post(GET_PF_EI);

        if (data.code === 200 && data.data.file) {
          this.downloadResource(data.data.file);
        }
      } catch {
        /** */
      }
    },
    /** отправка обновленных на бэк */
    formatInputDate(date) {
      return typeof date === 'string'
        ? date
        : format(date, 'yyyy-MM-dd HH:mm:SS');
    },
    // async createFile(file) {
    //   fetch(file.url, { mode: 'no-cors' })
    //     .then((res) => {
    //       return res.blob();
    //     })
    //     .then((blob) => {
    //       return new File([blob], file.name, { type: blob.type });
    //     });
    // },
    setRegisterAddressDadata(value) {
      this.registerAddressDadata = value ? JSON.stringify(value) : null;
      this.forms.passport.isSameRegAddress = false;
    },
    updateDataPassport(data, params) {
      const {form} = data;
      const date = this.formatInputDate(form.$model.date);

      if (!form.$model.type) return;

      const formData = new FormData();
      formData.append('document_type_id', form.$model.type);
      formData.append('type_id', form.$model.type);
      formData.append('serial', form.$model.serial ?? '');
      formData.append('number', form.$model.number ?? '');
      formData.append('date', date);
      formData.append('issued_by', form.$model.issuedBy ?? '');
      formData.append('birthplace', form.$model.birthPlace ?? '');
      formData.append('division_code', form.$model.divisionCode ?? '');
      formData.append('is_same_reg_address', form.$model.isSameRegAddress ?? '');

      if (this.citizenshipAvailable) {
        formData.append('citizenship_id', form.$model.citizenship_id);
      }

      if (this.forms.passport.registerAddress !== form.$model.registerAddress) {
        formData.append('register_address', form.$model.registerAddress);
        formData.append('register_dadata', this.registerAddressDadata);
      }

      if (form.$model.files[0] instanceof Blob) {
        formData.append(
          'file',
          form.$model.files[0],
          form.$model.files[0].name
        );
      }

      if (form.$model.id) {
        formData.append('id', form.$model.id);
        formData.append('_method', 'PATCH');
      }

      this.createDocument(formData, params).then(success => {
        this.$notify({
          type: success ? 'success' : 'error',
          message: success
            ? 'Паспортные данные успешно отправлены'
            : 'Не удалось отправить паспортные данные'
        });
      });

      data.$reset();
    },
    updateDataSnils(data, params) {
      if (!this.types.snils.value) return;

      const {form} = data;
      // const date = this.formatInputDate(form.$model.date);
      const formData = new FormData();
      formData.append('document_type_id', this.types.snils.value);
      formData.append('number', form.$model.snils.slice(0, 9));
      formData.append('serial', form.$model.snils.slice(-2));
      // formData.append('date', date);
      if (form?.file?.$model?.length && form.file.$model[0] instanceof File) {
        formData.append('file', form.file.$model[0]);
      } else if (form?.file?.$model?.length === 0) {
        formData.append('file', false);
      }
      if (form.$model.id) {
        formData.append('id', form.$model.id);
        formData.append('_method', 'PATCH');
      }

      this.createDocument(formData, params).then(success => {
        this.$notify({
          type: success ? 'success' : 'error',
          message: success
            ? 'Данные СНИЛС успешно отправлены'
            : 'Номер СНИЛС не удалось сохранить, проверьте правильность введеного номера и повторите попытку.'
        });
      });
      data.$reset();
    },
    updateDataEducation(data, params) {
      const {form} = data;

      const formData = new FormData();
      const date = this.formatInputDate(form.$model.date);

      formData.append('document_type_id', form.$model.document_type_id);
      formData.append('speciality', form.$model.speciality);
      formData.append('serial', form.$model.serial);
      formData.append('number', form.$model.number);
      formData.append('issued_by', form.$model.issuedBy);
      formData.append('date', date);

      if (form.$model.files[0] instanceof Blob) {
        formData.append(
          'file',
          form.$model.files[0],
          form.$model.files[0].name
        );
      }

      if (form.$model.id) {
        formData.append('id', form.$model.id);
        formData.append('_method', 'PATCH');
      }

      this.createDocument(formData, params).then(success => {
        this.$notify({
          type: success ? 'success' : 'error',
          message: success
            ? 'Данные об образовании успешно отправлены'
            : 'Не удалось отправить данные об образовании'
        });
      });
      data.$reset();
    },
    updateDataAcademicDegree(data, params) {
      const {form} = data;

      const formData = new FormData();
      const date = this.formatInputDate(form.$model.date);

      formData.append('degree_id', form.$model.degree);
      formData.append('document_type_id', form.$model.type);
      formData.append('serial', form.$model.serial);
      formData.append('number', form.$model.number);
      formData.append('date', date);
      formData.append('issued_by', form.$model.issuedBy);

      if (form.$model.files[0] instanceof Blob) {
        formData.append(
          'file',
          form.$model.files[0],
          form.$model.files[0].name
        );
      }

      if (form.$model.id) {
        formData.append('id', form.$model.id);
        formData.append('_method', 'PATCH');
      }

      this.createDocument(formData, params).then(success => {
        this.$notify({
          type: success ? 'success' : 'error',
          message: success
            ? 'Данные об ученой степени успешно отправлены'
            : 'Не удалось отправить данные об ученой степени'
        });
      });

      data.$reset();
    },
    updateDataWork(data) {
      this.$emit('on-work-change', data.form.$model);
      data.$reset();
    },
    /** ссылка на объект формы */
    setFormPassport(value) {
      this.validation.passport = value;
    },
    onRemoveSnilsFile(value) {
      if (value?.length === 0 && this.$v.forms?.snils?.file?.$model) {
        this.$v.forms.snils.file.$model = [];
      }
    },
    setFormSnils(value) {
      this.validation.snils = value;
    },
    setFormEducation(id, value) {
      this.validation.education[id] = value;
    },
    setFormAcademicDegree(id, value) {
      this.validation.academicDegree[id] = value;
    },
    setFormWork(value) {
      this.validation.work = value;
    },
    /** добавление формы */
    addEducation() {
      //
    },
    async onRemoveEducation(id) {
      try {
        await Axios.delete(`${GET_DOCUMENT}`, {
          data: {id}
        });
      } catch (e) {
        const message = e.response?.data?.message
          ? e.response.data.message
          : 'Не удалось удалить документ';
        setNotification(message);
      }
    },
    addAcademicDegree() {
      //
    },
    getNameFieldByKey(key) {
      const temp = {
        date: 'Когда выдан',
        degree: 'Ученая степень',
        files: 'Копия ученой степени',
        id: '',
        issuedBy: 'Кем выдан',
        number: 'Номер',
        serial: 'Серия',
        type: 'Тип документа'
      };

      return temp[key] ?? '';
    },
    /** кнопка сохранить */
    async onClickSave() {
      this.isSendProfileData = true;

      try {
        /** Валидация формы паспорта */
        if (
          !this.validation.passport?.$invalid &&
          this.validation.passport?.$anyDirty
        ) {
          this.updateDataPassport(this.validation.passport, {
            formName: 'passport'
          });
        }

        /** Валидация формы СНИЛС */
        if (
          !this.validation.snils.$invalid &&
          this.validation.snils.$anyDirty
        ) {
          this.updateDataSnils(this.validation.snils, {formName: 'snils'});
        }

        /** Валидация форм обучение */
        this.validation.education.forEach((education, index) => {
          // education?.$validate();
          if (!education?.$invalid && education?.$anyDirty) {
            this.updateDataEducation(education, {formName: 'education', index});
          }
        });

        /** Валидация форм ученая степень */
        this.validation.academicDegree.forEach((academicDegree, index) => {
          if (!academicDegree?.$invalid && academicDegree?.$anyDirty) {
            this.updateDataAcademicDegree(academicDegree, {
              formName: 'academicDegree',
              index
            });
          } else if (academicDegree.$invalid && academicDegree?.$anyDirty) {
            let msg = 'Заполните поля: ';
            for (const [key, value] of Object.entries(academicDegree.form)) {
              const name = this.getNameFieldByKey(key);
              if (name && value?.$invalid) {
                msg += `${name},`;
              }
            }
            setNotification({
              title: 'Ученая степень',
              message: msg.slice(0, -1)
            });
          }
        });

        /** Валидация формы работа */
        if (
          !this.validation.work?.$invalid &&
          this.validation.work?.$anyDirty
        ) {
          await this.updateDataWork(this.validation.work);
        }

        /** Валидация документов */
        Object.entries(this.$v.forms.documents.$model).forEach(
          ([field, files]) => {
            if (
              !this.$v.forms.documents?.[field]?.$invalid &&
              this.$v.forms.documents?.[field]?.$anyDirty
            ) {
              this.validateDocumentAndSend(field, files);
            }
          }
        );
      } catch {
        //
      } finally {
        this.isSendProfileData = false;
      }
    }
  }
};
</script>

<style lang="scss">
.add-documents {
  margin-top: 20px;
}
.wrapper-all__documents {
  display: flex;
  justify-content: space-between;

  .all__documents {
    width: 100%;
  }

  .wrapper-document {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .documents-menu {
    margin-top: 47px;
    margin-left: 24px;
  }
}
</style>
