// apiRequest.js
import elkAxios from '@/utils/Elk-Axios';

const handleResponse = response => {
  return response.data;
};

const handleError = error => {
  // Можно добавить кастомную обработку ошибок здесь глобального действия
  // при использовании этого подхода запросов к API
  // сейчас возникшая ошибка всплывает выше
  // по этому по хорошему вызовы yyy.perform(...) стоит оборачивать в try catch
  console.error('Ошибка при получении данных:', error);
  throw error;
};

const _apiRequest = {
  async get(url, config = {}) {
    try {
      const response = await elkAxios.get(url, config);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  },

  async post(url, config = {}, data) {
    try {
      const response = await elkAxios.post(url, data, config);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  },

  async delete(url, config = {}) {
    try {
      const response = await elkAxios.delete(url, config);
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  },

  createRequestToken() {
    return elkAxios.CancelToken.source();
  }
};

export default _apiRequest;
