<template>
  <div class="dpo-document-bills__table-wrapper">
    <section class="dpo-document-bills__table">
      <div class="header-contract__table">
        <div class="table--cell__hd">{{ localeText.dateNumBills }}</div>
        <div class="table--cell__hd">{{ localeText.agreeBills }}</div>
        <div class="table--cell__hd">{{ localeText.invoiceAmount }}</div>
        <div class="table--cell__hd">
          <div class="cell__hd-el">{{ localeText.actionBills }}</div>
        </div>
      </div>
      <div
        v-for="item in items"
        :key="item.id"
        class="item-act__row"
        :class="{
          'item-act__finished': !item?.contract?.program?.active
        }"
      >
        <div class="table--cell">
          <span>{{ getHumanDate(item.date) }}</span>
          <a
            class="item-act__agreement"
            href="#"
            >№{{ item.number }}</a
          >
        </div>
        <div class="table--cell">
          <div class="cell--mobile__hd">
            {{ localeText.agreeBills }}
          </div>
          {{ getHumanNameProgram(item) }}
        </div>
        <div class="table--cell">
          <div class="cell--mobile__hd">
            {{ localeText.invoiceAmount }}
          </div>
          {{ getHumanPrice(item.payment) }} ₽
        </div>
        <div class="table--cell">
          <div class="cell__btns">
            <el-tooltip
              :content="localeText.downloadDocument"
              placement="bottom"
              effect="light"
              popper-class="el-tooltip__custom"
              :disabled="!item.document"
            >
              <el-button
                class="btn el-btn__download"
                :disabled="!item.document"
                :class="{
                  'el-btn__download_disabled': !item.document
                }"
                @click="onDownloadDocument(item)"
              />
            </el-tooltip>
          </div>
        </div>
      </div>
    </section>
    <dpo-element-pagination
      v-if="meta"
      :total="meta.total"
      :page-size="meta.per_page"
      :current-page="currentPage"
      @change-page="onChangePage"
    />
  </div>
</template>

<script>
import dpoElementPagination from '@/components/dpoCorpComponents/dpoElementPagination';
import {format} from 'date-fns';

export default {
  name: 'InvoicesComponent',
  components: {
    dpoElementPagination
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    meta: {
      type: Object,
      default: null
    },
    currentPage: {
      type: Number,
      default: 1
    },
    localization: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      dialogAttachBill: false,
      uploadingFile: false,
      uploadedFile: null,
      currentItem: null,
      localeText: {
        dateNumBills: this.localization.widgetCaption('dateNumBills'),
        invoiceAmount: this.localization.widgetCaption('invoiceAmount'),
        attachPaymentOrder:
          this.localization.widgetCaption('attachPaymentOrder'),
        agreeBills: this.localization.commonCaption('agreement'),
        actionBills: this.localization.commonCaption('labelAction'),
        downloadDocument: this.localization.commonCaption('downloadDocument'),
        attachFile: this.localization.commonCaption('attachFile'),
        toSend: this.localization.commonCaption('toSend'),
        successfullySaved: this.localization.commonCaption('successfullySaved'),
        failUpload: this.localization.commonCaption('error_fetchData')
      }
    };
  },
  methods: {
    getHumanPrice(value) {
      if (!value) return '';
      const tempValue = parseInt(value, 10);
      if (!tempValue) return '';
      return tempValue.toLocaleString('ru');
    },
    getHumanNameProgram(invoice) {
      return invoice?.contract?.program?.title ?? '-';
    },
    getHumanDate(date) {
      return format(new Date(date), 'dd.MM.yyyy');
    },
    onDownloadDocument(item) {
      const link = document.createElement('a');

      link.setAttribute('download', item.document.split('/').at(-1));
      link.setAttribute('href', item.document);

      link.click();
      link.remove();
    },
    onChangePage(page) {
      this.$emit('on-page', page || 1);
    }
  }
};
</script>

<style scoped lang="scss">
.dpo-document-bills__table-wrapper {
  overflow-x: auto;
}
.dpo-document-bills__table {
  margin-top: 24px;
  background-color: #fff;
  padding: 24px;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(106, 122, 152, 0.15);
  display: table;
  width: 100%;
  .header-contract__table {
    display: table-row;
    margin-bottom: 16px;
  }
  .item-act__agreement {
    display: block;
    font-size: 14px;
    color: #0050cf;
    font-weight: 400;
    line-height: 20px;
  }
  .item-act__row {
    padding-top: 16px;
    display: table-row;
  }
  .table--cell__hd {
    display: table-cell;
    padding: 0 0 16px 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #6b7a99;
    white-space: nowrap;

    .cell__hd-el {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
  .table--cell__hd:first-child {
    width: 6%;
    min-width: 90px;
  }
  .table--cell__hd:nth-child(2) {
    width: 100%;
    padding-left: 24px;
  }
  .table--cell__hd:nth-child(3) {
    padding-left: 24px;
  }
  .table--cell__hd:nth-child(4) {
    padding-left: 24px;
  }
  .table--cell__hd:nth-child(5) {
    padding-left: 24px;
  }
  .table--cell__hd:nth-child(6) {
    padding-left: 24px;
  }
  .table--cell__hd:last-child {
    padding-left: 24px;
  }
  .table--cell:last-child {
    width: 88px;
  }
  .table--cell {
    display: table-cell;
    padding: 16px 0;
    box-sizing: border-box;
    border-top: 1px solid #d3d8e5;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #0f0f14;
    vertical-align: middle;
    .cell__btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .status__process {
      color: #0050cf;
    }
    .status__denied {
      color: #d82e2e;
    }
    .status__submitted {
      color: #00a35f;
    }
    .status__finished {
      color: #6b7a99;
    }
    .table--cell__content {
      position: relative;
    }
    .table--cell__content.marker--cell {
      padding-left: 36px;
    }
  }
  .table--cell:not(:first-child) {
    padding-left: 24px;
  }

  .btn.el-btn__attach-bill:before {
    content: '';
    -webkit-mask-image: url('@/assets/icons/attach-bill-icon.svg');
    mask-image: url('@/assets/icons/attach-bill-icon.svg');
    -webkit-mask-position: center center;
    mask-position: center center;
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    transition: background-color 200ms ease;
    cursor: pointer;
    position: absolute;
    width: 24px;
    height: 24px;
    background: $dusk-gray-color;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
  .btn.el-btn__download:before {
    content: '';
    -webkit-mask-image: url('@/assets/icons/icon-import.svg');
    mask-image: url('@/assets/icons/icon-import.svg');
    -webkit-mask-position: center center;
    mask-position: center center;
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    transition: background-color 200ms ease;
    cursor: pointer;
    position: absolute;
    width: 16px;
    height: 16px;
    background: $dusk-gray-color;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
  .btn.el-btn__download:hover:before,
  .btn.el-btn__attach-bill:hover:before {
    background: $primary-brand-1;
  }
  .btn.el-btn__download_disabled:hover:before {
    background: $dusk-gray-color;
  }

  .btn.el-btn__download,
  .btn.el-btn__attach-bill {
    width: 40px;
    height: 40px;
    padding: 8px;
    position: relative;
    background-color: #f8f9fb;
  }
  .btn.el-button:last-child {
    margin-left: 8px;
  }
}

.item-act__row:last-child {
  .table--cell {
    padding-bottom: 0;
  }
}
.item-act__row.item-act__finished {
  & > *:not(:first-child) {
    color: #6b7a99;
  }
}

.dpo-document-bills__table .item-act__row .table--cell .cell--mobile__hd {
  display: none;
}

@media screen and (max-width: 767px) {
  .dpo-document-bills {
    .contract__date-el {
      justify-content: flex-start;
      margin-top: 14px;
    }
    .count--contract {
      p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
      span {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
      .el-button__sort.el-button {
        span {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          :after {
            top: -2px;
          }
        }
      }
    }
  }

  .dpo-document-bills__table {
    padding: 16px;
    .header-contract__table {
      display: none;
    }
    .item-act__row {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      border-bottom: 1px solid #d3d8e5;
      position: relative;
      padding-top: 0;
      .table--cell:last-child {
        position: absolute;
        right: 0;
        padding: 0;
        width: auto;
        .cell__btns {
          flex-direction: column;
          align-items: flex-end;
          .btn.el-button:last-child {
            margin: 5px 0 0 0;
          }
        }
      }
      .table--cell {
        border: unset;
        padding: 6px 0 6px 0;
        width: 100%;
        text-align: justify;
        .cell--mobile__hd {
          display: block;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          color: #6b7a99;
          margin-bottom: 8px;
        }
        .table--cell__content {
          max-width: 540px;
        }
      }
      .table--cell:nth-child(6) {
        order: 1;
      }
      .table--cell:nth-child(1) {
        display: inline-flex;
        order: 2;

        & > span {
          margin-right: 8px;
        }
      }
      .table--cell:nth-child(2) {
        padding-right: 50px;
        order: 3;
      }
      .table--cell:nth-child(3) {
        order: 4;
      }
      .table--cell:nth-child(4) {
        order: 5;
        padding-bottom: 6px;
      }
      .table--cell:nth-child(5) {
        order: 6;
      }
    }
    .item-act__row:last-child {
      border: unset;
    }
    .item-act__row {
      padding-bottom: 10px;
    }
  }
}
</style>
