<script>
export default {
  props: {
    scrollHorizontal: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      scrollLeft: 0
    }
  },
  computed: {
    showRightArrow() {
      return this.scrollLeft !== this.container?.offsetWidth
    }
  },
  mounted() {
    this.container = this.$refs.wrapper.querySelector('.el-row')
  },
  methods: {
    handleLeftArrowClick() {
      this._scrollLeft(-350)
    },

    handleRightArrowClick() {
      this._scrollLeft(350)
    },

    _scrollLeft(position) {
      const left = this.container.scrollLeft

      this.container.scrollTo({
        left: left + position,
        behavior: 'smooth',
      })

      setTimeout(() => this.scrollLeft = this.container.scrollLeft, 350)
    },
  }
}
</script>

<template>
  <div ref="wrapper" class="wrapper">
    <slot></slot>

    <template v-if="scrollHorizontal">
      <button
        v-if="scrollLeft > 0"
        @click="handleLeftArrowClick"
        type="button"
        class="wrapper_arrow-left"
      ></button>
      <button
        v-if="showRightArrow"
        @click="handleRightArrowClick"
        type="button"
        class="wrapper_arrow-right"
      ></button>
    </template>
  </div>
</template>

<style scoped lang="scss">
  .wrapper {
    position: relative;

    .wrapper_arrow-left,
    .wrapper_arrow-right {
      position: absolute;
      width: 18px;
      height: 18px;
      top: 50%;
      transform: translateY(-50%);
      z-index: 99;
      cursor: pointer;
      background: url("@/assets/icons/icon-arrow-default.svg") 100% 100% no-repeat;
      border: 0;
      padding: 5px;
    }

    .wrapper_arrow-left {
      left: 28px;
      transform: scaleX(-1);
    }

    .wrapper_arrow-right {
      right: 28px;
    }

    .el-row {
      overflow-x: hidden;
    }
  }
</style>
