import Axios from '@/utils/Elk-Axios';
import setNotification from '@/utils/setNotification';
import I18N from '@/i18n';
import { getUiItemCaption } from '@/i18n/utils';
import { GET_MYSERVICES, GET_MYSERVICES2, GET_POPULAR_SERVICES } from '@/api';

const MY_SERVICES_SIGNATURE = 'elk.main.myservice';
export default {
  namespaced: true,

  state: {
    services: [],
    common: [],
    user: [],
    popularServices: [],
  },

  mutations: {
    addServices(state, services) {
      state.services = services;
    },
    removeServices(state, id) {
      state.services = state.services.filter((service) => service.id !== id);
    },
    addCommon(state, common) {
      state.common = common;
    },
    removeCommon(state, id) {
      state.common = state.common.filter((service) => service.id !== id);
    },
    addUser(state, user) {
      state.user = user;
    },
    removeUser(state, id) {
      state.user = state.user.filter((service) => service.id !== id);
    },
    setPopularServices(state, services) {
      state.popularServices = services;
    },
  },
  actions: {
    async getServices(context) {
      try {
        let { data: services } = await Axios.get(GET_MYSERVICES);
        context.commit('addServices', services);
      } catch (err) {
        if (!err.isHandled) {
          setNotification({
            message: I18N.t(
              getUiItemCaption(MY_SERVICES_SIGNATURE, 'fetchDataError')
            ),
          });
        }
      }
    },
    async getServices2(context) {
      try {
        const {
          data: { common: commonServices, user: personalServices },
        } = await Axios.get(GET_MYSERVICES2);

        const {
          data: { services: popularServices },
        } = await Axios.get(GET_POPULAR_SERVICES);

        const filteredPopularServices = popularServices.filter((item) => {
          return !commonServices.some((el) => el.id === item.id);
        });

        context.commit('addCommon', commonServices);
        context.commit('addUser', personalServices);
        context.commit('setPopularServices', filteredPopularServices);
      } catch (err) {
        if (!err.isHandled) {
          setNotification({
            message: I18N.t(
              getUiItemCaption(MY_SERVICES_SIGNATURE, 'fetchDataError')
            ),
          });
        }
      }
    },
  },
};
