<template>
  <div :class="{body: true, active: menuActive}">
    <menu-wrapper :page-name="name" />
    <div class="page-wrapper">
      <main
        class="content"
        :class="pageClass"
      >
        <div class="container">
          <div class="page__title">
            <h1 class="h2">Сервисы</h1>
          </div>
          <div
            id="elk-service"
            class="widget"
          >
            >
            <idashboard>
              <idashboard-panel
                v-for="(group, index) in services"
                :key="index"
                :i-dashboard-panel-caption="group.title"
              >
                <idashboard-item
                  v-for="service in group.items"
                  :key="service.id"
                  :id-el="'' + service.id"
                  :img-url="service.image"
                  :caption="service.name"
                  :description="service.fullName"
                  :is-favorite="service.favorite"
                  :link="'/dpo/corp/get-help/' + service.id"
                />
              </idashboard-panel>
            </idashboard>
          </div>
        </div>
      </main>
    </div>
    <page-footer />
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex';
import MenuWrapper from '@/components/menuWrapper';
import Axios from '@/utils/Elk-Axios';
import PageFooter from '@/components/PageFooter';
// import { PROGRAMSPAGE } from '@/router/routes';
// import {CHECK_ONLINE_PAYMENT_STATUS, GET_RECEIPT} from "@/api";

import IdashboardPanel from '@/components/idashboard/IdashboardPanel.vue';
import Idashboard from '@/components/idashboard/Idashboard.vue';
import IdashboardItem from '@/components/idashboard/IdashboardItem.vue';
import {GET_CORP_SERVICES} from '@/api';

//     idashboard
// idashboard-panel
// idashboard-item

/// НЕ ИСПОЛЬЗУЕТСЯ!!!
/// Вместо этого компонента используется @/components/dpoWidgets/dpoCorpServicesWidget

export default {
  name: 'DpoCormServices',
  components: {
    PageFooter,
    MenuWrapper,
    'idashboard-panel': IdashboardPanel,
    idashboard: Idashboard,
    'idashboard-item': IdashboardItem
  },
  data() {
    return {
      name: 'Cервисы',
      pageClass: 'dpo-corp-services',
      services: []
    };
  },
  computed: {
    ...mapState(['menuActive']),
    ...mapGetters(['isPublicUser'])
  },
  created() {
    this.get_services();
  },
  methods: {
    async get_services() {
      try {
        const {data} = await Axios.get(GET_CORP_SERVICES);
        if (data.code === 200) {
          Object.keys(data.data).forEach(el => {
            let tmp = {};
            tmp.title = el;
            tmp.items = data.data[el];
            this.services.push(tmp);
          });
          console.log(this.services);
        }
      } catch (e) {
        console.log('Error!', e);
      }
    }
  }
};
</script>
<style lang="scss"></style>
