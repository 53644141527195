<template>
  <div>
    <p class="profile__table-title">
      {{ labels.listenerCategory }}
    </p>
    <div
      class="profile__table-info"
      :class="{profile_el_empty: profileFillErrors}"
    >
      <div
        v-if="!isCategoryEditable"
        class="input-profile-contact"
      >
        <div class="input-profile-contact__value">
          <span v-if="category && category.name">{{ category.name }}</span>
          <span v-else> - </span>
        </div>
        <div class="input-profile-contact__btn">
          <button
            class="btn btn--secondary btn--icon"
            @click="setCategoryFieldEditable"
          >
            <edit class="edit-icon" />
          </button>
        </div>
      </div>
      <select-category
        v-else
        :category="category"
        @set-category="setCategory"
        @failed-setting-category="closeCategoryEditable"
      />
    </div>
  </div>
</template>

<script>
import {getCommonCaptionByName} from '@/i18n/utils';
import Edit from '@/assets/icons/edit.svg?inline';
import SelectCategory from './selectCategory';

export default {
  name: 'ElkProfileInfoCategory',
  components: {
    Edit,
    SelectCategory
  },
  inject: ['localization'],
  data() {
    return {
      isCategoryEditable: false,
      isCategorySelected: false
    };
  },
  /*props: {
    category: Object,
  },*/
  computed: {
    category() {
      return this.$store.getters['profileData/getCategoryListener'];
    },
    labels() {
      return {
        listenerCategory: this.localization.widgetCaption('listenerCategory')
      };
    },
    profileFillErrors() {
      let errors = this.$store.getters[
        'profileFillCheck/profileFillErrors'
      ].map(function (obj) {
        return obj.code;
      });
      if (
        errors?.includes('listener_category') &&
        !this.isCategoryEditable &&
        !this.isCategorySelected
      )
        return true;

      return false;
    }
  },
  methods: {
    getCommonCaptionByName,
    setCategory(categoryInfo) {
      this.closeCategoryEditable();
      this.$store.dispatch(
        'profileData/setCategoryListenerById',
        categoryInfo.id
      );
      this.isCategorySelected = true;
      //this.$emit('set-category', categoryInfo);
    },
    setCategoryFieldEditable() {
      this.isCategoryEditable = true;
    },
    closeCategoryEditable() {
      this.isCategoryEditable = false;
    }
  }
};
</script>
