<template>
  <div class="cascade-select">
    <SelectedContainer
      :text="selectedText"
      @on-click="toggleOptsContainer"
    >
      <template #actions>
        <i
          class="cascade-select__plus"
          :class="{'cascade-select__plus_active': optionsVisible}"
        />
      </template>
    </SelectedContainer>
    <Transition name="el-fade-in">
      <OptionsContainer
        v-show="optionsVisible"
        class="cascade-select__opts-container"
      >
        <template #header>
          <div class="cascade-select__search">
            <el-input
              v-model="search"
              name="search"
              placeholder="Найти..."
              suffix-icon="el-icon-search"
            />
          </div>
        </template>
        <CascadeListCheckbox
          ref="listCheckbox"
          :key="keyListComponent"
          :items="filterItems"
        />
      </OptionsContainer>
    </Transition>
  </div>
</template>

<script>
import OptionsContainer from '@/components/formElements/CascadeSelect/OptionsContainer';
import SelectedContainer from '@/components/formElements/CascadeSelect/SelectedContainer';
import CascadeListCheckbox from '@/components/formElements/CascadeSelect/CascadeListCheckbox';
import {debounce} from 'throttle-debounce';

export default {
  name: 'CascadeSelect',
  components: {OptionsContainer, SelectedContainer, CascadeListCheckbox},
  provide() {
    return {
      changeCheckbox: () => {
        this.onChange();
      }
    };
  },
  props: {
    placeholder: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    removeCodeItem: {
      type: String,
      default: ''
    },
    clearTrigger: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      input: '',
      optionsVisible: false,
      search: '',
      selectedText: this.placeholder,
      filterItems: this.items,
      selectedItems: [],
      keyListComponent: 'list-component',
      resetComponentIndex: 0
    };
  },
  watch: {
    search(v) {
      this.debouncedSearch(v);
    },
    items(v) {
      this.filterItems = v;
    },
    removeCodeItem(v) {
      if (!v) return;
      this.setActiveStateItem(v);
      this.$emit('code-item-removed');
    },
    clearTrigger(v) {
      if (v) {
        this.selectedItems = [];
        this.resetListCheckboxComponent();
        this.updateSelectedText();
        this.$emit('cleared');
      }
    }
  },
  methods: {
    resetListCheckboxComponent() {
      this.resetComponentIndex += 1;
      this.keyListComponent = `${this.keyListComponent}_${this.resetComponentIndex}`;
    },
    outsideHandler(e) {
      if (!this.optionsVisible) return;
      if (!e.target.closest('.cascade-select__opts-container')) {
        this.hideOptsContainer();
      }
    },
    addOutsideHandler() {
      setTimeout(() => {
        document.addEventListener('click', this.outsideHandler);
      }, 0);
    },
    removeOutsideHandler() {
      document.removeEventListener('click', this.outsideHandler);
    },
    hideOptsContainer() {
      this.removeOutsideHandler();
      this.optionsVisible = false;
    },
    showOptsContainer() {
      this.optionsVisible = true;
      this.addOutsideHandler();
    },
    toggleOptsContainer() {
      if (this.optionsVisible) {
        this.hideOptsContainer();
      } else {
        this.showOptsContainer();
      }
    },
    getSearchItems(value) {
      const findItems = (items = []) => {
        let tempResult = [];
        items.forEach(e => {
          if (e.items?.length) {
            const findTempResult = findItems(e.items);
            if (findTempResult.length) {
              tempResult.push({
                group: e.group,
                items: findTempResult
              });
            }
          } else if (e.name && e.name.includes(value)) {
            tempResult.push(e);
          }
        });
        return tempResult;
      };

      return value ? findItems(this.items) : this.items;
    },
    debouncedSearch: debounce(300, async function (queryString) {
      this.filterItems = this.getSearchItems(queryString);
    }),
    updateSelectedText() {
      if (!this.selectedItems.length) {
        this.selectedText = this.placeholder;
        return;
      }
      this.selectedText = '';
      const lastIndex = this.selectedItems.length - 1;
      this.selectedItems.forEach((e, i) => {
        const post = i == lastIndex ? '' : ', ';
        this.selectedText += `${e.name ?? ''}${post}`;
      });
    },
    setActiveStateItem(code) {
      const execute = ref => {
        for (const refKey in ref) {
          if (ref[refKey]?.setActiveByCode) {
            if (ref[refKey].setActiveByCode(code)) {
              break;
            }
          }
          if (Array.isArray(ref[refKey]) && ref[refKey].length) {
            ref[refKey].forEach(e => execute(e.$refs));
          } else {
            execute(ref[refKey].$refs);
          }
        }
      };

      execute(this.$refs);
    },
    getSelectedItems() {
      const activeItems = [];

      const getActiveItems = ref => {
        for (const refKey in ref) {
          if (ref[refKey]?.getActive) {
            const items = ref[refKey].getActive();
            if (items.length) {
              activeItems.push(...items);
            }
          }
          if (Array.isArray(ref[refKey]) && ref[refKey].length) {
            ref[refKey].forEach(e => getActiveItems(e.$refs));
          } else {
            getActiveItems(ref[refKey].$refs);
          }
        }
      };

      getActiveItems(this.$refs);
      return activeItems;
    },
    updateSelectedItems() {
      this.selectedItems = this.getSelectedItems();
    },
    onChange() {
      this.updateSelectedItems();
      this.updateSelectedText();
      this.$emit('on-change', this.selectedItems);
    }
  }
};
</script>

<style>
.cascade-select {
  position: relative;
}

.cascade-select__opts-container {
  position: absolute;
  width: 100%;
  left: 0px;
  top: 100%;
  background-color: white;
  z-index: 11;
  box-shadow: 0 6px 7px -9px #000000;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom: 1px solid #d3d8e6;
  border-left: 1px solid #d3d8e6;
  border-right: 1px solid #d3d8e6;
}

.cascade-select__search {
  padding: 10px;
}

.cascade-select__search .el-input__inner {
  background-color: #f0f5ff;
  border-color: #f0f5ff;
}

.cascade-select__plus {
  position: absolute;
  right: 14px;
  top: 50%;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
  transition: transform 0.3s ease;
  background-repeat: no-repeat;
  background-position: center center;
  background-image: url('data:image/svg+xml,%3Csvg width=%2712%27 height=%2712%27 viewBox=%270 0 12 12%27 fill=%27none%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cpath d=%27M12 5H7V0H5V5H0V7H5V12H7V7H12V5Z%27 fill=%27%236B7A99%27/%3E%3C/svg%3E');
}
.cascade-select__plus_active {
  transform: translateY(-50%) rotateZ(45deg);
}
</style>
