<template>
  <div
    :class="{
      'inputWrap-phone': true,
      'error-system': hasError,
    }"
  >
    <label
      :class="{ 'required': required, }"
      :for="id"
    >
      {{ label }}
    </label>
    <the-mask
      v-if="showPlacecholder"
      :id="id"
      placeholder="+7(___)___-__-__"
      type="tel"
      class="input-form__auth"
      :mask="['+7 (###) ###-##-##']"
      :name="id"
      :value="value.$model"
      :required="required"
      @input="onInput"
    />
    <the-mask
      v-else
      :id="id" 
      type="tel"
      class="input-form__auth"
      :mask="['+7 (###) ###-##-##']"
      :name="id"
      :value="value.$model"
      :required="required"
      @input="onInput"
    />

    <div class="icon-phone__input"  v-if="!hideFlag"/> 
    <slot name="errors" />
  </div>
</template>

<script>
import { TheMask } from 'vue-the-mask'// компонент маска для элементов форм
export default {
  name: "InputPhone",

  components: {
    TheMask,
  },

  props: {
    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      default: 'phone',
    },
    required: {
      type: Boolean,
      default: true,
    },
    value: {
      type: Object,
      default: () => ({ $model: '' }),
    },
    showPlacecholder: {//если не нужно выводить плейсходлер
      type: Boolean,
      default: true
    },
    hideFlag: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    hasError() {
      return this.value.$error || (this.value.$dirty && this.value.$invalid);
    },
  },

  methods: {
    onInput(value) {
      this.value.$model = value;
      let maskedPhone = '';
      if (value.length === 10) {
        const mask = value.match(/(\d{3})(\d{3})(\d{2})(\d{2})/);
        maskedPhone = `+7 ${mask[1]} ${mask[2]} ${mask[3]} ${mask[4]}`
      }
      this.$emit('get-masked-phone', maskedPhone);
    },
  },
}
</script>

<style lang="scss">
.inputWrap-phone {
  margin-bottom: 28px;
}

.inputWrap-phone {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  label {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $dusk-gray-color;
    margin-bottom: 8px;
    position: relative;
    display: inline-flex;
    width: fit-content;
  }

  label.required:before {
    content: '*';
    position: absolute;
    top: 0;
    right: -6px;
    width: 5px;
    height: 5px;
    display: block;
  }

  .input-form__auth {
    width: 100%;
    height: 48px;
    border-radius: 4px;
    border: 1px solid $border-color;
    padding: 14px 45px 12px 14px;

    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: $base-black-color;
    transition: all 0.3s;
    box-sizing: border-box;

    &:focus {
      outline: 1px solid #101010 !important;
      border: 1px solid #101010 !important;
    }

    &[readonly] {
      color: #6B7A99;
      background-color: #FAFAFA;
      cursor: not-allowed;

      &:focus {
        outline: none !important;
        border: 1px solid #D3D8E6 !important;
        cursor: auto;
      }
    }

    &[disabled="disabled"] {
      outline: none;
      background-color: #FAFAFA;
      color: #545479;
      cursor: not-allowed;
    }
  }

  .icon-phone__input {
    position: absolute;
    top: 38px;
    right: 15px;
    width: 24px;
    height: 24px;
    background: url("@/assets/icons/icon-rus.svg") 100% 100% no-repeat;
  }

  .input-form__auth:focus-visible {
    border: 1px solid $blue-color;
  }

  &.error-system .input-form__auth:focus-visible {
    border-color: $error-system-red;
    outline: 1px solid $error-system-red;
  }

  &.error-system input {
    border: 1px solid $error-system-red;
  }
}
</style>
