import Axios from '@/utils/Elk-Axios';
import setNotification from '@/utils/setNotification';
import { GET_SERVICE } from '@/api';
import I18N from '@/i18n';
import { getUiItemCaption } from '@/i18n/utils';

const HOME_SERVICES_SIGNATURE = 'elk.main.service';

export default {
  namespaced: true,

  state: {
    services: [],
  },

  mutations: {
    addServices(state, services) {
      state.services = services;
    },
    removeServices(state, id) {
      state.services = state.services.filter((service) => service.id !== id);
    },
    updateService(state, params) {
      let changedService = state.services.find(
        (service) => service.id == params.id
      );
      changedService.is_myservice = params.is_myservice;
    },
  },
  actions: {
    async getServices(context) {
      try {
        let {
          data: { services },
        } = await Axios.get(GET_SERVICE, {
          params: {
            url: '/',
          },
        });
        context.commit('addServices', services);
      } catch (err) {
        if (!err.isHandled) {
          setNotification({
            message: I18N.t(
              getUiItemCaption(HOME_SERVICES_SIGNATURE, 'fetchDataError')
            ),
          });
        }
      }
    },
  },
};
