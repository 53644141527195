import { debounce } from 'throttle-debounce';
import Axios from '@/utils/Elk-Axios';
import {GET_PROFILE_REFERENCES, PROFILE_V1} from '@/api';
import setNotification from "@/utils/setNotification";

export default {
  namespaced: true,
  state: {
    category_listener: {},
    register_address: null,
    residential_address: null,
    is_residential_same: false,
    listenerCategories: [],
    isInit: false,
    work: '',
    post: '',
    region: '',
    isSvo: false, // Являюсь участником СВО
    isSvoRelative: false // Являюсь близким родственником участника СВО
  },
  getters: {
    getCategoryListenerById: (state) => (id) => typeof id === 'number' ? state.listenerCategories.find((item) => item.id === id) ?? {} : {},
    /** @returns Object */
    getRegisterAddress: (state) => state.register_address,
    /** @returns Object */
    getResidentialAddress: (state) => state.residential_address,
    /** @returns Boolean */
    getIsResidentialSame: (state) => state.is_residential_same,
    getPost: (state) => state.post,
    getWork: (state) => state.work,
    getRegion: (state) => state.region,
    getCategoryListener: (state) => state.category_listener
  },
  mutations: {
    setListenerCategories: (state, listenerCategories) => {
      state.listenerCategories = listenerCategories;
    },
    setCategoryListener: (state, categoryListener) => {
      state.category_listener = categoryListener;
    },
    setRegisterAddress: (state, registerAddress) => {
      state.register_address = registerAddress;
    },
    setResidentialAddress: (state, residentialAddress) => {
      state.residential_address = residentialAddress;
    },
    setIsResidentialSame: (state, isSame) => {
      state.is_residential_same = isSame;
    },
    checkIsResidentialSame: (state) => {
      state.is_residential_same = state.register_address?.name && state.register_address?.name === state.residential_address?.name;
    },
    setSvo: (state, data) => {
      state.isSvo = data?.isSvo ?? false;
      state.isSvoRelative = data?.isSvoRelative ?? false;
    },
    setWork: (state, work) => {
      state.work = work;
    },
    setPost: (state, post) => {
      state.post = post;
    },
    setRegion: (state, region) => {
      state.region = region;
    },
    setInit: (state, value) => {
      state.isInit = value ?? false;
    }
  },
  actions: {
    setWork: (context, work) => {
      context.commit('setWork', work);
    },
    setPost: (context, post) => {
      context.commit('setPost', post);
    },
    setRegion: (context, region) => {
      context.commit('setRegion', region);
    },
    getProfileListenerCategories: async (context) => {
      const prepareList = (list) => list.length ? list.map( x => ({ 'name': x.label, 'id': x.value }) ) : [];

      try {
        const { data } = await Axios.get(GET_PROFILE_REFERENCES);

        if (data.code === 200 && data.data) {
          context.commit('setListenerCategories', prepareList(data.data.category_listener));
        }

      } catch (err) {
        console.error('getProfileListenerCategories', {err});
        /** */
      }
    },
    getProfileData: async (context) => {
      if (context.state.isInit) return;
      context.commit('setInit', true);
      try {
        const { data } = await Axios.get(PROFILE_V1);
        if (data.code === 200 && data.data) {
          const categoryListener = context.getters.getCategoryListenerById(data.data.category_listener);
          context.commit('setCategoryListener', { ...categoryListener });
          context.commit('setRegisterAddress', { name: data.data.register_address, dadata: data.data.register_dadata });
          context.commit('setResidentialAddress', { name: data.data.residential_address, dadata: data.data.residential_dadata });
          context.commit('setIsResidentialSame', data.data.register_address && data.data.register_address === data.data.residential_address);
          context.commit('setWork', data.data.job);
          context.commit('setPost', data.data.post);
          context.commit('setRegion', data.data.job_region_id);
          context.commit('setSvo', {
            isSvo: data.data?.svo,
            isSvoRelative: data.data?.svo_relative
          });
        }

      } catch (err) {
        console.error('getProfileData', {err});
        /** */
      }
    },
    updateRegisterAddress: async (context, { name, dadata })  => {
      const registerAddress = context.getters.getRegisterAddress;
      const isResidentialSame = context.getters.getIsResidentialSame;

      try {
        if (name.length < 2 || !dadata) return;
        context.commit('setRegisterAddress', { name, dadata });

        if (isResidentialSame) {
          context.dispatch('updateResidentialAddress',{ name, dadata });
        }

        const postBody = {
          'register_address': name,
          'register_dadata': dadata,
        };

        await Axios.patch(PROFILE_V1, postBody);


        setNotification({
          message: `Адрес постоянной регистрации сохранён`,
          type: 'success',
        });

      } catch (err) {
        context.commit('setRegisterAddress', registerAddress);

        if (!err.isHandled) {
          setNotification({
            message: `Не удалось сохранить адрес постоянной регистрации. Попробуйте позже или обратитесь в службу поддержки`,
          });
        }
      }
    },
    updateResidentialAddress: async (context, { name, dadata })  => {
        const residentialAddress = context.getters.getResidentialAddress;
        const isResidentialSame = context.getters.getIsResidentialSame;

        try {
          if (name.length < 2 || !dadata) return;

          context.commit('setResidentialAddress', { name, dadata });
          context.commit('checkIsResidentialSame');

          const postBody = {
            'residential_address': name,
            'residential_dadata': dadata,
          };

          await Axios.patch(PROFILE_V1, postBody);


          setNotification({
            message: `Адрес проживания сохранён`,
            type: 'success',
          });
        } catch (err) {
          context.commit('setResidentialAddress', residentialAddress);
          context.commit('setIsResidentialSame', isResidentialSame);

          if (!err.isHandled) {
            setNotification({
              message: `Не удалось сохранить адрес проживания. Попробуйте позже или обратитесь в службу поддержки`,
            });
          }
        }
    },
    updateResidentialIsSame: (context, isSame) => {
      const registerAddress = context.getters.getRegisterAddress;
      const residentialAddress = context.getters.getResidentialAddress;

      if (isSame && registerAddress && residentialAddress?.name !== registerAddress.name) {
        // context.commit('setResidentialAddress', { ...registerAddress });
        context.dispatch('updateResidentialAddress',{ ...registerAddress });
      }

      context.commit('setIsResidentialSame', isSame);
    },
    setCategoryListenerById: (context, id) => {
      const categoryListener = context.getters.getCategoryListenerById(id);
      context.commit('setCategoryListener', { ...categoryListener });
    }
  }
}
