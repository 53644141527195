<template>
  <div>
    <form
      id="fin-literacy"
      class="fin-literacy common-wrapper__documents"
    >
      <div class="inner_literacy">
        <label for="region">{{ localeText.regionPlaceWork }}*</label>
        <div>
          <el-select
            id="region"
            v-model="region"
            :placeholder="localeText.regionPlaceWork"
            @change="verify_data"
            class="el-form-item__corp"
            filterable
          >
            <el-option
              v-for="item in region_cat"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </div>
      </div>

      <div class="inner_literacy">
        <label for="register_address">{{ localeText.orgAddress }}* </label>
        <el-autocomplete
          id="register_address"
          v-model="address"
          :placeholder="localeText.orgAddress"
          class="dadata"
          name="register_address"
          :fetch-suggestions="searchAddress"
          @change="verify_data"
        />
      </div>

      <div class="inner_literacy">
        <label for="email">{{ localeText.orgEmail }}*</label>
        <div>
          <input
            id="email"
            v-model="email"
            :placeholder="localeText.orgEmail"
            class="el-input__inner"
            type="email"
            @change="verify_data"
          />
        </div>
      </div>

      <div class="inner_literacy">
        <label for="teacher">{{ localeText.teacherCategory }}*</label>
        <div>
          <el-select
            id="teacher"
            v-model="teacher"
            :placeholder="localeText.teacherCategory"
            class="el-form-item__corp"
            filterable
          >
            <el-option
              v-for="item in teacher_cat"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              @change="verify_data"
            />
          </el-select>
        </div>
      </div>

      <div class="inner_literacy">
        <label for="post">{{ localeText.positionSubj }}*</label>
        <div>
          <input
            id="post"
            v-model="post"
            :placeholder="localeText.positionSubj"
            class="el-input__inner"
            @change="verify_data"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import getDadata from '@/components/widgets/elkOlympiads/getDadata';
import Axios from '@/utils/Elk-Axios';
import {GET_REF_REGIONS, PROFILE_V1, REF_TEACHER_CAT} from '@/api';
import {filterBus} from '@/main';

export default {
  name: 'FinLiteracy',
  props: {
    localization: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      teacher_cat: [],
      region_cat: [],

      teacher: '',
      address: '',
      region: '',
      email: '',
      post: '',
      localeText: {
        regionPlaceWork: this.localization.commonCaption('regionPlaceWork'),
        orgAddress: this.localization.widgetCaption('orgAddress'),
        orgEmail: this.localization.widgetCaption('orgEmail'),
        teacherCategory: this.localization.widgetCaption('teacherCategory'),
        positionSubj: this.localization.widgetCaption('positionSubj')
      }
    };
  },

  created() {
    this.get_regions();
    this.get_teacher_cat();
  },

  methods: {
    getDadata,
    verify_data() {
      filterBus.$emit('finLiteracyRegion', this.region);
      if (
        this.region &&
        this.teacher &&
        this.address.length > 10 &&
        this.email.includes('@') &&
        this.email.includes('.') &&
        this.post.length > 5
      ) {
        filterBus.$emit('finLiteracy', {
          region: this.region,
          teacher: this.teacher,
          address: this.address.trim(),
          email: this.email.trim(),
          post: this.post.trim()
        });
      }
    },
    setDadataItem(address) {
      this.$emit('on-select-register-address', address);
    },
    async searchAddress(queryString, cb) {
      if (queryString) {
        try {
          const data = await this.getDadata('urlAddress', queryString);
          if (data) {
            cb(data.suggestions);
          } else {
            cb([]);
          }
        } catch (err) {
          cb([]);
          if (!err.isHandled) {
            this.setNotification({
              message: `Не удалось получить адреса. Попробуйте позже или обратитесь в службу поддержки`
            });
          }
        }
      } else {
        cb([]);
      }
    },
    async get_regions() {
      try {
        const {data} = await Axios.get(GET_REF_REGIONS);
        if (data.code === 200) {
          this.region_cat = data.data.regions ?? [];
          await this.get_profile_reg();
        }
      } catch (e) {
        console.log('Error!', e);
      }
    },
    async get_profile_reg() {
      try {
        const {data} = await Axios.get(PROFILE_V1);
        if (data.code === 200) {
          this.region = data.data.job_region_id ?? [];
          this.email = data.data.org_email ?? '';
          this.teacher = data.data.org_teacher_cat_id ?? [];
          this.address = data.data.org_address ?? '';
          this.post = data.data.org_teacher_post ?? '';
          this.verify_data();
        }
      } catch {
        console.log('ERROR');
      }
    },
    async get_teacher_cat() {
      try {
        const {data} = await Axios.get(REF_TEACHER_CAT);
        if (data.code === 200) {
          this.teacher_cat = data.data.teacher_cat ?? [];
        }
      } catch {
        console.log('ERROR');
      }
    }
  }
};
</script>

<style lang="scss">
#fin-literacy {
  box-shadow: unset;
  padding: unset;
  .inner_literacy {
    margin-top: 20px;
    label {
      display: block;
      color: $color-gray-dusk;
      margin-bottom: 8px;
    }
  }
  .dadata,
  .el-select {
    width: 100%;
  }
  .el-input__inner {
    height: 40px;
    line-height: 40px;
  }
  .inputWrap {
    .input-form__prof:hover {
      border: 1px solid var(--dark-blue);
    }
    .input-form__prof:active {
      border: 1px solid var(--dark-blue);
    }
    .input-form__prof:focus {
      border: 1px solid var(--dark-blue);
    }
  }
}
</style>
