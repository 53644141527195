<template>
  <div>
    <p class="profile__table-title">
      {{ $t(getCommonCaptionByName('contacts_nationality')) }}
    </p>
    <div class="profile__table-info">
      <div class="input-profile-contact" v-if="!isNationalityEditable">
        <div class="input-profile-contact__value">
          <span v-if="nationality && nationality.name">{{
            nationality.name
          }}</span>
          <span v-else>
            -
          </span>
        </div>
        <div class="input-profile-contact__btn">
          <button
            @click="openNationalityEditable"
            class="btn btn--secondary btn--icon"
          >
            <edit class="edit-icon" />
          </button>
        </div>
      </div>
      <select-nationality
        :nationality="nationality"
        @set-nationality="setNationality"
        @failed-setting-nationality="closeNationalityEditable"
        v-else
      />
    </div>
  </div>
</template>

<script>
import { getCommonCaptionByName } from '@/i18n/utils';
import Edit from '@/assets/icons/edit.svg?inline';
import SelectNationality from './selectNationality';

export default {
  name: 'elk-profile-info-nationality',
  components: {
    Edit,
    SelectNationality,
  },
  props: {
    nationality: Object,
  },
  data() {
    return {
      isNationalityEditable: false,
    };
  },
  methods: {
    getCommonCaptionByName,
    setNationality(nationalityInfo) {
      this.closeNationalityEditable();
      this.$emit('set-nationality', nationalityInfo);
    },
    openNationalityEditable() {
      this.isNationalityEditable = true;
    },
    closeNationalityEditable() {
      this.isNationalityEditable = false;
    },
  },
};
</script>

<style></style>
