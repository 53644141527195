<template>
  <div>
    <ProgramTabs
      :selected="selected"
      :items="statuses"
      @select="selectHandler"
    />
    <ProgramList
      :programs="filtered"
      :sendReviewId="sendReviewId"
      :localization="localization"
      @sendReview="onSendReview"
    />
  </div>
</template>

<script>
import ProgramList from './common/programList.vue';
import ProgramTabs from './common/programTabs.vue';
import {PROGRAM_STATUS} from '@/utils/getProgramDataByStatus';

export default {
  name: 'ProgramDocuments',

  components: {
    ProgramList,
    ProgramTabs
  },

  props: {
    localization: {
      type: Object,
      default: () => {}
    },
    documents: {
      type: Array,
      required: true
    },
    statuses: {
      type: Array,
      required: true
    },
    sendReviewId: {
      type: String,
      default: ''
    }
  },

  data: function () {
    return {
      selected: PROGRAM_STATUS.ALL,
      program: null,
      sendingPaymentRequest: false
    };
  },
  computed: {
    filtered() {
      if (this.selected === PROGRAM_STATUS.ALL) {
        return this.documents ?? [];
      }

      return this.documents?.filter(e => e.status.value === this.selected);
    }
  },

  methods: {
    onSendReview(id) {
      this.$emit('sendReview', id);
    },
    selectHandler(code) {
      this.selected = code;
    }
  }
};
</script>
