<template>
  <header class="header-mobile">
    <div class="header-mobile__content">
      <ul class="header-mobile__list">
        <li class="header-mobile__item">
          <router-link
            class="header-mobile__link"
            :to="HOME_ROUTE"
          >
            <iconLogo />
          </router-link>
        </li>
        <li
          v-if="!isPublicUser"
          class="header-mobile__item"
        >
          <router-link
            class="header-mobile__link"
            active-class="choosen"
            :to="PROFILE_ROUTE"
          >
            <avatar
              class="header-mobile__avatar"
              :url="$store.state.user.avatar"
              size="32"
              :fio-separate="[
                $store.state.user.first_name,
                $store.state.user.last_name
              ]"
              :gender-id="$store.state.user.gender_code"
              alt="Профиль"
            />
          </router-link>
        </li>
        <template v-if="$store.getters.userDpo">
          <li class="header-mobile__item with-delimiter with-delimiter-double">
            <link-like-header :mobile="true" />
          </li>
          <!--          <li class="header-mobile__item with-delimiter">
            <link-btn-menu-header :mobile="true" />
          </li>-->
          <li class="header-mobile__item with-delimiter">
            <link-basket-header :mobile="true" />
          </li>
        </template>
        <li
          v-if="!isPublicUser && notification"
          class="header-mobile__item"
        >
          <AbsoluteRelativeLink
            :to="notification.url"
            active-class="choosen"
            exact
            class="header-mobile__link"
          >
            <div class="noty-badge">
              <div class="noty-badge__body">
                <div class="noty-badge__text">
                  <component
                    :is="iconLoader(notification.signature)"
                    class="icon header-mobile__icon"
                  />
                </div>
                <div
                  v-if="notificationCount > 0"
                  class="noty-badge__noty"
                >
                  <div class="noty-badge__count js-notif-count">
                    {{ notificationCount }}
                  </div>
                </div>
              </div>
            </div>
          </AbsoluteRelativeLink>
        </li>
      </ul>
      <div
        :class="{'burger-container': true, open: menuActive}"
        @click.stop="openMenu(!menuActive)"
      >
        <div class="burger">
          <span>&nbsp;</span>
          <span>&nbsp;</span>
          <span>&nbsp;</span>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import {mapGetters} from 'vuex';

import iconLoader from '@/utils/iconLoader';
import avatar from '@/components/common/elkAvatar';
import iconLogo from '@/assets/icons/circleLogo.svg?inline';
import {mapState} from 'vuex';
import {HOME_ROUTE, PROFILE_ROUTE} from '@/router/routes.js';
import AbsoluteRelativeLink from '@/components/AbsoluteRelativeLink';
import LinkLikeHeader from '@/components/common/commonLinkBtnHeader/linkLikeHeader.vue';
import LinkBtnMenuHeader from '@/components/common/commonLinkBtnHeader/linkBtnMenuHeader.vue';
import LinkBasketHeader from '@/components/common/commonLinkBtnHeader/linkBasketHeader.vue';

export default {
  name: 'HeaderMobile',
  components: {
    LinkBasketHeader,
    LinkBtnMenuHeader,
    LinkLikeHeader,
    AbsoluteRelativeLink,
    iconLogo,
    avatar
  },
  data() {
    return {HOME_ROUTE, PROFILE_ROUTE};
  },
  computed: {
    notification() {
      return this.$store.getters.pageTreeForMenu.find(
        link => link.signature === 'elk.notification'
      );
    },
    ...mapState({
      menuActive: state => state.menuActive,
      notificationCount: state => state.notificationCounter.notificationCount
    }),
    ...mapGetters(['isPublicUser'])
  },
  methods: {
    iconLoader,
    openMenu(active) {
      this.$store.commit('resizeMenu', active);
    }
  }
};
</script>

<style lang="scss">
.with-delimiter {
  border-right: 1px solid rgba(211, 215, 225, 0.6);
  margin-right: 15px;
  &-double {
    border-left: 1px solid rgba(211, 215, 225, 0.6);
    padding-left: 15px;
  }
}
</style>
