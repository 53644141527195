<template>
  <div class="show_more_programs">
    <a
      :href="link"
      target="_blank"
    >
      {{ morePrograms }}
    </a>
  </div>
</template>

<script>
import {getCommonCaptionByName} from '@/i18n/utils';

export default {
  name: 'ShowMoreProgram',
  computed: {
    link() {
      let navItem = this.$store.getters.dpoNavLinks.find(
        e => e.slug === 'katalog-programm'
      );
      return navItem?.link ?? 'https://www.hse.ru/edu/dpo/';
    },
    morePrograms() {
      return this.$t(getCommonCaptionByName('viewMorePrograms'));
    }
  }
};
</script>
<style lang="scss">
.show_more_programs {
  font-weight: bold;
  margin-top: 20px;
  display: flex;
  justify-content: right;
}
.show_more_programs a {
  font-weight: 600;
}
</style>
