import {AUTHORIZATION} from '../routes';
import store from '@/store/index.js';

export default async (_, __, next) => {
  const tempRedirect = {
    path: AUTHORIZATION,
  };

  const allowRoles = ["adm", "dpo"];

  const roles = store.getters["userRoles"];

  if (roles.some((role) => allowRoles.includes(role))) {
    next();
  } else {
    next();
  }
};
