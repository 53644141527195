<template>
  <div class="menu-wrapper">
    <div v-if="mainPageNotAvailable">
      <header class="header">
        <div class="container">
          <div class="header__wrapper">
            <div class="header__logo-wrapper">
              <iconLogo class="header__logo" />
            </div>
          </div>
        </div>
      </header>
    </div>
    <div v-else>
      <elk-header
        v-if="!isMobile"
        :page-name="pageName"
      />
      <elk-sidebar v-if="!isMobile" />
      <elkMobileHeader v-if="isMobile" />
      <elkMobileSidebar v-if="isMobile" />
      <elkMobileBottomNav v-if="isMobile" />
    </div>
  </div>
</template>

<script>
import elkHeader from '@/components/elkHeader';
import elkSidebar from '@/components/elkSidebar';
import elkMobileHeader from '@/components/elkMobileHeader';
import elkMobileBottomNav from '@/components/elkMobileBottomNav';
import elkMobileSidebar from '@/components/elkMobileSidebar';
import {mapGetters, mapState} from 'vuex';
import iconLogo from '@/assets/icons/smartPoint-2.svg?inline';

export default {
  name: 'MenuWrapper',
  components: {
    iconLogo,
    elkHeader,
    elkSidebar,
    elkMobileHeader,
    elkMobileBottomNav,
    elkMobileSidebar
  },
  props: ['pageName'],
  computed: {
    ...mapGetters(['isMobile']),
    ...mapState(['mainPageNotAvailable'])
  }
};
</script>
<style lang="scss">
@media screen and (max-width: 768px) {
  .header-mobile__list {
    display: flex;
    align-items: center;

    .header-mobile__avatar {
      margin-bottom: 0;
    }
  }

  .header-mobile__link {
    display: flex !important;
  }
}
</style>
