<template>
  <filter-block
    searchable
    :search-placeholder="labels.filterFindProgram"
    sortable
    :sort-options="sortOptions"
    :result-text="labels.programsFound"
    :result-count="resultCount"
    :load-method="loadMethod"
    :sort-by-init="sortOptions[0]"
    @loaded="loaded"
  >
    <!--        <training-areas-filter />-->
    <training-areas-select-cascade
      :placeholder="labels.filterDirections"
      param="training_areas"
    />
    <!--    <department-filter />-->
    <!--    <duration-type-filter />-->

    <!--      <program-start-filter />-->
    <type-program-cascade :placeholder="labels.filterProgramType" />
    <edu-format-filter :label="labels.filterTrainingFormat" />
    <campus-filter :label="labels.filterCampus" />
    <period-range
      :start-placeholder="labels.filterFromToProgram.from"
      :end-placeholder="labels.filterFromToProgram.to"
    />
    <duration-hours :label="labels.filterDurationTraining" />
    <language-filter :label="labels.filterLanguage" />

    <program-price-filter
      :label="labels.filterCost"
      :min="1000"
      :max="2500000"
      :range="[1000, 2500000]"
    />

    <represent-switch
      v-if="representFilters"
      :label="labels.onlyEnterpriseProgramm"
      param="corporative"
    />
    <represent-switch
      v-if="representFilters"
      :label="labels.onlyCustomProgramm"
      param="by_legal"
    />

    <!--    <final-document-type-filter :label="labels.filterDocType" />-->
    <!--    <for-whom :label="labels.filterForWhom" />-->
  </filter-block>
</template>

<script>
import FilterBlock from '@/components/filters/FilterBlock.vue';
import ProgramPriceFilter from '@/components/filters/ProgramPriceFilter.vue';
import EduFormatFilter from '@/components/filters/EduFormatFilter.vue';
import CampusFilter from '@/components/filters/CampusFilter.vue';
import DurationHours from '@/components/filters/DurationHours.vue';
// import ForWhom from '@/components/filters/ForWhom.vue';
import FinalDocumentTypeFilter from '@/components/filters/FinalDocumentTypeFilter.vue';
import PeriodRange from '@/components/filters/PeriodRange.vue';
import TrainingAreasSelectCascade from '@/components/filters/TrainingAreasSelectCascade';
import TypeProgramCascade from '@/components/filters/TypeProgramCascade';
import {getCommonCaptionByName} from '@/i18n/utils';
import LanguageFilter from '@/components/filters/LanguageFilter.vue';
import RepresentSwitch from '@/components/filters/RepresentSwitch.vue';

export default {
  name: 'ProgramPresetFilter',
  components: {
    RepresentSwitch,
    LanguageFilter,
    PeriodRange,
    TrainingAreasSelectCascade,
    TypeProgramCascade,
    FinalDocumentTypeFilter,
    DurationHours,
    EduFormatFilter,
    CampusFilter,
    ProgramPriceFilter,
    FilterBlock
    // ForWhom
  },
  props: {
    loadMethod: {
      type: Function,
      required: true
    },
    loaded: {
      type: Function,
      default: () => () => {}
    },
    resultCount: {
      type: Number,
      default: 0
    },
    representFilters: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dateInterval: ''
    };
  },
  computed: {
    labels() {
      const tempFromTo = this.$t(
        this.getCommonCaptionByName('filterFromToProgram')
      ).split('{to}');

      return {
        programsFound: this.$t(this.getCommonCaptionByName('programsFound')),
        filterFindProgram: this.$t(
          this.getCommonCaptionByName('filterFindProgram')
        ),
        onlyEnterpriseProgramm: this.$t(
          this.getCommonCaptionByName('onlyEnterpriseProgramm')
        ),
        onlyCustomProgramm: this.$t(
          this.getCommonCaptionByName('onlyCustomProgramm')
        ),
        filterDirections: this.$t(
          this.getCommonCaptionByName('filterDirections')
        ),
        filterLanguage: this.$t(this.getCommonCaptionByName('filterLanguage')),
        filterCost: this.$t(this.getCommonCaptionByName('filterCost')),
        filterDocType: this.$t(this.getCommonCaptionByName('filterDocType')),
        filterTrainingFormat: this.$t(
          this.getCommonCaptionByName('filterTrainingFormat')
        ),
        filterProgramType: this.$t(
          this.getCommonCaptionByName('filterProgramType')
        ),
        filterCampus: this.$t(this.getCommonCaptionByName('filterCampus')),
        filterDurationTraining: this.$t(
          this.getCommonCaptionByName('filterDurationTraining')
        ),
        filterForWhom: this.$t(this.getCommonCaptionByName('filterForWhom')),
        filterFromToProgram: {
          from: tempFromTo.length ? tempFromTo[0] : '',
          to: tempFromTo.length ? tempFromTo[1] : ''
        }
      };
    },
    sortOptions() {
      return [
        {
          label: this.$t(this.getCommonCaptionByName('sortByDefault')),
          value: 'default'
        },
        {
          label: `${this.$t(this.getCommonCaptionByName('sortByDate'))}
<!--<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 13.5V2.5" stroke="#0F0F14" stroke-linecap="round" stroke-linejoin="round"/><path d="M3.5 7L8 2.5L12.5 7" stroke="#0F0F14" stroke-linecap="round" stroke-linejoin="round"/></svg>-->`,
          value: 'date'
        },
        // {
        //   label: `${this.$t(
        //     this.getCommonCaptionByName('sortByDate')
        //   )} <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 2.5L8 13.5" stroke="#0F0F14" stroke-linecap="round" stroke-linejoin="round"/><path d="M12.5 9L8 13.5L3.5 9" stroke="#0F0F14" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
        //   value: 'date_desc'
        // },
        // {
        //   label: this.$t(this.getCommonCaptionByName('sortByProgram')),
        //   value: 'title'
        // },
        {
          label: `${this.$t(this.getCommonCaptionByName('sortByPriceAsc'))}
<!--<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 13.5V2.5" stroke="#0F0F14" stroke-linecap="round" stroke-linejoin="round"/><path d="M3.5 7L8 2.5L12.5 7" stroke="#0F0F14" stroke-linecap="round" stroke-linejoin="round"/></svg>-->`,
          value: 'price'
        }
        // {
        //   label: `${this.$t(
        //     this.getCommonCaptionByName('sortByPriceDesc')
        //   )} <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 2.5L8 13.5" stroke="#0F0F14" stroke-linecap="round" stroke-linejoin="round"/><path d="M12.5 9L8 13.5L3.5 9" stroke="#0F0F14" stroke-linecap="round" stroke-linejoin="round"/></svg>`,
        //   value: 'price_desc'
        // }
        // {
        //   label: this.$t(this.getCommonCaptionByName('sortByDiscount')),
        //   value: 'discount'
        // }
      ];
    }
  },
  methods: {
    getCommonCaptionByName
  }
};
</script>
