<template>
  <div
    id="business-card"
    class="business-card"
  >
    <div>
      <IconLogo class="business-card__logo" />

      <h4 class="h4--hse">
        {{ userData.last_name }}
        {{ userData.first_name }}
        {{ userData.middle_name }}
      </h4>

      <p v-if="mainPositionName">{{ mainPositionName }}</p>

      <p v-if="addressText">
        {{ addressText }}
      </p>

      <div class="business-card__contact-block">
        <p v-if="mainAddress && mainAddress.phone_work">
          Тел.: {{ mainAddress.phone_work }}
          <template v-if="mainAddress.phone_internal">
            *{{ mainAddress.phone_internal }}
          </template>
        </p>
        <p v-if="userData.phone_mobile">Моб.: {{ phoneText }}</p>
        <p v-if="userData.email">{{ userData.email }}</p>
      </div>
    </div>
    <div
      class="flex justify-center"
      style="margin-top: 320px"
    >
      <canvas ref="business-card-qrcode" />
    </div>
  </div>
</template>

<script setup>
import IconLogo from '@/assets/icons/hse-text-icon.svg?inline';
import formatRusPhone from '@/utils/formatRusPhone';
import {computed} from 'vue';

const props = defineProps({
  userData: {
    type: Object,
    required: true
  },
  mainAddress: {
    type: Object,
    default: null
  },
  mainPositionName: {
    type: String,
    default: null
  }
});

const addressText = computed(() => {
  if (!props.mainAddress) return null;

  const addressArray = [];

  if (props.mainAddress.city) {
    addressArray.push(props.mainAddress.city);
  }

  if (props.mainAddress.address) {
    addressArray.push(props.mainAddress.address);
  }

  if (props.mainAddress.room_number) {
    addressArray.push('Аудитория ' + props.mainAddress.room_number);
  }

  return addressArray.join(', ');
});

const phoneText = computed(() => {
  return props.userData.phone_mobile
    ? formatRusPhone(props.userData.phone_mobile)
    : null;
});
</script>
