<template>
  <div>
    <p class="profile__table-title">
      {{ $t(getCommonCaptionByName('residentialAddress')) }}
    </p>
    <div class="profile__table-info">
      <div class="input-profile-contact" v-if="!isAddressEditable" :class="{profile_el_empty: profileFillErrors}">
        <div class="input-profile-contact__value">
          <span v-if="address && address.name">{{
            address.name
          }}</span>
          <span v-else>
            -
          </span>
        </div>
        <div class="input-profile-contact__btn">
          <button
            @click="openAddressEditable"
            class="btn btn--secondary btn--icon"
          >
            <edit class="edit-icon" />
          </button>
        </div>
      </div>
      <select-address
        id="residentialAddress"
        :address="address"
        @set-address="setAddress"
        @failed-setting-address="closeAddressEditable"
        v-else
      />
    </div>
    <el-checkbox
      :label="$t(getCommonCaptionByName('matchesRegistrationAddress'))"
      v-model="checked"
      class="profile__table-title"
    />
  </div>
</template>

<script>
import {getCommonCaptionByName} from '@/i18n/utils';
import Edit from '@/assets/icons/edit.svg?inline';
import SelectAddress from './selectAddress';

export default {
  name: 'elk-profile-info-residential-address',
  components: {
    Edit,
    SelectAddress,
  },

  data() {
    return {
      isAddressEditable: false,
    };
  },

  computed: {
    profileFillErrors() {
      let errors = this.$store.getters['profileFillCheck/profileFillErrors'].map(function(obj) {
        return obj.code;
      });
      if(errors?.includes('residential_address')) return true;
    },
    address() {
      return this.$store.getters['profileData/getResidentialAddress'];
     },
    checked: {
      get() {
        return this.$store.getters['profileData/getIsResidentialSame'];
      },
      set(value) {
        this.$store.dispatch('profileData/updateResidentialIsSame', value);
      },
    },
  },

  methods: {
    getCommonCaptionByName,
    setAddress() {
      this.closeAddressEditable();
    },
    openAddressEditable() {
      this.isAddressEditable = true;
    },
    closeAddressEditable() {
      this.isAddressEditable = false;
    },
  },
};
</script>

<style lang="scss">
</style>
