import localStorageService from '@/utils/localStorageService.js';
import { PUBLIC_TOKEN } from '@/utils/constants';
const { getLocaleId, setLocaleId, getToken } = localStorageService;

const LANG_PARAM = 'lang';
const RECOVERY_PATH = '/password_recovery';

const accessToken = getToken();
const isPublicUser = accessToken === PUBLIC_TOKEN;
const isNotAuthorized = isPublicUser || accessToken === null;

const { origin, pathname, search } = location;

const searchParams = new URLSearchParams(search);

const lang = searchParams.get(LANG_PARAM);
searchParams.delete(LANG_PARAM);

if (isNotAuthorized && lang && pathname === RECOVERY_PATH) {
  const originPath = origin + pathname;
  const searchParamsString = searchParams.toString();
  const newSearchParams = searchParamsString ? '?' + searchParamsString : '';
  const urlWithoutLang = originPath + newSearchParams;

  const currentLocale = getLocaleId();

  if (lang !== currentLocale) {
    setLocaleId(lang);

    location.assign(urlWithoutLang);
  } else {
    location.replace(urlWithoutLang);
  }
}
