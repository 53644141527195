<script>
import CascadeFilterItem from '@/components/filters/CascadeFilterItem';
export default {
  name: 'TypeProgramCascade',
  extends: CascadeFilterItem,
  props: {
    placeholder: {
      type: String,
      default: 'Тип программы'
    },
    param: {
      type: String,
      default: 'program_types'
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$store
        .dispatch('refProgramTypes/fetch')
        .then(() => (this.options = this.$store.state.refProgramTypes.items));
    }
  }
};
</script>

<style scoped></style>

