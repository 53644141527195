<template>
  <div :class="{body: true, active: menuActive}">
    <menu-wrapper :page-name="name" />
    <div class="page-wrapper">
      <main class="content">
        <div class="container">
          <div
            id="elk-grants-page"
            class="page"
          >
            <div class="page__title">
              <h2>{{ name }}</h2>
            </div>
            <component
              :is="loader(widget.signature)"
              v-for="widget in widgets"
              :key="widget.signature"
              :widget-data="widget"
            />
          </div>
        </div>
      </main>
    </div>
    <page-footer />
  </div>
</template>

<script setup>
import loader from '@/utils/widgetLoader';
import menuWrapper from '@/components/menuWrapper';
import {computed} from 'vue';
import store from '@/store';
import PageFooter from '@/components/PageFooter.vue';

defineProps({
  name: String,
  widgets: Array
});

const menuActive = computed(() => store.state.menuActive);
</script>

<script>
export default {
  name: 'ElkGrants'
};
</script>
