<template>
  <div class="agreement-element">
    <h3 class="h4">
      {{ title }}
    </h3>
    <div class="agreement-element__wrapper">
      <el-alert
        v-if="errorText"
        class="alert alert--danger"
      >
        {{ errorText }}
      </el-alert>
      <alert-attention :text="text" />
      <div class="agreement-element__file">
        <a
          :class="{'btn__link-download': true, disabled: isLoad}"
          href="javascript:void(0);"
          @click.stop="onClick"
        >
          <loading v-if="isLoad" />
          <span v-else>{{ template }} </span>
        </a>
        <input-file
          :id="id"
          text-align="center"
          :label="file"
          :disabled="disabled"
          :accept-extensions="arrImageExtensions"
          :value="attachment"
          @on-change="onChangeAttachment"
        />
      </div>
    </div>
  </div>
</template>

<script>
import alertAttention from '@/components/newAlerts/alertAttention';
import InputFile from '@/components/inputComponent/InputFile.vue';
import Loading from '@/components/common/elkLoading';
import { MAX_FILE_SIZE_TEXT} from '@/api';

export default {
  name: 'AgreementElement',

  components: {alertAttention, InputFile, Loading},
  model: {
    prop: 'attachment',
    event: 'change'
  },
  props: {
    attachment: {type: Object, default: () => ({$model: []})},
    disabled: {type: Boolean, default: false},
    file: {type: String, default: 'Прикрепить файл'},
    href: {type: String, default: '#'},
    id: {type: String, required: true},
    info: {type: String, default: 'документа'},
    isLoad: {type: Boolean, default: false},
    template: {type: String, default: 'Скачать шаблон'},
    title: {type: String, default: ''},
    errorText: {type: String, default: ''}
  },

  data() {
    return {
      value: {$model: []},
      arrImageExtensions: ['jpg', 'jpeg', 'pdf', 'png']
    };
  },

  computed: {
    text() {
      return `Внимание! Для заполнения информации ниже, вам необходимо скачать шаблон ${this.info},
      заполнить, отсканировать и прикрепить в одном из форматов: pdf, png, jpeg. После обработки вам будет доступен полноценный личный кабинет. Максимальный размер файла ` + MAX_FILE_SIZE_TEXT;
    }
  },

  methods: {
    onClick() {
      this.$emit('on-get-document', this.template);
    },
    onChangeAttachment(files) {
      this.$emit('change', files);
    }
  }
};
</script>

<style lang="scss">
.agreement-element {
  margin-bottom: 48px;
  &__wrapper {
    background-color: #fff;
    padding: 24px 32px;
    box-shadow: 0 1px 2px rgba(106, 122, 152, 0.15);
    border-radius: 4px;
    margin-top: 20px;
  }
  .agreement-element__file {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    .btn__link-download {
      background-color: $blue-color;
      font-size: 14px;
      line-height: 20px;
      color: #fff;
      border-radius: 4px;
      padding: 14px 32px;
      text-align: center;
      width: 50%;
      min-height: 48px;
      word-break: break-word;
    }
    .btn__link-download.disabled {
      pointer-events: none;
      background-color: $secondary-slateblue-0-1;
      border-color: $primary-morn-0-4;
      color: $primary-dusk-0-4;
      fill: $primary-dusk-0-4;
      cursor: not-allowed;
    }
    .btn__link-download .loader {
      padding: 0;
      height: 16px;
      width: 24px;
    }
    .inputFile-wrapper {
      width: 50%;
    }
  }
}

@media (max-width: 530px) {
  .agreement-element__wrapper {
    padding: 24px 16px;
  }
  .agreement-element {
    .agreement-element__file {
      flex-direction: column;
      .btn__link-download,
      .inputFile-wrapper {
        width: 100%;
      }
    }
  }
}
</style>
