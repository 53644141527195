<template>
  <button
    class="btn-modal__status"
    :style="cssVars"
  >
    {{ status.label }}
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 0C4.81331 0 3.65328 0.351894 2.66658 1.01118C1.67989 1.67047 0.910851 2.60754 0.456726 3.7039C0.00259972 4.80026 -0.11622 6.00666 0.115291 7.17054C0.346802 8.33443 0.918247 9.40353 1.75736 10.2426C2.59648 11.0818 3.66558 11.6532 4.82946 11.8847C5.99335 12.1162 7.19975 11.9974 8.2961 11.5433C9.39246 11.0892 10.3295 10.3201 10.9888 9.33342C11.6481 8.34673 12 7.18669 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0ZM7 10H5V6H4V5H7V10ZM6 4C5.80222 4 5.60888 3.94135 5.44443 3.83147C5.27998 3.72159 5.15181 3.56541 5.07612 3.38268C5.00044 3.19996 4.98063 2.99889 5.01922 2.80491C5.0578 2.61093 5.15304 2.43275 5.2929 2.29289C5.43275 2.15304 5.61093 2.0578 5.80491 2.01921C5.99889 1.98063 6.19996 2.00043 6.38269 2.07612C6.56541 2.15181 6.72159 2.27998 6.83147 2.44443C6.94135 2.60888 7 2.80222 7 3C7 3.26522 6.89465 3.51957 6.70711 3.70711C6.51957 3.89464 6.26522 4 6 4Z"
        :fill="status.iconColor"
      />
    </svg>
  </button>
</template>

<script>

export default {
  name: "ProgramBadgeStatus",

  props: {
    status: {
      type: Object,
      required: true,
    },
  },

  computed: {
    cssVars() {
      return {
        '--badge-status-bg': `${this.status.iconColor}15`,
        '--badge-status-text-color': this.status.iconColor,
      }
    },
  },
}
</script>

<style lang="scss">
.btn-modal__status{
  cursor: pointer;
  box-sizing: border-box;
  border: unset;
  border-radius: 4px;
  padding: 6px 10px;
  margin-bottom: 8px;
  position: relative;
  background-color: var(--badge-status-bg);
  color: var(--badge-status-text-color);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 8px;
}
</style>
