import { SET_PROFILE_BIRTHDATE, SET_BIRTHDATE_DATE_SHOW_TYPE } from '@/api';
import Axios from '@/utils/Elk-Axios';
import setNotification from "@/utils/setNotification";

export async function apiSetProfileBirthdate(birthdate) {
  return await Axios.post(SET_PROFILE_BIRTHDATE, {
    birth_date: birthdate,
  });
}

export async function apiSetBirthDateShowType(type) {
  setNotification({
    message: `Данные сохранены`,
    type: 'success',
  });
  return await Axios.post(SET_BIRTHDATE_DATE_SHOW_TYPE, {
    birth_date_show_type_id: type,
  });
}
