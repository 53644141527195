function getFilteredPageTreeByBlockWidgetSignature(
  pageTree,
  blockWidgetSignature
) {
  const BLOCKING_WIDGET_UI_ITEM_TYPE = 'login-block';

  const filteredPageTree = pageTree.find((page) => {
    if (page.widgets) {
      return Boolean(
        page.widgets.find((widget) => widget.signature === blockWidgetSignature)
      );
    }
    return false;
  });

  if (!filteredPageTree) {
    return pageTree;
  }

  return [
    {
      ...filteredPageTree,
      widgets: filteredPageTree.widgets.filter(
        (widget) => widget.ui_item_type === BLOCKING_WIDGET_UI_ITEM_TYPE
      ),
    },
  ];
}

function getCodeByName(name, obj) {
  let res = [];
  if (name && obj) {
    name.forEach(el => {
      obj.forEach(item => {
        if(item.name.toLowerCase().includes(el.toLowerCase())) {
          res.push(item.code);
        }
      })
    })
  }
  let uniq = res.filter((value, index, array) => array.indexOf(value) === index);
  return uniq.join(',');
}

function get_codes(str, arr) {
  if(arr && str) {
    str = str.toLowerCase();
    return arr.filter(el => el.name.toLowerCase().indexOf(str) !== -1).map(el => el.code).join(',');
  }
  return false;
};

export { getFilteredPageTreeByBlockWidgetSignature, getCodeByName, get_codes };
