<template>
  <div class="list-wrapper-document doc__ed-mod">
    <div class="title-number__list">
      <div class="data-number">{{ localeText.year }}</div>
      <div class="name-list">{{ localeText.myDocsProgram }}</div>
    </div>
    <div class="container-list">
      <!-- classes elect_id elect_certificate elect_diploma paper_id paper_diploma elect_certificate -->
      <div
        v-for="item of items"
        :key="item.id"
        class="item-list__document document_ed elect_id"
      >
        <div class="item-list__number">
          <p>{{ item.year }}</p>
        </div>
        <div class="item-list__content">
          <div class="item-list__content-type">
            {{ item.type }}
            <!--            <a
              v-if="item.files[0].length && item.files[0]."
              class="item-list__content-tag"
              :href="item.file_url_sign"
              :download="getNameByPath(item.file_url_sign)"
              target="_blank"
            >
              Подписан ЭЦП
            </a>-->
          </div>
          <div class="item-list__content-name">
            {{ item.full_name }}
          </div>
        </div>
        <div class="item-list__buttons">
          <button
            class="button"
            @click="onShowModalInfo(item.originalId)"
          />
          <el-tooltip
            :content="localeText.requestChanges"
            placement="bottom"
            effect="light"
            popper-class="el-tooltip__custom"
          >
            <button
              class="button button_change-request"
              @click="onChangeRequest(item.originalId)"
            />
          </el-tooltip>
          <!--          <a :href="item.file_url" class="button" target="_blank" />-->
          <!--          <a
            href="javascript:void(0)"
            class="link-btn-share"
            @click="copyToClipboard(item.file_url)"
          >
            Поделиться
          </a>
          <a
            v-if="item.file_url"
            :href="item.file_url"
            class="link-btn-download"
            :download="item.file_url"
            target="_blank"
          />-->
          <button
            class="button link-btn-download"
            @click="onShowModalFiles(item.originalId)"
          />
        </div>
      </div>
    </div>
    <el-dialog
      :title="localeText.detailInformation"
      :visible.sync="showModalInfo"
      class="list-wrapper-document__dialog"
      top="6vh"
    >
      <DetailedInformation
        :items="detailInfoItems"
        @changeRequest="onChangeRequest"
      />
    </el-dialog>
    <el-dialog
      :title="localeText.requestChanges"
      :visible.sync="showModalChangeRequest"
      class="list-wrapper-document__dialog"
      top="6vh"
    >
      <ChangeRequestForm
        v-if="selectedDocItem"
        :reset-form="showModalChangeRequest"
        :document-id="selectedDocItem.originalId"
        :program-id="selectedDocItem.program_id"
        :localization="localization"
        @formSuccess="onFormSuccess"
      />
    </el-dialog>
    <el-dialog
      :title="localeText.notifyRequestSent + '!'"
      :visible.sync="showModalSuccess"
      class="list-wrapper-document__dialog"
    >
      <p>
        {{ localeText.infoAppeal }}
      </p>
    </el-dialog>
    <el-dialog
      :title="localeText.completionDocuments"
      :visible.sync="showModalFiles"
      class="list-wrapper-document__dialog"
      top="6vh"
    >
      <ul>
        <div
          v-for="file of selectedDocItem?.files"
          :key="`file_${file.id}_${selectedDocItem.originalId}`"
          style="
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
            grid-gap: 4px;
          "
        >
          <!--          <a
            class="eye_button"
            v-if="file.signed_file?.url"
            target="_blank"
            :href="file.signed_file?.url"
          />-->
          <a
            v-if="file.unsigned_file?.url"
            class="hse-modal__template-link"
            style="width: 100%"
            :href="file.unsigned_file.url"
            target="_blank"
            :download="file.unsigned_file.name"
          >
            <span class="hse-modal__template-link-icon-clip" />
            <span class="word_break">{{
              file.is_document
                ? localeText.labelDocFile
                : localeText.labelAppFile
            }}</span>
            <span class="hse-modal__template-link-icon-import" />
          </a>

          <a
            v-if="file.signature_file?.url"
            class="hse-modal__template-link"
            style="width: 100%"
            :href="file.signature_file.url"
            target="_blank"
            :download="file.signature_file.name"
          >
            <span class="hse-modal__template-link-icon-clip" />
            <span class="word_break">{{
              file.is_document
                ? localeText.labelDocSignature
                : localeText.labelAppSignature
            }}</span>
            <span class="hse-modal__template-link-icon-import" />
          </a>

          <a
            v-if="file.signed_file?.url"
            class="hse-modal__template-link"
            style="width: 100%"
            :href="file.signed_file.url"
            target="_blank"
            :download="file.signed_file.name"
          >
            <span class="hse-modal__template-link-icon-clip" />
            <span class="word_break">
              {{ localeText.localDocECP }}
<!--              {{-->
<!--              file.is_document-->
<!--                ? localeText.labelDocSignature-->
<!--                : localeText.labelAppSignature-->
<!--            }}-->
            </span>
            <span class="hse-modal__template-link-icon-import" />
          </a>
          <!--          <span>{{ file.is_document ? 'Документ' : 'Приложение' }}</span>
          <a
            v-if="file.signed_file?.url && file.signature_file?.url"
            class="link-btn-download"
            :download="file.signed_file.name"
            :href="file.signed_file.url"
            target="_blank"
          >
            Скачать подписанный ЭЦП
          </a>
          <a
            v-else
            class="link-btn-download"
            :download="file.unsigned_file.name"
            :href="file.unsigned_file.url"
            target="_blank"
          >
            Скачать
          </a>-->
        </div>
      </ul>
    </el-dialog>
  </div>
</template>

<script>
import setNotification from '@/utils/setNotification';
import DetailedInformation from '@/components/listDocuments/DocumentsPrograms/common/DetailedInformation';
import ChangeRequestForm from '@/components/listDocuments/DocumentsPrograms/common/ChangeRequestForm';

export default {
  name: 'DocListEducComponent',
  components: {
    DetailedInformation,
    ChangeRequestForm
  },
  props: {
    items: {type: Array, default: () => []},
    localization: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      showModalInfo: false,
      showModalChangeRequest: false,
      showModalSuccess: false,
      showModalFiles: false,
      docId: -1,
      localeText: {
        year: this.localization.commonCaption('filterYear'),
        detailInformation: this.localization.commonCaption('detailInformation'),
        myDocsProgram: this.localization.commonCaption('myDocsProgram'),
        myDocsName: this.localization.widgetCaption('myDocsName'),
        requestChanges: this.localization.widgetCaption('requestChanges'),
        notifyRequestSent: this.localization.widgetCaption('notifyRequestSent'),
        infoAppeal: this.localization.widgetCaption('infoAppeal'),
        myDocsDocument: this.localization.widgetCaption('myDocsDocument'),
        localDocECP: this.localization.widgetCaption('localDocECP'),
        completionDocuments: this.localization.widgetCaption(
          'completionDocuments'
        ),
        shareNotify: this.localization.widgetCaption('shareNotify'),
        labelDocSignature: this.localization.widgetCaption('labelDocSignature'),
        labelAppSignature: this.localization.widgetCaption('labelAppSignature'),
        labelDocFile: this.localization.widgetCaption('labelDocFile'),
        labelAppFile: this.localization.widgetCaption('labelAppFile'),
        docType: this.localization.widgetCaption('docType'),
        yearDocumentReceived: this.localization.widgetCaption(
          'yearDocumentReceived'
        )
      }
    };
  },
  computed: {
    selectedDocItem() {
      return this.items.find(e => e.originalId === this.docId);
    },
    detailInfoItems() {
      const result = [];
      if (this.selectedDocItem) {
        if (this.selectedDocItem.year) {
          result.push({
            name: this.localeText.yearDocumentReceived,
            value: this.selectedDocItem.year
          });
        }
        if (this.selectedDocItem.doc_number) {
          result.push({
            name: this.localeText.myDocsDocument + '№',
            value: this.selectedDocItem.doc_number
          });
        }
        if (this.selectedDocItem.type) {
          result.push({
            name: this.localeText.docType,
            value: this.selectedDocItem.type
          });
        }
        if (this.selectedDocItem.program_name) {
          result.push({
            name: this.localeText.myDocsProgram,
            value: this.selectedDocItem.program_name
          });
        }
        if (this.selectedDocItem.full_name) {
          result.push({
            name: this.localeText.myDocsName,
            value: this.selectedDocItem.full_name
          });
        }
      }
      return result;
    }
  },
  methods: {
    onChangeRequest(id) {
      this.docId = id;
      this.showModalInfo = false;
      this.showModalChangeRequest = true;
    },
    onShowModalInfo(id) {
      this.docId = id;
      this.showModalInfo = true;
    },
    onFormSuccess() {
      this.showModalChangeRequest = false;
      this.showModalSuccess = true;
      this.docId = -1;
    },
    onShowModalFiles(id) {
      this.docId = id;
      this.showModalFiles = true;
    },
    getNameByPath(path) {
      if (!path) {
        return 'document';
      }
      return path.split('/').at(-1) ?? 'document';
    },
    copyToClipboard(url) {
      navigator.clipboard.writeText(url);
      setNotification({
        message: this.localeText.shareNotify,
        type: 'success'
      });
    }
  }
};
</script>

<style lang="scss">
.word_break {
  word-break: normal;
}
.eye_button {
  width: 16%;
  background-color: #f0f5ff !important;
  cursor: pointer;
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 9px);
    width: 20px;
    height: 19px;
    background: url('@/assets/icons/icon-eye-open.svg') 100% 100% no-repeat;
  }
}

.doc__ed-mod .container-list {
  padding: 0;
}

.item-list__document.document_ed {
  display: flex;
  padding: 24px 20px;
  border-bottom: 1px solid #edeff3;
  align-items: stretch;

  &:last-child {
    border-bottom: 0px solid #edeff3;
  }

  .button_change-request {
    margin-left: 4px;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: calc(50% - 12px);
      left: calc(50% - 10px);
      width: 24px;
      height: 24px;
      background: url('../../assets/icons/icon-edit.svg') 100% 100% no-repeat;
    }
  }

  .link-btn-download {
    margin-left: 4px;
  }

  .item-list__number {
    font-family: 'HSE sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: #0f0f14;
    width: 55px;
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }
  .item-list__content {
    min-height: 62px;
    border-left: 1px solid #edeff3;
    padding-left: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;

    .item-list__content-type {
      font-family: 'HSE sans';
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #6650ec;
    }

    .item-list__content-name {
      margin-top: 4px;
      font-family: 'HSE sans';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #0f0f14;
    }

    &-tag {
      display: inline-block;
      border: 1px solid $color-gray-dusk-5;
      border-radius: 4px;
      padding: 6px 8px 6px 8px;
      margin-left: 8px;

      font-weight: 600;
      font-size: 12px;
      line-height: 12px;
      color: $color-gray-dusk;
    }
  }
  .item-list__buttons {
    position: static;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    .button {
      margin-right: 0px;
    }
  }
  p {
    margin-bottom: 0;
  }

  .link-btn-share {
    background-color: #f8f9fb;
    color: $blue-color;
    border-radius: 4px;
    padding: 10px 35px;
    height: 44px;
    margin-left: 4px;
    transition: all 0.3s ease-in-out;
    &:hover {
      background-color: $blue-color;
      color: #fff;
    }
  }
}
.item-list__document.document_ed.elect_certificate {
  .item-list__content {
    span {
      color: #e9a21d;
    }
  }
}
.item-list__document.document_ed.elect_diploma {
  .item-list__content {
    span {
      color: #00a35f;
    }
  }
}
.item-list__document.document_ed.paper_id {
  .item-list__content {
    span {
      color: #e9a21d;
    }
  }
}
.item-list__document.document_ed.paper_diploma {
  .item-list__content {
    span {
      color: #00a35f;
    }
  }
}

.list-wrapper-document__dialog .el-dialog {
  width: 620px;
  .el-dialog__title {
    font-size: 24px;
    font-weight: 600;
  }
}
@media (max-width: 640px) {
  .list-wrapper-document__dialog .el-dialog {
    width: 100%;
  }
}

@media (max-width: 920px) {
  .list-wrapper-document.doc__ed-mod {
    .container-list {
      background-color: transparent;
      box-shadow: unset;
      border-radius: 0;
      padding: 0;
      .item-list__document.document_ed {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        background-color: #ffffff;
        box-shadow: 0 1px 2px rgb(106 122 152 / 15%);
        border-radius: 4px;
        padding: 16px;
        margin-bottom: 8px;
        .item-list__buttons {
          position: static;
        }
        .item-list__number {
          width: 100%;
          padding: 0 0 16px 0;
          border-right: unset;
          border-bottom: 1px solid #edeff3;
        }
        .item-list__content {
          padding: 0;
          margin: 16px 0;
        }
      }
    }
  }
}
</style>

<style lang="scss">
//.list-wrapper-document__dialog .el-dialog {
//  width: 520px;
//  .el-dialog__title {
//    font-size: 24px;
//    font-weight: 600;
//  }
//}
//@media (max-width: 640px) {
//  .list-wrapper-document__dialog .el-dialog {
//    width: 100%;
//  }
//}
</style>
