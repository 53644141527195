<template>
  <a
    name="Оплатить"
    href="javascript:void(0);"
    class="btn__programs btn-prog__pay"
  >
    <span>Оплатить</span>
  </a>
</template>

<script>
export default {
  name: "LinkPay",
}
</script>

<style lang="scss">
.btn__programs.btn-prog__pay{
  width: 220px;
  height: 44px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border-radius: 4px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 0;
  position: relative;
  transition: all .3s ease-in-out;
  background-color: #EB6D00;
  &:before{
    content: '';
    width: 18px;
    height: 13px;
    margin-right: 11px;
    background: url("@/assets/icons/icon-status-card.svg")100% 100% no-repeat;
  }
  &:hover{
    background-color: rgb(235 109 0 / 85%);
  }
}
</style>
