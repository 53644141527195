<template>
  <footer class="footer">
    <div class="container pt-20">
      <div class="md:flex md:space-x-6 space-y-5 md:space-y-0">
        <div class="md:w-1/3 -mt-20">
          <DpoLogo class="mb-4" />
          <FooterMenu
            v-if="DpoCol1"
            :footer-column="DpoCol1"
          />
        </div>
        <div class="md:w-1/3 relative">
          <h2 class="mb-4 md:absolute md:-top-14 left-0 text-white text-2xl">
            Каталог программ
          </h2>
          <FooterMenu
            v-if="DpoCol2"
            :footer-column="DpoCol2"
          />
        </div>
        <div class="md:w-1/3">
          <FooterMenu
            v-if="DpoCol3"
            :footer-column="DpoCol3"
          />
        </div>
      </div>
    </div>
  </footer>
</template>

<script setup>
import DpoLogo from '@/assets/icons/dpo-logo.svg?inline';
import FooterMenu from '@/components/Footer/FooterMenu.vue';
import {getUiItemCaption} from '@/i18n/utils';
import {onMounted, ref} from 'vue';
import {useI18n} from 'vue-i18n-composable';

const {t} = useI18n();

const DpoCol1 = ref(null);
const DpoCol2 = ref(null);
const DpoCol3 = ref(null);

onMounted(() => {
  setDPOFooter();
});

function setDPOFooter() {
  DpoCol1.value = JSON.parse(
    t(getUiItemCaption('elk.footer.dpo.widget', 'FooterDpo1'))
  );
  DpoCol2.value = JSON.parse(
    t(getUiItemCaption('elk.footer.dpo.widget', 'FooterDpo2'))
  );
  DpoCol3.value = JSON.parse(
    t(getUiItemCaption('elk.footer.dpo.widget', 'FooterDpo3'))
  );
}
</script>
