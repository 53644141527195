<template>
  <vue-recaptcha
    ref="recaptcha"
    :language="getLocaleName"
    :loadRecaptchaScript="true"
    :sitekey="recaptchaKey"
    size="invisible"
    @error="onError"
    @expired="onCaptchaExpired"
    @verify="verify"
  />
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';
import setNotification from '@/utils/setNotification';
import {getCommonCaptionByName} from '@/i18n/utils';
import {mapGetters} from 'vuex';

export default {
  name: 'elk-recaptcha',
  components: {
    VueRecaptcha
  },
  data: function () {
    return {
      recaptchaKey: process.env.VUE_APP_RECAPTCHA_TOKEN
    };
  },
  computed: {
    ...mapGetters('locale', ['getLocaleName'])
  },
  methods: {
    getCommonCaptionByName,
    onError() {
      this.$emit('error');
    },
    execute() {
      try {
        this.$refs.recaptcha.execute();
      } catch (e) {
        setNotification({
          message: this.$t(
            this.getCommonCaptionByName('recaptcha_verificationRequestsError')
          )
        });
      }
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
    verify(token) {
      this.onCaptchaExpired();
      this.$emit('verify', token);
    }
  }
};
</script>
