<template>
  <error-page-public v-if="isPublicUser" />
  <div
    v-else
    :class="{body: true, active: menuActive}"
  >
    <menu-wrapper :page-name="name" />
    <div class="page-wrapper">
      <main
        class="content"
        :class="pageClass"
      >
        <div class="container">
          <loading v-if="isLoadingFormData" />
          <FormServiceContainer
            v-else-if="!isSuccess && serviceData"
            :form-data="serviceData"
            :id-service="idService"
            :localization="localization"
            class="reference-page__form"
            @success="onSuccess"
          />
          <ElkEmptyDataMessage
            v-else-if="!serviceData"
            :text="localeText.sorryNoContent"
          />
          <ServiceSuccess
            v-else-if="isSuccess"
            :localization="localization"
          />
        </div>
      </main>
    </div>
    <page-footer />
  </div>
</template>

<script>
import Axios from '@/utils/Elk-Axios';
import menuWrapper from '@/components/menuWrapper/index.vue';
import PageFooter from '@/components/PageFooter.vue';
import Loading from '@/components/common/elkLoading';
import {mapState, mapGetters} from 'vuex';
import {GET_SERVICE_FORM} from '@/api';
import ErrorPagePublic from '@/views/404/index.vue';
import FormServiceContainer from '@/components/formService/FormServiceContainer';
import ServiceSuccess from '@/components/formService/ServiceSuccess';
import ElkEmptyDataMessage from '@/components/common/elkEmptyDataMessage';
import setNotification from '@/utils/setNotification';
import localization from '@/i18n/localization';

export default {
  name: 'ReferencePage',
  components: {
    ErrorPagePublic,
    FormServiceContainer,
    ServiceSuccess,
    menuWrapper,
    PageFooter,
    ElkEmptyDataMessage,
    Loading
  },
  data() {
    return {
      name: 'Сервис заявка',
      pageClass: 'reference-page',
      idService: this.$route.params['id'] ?? '',
      isLoadingFormData: true,
      serviceData: null,
      isSuccess: false,
      localization: localization('dpo.get_help')
    };
  },
  computed: {
    ...mapState(['menuActive']),
    ...mapGetters(['isPublicUser', 'isAuth', 'isLoadUser']),
    isLoadingServices() {
      return this.$store.state.services.isLoading || this.isLoadingFormData;
    },
    localeText() {
      return {
        sorryNoContent: this.localization.commonCaption('sorryNoContent')
      };
    },
    isCorp() {
      return this.$route?.name.includes('corp');
    }
  },
  mounted() {
    this.$store.dispatch('services/init', {type: this.isCorp ? 'corp' : ''});
    this.refreshData();
  },
  methods: {
    getPayload() {
      let query = '';
      if (this.idService) {
        query += `?service=${this.idService}`;
      }
      if (this.isCorp) {
        query += query ? '&corp=true' : '?corp=true';
      }
      if (this.$route.query['contract_id']) {
        const temp = `contract_id=${this.$route.query['contract_id']}`;
        query += query ? `&${temp}` : `?${temp}`;
      }
      return query;
    },
    async fetchFormData() {
      return (await Axios.get(`${GET_SERVICE_FORM}${this.getPayload()}`))?.data
        ?.data;
    },
    async refreshData() {
      this.isLoadingFormData = true;
      try {
        this.serviceData = await this.fetchFormData();
      } catch (e) {
        if (e.response.data?.message) {
          setNotification({message: e.response.data.message});
        }
        // Не удалось получть  данные
      } finally {
        this.isLoadingFormData = false;
        // if (!this.application && !this.applications?.length) {
        //   this.showNoApplicationsDialog();
        // }
      }
    },
    onSuccess() {
      this.isSuccess = true;
    },
    showNoApplicationsDialog() {
      this.$confirm('У вас нет заявок', {
        confirmButtonText: 'На главную страницу',
        type: 'warning',
        showClose: false,
        showCancelButton: false,
        closeOnPressEscape: false,
        closeOnClickModal: false,
        center: true
      })
        .then(() => this.$router.push('/'))
        .catch(() => {});
    }
  }
};
</script>

<style lang="scss">
.reference-page__form {
  margin: 0 auto;
}
</style>
