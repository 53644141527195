const faqMaxStrLetters = 100;

const shortText = (str, faqMaxStrLetters) => {
  if (str.length > faqMaxStrLetters) {
    const shortedText = str.slice(0, faqMaxStrLetters);
    const lastLetter = shortedText[faqMaxStrLetters - 1];
    if (lastLetter === '.') return shortedText + '..';
    return shortedText + '...';
  }
  return str;
};

export { shortText, faqMaxStrLetters };
