/**
 * @param phone {string} 79163118521 | +79163118521 | +7 (916) 311-8521 | +7 (916) 311-85-21
 * @returns отформатированный номер +7 (916) 311-85-21
 */
export default (phone) => {
  const pattern =
    /(\+?7|8)[\s(]?(\d{3})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g;

  const formatedNumber = phone.replace(pattern, '+7 ($2) $3-$4-$5');

  return formatedNumber;
};
