<template>
    <div class="search-form" v-if="noForm">
        <input 
        type="search" 
        class="vi-input vi-input--search" 
        :placeholder="fieldPlaceholder"  
        :value="value"
        @input="$emit('input', $event.target.value)"
        ref="searchInput"
        >
        <button class="search-form__reset-btn" type="button" v-if="value" @click.prevent="clearField($event)">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#6B7A99"/>
            </svg> 
        </button>
        <button type="submit" class="search-form__loupe-btn" >
            <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.84 11.8444C13.5973 10.707 14.0009 9.37086 14 8.0044C14.0021 6.44648 13.4844 4.93238 12.5289 3.70188C11.5733 2.47138 10.2347 1.59479 8.72476 1.21093C7.21487 0.827069 5.62007 0.957871 4.1929 1.58262C2.76573 2.20738 1.58776 3.29037 0.845517 4.66012C0.103278 6.02987 -0.160809 7.60808 0.0950754 9.14485C0.35096 10.6816 1.11219 12.0891 2.25823 13.1444C3.40426 14.1998 4.8696 14.8426 6.42221 14.9712C7.97482 15.0998 9.52596 14.7068 10.83 13.8544L14 17.0044L16 15.0044L12.84 11.8444ZM2 8.0044C2 7.01549 2.29325 6.04879 2.84266 5.22655C3.39206 4.4043 4.17296 3.76344 5.08659 3.385C6.00022 3.00656 7.00555 2.90755 7.97546 3.10047C8.94536 3.2934 9.83628 3.7696 10.5355 4.46887C11.2348 5.16813 11.711 6.05904 11.9039 7.02895C12.0969 7.99885 11.9978 9.00419 11.6194 9.91782C11.241 10.8314 10.6001 11.6123 9.77785 12.1617C8.95561 12.7112 7.98891 13.0044 7 13.0044C5.67392 13.0044 4.40215 12.4776 3.46447 11.5399C2.52679 10.6023 2 9.33048 2 8.0044Z" fill="#6B7A99"/>
            </svg> 
        </button> 
    </div>
    <form  class="search-form" v-else>
        <input 
        type="search" 
        class="vi-input vi-input--search" 
        :placeholder="fieldPlaceholder"  
        :value="value"
        @input="$emit('input', $event.target.value)"
        ref="searchInput"
        >
        <button class="search-form__reset-btn" type="button" v-if="value" @click.prevent="clearField($event)">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#6B7A99"/>
            </svg> 
        </button>
        <button type="submit" class="search-form__loupe-btn" >
            <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.84 11.8444C13.5973 10.707 14.0009 9.37086 14 8.0044C14.0021 6.44648 13.4844 4.93238 12.5289 3.70188C11.5733 2.47138 10.2347 1.59479 8.72476 1.21093C7.21487 0.827069 5.62007 0.957871 4.1929 1.58262C2.76573 2.20738 1.58776 3.29037 0.845517 4.66012C0.103278 6.02987 -0.160809 7.60808 0.0950754 9.14485C0.35096 10.6816 1.11219 12.0891 2.25823 13.1444C3.40426 14.1998 4.8696 14.8426 6.42221 14.9712C7.97482 15.0998 9.52596 14.7068 10.83 13.8544L14 17.0044L16 15.0044L12.84 11.8444ZM2 8.0044C2 7.01549 2.29325 6.04879 2.84266 5.22655C3.39206 4.4043 4.17296 3.76344 5.08659 3.385C6.00022 3.00656 7.00555 2.90755 7.97546 3.10047C8.94536 3.2934 9.83628 3.7696 10.5355 4.46887C11.2348 5.16813 11.711 6.05904 11.9039 7.02895C12.0969 7.99885 11.9978 9.00419 11.6194 9.91782C11.241 10.8314 10.6001 11.6123 9.77785 12.1617C8.95561 12.7112 7.98891 13.0044 7 13.0044C5.67392 13.0044 4.40215 12.4776 3.46447 11.5399C2.52679 10.6023 2 9.33048 2 8.0044Z" fill="#6B7A99"/>
            </svg> 
        </button> 
    </form>

</template>
 
<script>

/**
 * входной параментр noForm нужен для того, что бы определить, форма будет выводиться или компонент формы
*/

export default {
    props: {
        fieldPlaceholder: String,
        value: String,
        noForm: {
            type: Boolean, 
            default: false
        }
    },
    name: 'SearchForm',
    valueFromSearch: '',
    data(){
        return{
            
        }
    },
    methods:{ 
        clearField(e){
            this.$refs.searchInput.value = '';
            this.$emit('clearField');
        }
    },
    
}
</script>

<style lang="scss">
    .search-form{
        position: relative;
        .vi-input{
            padding-right: 40px; 
        }
        button{
            border: none;
            outline: none;
            background-color: transparent;
            margin: 0;
            padding: 0;
            cursor: pointer;
            transition: all 200ms ease;
            &:hover{
                svg{
                    width: 100%;
                    height: auot;
                    path{
                        fill: #0050CF;
                    }
                }
            }
        }
        .search-form__loupe-btn{
            position: absolute;
            top: 50%;
            right: 16px;
            width: 16px;
            height: 16px;
            transform: translateY(-50%);
            opacity: 1;
            visibility: visible;
            svg{
                position: relative;
                top: -1px;
            }
            &.hide{
                opacity: 0;
                visibility: hidden;
            }
            
        }
        .search-form__reset-btn{ 
            position: absolute;
            top: 50%;
            right: 36px;
            width: 14px;
            height: 14px;
            transform: translateY(-50%);
            opacity: 1;
            visibility: visible;
            &.hide{
                opacity: 0;
                visibility: hidden;
            }
        }
    }
</style>