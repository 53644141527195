import Axios from '@/utils/Elk-Axios';
import {REF_PROGRAM_TYPES} from '@/api';
/*
import {getCodeByName} from '@/store/utils';
*/

export default {
  namespaced: true,
  state: {
    /** @type {Reference[]} */
    items: []
  },
  getters: {
    /** @returns {Reference[]} */
    items: state => () => state.items
  },
  mutations: {
    set(state, items) {
      state.items = items ?? [];
    }
  },
  actions: {
    async fetch({commit}) {
      const response = await Axios.get(REF_PROGRAM_TYPES);
      if (response?.data?.data) {
        /*let tmp = [
          {
            name: 'MBA, EMBA',
            code: getCodeByName(
              ['Master of Business Administration'],
              response.data.data
            )
          },
          {
            name: 'DBA, Doctor of',
            code: getCodeByName(['Doctor of'], response.data.data)
          },
          {
            name: 'Master in, Specialized Master, Executive Master in, MPA, EMPA',
            code: getCodeByName(
              ['Master in', 'Specialized Master'],
              response.data.data
            )
          },
          {
            name: 'Профессиональная переподготовка',
            code: getCodeByName(['профессиональная', 'пп'], response.data.data)
          },
          {
            name: 'Повышение квалификации',
            code: getCodeByName(['повышение', 'пк'], response.data.data)
          },
          {
            name: 'Дополнительное образование',
            code: getCodeByName(['дополнительное'], response.data.data)
          }
        ];*/
        commit('set', response.data.data);
      } else {
        commit('set', []);
      }
    }
  }
};
