<template>
  <span>
    <span>
      {{ label }}
    </span>
    <span class="form__label--required" v-if="isRequired">* </span>
    <el-popover v-if="hint" placement="top-start" trigger="hover">
      <i slot="reference" class="el-icon-info" />
      <AsavMarkdownText :text="hint" />
    </el-popover>
  </span>
</template>

<script>
import AsavMarkdownText from '@/components/common/AsavMarkdownText';

export default {
  name: 'asav-label-with-hint',

  components: {
    AsavMarkdownText,
  },

  props: ['label', 'hint', 'isRequired'],
};
</script>
