import Axios from '@/utils/Elk-Axios';
import { GET_PROFILE_WORKPLACE } from '@/api';
import getDadata from "@/components/widgets/elkOlympiads/getDadata";

export const getInternalAndWorkingPhone = async () => {
  const { data: externalApiResponse } = await Axios.get(GET_PROFILE_WORKPLACE);

  return externalApiResponse;
};

export async function getSuggestions(queryString, cb) {
  try {
    const data = await getDadata('urlAddress', queryString);

    cb(data.suggestions ?? []);
  } catch (err) {
    cb([]);
    if (!err.isHandled) {
      setNotification({
        message: `Не удалось получить адреса. Попробуйте позже или обратитесь в службу поддержки`,
      });
    }
  }
};
