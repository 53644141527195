<template>
  <div
    class="idashboard-panel"
    :class="{'idashboard-panel--contains-header': iDashboardPanelCaption}"
  >
    <div
      v-if="iDashboardPanelCaption"
      class="idashboard-panel__header"
      :class="{
        'idashboard-panel__header--open': !isMobile
      }"
    >
      <h3
        class="idashboard-panel-caption"
        @click="toggleSlideContainer($event)"
      >
        {{ iDashboardPanelCaption }}
      </h3>
      <button
        v-if="textBtn"
        class="idashboard-panel-spoiler-btn-text"
        @click="toggleSlideContainer($event)"
      >
        <span class="idashboard-panel-spoiler-btn-text--hide">Свернуть</span>
        <span class="idashboard-panel-spoiler-btn-text--show">Развернуть</span>
      </button>
      <button
        v-if="!textBtn"
        class="idashboard-panel-spoiler-btn"
        @click="toggleSlideContainer($event)"
      >
        <svg
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.76217 6L10.5 11.564L16.2379 6L18 7.71236L10.5 15L3 7.71236L4.76217 6.00134L4.76217 6Z"
            fill="#0F0F14"
          />
        </svg>
      </button>
    </div>

    <div
      class="idashboard-panel__container"
      :style="{
        display: isMobile || isClosedPanel ? 'none' : 'grid'
      }"
    >
      <slot />
    </div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';

export default {
  name: 'IdashboardPanel',
  props: {
    iDashboardPanelCaption: String,
    textBtn: true,
    isClosedPanel: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(['isMobile'])
  },
  data() {
    return {};
  },
  methods: {
    /**
     *
     * @param {$event} e
     */
    toggleSlideContainer(e) {

      let ipHeader = e.currentTarget.parentElement;
      let ipContainer = ipHeader.nextElementSibling;

      ipHeader.classList.toggle('idashboard-panel__header--open');

      if (ipContainer.style.display == 'none') {
        ipContainer.style.display = 'grid';
        ipContainer.style.height = 'auto';

        let ipContainerMaxHeight = ipContainer.offsetHeight;
        ipContainer.style.height = '0';

        setTimeout(function () {
          ipContainer.style.height = ipContainerMaxHeight + 'px';
          setTimeout(function () {
            ipContainer.style.height = '';
          }, 100);
        }, 30);
      } else {
        ipContainer.style.height = ipContainer.offsetHeight + 'px';
        setTimeout(function () {
          ipContainer.style.height = '0';
          setTimeout(function () {
            ipContainer.style.display = 'none';
          }, 100);
        }, 100);
      }
    }
  }
};
</script>
<style lang="scss">
.idashboard-panel {
  &--contains-header {
    padding-bottom: 14px;
  }

  .idashboard-panel-spoiler-btn-text {
    margin-left: auto;
    border: none;
    background-color: transparent;
    color: #6b7a99;
    font-size: 16px;
    line-height: 1;
    outline: none;
    cursor: pointer;

    .idashboard-panel-spoiler-btn-text--hide {
      display: none;
    }

    .idashboard-panel-spoiler-btn-text--show {
      display: block;
    }
  }

  .idashboard-panel__header--open {
    .idashboard-panel-spoiler-btn {
      transform: unset;
    }
    .idashboard-panel-spoiler-btn-text {
      .idashboard-panel-spoiler-btn-text--hide {
        display: block;
      }

      .idashboard-panel-spoiler-btn-text--show {
        display: none;
      }
    }
  }

  .idashboard-panel__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 48px;
    background-color: var(--white);
    box-shadow: 0px 1px 2px rgba(106, 122, 152, 0.15);
    border-radius: 4px;
    padding: 14px;
    box-sizing: border-box;
    opacity: 1;
    overflow: hidden;
    transition: all 200ms ease;
  }
}

.idashboard-panel__header {
  display: flex;
  align-items: center;
  padding: 0 24px;
  padding-top: 24px;

  & + .idashboard-panel__container {
    padding-bottom: 0;
  }

  .idashboard-panel-spoiler-btn {
    width: 21px;
    height: 21px;
    padding: 0;
    background-color: transparent;
    margin-left: auto;
    border: none;
    outline: none;
    transition: all 200ms ease;
    transform-origin: center center;
    transform: scaleY(-1);
    cursor: pointer;

    svg {
      width: 100%;
      height: 100%;

      path {
        fill: #0f0f14;
        transition: all 200ms ease;
      }
    }

    &:hover {
      svg {
        path {
          fill: #0050cf;
        }
      }
    }
  }
}

.idashboard-panel-caption {
  font-size: 20px;
  line-height: 1.1;
  font-weight: 600;
  cursor: pointer;
  transition: all 200ms ease;
}

@media screen and (max-width: 1100px) {
  .idashboard-panel {
    .idashboard-panel__container {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 24px;
    }
  }
}

@media screen and (max-width: 640px) {
  .idashboard-panel {
    .idashboard-panel__container {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 16px;
      padding: 20px 10px;
    }
  }
}

@media screen and (max-width: 400px) {
  .idashboard-panel {
    .idashboard-panel__container {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 8px;
      padding: 24px 16px;
    }
  }
}
</style>
