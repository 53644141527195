<template>
  <label>
    <labelWithHint
      class="form__label"
      :label="label"
      :hint="hint"
      :isRequired="isRequired"
    />

    <the-mask
      class="input input--mask"
      :class="{ 'input--disabled': disabled }"
      type="text"
      :placeholder="placeholder"
      :disabled="disabled"
      :mask="mask"
      v-model="text"
    />
  </label>
</template>

<script>
import labelWithHint from '@/components/FormGenerator/common/labelWithHint';
import { TheMask } from 'vue-the-mask';
export default {
  name: 'input-for-mask',

  components: {
    labelWithHint,
    TheMask,
  },

  model: {
    prop: 'value',
    event: 'input',
  },

  props: {
    value: { type: String },
    hint: { type: String },
    isRequired: { default: false },
    placeholder: { type: String },
    label: { type: String },
    disabled: {},
    mask: {},
    valueLength: {},
    isRemoveMasked: {},
  },

  computed: {
    text: {
      get() {
        return this.value;
      },
      set(v) {
        if (!this.isRemoveMasked && v.length === this.valueLength) {
          this.$emit('input', v);
        } else {
          this.$emit('input', null);
        }
      },
    },
  },
};
</script>
