<template>
    <div class="idashboard">
        <slot></slot> 
    </div>
</template>
<script>
export default {
    name: 'Idushboard',
    data() {
        return {

        }
    }
}
</script>
<style lang="scss">
.idashboard {
    background-color: var(--white);
    box-shadow: 0px 1px 2px rgb(106 122 152 / 15%);
    border-radius: 4px;
    padding-bottom: 10px;
    .idashboard-panel__caption {
        padding: 0 24px;
    }

    .idashboard-panel {
        position: relative;
        &:not(:first-child){
          &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            width: 97%;
            height: 1px;
            background-color: #EDEFF3;
            transform: translateX(-50%);
          }  
        }
        .idashboard-panel__container {
            box-shadow: none; 
        }
    } 
}
</style>