<template>
  <div :class="{ body: true, active: menuActive}">
    <menu-wrapper
      :page-name="name"
    />
    <div class="page-wrapper">
      <main
        class="content"
        :class="pageClass"
      >
        <div class="container">
          <div class="wrapper-review-finish">
            <div class="info-review__finish">
              <!--              <div class="img&#45;&#45;finish"></div>-->
              <img
                src="@/assets/img/DPO_Logo_Main.svg"
                class="float-panel__decor-img"
              />
              <h1>Спасибо за отзыв!</h1>
              <p>
                Вы получили промокод
              </p>
              <div class="item--promo">
                <button
                  :plain="true"
                  title="Скопировать промокод"
                  class="btn-promo-code"
                  @click="copy(), openCenter()"
                >
                  {{ text }}
                </button>
                <span>Нажмите на промокод чтобы скопировать его</span>
              </div>
              <div class="review--description">
                Промокод даёт скидку в 20% на любые программы,
                вы можете воспользоваться им при <router-link to="order">оформлении заказа</router-link>,
                или подарить другу!
              </div>

              <div class="block">
                <router-link
                  to="/"
                  class="btn"
                >
                  На главную
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
    <page-footer />
  </div>
</template>

<script>
import MenuWrapper from "@/components/menuWrapper/index.vue";
import PageFooter from "@/components/PageFooter.vue";

import { mapState, mapGetters } from 'vuex';
export default {
  name: "reviewFinish",
  components: {PageFooter, MenuWrapper},
  data() {
    return{
      text: 'HSE21122023',
      pageClass: 'reviewFinish',
      name: ' '
    }
  },
  computed: {
    ...mapState(['menuActive']),
    ...mapGetters(['isPublicUser']),
  },
  methods: {
    copy() {
      try {
        navigator.clipboard.writeText(this.text)
      } catch (e) {
        throw e
      }
    },
    openCenter() {
      this.$message({
        message: 'Промокод скопирован',
        center: true,
        offset: 100,
        customClass: 'alert-show__copy',
        iconClass: 'el-icon-copy-document',
      });
    }
  }
}
</script>

<style lang="scss">
// custom style alert copy
.el-message.is-center.alert-show__copy{
  transform: translateX(calc(50% - -38px));
  left: calc(50% - 380px);
  .el-message__content{
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: var(--font-color);
    padding-left: 8px;
  }
  .el-icon-copy-document{
    padding-right: 8px !important;
  }
}

 .wrapper-review-finish{
   display: flex;
   align-items: center;
   justify-content: center;
   .info-review__finish{
     width: 776px;
     height: auto;
     background-color: #FFFFFF;
     box-shadow: 0 1px 2px rgba(106, 122, 152, 0.15);
     padding: 48px 101px;
     display: flex;
     align-items: center;
     justify-content: center;
     flex-direction: column;
     transition: width 0.2s ease-in-out;
     border-radius: 4px;
     .img--finish{
       width: 216px;
       height: 200px;
       background: url("../../assets/icons/icon-finish.svg") 100% 100% no-repeat;
       margin-bottom: 24px;
     }
     h1{
       font-size: 32px;
       line-height: 40px;
       text-align: center;
       margin-bottom: 24px;
     }
     p{
       font-weight: 400;
       font-size: 18px;
       line-height: 24px;
       margin-bottom: 24px;
     }
     .item--promo{
       display: flex;
       flex-direction: column;
       .btn-promo-code{
         border: none;
         background-color: transparent;
         font-weight: 600;
         font-size: 42px;
         line-height: 44px;
         color: var(--dark-blue);
         cursor: pointer;
         padding: 0;
         text-transform: uppercase;
       }
       span{
         font-weight: 400;
         font-size: 12px;
         line-height: 16px;
         color: var(--font-color-alt);
         margin-bottom: 24px;
         text-align: center;
       }
     }
     .review--description{
       font-weight: 400;
       font-size: 16px;
       line-height: 20px;
       color: var(--font-color);
       text-align: center;
       a{
         font-weight: 400;
         font-size: 16px;
         line-height: 20px;
       }
     }
     .block{
       margin-top: 24px;
       .btn.router-link-active{
         padding: 14px 47px;
       }
     }


   }
 }

@media (max-width: 1440px) {
  .wrapper-review-finish  {
    .info-review__finish {
      width: 635px;
      padding: 48px 95px;
    }
  }
}

@media (max-width: 1024px) {
  .wrapper-review-finish{
    .info-review__finish{
      width: 448px;
      padding: 48px;
      .img--finish{
        width: 162px;
        height: 150px;
      }
      h1{
        font-size: 24px;
        line-height: 32px;
      }
      .item--promo{
        .btn-promo-code{
          font-size: 32px;
          line-height: 40px;
        }
      }

    }
  }


  .el-message.is-center.alert-show__copy{
    min-width: 250px;
    padding: 12px 15px;
    transform: translateX(calc(50% + 30px));
    left: calc(50% - 250px);
  }
}

@media (max-width: 768px) {
  .wrapper-review-finish {
    .info-review__finish {
      .review--description{
        a{
          font-size: 14px;
          line-height: 20px;
        }
      }
      .img--finish{
        width: 130px;
        height: 120px;
        margin-bottom: 16px;
      }
      h1{
        margin-bottom: 16px;
      }
      p{
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 8px;
      }
      .item--promo{
        span{
          margin-bottom: 8px;
        }
      }
      .review--description{
        font-size: 14px;
        line-height: 20px;
      }
      .block{
        margin-top: 16px;
      }
    }
  }



  .el-message.is-center.alert-show__copy{
    min-width: 250px;
    padding: 12px 15px;
    transform: translateX(calc(50% + 30px));
    left: calc(50% - 250px);
  }
}



@media (max-width: 767px) {
  .el-message.is-center.alert-show__copy{
    min-width: 250px;
    padding: 12px 15px;
    transform: translateX(50%);
    left: calc(50% - 250px);
  }
}

@media (max-width: 530px) {
  .wrapper-review-finish{
    .info-review__finish{
      padding: 24px;
    }
  }
}







</style>
