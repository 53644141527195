<template>
  <div
    class="schedule-today-classes"
    :data-id="id"
  >
    <div class="schedule-today-classes__time-col">
      <span class="schedule-today-classes__beginning">
        {{ eventBegin }}
      </span>
      —
      <span class="schedule-today-classes__end">
        {{ eventEnd }}
      </span>
    </div>
    <div class="schedule-today-classes__info-col">
      <div class="schedule-today-classes__cell schedule-today-classes-cell schedule-today-classes__cell--head">
        <span
          v-if="type"
          class="schedule-today-classes-cell__type"
          :class="addTypeOfEventClass(typeOfEvent)"
        >{{ type }}</span>

        <span class="schedule-today-classes-cell__caption-classes">{{ caption }}</span>

        <span class="schedule-today-classes-cell__direction">{{ direction }}</span>
      </div>
      <div
        v-if="venue || venueLink"
        class="schedule-today-classes__cell schedule-today-classes-cell schedule-today-classes__cell--venue"
      >
        <span class="schedule-today-classes-cell__caption">
          Место проведения
        </span>
        <a
          v-if="venueLink"
          :href="venueLink"
        >Ссылка на событие</a>
        <span v-else>{{ venue }}</span>
      </div>
      <div
        v-if="auditorium || venueLink"
        class="schedule-today-classes__cell schedule-today-classes-cell schedule-today-classes__cell--auditorium"
      >
        <span class="schedule-today-classes-cell__caption">
          Аудитория
        </span>
        <div>
<!--          <a
            v-if="venueLink"
            :href="venueLink"
          >online</a>
          <span v-else>{{ auditorium }}</span>-->
          <span>{{ auditorium }}</span>
        </div>
      </div>
      <div class="schedule-today-classes__cell schedule-today-classes-cell schedule-today-classes__cell--auditorium">
        <span class="schedule-today-classes-cell__caption">
          Преподаватель
        </span>
        <span>{{ glueVariables }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
    name: 'ScheduleTodayClasses',
    props: {
        id: Number,
        type: String,
        typeOfEvent: String,
        eventBegin: String,
        eventEnd: String,
        caption: String,
        direction: String,
        venue: String,
        venueLink: String,
        auditorium: String,
        auditoriumLink: String,
        teacherRang: String,
        teacherName: String
    },
    data() {
        return {

        }
    },
    computed: {
        glueVariables() {
            return this.teacherRang + ' ' + this.teacherName;
        },
    },
    methods:{
        addTypeOfEventClass(toe){
            let typeOfEventClass = '';
            /*
            hee
            События высшего образования
            Higher Education events

            bdpotp
            Начало учебной программы ДПО
            Beginning of the DPO training program

            public-e
            Общественные события
            Public events

            pdo-e
            События ДПО
            DPO events

            past-e
            Прошедшие события*/
            switch(toe){
                case 'he': // События высшего образования
                    typeOfEventClass = 'schedule-today-classes-cell__type--hee';
                    break;
                case 'dpos': // Начало учебной программы ДПО
                    typeOfEventClass = 'schedule-today-classes-cell__type--bdpotp';
                    break;
                case 'pub': // Общественные события
                    typeOfEventClass = 'schedule-today-classes-cell__type--public-e';
                    break;
                case 'dpo': // События ДПО
                    typeOfEventClass = 'schedule-today-classes-cell__type--pdo-e';
                    break;
                default: typeOfEventClass = '';
            }
            return typeOfEventClass;
        }
    }
}
</script>
<style lang="scss">
.schedule-today-classes {
    display: flex;
    border-top: 1px solid #EDEFF3;
    padding: 16px 0;
    font-size: 16px;
    line-height: 1.33;
    box-sizing: border-box;

    .schedule-today-classes__time-col {
        width: 14%;
        padding-right: 24px;
        padding-top: 4px;
        padding-bottom: 4px;
        box-sizing: border-box;

        .schedule-today-classes__beginning {
            display: block;
            margin-bottom: 6px;
        }

        .schedule-today-classes__end {
            display: block;
            margin-top: 6px;
            color: #6B7A99;
        }

        .schedule-today-classes__cell {
            padding: 0 24px;
            border-left: 1px solid #EDEFF3;
        }
    }

    .schedule-today-classes__info-col {
        display: flex;
        flex-grow: 1;

        .schedule-today-classes__cell {
            border-left: 1px solid #EDEFF3;
        }

        .schedule-today-classes__cell--head {
            width: 44.22%;
        }

        .schedule-today-classes__cell--venue {
            width: 20.31%;
        }

        .schedule-today-classes__cell--auditorium {
            width: 13.07%;
        }

        .schedule-today-classes__cell--auditorium {
            width: 20.6%
        }

    }
    &--hide{
        display: none;
    }
}

.schedule-today-classes-cell {
    padding: 4px 24px;


    .schedule-today-classes-cell__type {
        display: inline-block;
        padding: 6px 10px;
        border-radius: 4px;
        background-color: #F0F5FF;
        margin-bottom: 5px;
        color: #0050CF;
        font-weight: 600;
        font-size: 12px;
        line-height: 1;
        &--hee{
            background-color: #F0F5FF;
            color: #0050CF;
        }
        &--bdpotp{
            background-color: #fcdcd4;
            color: #E03A15;
        }
        &--public-e{
            background-color: #dbf9ed;
            color: #00B86E;
        }
        &--pdo-e{
            background-color: #FEF8EC;
            color: #E9A21D;
        }
        // &--past-e{background-color: #D3D8E5;}
    }

    .schedule-today-classes-cell__caption-classes {
        display: block;
        margin-bottom: 5px;
        font-size: 18px;
        line-height: 1.33;
        font-weight: 600;
    }

    .schedule-today-classes-cell__direction {
        display: block;
        color: #6B7A99;
    }

    .schedule-today-classes-cell__caption {
        display: block;
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 1.33;
        color: #6B7A99;
    }

    a {
        display: inline-block;
        color: #0050CF;
        box-shadow: 0 1px 0 0 rgba(0, 79, 207, 0);
        transition: var(--great-transition);
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;

        &:hover {
            box-shadow: 0 1px 0 0 rgba(0, 79, 207, 1);
        }
    }
}

@media screen and (max-width: 999px) {
    .schedule-today-classes-cell {
        .schedule-today-classes-cell__caption-classes {
            font-size: 16px;
        }
    }

    .schedule-today-classes {
        display: flex;
        border-top: 1px solid #EDEFF3;
        padding: 16px 0;
        font-size: 14px;
    }
}

@media screen and (max-width: 870px) {
    .schedule-today-classes-cell {
        .schedule-today-classes-cell__type {
            margin-bottom: 7px;
        }
    }

    .schedule-today-classes {
        .schedule-today-classes__time-col {
            width: auto;
            padding-right: 24px;
        }

        .schedule-today-classes__info-col {
            flex-direction: column;

            .schedule-today-classes__cell {
                &:not(:last-child) {
                    margin-bottom: 12px;
                }

                &--head {
                    width: 100%;
                }

                &--venue {
                    width: 100%;
                }

                &--auditorium {
                    width: 100%;
                }

                &--auditorium {
                    width: 100%;
                }
            }
        }
    }

}

@media screen and (max-width: 470px) {
    .schedule-today-classes {
        .schedule-today-classes__time-col {
            width: auto;
            padding-right: 16px;
        }
    }
}
</style>
