import stateStore from './state';
import gettersStore from './getters';
import mutationsStore from './mutations';
import actionsStore from './actions';

export default {
  namespaced: true,
  state: stateStore,
  getters: gettersStore,
  mutations: mutationsStore,
  actions: actionsStore
}