<template>
  <div
    class="psevdoselect"
    :class="{psevdoselect__open: isOpen}"
  >
    <button
      class="psevdoselect__btn"
      data-value="psParam.value"
      @click="openPsevdoselect"
      v-html="psParam.label"
    />
    <input
      class="psevdoselect__input"
      type="text"
      :value="psParam.value"
      @input="inputEvent($event)"
    />
    <ul class="psevdoselect-menu">
      <li
        v-for="option in arOptions"
        :key="option.value"
        class="psevdoselect-menu__item"
        :data-value="option.value"
        @click="selectItem(option.value, option.label, $event)"
        v-html="option.label"
      />
    </ul>
  </div>
</template>

<script>
import {filterBus} from '@/main';

export default {
  name: 'Psevdoselect',
  props: {
    arOptions: {
      type: Array
    },
    // psevdoSelectLabel: {
    //   type: String,
    //   default: 'По умолчанию'
    // },
    defaultValue: {
      type: Object,
      default() {
        return {
          value: 'default',
          label: 'По умолчанию'
        };
      }
    }
  },

  data() {
    return {
      isOpen: false,
      psevdoSelectValue: '',
      psevdoSelectLabel: '',
      psParam: {
        value: '',
        label: ''
      }
    };
  },

  mounted() {
    // this.psevdoSelectValue = this.defaultValue.value;
    // this.psevdoSelectLabel = this.defaultValue.label;
    this.psParam.value = this.defaultValue.value;
    this.psParam.label = this.defaultValue.label;
    //закрытие псевдоселекта при клике вне него
    document.addEventListener('click', e => {
      if (!e.target.closest('.psevdoselect')) {
        this.isOpen = false;
      }
    });
    filterBus.$on('default', () => {
      // this.psParam.value = this.psevdoSelectValue;
      // this.psParam.label = this.psevdoSelectLabel;
      this.psParam.value = this.defaultValue.value;
      this.psParam.label = this.defaultValue.label;
    });
  },

  methods: {
    openPsevdoselect() {
      this.isOpen = !this.isOpen;
    },
    selectingAnItem(e) {
      console.log(event.currentTarget);
    },
    selectItem(dataValue, dataLabel, event) {
      // console.log(event.currentTarget)
      // console.log(dataValue)
      this.psParam.value = dataValue;
      // this.value = dataValue;
      this.psParam.label = dataLabel;
      this.$emit('input', [this.psParam.value, this.psParam.label]);

      let psevdoselecItems = event.target.closest('.psevdoselect-menu');
      let selectedItem = psevdoselecItems.querySelector('.selected');
      if (selectedItem) {
        selectedItem.classList.remove('selected');
      }
      event.target.classList.add('selected');
      this.isOpen = false;
    },
    inputEvent(e) {
      console.log(e.target.value);
      this.$emit('input', [this.psParam.value, this.psParam.label]);
    }
  }
};
</script>
<style lang="scss">
.psevdoselect {
  position: relative;

  .psevdoselect__btn {
    position: relative;
    display: inline-block;
    background-color: transparent;
    margin: 0;
    padding: 0;
    padding-right: 22px;
    font-size: 18px;
    line-height: 1.33;
    border: none;
    outline: none;
    cursor: pointer;

    svg {
      position: relative;
      top: 3px;
      transition: all 200ms ease;
    }

    &::after {
      content: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.41 0.294922L6 4.87492L10.59 0.294922L12 1.70492L6 7.70492L0 1.70492L1.41 0.294922Z' fill='%230050CF'/%3E%3C/svg%3E%0A");
      position: absolute;
      top: 50%;
      right: 0;
      width: 12px;
      height: 8px;
      font-size: 0;
      line-height: 0;
      transform: translateY(-50%) scale(1);
      transition: all 200ms ease-in-out;
    }
  }

  .psevdoselect__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
  }

  .psevdoselect-menu {
    position: absolute;
    top: 101%;
    right: 0;
    width: 210px;
    padding: 10px;
    background-color: #ffffff;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: 0 0 10px -5px #000000;
    transition: all 200ms ease-in-out;
    transform: scaleY(0);
    opacity: 0;
    transform-origin: top center;
    z-index: 10;

    .psevdoselect-menu__item {
      padding: 10px 12px;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.2;
      cursor: pointer;
      transition: all 200ms ease;
      border-radius: 4px;
      background-color: rgba(237, 239, 243, 0);

      &:not(:last-child) {
        margin-bottom: 4px;
      }

      svg {
        position: relative;
        top: 3px;
        transition: all 200ms ease;
      }

      &:hover {
        background-color: rgba(237, 239, 243, 1);
      }

      &.selected {
        background-color: #0060cf;
        color: #ffffff;

        svg {
          path {
            stroke: #ffffff;
          }
        }
      }
    }
  }

  &.psevdoselect__open {
    .psevdoselect__btn {
      &::after {
        transform: translateY(-50%) scale(-1);
      }
    }

    .psevdoselect-menu {
      transform: scaleY(1);
      opacity: 1;
    }
  }
}

@media screen and (max-width: 1100px) {
  .psevdoselect {
    .psevdoselect__btn {
      font-size: 16px;
    }
  }
}

@media screen and (max-width: 530px) {
  .psevdoselect {
    .psevdoselect__btn {
      padding-right: 14px;
      font-size: 14px;

      &::after {
        content: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.41 0.294922L6 4.87492L10.59 0.294922L12 1.70492L6 7.70492L0 1.70492L1.41 0.294922Z' fill='%230F0F14'/%3E%3C/svg%3E%0A");
        width: 10px;
        height: 6px;
      }
    }

    .psevdoselect-menu {
      top: 110%;
      right: auto;
      left: -50px;

      .psevdoselect-menu__item {
        margin-bottom: 10px;
      }
    }
  }
}
</style>
