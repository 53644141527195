import i18n from '@/i18n';
import { getUiItemCaption, getCommonCaptionByName } from '@/i18n/utils';

export default (signature) => {
  return {
    widgetCaption(name, value) {
      const captionName = getUiItemCaption(signature, name);
      return i18n.t(captionName, value);
    },
    commonCaption(name, value) {
      const captionName = getCommonCaptionByName(name);
      return i18n.t(captionName, value);
    },
    dateCaption(date, value) {
      const captionDate = new Date(date);
      return i18n.d(captionDate, value);
    },
  };
};
