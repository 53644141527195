<template>
  <loader
    class="loader loader--default-color"
    :class="{'loader--small': small, 'loader--default-color': !color}"
    :style="{'fill': colors[color]}"
  />
</template>

<script>
import loader from '@/assets/icons/loader.svg?inline';

export default {
  name: 'ElkLoading',
  components: {
    loader,
  },
  props: {
    small: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      colors: {
        'white': '#FFFFFF'
      }
    }
  }
};
</script>
