<template>
  <div class="input-profile-contact input-profile-contact--no-wrap">
    <el-select
      ref="gender_select"
      v-model="chosenGender"
      @change="setGender"
      filterable
      placeholder=""
      :disabled="isLoading"
      id="gender"
      value-key="id"
      class="input-profile-contact__value"
    >
      <el-option
        v-for="gender in genders"
        :key="gender.id"
        :label="gender.name"
        :value="gender"
      >
      </el-option>
    </el-select>
    <loading v-if="isLoading" class="loader loader--input" />
    <div v-else class="input-profile-contact__btn">
      <button
        @click="setGenderSettingClose"
        class="btn btn--secondary btn--icon"
      >
        {{ $t(getCommonCaptionByName('cancel')) }}
      </button>
    </div>
  </div>
</template>

<script>
import { getGenders as getGendersApi, setGender as setGenderApi } from './api';
import { getCommonCaptionByName, getUiItemCaption } from '@/i18n/utils';
import Loading from '@/components/common/elkLoading';

import { isArrayNotEmpty } from '@/utils/types';
import setNotification from '@/utils/setNotification';

export default {
  name: 'elk-profile-info-select-gender',
  inject: ['signature'],
  components: {
    Loading,
  },
  props: {
    gender: Object,
  },
  data() {
    return {
      genders: null,
      isLoading: false,
      chosenGender: null,
    };
  },
  methods: {
    getCommonCaptionByName,
    async getGenders() {
      this.isLoading = true;
      try {
        const genderList = await getGendersApi();
        if (isArrayNotEmpty(genderList)) {
          this.genders = genderList;
          this.$nextTick(() => this.$refs.gender_select.focus());
        } else {
          //sentry
          throw new Error();
        }
      } catch (e) {
        this.setGenderSettingClose();
        setNotification({
          message: this.$t(getCommonCaptionByName('error_fetchingGenders')),
        });
      } finally {
        this.isLoading = false;
      }
    },
    async setGender(gender) {
      this.isLoading = true;
      try {
        await setGenderApi(gender.id);
        this.$emit('set-gender', gender);
      } catch (e) {
        this.$emit('failed-setting-gender');
        setNotification({
          message: this.$t(getUiItemCaption(this.signature, 'setGenderError')),
        });
      } finally {
        this.isLoading = false;
      }
    },
    setGenderSettingClose() {
      this.$emit('failed-setting-gender');
    },
  },
  created() {
    this.chosenGender = this.gender;
    this.getGenders();
  },
};
</script>

<style></style>
