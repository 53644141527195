<template>
  <error-page-public
    v-if="isPublicUser || !isCanOrder"
    :is-lock-order="!isPublicUser && !isCanOrder"
  />
  <div
    v-else
    :class="{body: true, active: menuActive}"
  >
    <menu-wrapper :page-name="name" />
    <div class="page-wrapper">
      <main
        class="content"
        :class="pageClass"
      >
        <Loading v-if="isLoading || isLoadUser" />
        <div
          v-else-if="cartItem"
          class="container"
        >
          <new-order-form :cart-item="cartItem" />
        </div>
        <empty-data-message
          v-else
          text="Нет подходящей программы "
        />
      </main>
    </div>
    <page-footer />
  </div>
</template>

<script>
import Loading from '@/components/common/elkLoading';
import EmptyDataMessage from '@/components/common/elkEmptyDataMessage';
import menuWrapper from '@/components/menuWrapper';
import PageFooter from '@/components/PageFooter';

import {mapState, mapGetters} from 'vuex';
import NewOrderForm from '@/components/formOrder/newOrderForm.vue';
import ErrorPagePublic from '../404/index.vue';
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Order',
  components: {
    ErrorPagePublic,
    Loading,
    NewOrderForm,
    PageFooter,
    menuWrapper,
    EmptyDataMessage
  },
  data() {
    return {
      name: 'Заказ',
      pageClass: 'order'
    };
  },
  computed: {
    ...mapState(['menuActive']),
    ...mapGetters(['isPublicUser', 'isCanOrder', 'isAuth', 'isLoadUser']),
    programId() {
      const id = Number.parseInt(this.$route.params['id']);
      return isNaN(id) ? null : id;
    },
    isLoading() {
      return this.$store.getters['cart/isLoading'] ?? false;
    },
    cartItem() {
      return this.programId
        ? this.$store.getters['cart/cartItem'](this.programId)
        : null;
    }
  }
};
</script>

<style lang="scss"></style>
