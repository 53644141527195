<template>
  <div
    class="selected-container"
    tabindex="0"
    @click="$emit('on-click')"
  >
    <span class="selected-container__text">
      {{ text }}
    </span>
    <div class="selected-container__actions">
      <slot name="actions" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'SelectedContainer',
  props: {
    text: {
      type: String,
      default: ''
    }
  }
};
</script>

<style scoped>
.selected-container {
  height: 48px;
  padding-right: 36px;
  background-color: #ffffff;
  border: 1px solid #d3d8e6;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none;
  position: relative;
}
.selected-container:hover,
.selected-container:focus {
  border-color: #0050cf;
}
.selected-container__text {
  font-size: 16px;
  color: #6b7a99;
  max-width: calc(100% - 36px);
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  padding-left: 15px;
  left: 0px;
  top: 30%;
  white-space: nowrap;
}
</style>
