<template>
  <div class="quan-item-wr">
    <div
      v-if="qgiTags"
      :data-id="qgiId"
      class="quan-grid-item"
    >
      <span class="quan-grid-item__caption">
        {{ qgiCaption }}
      </span>
      <ul class="quan-grid-item__tags">
        <li
          v-for="tag in qgiTags"
          :key="tag"
        >
          <span @click="$emit('selectTag', tag)">#{{ tag }}</span>
        </li>
      </ul>
      <span class="quan-grid-item__text" v-html="qgiText">
      </span>
    </div>

    <a
      v-else
      :href="qgiLink"
      :data-id="qgiId"
      class="quan-grid-item"
    >
      <span class="quan-grid-item__caption">
        {{ qgiCaption }}
      </span>
      <span class="quan-grid-item__text" v-html="qgiText">

      </span>
    </a>
  </div>
</template>

<script>
export default{
    name: 'QuanGridItem',
    props:{
        qgiId: Number,
        qgiCaption: String,
        qgiTags: Array,
        qgiText: String,
        qgiLink: String,
    },
    data(){
        return{

        }
    }
}
</script>

<style lang="scss">


    .quan-item-wr{
        background-color: #ffffff;
        box-shadow: 0px 1px 2px 0 rgba(106, 122, 152, 0.15);
    }

    a.quan-grid-item{
        .quan-grid-item__caption{
            margin-bottom: 24px;
        }
    }

    div.quan-grid-item{
        .quan-grid-item__caption{
            margin-bottom: 14px;
        }
        .quan-grid-item__text{
            padding-top: 8px;
        }
        .quan-grid-item__text{
            color: #0F0F14;
        }
    }

    .quan-grid-item{
        display: block;
        height: 100%;
        max-width: 1000px;
        background-color: #ffffff;
        padding: 24px;

        border-radius: 4px;
        box-sizing: border-box;
        .quan-grid-item__caption{
            display: block;

            font-size: 18px;
            line-height: 1.33;
            font-weight: 600;
            color: #0F0F14;
        }
        .quan-grid-item__text{
            display: block;
            color: #6B7A99;
            font-weight: 400;
            font-size: 14px;
            line-height: 1.4;
        }
        .quan-grid-item__tags{
            display: flex;
            flex-wrap: wrap;
            li{
                margin-right: 8px;
                margin-bottom: 8px;
                a, span{
                    position: relative;
                    display: block;
                    padding: 6px 10px;
                    box-sizing: border-box;
                    background-color: #F0F5FF;
                    border-radius: 4px;
                    color: #0050CF;
                    font-size: 12px;
                    line-height: 1.33;
                    cursor: pointer;
                }
            }
        }
        &:hover{
            text-decoration: none;
            box-shadow: 0px 0 0 0 rgba(106, 122, 152, 0.0);
        }
    }
    @media screen and (max-width: 400px){
        .quan-grid-item{
            padding: 16px;
        }
    }
</style>
